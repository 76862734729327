import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { KeycloakUserModel } from '../../../../model/keycloak-user.model';
import { first, Subscription } from 'rxjs';
import { KamUserService } from '../../../../services/kam-user.services';
import { KeycloakGroupModel } from '../../../../model/keycloak-group.model';
import { KeycloakRoleModel } from '../../../../model/keycloak-role.model';
import { v4 as uuidv4 } from 'uuid';
import { KamUserFilter } from '../../../../services/kam-user.filter';

@Component({
  selector: 'keycloak-auth-manager-kam-nu-step5',
  templateUrl: './kam-nu-step5.component.html',
  styleUrls: ['./kam-nu-step5.component.css']
})
export class KamNuStep5Component implements OnInit, OnDestroy {

  userSubscription: Subscription | undefined;
  user: KeycloakUserModel | undefined;
  groupsSubscription: Subscription|undefined;
  groups: KeycloakGroupModel[] | undefined;
  rolesSubscription: Subscription|undefined;
  roles: KeycloakRoleModel[] | undefined;
  userAttributesSubscription: Subscription | undefined;
  userDataAttributesMap: Map<string, string[]> | undefined;
  
  @Output() completed = new EventEmitter<boolean>();

  constructor(private kamUserService: KamUserService,) { }

  ngOnInit(): void {
    this.userSubscription = this.kamUserService.userDataSubscription()?.subscribe(user=> {
      this.user = user;
    })

    this.userAttributesSubscription = this.kamUserService.userDataCustomAttributesSubscription()?.subscribe(userDataAttributesMap=> {
      this.userDataAttributesMap = userDataAttributesMap;
    })

    this.groupsSubscription = this.kamUserService.userGroupSubscription()?.subscribe(groups=> {
      this.groups = groups;
    })

    this.rolesSubscription = this.kamUserService.userRolesSubscription()?.subscribe(roles=> {
      this.roles = roles;
    })
  }

  step5End(){
    if (!this.user) return;
    let kUser: KeycloakUserModel = {...this.user}
    kUser.id = uuidv4()

    //transform attibutes to json
    if (this.userDataAttributesMap){
      let jsonObject:any = {};
      this.userDataAttributesMap.forEach((value, key) => {
        jsonObject[key] = value;
      });      
      kUser.attributes = jsonObject;
    }
    
    let kUserGroups: string[] = []
    this.groups?.forEach(group=>{
      kUserGroups.push("/"+group.name)
    })
    kUser.groups = kUserGroups
    console.log(kUser)
    this.kamUserService.postUser(kUser).subscribe({
      next: (value)  => {
        //udpate roles
        if (this.roles){
          const userFilter: KamUserFilter ={
            username: kUser.username,
            exact: true
          }
          this.kamUserService.getUsers(userFilter).subscribe({
            next: (newUser)  => {
              if (this.roles)          
                this.kamUserService.postUserRoles(newUser[0].id,this.roles).subscribe({
                  next: (response)  => {},
                  error:(e) =>{
                    console.error(e)
                    this.completed.emit(false)
                  },
                  complete:()=>{
                    console.log("completed roles")
                    this.completed.emit(true)
                  },
                })
            }          
          })
        }
      },
      error:(e) =>{
        console.error(e)
        this.completed.emit(false)
      },
      complete:()=>{
        console.log("completed")
        this.completed.emit(true)
      },
    })
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.userAttributesSubscription?.unsubscribe();
    this.groupsSubscription?.unsubscribe();
    this.rolesSubscription?.unsubscribe();
  }
}
