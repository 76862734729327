export * from './helloWorldController.service';
import { HelloWorldControllerService } from './helloWorldController.service';
export * from './solicitudReutilizacionController.service';
import { SolicitudReutilizacionControllerService } from './solicitudReutilizacionController.service';
export * from './solicitudReutilizacionEstadoController.service';
import { SolicitudReutilizacionEstadoControllerService } from './solicitudReutilizacionEstadoController.service';
export * from './subTipoEnvaseController.service';
import { SubTipoEnvaseControllerService } from './subTipoEnvaseController.service';
export * from './tarifaGestorSddrcaController.service';
import { TarifaGestorSddrcaControllerService } from './tarifaGestorSddrcaController.service';
export const APIS = [HelloWorldControllerService, SolicitudReutilizacionControllerService, SolicitudReutilizacionEstadoControllerService, SubTipoEnvaseControllerService, TarifaGestorSddrcaControllerService];
