import { Injectable } from "@angular/core";
import { KeycloakUserModel } from "../keycloak-user.model";
import { Adapter } from "./keycloak.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakUserModelAdapter implements Adapter<KeycloakUserModel> {

    constructor() { }
    adapt(item: any): KeycloakUserModel {
        let model:KeycloakUserModel = {
            id: item.id,
            username: item.username,
        };
        if (item.email)
            model.email=item.email
        if (item.emailVerified)
            model.emailVerified=item.emailVerified
        if (item.enabled)
            model.enabled=item.enabled
        if (item.firstName)
            model.firstName=item.firstName
        if (item.groups)
            model.groups=item.groups
        if (item.lastName)
            model.lastName=item.lastName
        if (item.attributes){
            let attributesMap: Map<string, string[]> = new Map<string, string[]>();
            Object.keys(item.attributes).forEach(attributeId=>{                
                attributesMap.set(attributeId, item.attributes[attributeId])
            })
            if (Object.keys(item.attributes).length>0){
                model.attributesMap=attributesMap
            }
        }
        return model;
    }
}