import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { Router } from '@angular/router';
import { GestorPtoRecogidaControllerService } from 'api-rest';

@Component({
  selector: 'app-collection-points-select-manager-down',
  templateUrl: './collection-points-select-manager-down.component.html',
  styleUrls: ['./collection-points-select-manager-down.component.scss'],
})
export class CollectionPointsSelectManagerDownComponent implements OnInit {
  formGroup!: FormGroup;
  gestorDetalle: any;
  gestorArray: any[] = [];

  ptoEndpoint =
    this.environment.urlBackCore + 'api/v1/puntos-recogida/gestorptorecogida?estado.id=3';

  constructor(
    public dialogRef: MatDialogRef<CollectionPointsSelectManagerDownComponent>,
    @Inject('environment') private environment: any,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService | any,
    private formBuilder: FormBuilder,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private router: Router,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService
  ) {
    this.formGroup = this.formBuilder.group({
      gestorForm: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
    this.gestorDetalle = this.data.gestorDetalle;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}

  getLerDescription(gestor: any) {
    return gestor?.razonSocial + ' - ' + gestor?.denominacion;
  }

  getOperacionDescription(gestor: any) {
    return gestor?.razonSocial;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'gestorForm': {
        return this.formGroup.get('gestorForm') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  baja() {
    const gestorSeleccionado = this.formGroup.get('gestorForm')?.value;
    this.spinnerSrv.loadingShow();
    this.gestorPtoRecogidaControllerService
      .bajaGestorPtoRecogida(this.gestorDetalle?.id)
      .subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha dado de baja el gestor punto recogida correctamente',
            'success'
          );
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          console.log(err);
        },
      });
  }

  bajaGestion() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      const gestorSeleccionado = this.formGroup.get('gestorForm')?.value;
      this.spinnerSrv.loadingShow();
      this.gestorPtoRecogidaControllerService
        .bajaCambiarGestorPtoRecogida(
          this.gestorDetalle?.id,
          gestorSeleccionado
        )
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Se ha dado de baja gestión el gestor punto recogida correctamente',
              'success'
            );
            this.dialogRef.close(true);
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Seleccionar el gestor de puntos recogida',
        'error'
      );
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }
}
