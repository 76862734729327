import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KamUserService } from '../../../services/kam-user.services';
import { KeycloakRoleModel } from '../../../model/keycloak-role.model';
import { KamUserRolesData } from './kam-user-roles-data.interface';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'keycloak-auth-manager-kam-user-roles',
  templateUrl: './kam-user-roles.component.html',
  styleUrls: ['./kam-user-roles.component.css']
})
export class KamUserRolesComponent implements OnInit {

  initialRolesSelected: string[]|undefined;
  roleSelection: KeycloakRoleModel[]=[];
  rolesUnselected: KeycloakRoleModel[]=[];
  

  constructor(public dialogRef: MatDialogRef<KamUserRolesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KamUserRolesData,
    private kamUserService: KamUserService) {
      this.initialRolesSelected = data.roles
    }

  ngOnInit(): void {
  }

  setRoleSelection($event: any){
    this.roleSelection = $event
  }

  setRolesUnselected($event: any){
    this.rolesUnselected = $event
  }

  cancel(){      
    this.dialogRef.close(undefined);
  }
  
  updateRoles(){
    let rolesToUpdateAndDelete: Observable<any>[]=[];
    if (this.roleSelection.length>0){
      rolesToUpdateAndDelete.push(this.kamUserService.postUserRoles(this.data.user.id,this.roleSelection))
    }
    if (this.rolesUnselected.length>0){
      rolesToUpdateAndDelete.push(this.kamUserService.deleteUserRoles(this.data.user.id,this.rolesUnselected))
    }
    
    forkJoin(rolesToUpdateAndDelete).subscribe({
      next: (response)  => {},
      error:(e) =>{
        console.error(e)
        this.dialogRef.close(false);
      },
      complete:()=>{
        this.dialogRef.close(true);
      },
    })

  }
}
