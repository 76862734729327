<empusa-app-header-classic [data]="headerData"></empusa-app-header-classic>
<div class="empusa-microapp-fondo">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="{{'KEYCLOAK-AUTH-MANAGER-MAIN.USERS' | translate}}"> 
            <keycloak-auth-manager-kam-users-tab></keycloak-auth-manager-kam-users-tab>
        </mat-tab>
        <mat-tab label="{{'KEYCLOAK-AUTH-MANAGER-MAIN.ROLES' | translate}}"> 
            <keycloak-auth-manager-kam-roles-tab></keycloak-auth-manager-kam-roles-tab>
        </mat-tab>
        <mat-tab label="{{'KEYCLOAK-AUTH-MANAGER-MAIN.GROUPS' | translate}}"> 
            <keycloak-auth-manager-kam-groups-tab></keycloak-auth-manager-kam-groups-tab>
        </mat-tab>
        <mat-tab label="{{'KEYCLOAK-AUTH-MANAGER-MAIN.PERMISSIONS' | translate}}">
            <keycloak-auth-manager-kam-permissions-tab></keycloak-auth-manager-kam-permissions-tab>
        </mat-tab>
    </mat-tab-group>
</div>
