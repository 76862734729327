import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KamUserService } from '../../../services/kam-user.services';
import { KeycloakUserCredentials, KeycloakUserModel } from '../../../model/keycloak-user.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'keycloak-auth-manager-kam-manage-user',
  templateUrl: './kam-manage-user.component.html',
  styleUrls: ['./kam-manage-user.component.css']
})
export class KamManageUserComponent implements OnInit {

  formGroup: FormGroup | undefined;
  emailCtrl = new FormControl('', [Validators.email]);
  userNameCtrl = new FormControl({value: '', disabled: false}, [Validators.required]);
  firstNameCtrl = new FormControl('');
  lastNameCtrl = new FormControl('');
  enabledCtrl = new FormControl(true);
  setPasswordCtrl = new FormControl(false);
  newPasswordCtrl = new FormControl('');
  repeatPasswordCtrl = new FormControl('');
  update: boolean = false;
  showPass: boolean = false;


  constructor(public dialogRef: MatDialogRef<KamManageUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KamManageUserData,
    private kamUserService: KamUserService,
    ) { }

    ngOnInit(): void {
      if (this.data.user)
        this.update=true;
      this.buildForm();
    }
  
    private buildForm() {
      if (this.update){
        this.emailCtrl.setValue(this.data.user.email?this.data.user.email:null);
        this.userNameCtrl.setValue(this.data.user.username);
        this.userNameCtrl.disable(),
        this.firstNameCtrl.setValue(this.data.user.firstName?this.data.user.firstName:null);
        this.lastNameCtrl.setValue(this.data.user.lastName?this.data.user.lastName:null);
        this.enabledCtrl.setValue(this.data.user.enabled?this.data.user.enabled:false);
      }
      this.formGroup = new FormGroup({
        userEmail: this.emailCtrl,
        userName: this.userNameCtrl,
        firstName: this.firstNameCtrl,
        lastName: this.lastNameCtrl,
        enabled:this.enabledCtrl,
        setPassword: this.setPasswordCtrl,
        newPassword: this.newPasswordCtrl,
        repeatPasswordCtrl: this.repeatPasswordCtrl
      });    
    }

  passwordConfirming(c: AbstractControl): { passwordNoMatch: boolean }|null {    
    if (c.get('newPassword')?.value !== c.get('repeatPasswordCtrl')?.value) {
      return {passwordNoMatch: true};
    }
    return null;
  }


  setPasswordCtrlChange(){
    if (this.setPasswordCtrl.value){      
      this.repeatPasswordCtrl.setValidators([Validators.required])     
      this.newPasswordCtrl.setValidators([Validators.required])
      this.formGroup?.setValidators(this.passwordConfirming)
      this.evaluateForm();
      this.showPass = true;
    }else{
      this.repeatPasswordCtrl.clearValidators()
      this.newPasswordCtrl.clearValidators()
      this.formGroup?.clearValidators()
      this.evaluateForm();
      this.showPass = false;
    }
  }
  
  private evaluateForm() {
    if (this.formGroup){
      Object.keys(this.formGroup.controls).forEach(key => {
        //if (this.formGroup)
        this.formGroup?.get(key)?.updateValueAndValidity();
      });
      this.formGroup.updateValueAndValidity()
    }
  }

  userCancel(){      
    this.dialogRef.close(undefined);
  }

    userAcept(){
        let user: KeycloakUserModel = {
          email: this.emailCtrl.value ? this.emailCtrl.value : "",
          id: '',
          username: this.userNameCtrl.value? this.userNameCtrl.value : "",
          firstName: this.firstNameCtrl.value?this.firstNameCtrl.value:undefined,
          lastName: this.lastNameCtrl.value?this.lastNameCtrl.value:undefined,
          enabled: this.enabledCtrl.value?this.enabledCtrl.value:false,
        }

        let request:Observable<KeycloakUserModel|void>;
        if (this.update){
          user.id=this.data.user.id
          request =  this.kamUserService.putUser(user);
        }else{
          request = this.kamUserService.postUser(user)
        }

        let setCredentialsRequest:Observable<void>|undefined = undefined; 
        if (this.setPasswordCtrl.value && this.newPasswordCtrl.value){
          const credentials:KeycloakUserCredentials ={
            temporary: false,
            type: 'password',
            value: this.newPasswordCtrl.value
          }
          setCredentialsRequest = this.kamUserService.resetPassword(user.id,credentials)
        }


        request.subscribe({
          next: (v) => {
            if (setCredentialsRequest){
              setCredentialsRequest.subscribe({
                next: (v) => {
                  this.dialogRef.close(true);
                },
                error: (e) => console.error(e),
                complete: () => {console.info('complete ser credentials') }
              });
            }else
              this.dialogRef.close(true);
          },
          error: (e) => console.error(e),
          complete: () => {console.info('complete user') }
        });
    }
}

export interface KamManageUserData {
  user: KeycloakUserModel,
}