import { HttpResponse } from '@angular/common/http';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PoseedorControllerService, PoseedorDTO } from 'api-rest';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'lib-holders-upload-production-num',
  templateUrl: './holders-upload-production-num.component.html',
  styleUrls: ['./holders-upload-production-num.component.scss'],
})
export class HoldersUploadProductionNumComponent {
  titulo = 'Importar números de registro de productor';
  nombreArchivo: string = '';
  file: File | undefined;
  @ViewChild('fileInput') fileInput: any;
  poseedorInfo!: PoseedorDTO;
  anio!: number;

  constructor(
    public dialogRef: MatDialogRef<HoldersUploadProductionNumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private poseedorControllerSrv: PoseedorControllerService
  ) {
    this.poseedorInfo = this.data.poseedorInfo;
    this.anio = this.data.anio;
  }
  adjuntar() {
    this.fileInput.nativeElement.click();
  }

  handleFileInput(event: any) {
    this.file = event.target.files[0];
    if (this.file && this.file.type === 'text/plain') {
      this.nombreArchivo = this.file.name;
      this.snackBarSrv.showSnackBar(
        'Se ha adjuntado el archivo correctamente.',
        'success'
      );
    } else {
      this.snackBarSrv.showSnackBar(
        'Por favor, seleccione un archivo de texto (.txt).',
        'error'
      );
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  Importar() {
    if (this.file) {
      this.spinnerSrv.loadingShow();
      this.poseedorControllerSrv
        .importRegistroProductor(this.poseedorInfo.id!, this.anio, this.file)
        .subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            if (response.datosCorrectos) {
              this.dialogRef.close(response.numRegistroProductores);
            } else {
              this.snackBarSrv.showSnackBar(
                'Ha ocurrido un error al importar la plantilla. Por favor, revise los números de registro de productor',
                'error'
              );
              this.dialogRef.close(false);
            }
          },
          error: (error) => {
            this.spinnerSrv.loadingHide();
            if (error.error?.detail) {
              this.snackBarSrv.showSnackBar(
                error.error?.detail,
                'error',
                15000
              );
            } else {
              this.snackBarSrv.showSnackBar(
                'Ha ocurrido un error al importar la plantilla. Por favor, contacte con el administrador de sistemas',
                'error'
              );
            }
          },
        });
    }
  }

  descargar() {
    this.spinnerSrv.loadingShow();
    this.poseedorControllerSrv
      .exportRegistroProductorTemplate('response')
      .subscribe({
        next: (response) => {
          let nombreFichero = 'numRegistroProductor_Template.txt';
          const templateName = this.downloadService.getFileName(
            response.headers
          );
          nombreFichero = templateName ? templateName : nombreFichero;

          this.downloadService.downloadBlob(response.body!, nombreFichero!);
          this.spinnerSrv.loadingHide();
        },
        error: (error) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al descargar la plantilla',
            'error'
          );
        },
      });
  }
}
