<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">Seleccionar el gestor</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pb-5 pt-4">
    <form [formGroup]="formGroup">
      <div class="col-sm flex-grow-1">
        <div class="custom-mat-field">
          <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="ptoEndpoint"
            [descripcion]="getLerDescription" [formControlNameSelect]="getFormControl('gestorForm')"
            [objQueryName]="getObjectQuery" [placeHolder]="'Gestor'" [customClass]="rightHolder('gestorForm')">
          </lib-ge-select-pagination>
        </div>
      </div>
    </form>
  </div>


  <div class="modal-footer actions ">
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="baja()" aria-label="Guardar">Dar de
        baja</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="bajaGestion()"
        aria-label="Guardar">Dar de baja y cambiar gestión</button>
    </div>
  </div>
</div>
