<div *ngIf="formGroup">
    <h1 mat-dialog-title>
        <mat-icon class="icon" style="vertical-align: middle;">{{data.icon}}</mat-icon>
        {{data.title| translate}}
    </h1>

    <div mat-dialog-content>
        <form [formGroup]="formGroup" >
            <mat-form-field>
                <mat-label>{{'KAM-GENERAL-DIALOG.NAME'| translate}}</mat-label>
                <input type="text" matInput [formControl]="scopeNameCtrl">
                <mat-error *ngIf="scopeNameCtrl.invalid && (scopeNameCtrl.dirty || scopeNameCtrl.touched)">
                    {{'KAM-GENERAL-DIALOG.ERROR'| translate}}
                </mat-error>
            </mat-form-field>
        </form>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="scopeCancel()">{{'KAM-GENERAL-DIALOG.CANCEL'| translate}}</button>
        <button mat-raised-button color="primary" 
                (click)="scopeAccept()" 
                [disabled]="!formGroup.valid">{{'KAM-GENERAL-DIALOG.ACCEPT'| translate}}</button>
    </div>
</div>