/**
 * Core API
 * Core API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TipoEnvasesDTO {
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    descripcion?: string;
    deleted?: string;
    deletedBy?: string;
    tipologia1?: string;
    tipologia2?: string;
    tipologia3?: string;
}

