import { locale_en_GB as english_GB } from '../app/translations/translation.en-GB';
import { locale_es_ES as spanish_ES } from '../app/translations/translation.es-ES';
import { locale_es_MX as spanish_MX } from '../app/translations/translation.es-MX';

export const keycloakRealm = 'envalora';

export const environment = {
  production: false,

  site: 'Demo site',
  snackBarDuration: 5000,
  urlbase: '/',
  userPhotoUrl: '/assets/img/',
  privacyPolicyUrl: 'http://google.com',
  termsOfUse: true,

  /** Auth and security info*/
  auth: 'keycloak', //'fake'
  backend: 'real',
  empusaPermissionServer: 'empusa-permission-server',
  grafana: 'https://grafana_url/grafana',
  keycloak: {
    url: 'https://devenvanet.envalora.es/auth/',
    realm: keycloakRealm,
    clientId: 'empusa',
  },

  /** Themes */
  theme: 'default',
  //selectableThemes: ['default','mori'],

  /** Languajes */
  selectableLanguajes: [
    /**SelectableLanguaje interface*/
    {
      locale: 'es-ES',
      svgIconName: 'flag-es-ES',
      svgIconFile: 'spain.svg',
      translation: 'HOME.MENU.LANGUAGE.SPANISH',
      data: spanish_ES,
    },
    {
      locale: 'es-MX',
      svgIconName: 'flag-es-MX',
      svgIconFile: 'mexico.svg',
      translation: 'HOME.MENU.LANGUAGE.SPANISH_MEXICO',
      data: spanish_MX,
    },
    {
      locale: 'en-GB',
      svgIconName: 'flag-en-GB',
      svgIconFile: 'england.svg',
      translation: 'HOME.MENU.LANGUAGE.ENGLISH',
      data: english_GB,
      default: true,
    },
  ],

  //TODO: AÑADIR LAS URL BASE DE CADA MÓDULO
  urlBackAdheridos: 'https://devenvanet.envalora.es/',
  urlBackAdministration: 'https://devenvanet.envalora.es/',
  urlBackCore: 'https://devenvanet.envalora.es/',
  urlBackDocum: 'https://devenvanet.envalora.es/',
  urlBackPoseedores: 'https://devenvanet.envalora.es/',
  urlBackPuntoRecogida: 'https://devenvanet.envalora.es/',
  urlBackComunicaciones: 'https://devenvanet.envalora.es/',
  urlBackSddrCa: 'https://devenvanet.envalora.es/',
  urlBackAcuerdosConGestores: 'https://devenvanet.envalora.es/',

  /*** keycloak Auth manager module config*/

  kamUrlClients: '/admin/realms/' + keycloakRealm + '/clients',
  kamUrlUsers: '/admin/realms/' + keycloakRealm + '/users',
  kamUrlUsersCount: '/admin/realms/' + keycloakRealm + '/users/count',
  kamUrlUserRoles:
    '/admin/realms/' + keycloakRealm + '/users/{{userId}}/role-mappings/realm',
  kamUrlUserGroups:
    '/admin/realms/' + keycloakRealm + '/users/{{userId}}/groups',
  kamUrlRoles: '/admin/realms/' + keycloakRealm + '/roles',
  kamUrlGroups: '/admin/realms/' + keycloakRealm + '/groups',
  kamUrlSubgroups:
    '/admin/realms/' + keycloakRealm + '/groups/{{groupId}}/children',
  kamUrlGroupMembers:
    '/admin/realms/' + keycloakRealm + '/groups/{{groupId}}/members',
  kamUrlResourceServerSettings:
    '/admin/realms/' +
    keycloakRealm +
    '/clients/{{clientId}}/authz/resource-server/settings',
  kamUrlResource:
    '/admin/realms/' +
    keycloakRealm +
    '/clients/{{id}}/authz/resource-server/resource',
  kamUrlScope:
    '/admin/realms/' +
    keycloakRealm +
    '/clients/{{id}}/authz/resource-server/scope',
  kamUrlPermission:
    '/admin/realms/' +
    keycloakRealm +
    '/clients/{{id}}/authz/resource-server/permission',
  kamUrlPolicies:
    '/admin/realms/' +
    keycloakRealm +
    '/clients/{{id}}/authz/resource-server/policy',
  kamUrlAssociatedPolicies:
    '/admin/realms/' +
    keycloakRealm +
    '/clients/{{id}}/authz/resource-server/policy/{{policyId}}/associatedPolicies',

  kamGroupAttributes: {
    /*'attributo1': {
      'isList': true, // optional. default = false, field can have multiple values
      'type':'string', // mandatory (string, number,enum)
      'values': [], //mandatory if type enum
      'mandatory': true, // optional. default = false
      'description': 'KAM-ATTRIBUTES-FORM.ATTRIBUTE_1_DESCRIPTION' // optional. default = ""
    },*/
    groupType: {
      isList: false,
      type: 'enum',
      values: ['tenant', 'building', 'internal'],
      mandatory: true,
      description: 'KAM-ATTRIBUTES-FORM.ATTRIBUTE_GROUP_TYPE',
    },
  },

  kamUserAttributes: {
    IsDefaultHost: {
      isList: false, // optional. default = false
      type: 'enum',
      values: [true, false],
      mandatory: false, // optional. default = false
      description: 'KAM-ATTRIBUTES-FORM.ATTRIBUTE_IS_DEFAULT_HOST', // optional. default = ""
    },
  },
  /*** END keycloak Auth manager module config*/
};
