import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import swal from 'sweetalert2';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { SnackbarService } from '../../../services/snackbar.service';
import { WasteManagersContactDialogComponent } from '../waste-managers-contact-dialog/waste-managers-contact-dialog.component';
import { ContactoControllerService, GestoresResiduosDTO } from 'api-rest';
@Component({
  selector: 'lib-waste-managers-detail-contact-data',
  templateUrl: './waste-managers-detail-contact-data.component.html',
  styleUrls: ['./waste-managers-detail-contact-data.component.scss'],
})
export class WasteManagersDetailContactDataComponent
  implements OnInit, OnChanges
{
  @Input() gestorInfo!: GestoresResiduosDTO;
  @Output() public refrescarGestorEvent = new EventEmitter<any>();
  contactos: any;

  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  gridArray: any[] = [];

  headArray: any = [
    {
      Head: 'Requerida',
      FieldName: 'requerido',
      check: true,
      disabled: true,
    },
    {
      Head: 'Tipo contacto',
      FieldName: 'tipo_contacto',
      Attribute: 'descripcionCorta',
      Tooltip: true,
    },
    {
      Head: 'Nombre',
      FieldName: 'fullName',
      Tooltip: true,
    },
    {
      Head: 'Tipo telf.',
      FieldName: 'tipo_telefono',
    },
    {
      Head: 'Teléfono ',
      FieldName: 'numero_telefono',
      Tooltip: true,
    },
    {
      Head: 'Email  ',
      FieldName: 'email',
      Tooltip: true,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '8',
      permanent: true,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private contactoControllerService: ContactoControllerService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gestorInfo) {
      this.contactos = this.gestorInfo?.contactos;
      this.gridArray = this.canAccessAll(this.contactos);
    }
  }

  canAccessNewContact(): boolean {
    return (
      this.authService.can('poseedores-gestor-contactos', 'create') &&
      this.gestorInfo.gestoresResiduosEstado! &&
      [1, 2].includes(this.gestorInfo?.gestoresResiduosEstado?.id!)
    );
  }

  canAccessAll(list: any) {
    const resultListShow = this.authService.can(
      'poseedores-gestor-contactos',
      'access'
    );
    const resultDelete = this.authService.can(
      'poseedores-gestor-contactos',
      'delete'
    );

    const listPermission = list.map((objeto: any) => {
      const estadoEliminar =
        this.gestorInfo.gestoresResiduosEstado &&
        [1, 2].includes(this.gestorInfo?.gestoresResiduosEstado?.id!) &&
        (!objeto.requerido || objeto.requerido === null);
      return {
        ...objeto,
        canAccessView: resultListShow,
        canAccessDelete: resultDelete && estadoEliminar,
      };
    });

    return listPermission;
  }

  openModalContact(element?: any): void {
    const dialogRef = this.dialog.open(WasteManagersContactDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        contacto: element,
        gestorInfo: this.gestorInfo,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refrescarGestorEvent.emit(true);
      }
    });
  }
  checked(item: any) {}

  action(item: any) {
    if (item.nameButton == 'view') {
      this.openModalContact(item);
    } else if (item.nameButton == 'edit') {
      this.openModalContact(item);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }
  confirmacionEliminar(element?: any): void {
    swal
      .fire({
        text: '¿Desea eliminar el contacto?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.eliminarContacto(element);
        }
      });
  }
  eliminarContacto(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.contactoControllerService.borrarContacto(element.id).subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El contacto se ha eliminado correctamente',
            'success'
          );
          this.refrescarGestorEvent.emit(true);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar el contacto',
            'error'
          );
          console.log(err);
        },
      });
    }
  }

  renderPage(event: number) {
    this.pageNumber = event;
  }
}
