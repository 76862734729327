<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="container-form" style="background-color: white;">
    <form class="container form-search py-3 padding-form" [formGroup]="datosSolicitudForm">
      <div class="title-group">Datos de la solicitud</div>
      <div class="row-fields">
        <div class="field field-25">
          <label for="tipoEnvase">Tipo de envase <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="tipoEnvase" id="tipoEnvase" placeholder="-- Tipo de envase --">
              <mat-option *ngFor="let tipoEnvase of tipoEnvaseArray" [value]="tipoEnvase.id">
                {{tipoEnvase.descripcion}}
              </mat-option>
            </mat-select>
            <span *ngIf="showClearButton('tipoEnvase')" tabindex="0" class="ng-clear-wrapper"
              (click)="clearSelection($event,'tipoEnvase')">
              <span aria-hidden="true" class="ng-clear">×</span>
            </span>
            <mat-error *ngIf="controlHasError(datosSolicitudForm,'tipoEnvase', 'required')">Campo
              obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="field field-25">
          <label for="subtipoEnvase">Subtipo de envase </label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="subtipoEnvase" id="subtipoEnvase" placeholder="-- Subtipo de envase --">
              <mat-option *ngFor="let item of subtipoEnvaseArray" [value]="item.id">
                {{ item.descripcion }}
              </mat-option>
            </mat-select>
            <span *ngIf="showClearButton('subtipoEnvase')" tabindex="0" class="ng-clear-wrapper"
              (click)="clearSelection($event,'subtipoEnvase')">
              <span aria-hidden="true" class="ng-clear">×</span>
            </span>
          </mat-form-field>
        </div>
        <div class="field field-25">
          <div style="display: flex; align-items: center;">
            <label for="unidadesRetirar">Cantidad a retirar (ud) <span class="oblig">*</span></label>
            <div class="tool-help"
              matTooltip="Cantidad mínima de solicitud de retirada para envases de tipo IBC = 5 y para tipo Pallets = 10"
              data-placement="top" title="">
              <span>?</span>
            </div>
          </div>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="unidadesRetirar" id="unidadesRetirar" placeholder="Ud."
                value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-25">
          <label for="pesoRetirar">Cantidad a retirar (kg) </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="pesoRetirar" id="pesoRetirar" placeholder="Kg." value="" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row-fields">
        <div class="field field-20">
          <div style="display: flex; align-items: center;">
            <label for="aceptacionRetirada">Aceptación de condiciones de retirada <span class="oblig">*</span></label>
            <div class="tool-help" matTooltip="ENVALORA retornará el importe del depósito del número de los envases que el gestor/acondicionador determine que son aptos para su reutilización posterior, una vez reciba el informe de dicho agente.
              Los palets no deben tener restos de productos o sustancias que hayan contaminado el envase, ni restos de otros envases, por ejemplo, precintos, flejes o films.
              Los IBC deben estar vacíos y cerrados con menos de un 1% en peso de producton" data-placement="top"
              title="">
              <span>?</span>
            </div>
          </div>
          <div class="field field-20">
            <mat-checkbox formControlName="aceptacionRetirada" id="aceptacionRetirada" color="primary">
            </mat-checkbox>
          </div>
        </div>

        <div class="field field-50">
          <label for="acondicionador">Acondicionador <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="acondicionador" id="acondicionador"
                placeholder="Gestor acondicionador" value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-30">
          <label for="loteEnvase">Lote del envase</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="loteEnvase" id="loteEnvase" placeholder="Lote" value="" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row-fields">
        <div class="field field-100">
          <label for="observacionesRetirada">Observaciones sobre la retirada de envases </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <textarea class="form-control" matInput formControlName="observacionesRetirada" id="observacionesRetirada"
                rows="4"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row-fields">
        <div class="field field-100">
          <label *ngIf="(this.infoRequests?.autofactura)" for="autofactura">Autofactura</label>
          <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="(this.infoRequests?.autofactura)"
            class="centrar archivo">
            <div>
              <span class="sprite-icon ico-adjuntar"></span><span
                class="nombre-archivo">{{this.infoRequests?.autoFactura?.nombre}}
              </span>
              <mat-icon (click)="descargar(this.infoRequests?.autofactura)" matTooltip="Descargar"
                class="ml-2 color-custom pointer-cursor">
                download</mat-icon>
            </div>
          </div>
        </div>
      </div>

    </form>

    <form class="container form-search py-3 padding-form" [formGroup]="facturaForm">
      <div class="title-group">Facturas en los que se le ha cobrado el importe de los depósitos de los envases
        reutilizables </div>
      <div class="row-fields">
        <div class="field field-20">
          <label for="importeDepositoUnidad">Importe depósito/unidad <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="importeDepositoUnidad" id="importeDepositoUnidad"
                placeholder="Importe" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="field field-20">
          <label for="nFacturaDeposito">Número de factura depósito <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="nFacturaDeposito" id="nFacturaDeposito"
                placeholder="Número factura depósito" value="" maxlength="10" (keydown)="onlyNumberKey($event)">
            </mat-form-field>
          </div>
        </div>
        <div class="field field-50">
          <label for="documentoDeposito">Documento <span class="oblig">*</span></label>
          <div class="attach">
            <button mat-button aria-labe="Adjuntar archivo" class="adjuntar-btn" type="button" (click)="fileA.click()">
              Adjuntar archivo
            </button>
            <label class="sr-only" for="factura">Adjuntar archivo </label>
            <input #fileA type="file" (change)="fileA && fileA.files && attachFile(fileA.files[0], 'factura')"
              class="hide" id="factura" name="factura">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="centrar archivo">
              <div>
                <span class="sprite-icon ico-adjuntar"></span><span class="nombre-archivo">{{facturaBlob?.name}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="field" style="align-self: center;">
          <button type="button" (click)="addFactura()" class="btn btn-primary ">
            <mat-icon aria-hidden="false" aria-label="Añadir" fontIcon="add"></mat-icon>
          </button>
        </div>
      </div>

      <div class="row-fields">
        <div class="field field-100">
          <div *ngIf="archivosAdjuntosArray?.length !== 0 else messageClear"
            class="datatable-container table-responsive">
            <lib-ge-table [headArray]="headFacturaArray" [gridArray]="archivosAdjuntosArray"
              (actionButton)="action($event)">
            </lib-ge-table>
          </div>
        </div>
      </div>
    </form>

    <form class="form-modal py-3" [formGroup]="informacionSolicitud">
      <div class="title-group">Información de la solicitud </div>
      <div class="row-fields">
        <div class="field field-33">
          <label for="usuarioSolicitud">Usuario </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="usuarioSolicitud" id="usuarioSolicitud" placeholder="Usuario"
                value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-33">
          <label for="fechaSolicitud">Fecha de solicitud</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="fechaSolicitud" id="fechaSolicitud"
                placeholder="Fecha solicitud" value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-33">
          <label for="estadoSolicitud">Estado </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="estadoSolicitud" id="estadoSolicitud" placeholder="Estado"
                value="">
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>

    <form class="form-modal py-3" [formGroup]="informeRetiradaForm">
      <div class="title-group">Informe de retirada</div>
      <div class="row-fields">
        <div class="field field-25">
          <label for="usuarioSddrCa">Usuario SDDR CA</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="usuarioSddrCa" id="usuarioSddrCa" placeholder="Ud."
                value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-33">
          <label for="verificacion">Verificación de que se trata del usuario SDDR CA <span
              class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <mat-select formControlName="verificacion" id="verificacion" placeholder="Seleccionar">
                <mat-option [value]="true">Sí</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="field field-20">
          <label for="udEnvases">Envases solicitados (ud) <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="udEnvases" id="udEnvases" placeholder="Ud." value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-20">
          <label for="kgEnvases">Envases solicitados (kg) </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="kgEnvases" id="kgEnvases" placeholder="Kg." value="" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row-fields">
        <div class="field field-25">
          <label for="udRetirados">Envases retirados (ud) <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="udRetirados" id="udRetirados" placeholder="Kg." value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-25">
          <label for="kgRetirados">Envases retirados (kg) </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="kgRetirados" id="kgRetirados" placeholder="Kg." value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-25">
          <label for="udAptos">Envases aptos para reutilizar (ud) <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="udAptos" id="udAptos" placeholder="Kg." value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-25">
          <label for="kgAptos">Envases aptos para reutilizar (kg) </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="kgAptos" id="kgAptos" placeholder="Kg." value="" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row-fields">
        <div class="field field-20">
          <label for="udNoAptos">Envases no aptos para reutilizar(ud) <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="udNoAptos" id="udNoAptos" placeholder="Kg." value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-20">
          <label for="kgNoAptos">Envases no aptos para reutilizar (kg) <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="kgNoAptos" id="kgNoAptos" placeholder="Kg." value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-30">
          <div style="display: flex; align-items: center;">
            <label for="envasesSalenSddr">Envases que salen del SDDR CA ENVALORA <span class="oblig">*</span></label>
            <div class="tool-help" matTooltip="Se compromete a la eliminación de los símbolos del SDDR CA de Envalora
              de aquellos envases aptos pero que no se van a mantener dentro del SDDR abierto y a no cobrar a ninguno de
              sus clientes ningún concepto de depósito asociado a este envase" data-placement="top" title="">
              <span>?</span>
            </div>
          </div>
          <div class="field field-100">
            <mat-checkbox formControlName="envasesSalenSddr" id="envasesSalenSddr" color="primary">
            </mat-checkbox>
          </div>
        </div>
        <div class="field field-30">
          <div style="display: flex; align-items: center;">
            <label for="envasesMantienenSddr">Envases que se mantienen en el SDDR CA de ENVALORA<span
                class="oblig">*</span></label>
            <div class="tool-help" matTooltip="Se compromete a realizar una solicitud para aquellos envases aptos para
                reutilizar que quiere que se mantengan dentro del SDDR CA de Envalora, en este caso mantendrá o repondrá el
                Símbolo de Envalora y de envase reutilizable" data-placement="top" title="">
              <span>?</span>
            </div>
          </div>
          <div class="field field-100">
            <mat-checkbox formControlName="envasesMantienenSddr" id="envasesMantienenSddr" color="primary">
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="title-group">Documentos </div>
    </form>
    <form class="form-modal py-3" [formGroup]="documentoInformeForm">
      <div class="row-fields">
        <div class="field field-20">
          <label for="nombre">Documento</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="nombre" id="nombre" placeholder="Documento informe"
                value="" />
            </mat-form-field>
          </div>
        </div>
        <div class="field field-75" style="align-self: center;">
          <div class="attach">
            <button mat-button aria-labe="Adjuntar documento" class="adjuntar-btn" type="button"
              (click)="fileB.click()">
              Adjuntar documento
            </button>
            <label class="sr-only" for="documento">Adjuntar documento </label>
            <input #fileB type="file" (change)="fileB && fileB.files && attachFile(fileB.files[0], 'documento')"
              class="hide" id="documento" name="documento">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="centrar archivo">
              <div>
                <span class="sprite-icon ico-adjuntar"></span><span class="nombre-archivo">{{documentoBlob?.name}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="field" style="align-self: center;">
          <button type="button" (click)="addDocumento()" class="btn btn-primary ">
            <mat-icon aria-hidden="false" aria-label="Añadir" fontIcon="add"></mat-icon>
          </button>
        </div>
      </div>
      <div class="row-fields">
        <div class="field field-100">
          <div *ngIf="documentosAdjuntosArray?.length !== 0 else messageClear"
            class="datatable-container table-responsive">
            <lib-ge-table [headArray]="headDocumentosArray" [gridArray]="documentosAdjuntosArray"
              (actionButton)="action($event)">
            </lib-ge-table>
          </div>
        </div>
      </div>
    </form>

    <div class="fixed-footer">
      <div class="modal-footer actions">
        <div class="note">* Campos Obligatorios</div>
        <div class="button-group">
          <button type="button" (click)="closeDialog()" class="btn btn-outline-primary" data-dismiss="modal"
            aria-label="Close">Volver</button>
          <button *ngIf="canSaveDraftDataRequests() || canSaveDraftReportRequests()" type="button"
            class="btn btn-primary" data-dismiss="modal" aria-label="Guardar" (click)="saveDataCourse()">Guardar
            borrador</button>
          <button *ngIf="canSaveAndSendDataRequests() || canSaveAndSendDraftRequests()" type="button"
            class="btn btn-primary" data-dismiss="modal" aria-label="Guardar" (click)="saveDataFinal()">Guardar y
            enviar</button>
        </div>
      </div>
    </div>
    <ng-template #messageClear>
      <div class="container-menu">
        <div class="mensaje-container">
          <p class="mensaje-linea">Sin información registrada</p>
        </div>
      </div>
    </ng-template>
  </div>
