<!-- MIGAS DE PAN Y TÍTULO -->
<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">SDDR Abierto</li>
          <li class="breadcrumb-item active"><a href="#/open-sddr/withdrawn-search">Búsqueda de solicitudes de
            retirada</a>
        </ol>
      </nav>
      <div class="content-title">
        <h2 class="title-pag">Solicitudes de retirada</h2>
        <button *ngIf="canAccessCrearSolicitud()" type="button" class="btn btn-primary" data-toggle="modal"
                (click)="openModalDetailRequest()">
          <span class="icon-add"></span>Nueva solicitud
        </button>
      </div>
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->
<!-- CONTENIDO -->

<div class="container-form" *ngIf="!ocultarBuscador">
  <!-- Formulario de búsqueda -->
  <form class="container form-search py-3 padding-form" [formGroup]="searchForm">
    <div class="row-fields">
      <div class="field field-20" *ngIf="canAccessEnvalora()">
        <label for="tipoUsuario">Tipo de usuario</label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="tipoUsuario" id="tipoUsuario" placeholder="-- Tipo de usuario --">
            <mat-option *ngFor="let tipoUsuario of tiposUsuarios" [value]="tipoUsuario.id">
              {{ tipoUsuario.name }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('tipoUsuario')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'tipoUsuario')">
            <span aria-hidden="true" class="ng-clear">×</span>
          </span>
        </mat-form-field>
      </div>
      <div class="field field-grow" *ngIf="canAccessEnvalora()">
        <label for="idUsuario">ID. Usuario</label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input matInput formControlName="idUsuario" id="idUsuario" placeholder="ID. Usuario" type="text" maxlength="10" (keydown)="onlyNumberKey($event)">
        </mat-form-field>
      </div>
      <div class="field field-grow" *ngIf="canAccessSDDRCAUser()">
        <label for="usuarioSDDRCA">Usuario SDDR CA</label>
        <mat-form-field appearance="outline" class="custom-mat-form-field">
          <mat-select formControlName="usuarioSDDRCA" id="usuarioSDDRCA" placeholder="-- Usuario SDDR CA --">
            <mat-option *ngFor="let tipo of tiposUsuariosSddr" [value]="tipo.id">
              {{ tipo?.tipoUsuario === 'POSEEDOR' ? 'PO' : 'AD' }} -
              {{ tipo?.razonSocial }}
              {{ tipo?.denominacion ? ' - ' + tipo.denominacion : '' }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('tipoUsuario')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'usuarioSddr')">
            <span aria-hidden="true" class="ng-clear">×</span>
          </span>
        </mat-form-field>
      </div>
      <div class="field field-20">
        <label for="tipoEnvase">Tipo de envase </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="tipoEnvase" id="tipoEnvase" placeholder="-- Tipo de envase --">
            <mat-option *ngFor="let item of tiposEnvases" [value]="item.id">
              {{ item.descripcion }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('tipoEnvase')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'tipoEnvase')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
        </mat-form-field>
      </div>
      <div class="field field-20">
        <label for="subtipoEnvase">Subtipo de envase </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="subtipoEnvase" id="subtipoEnvase" placeholder="-- Subtipo de envase --">
            <mat-option *ngFor="let tipo of subtiposEnvases" [value]="tipo.id">
              {{ tipo?.descripcion }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('subtipoEnvase')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'subtipoEnvase')">
                        <span aria-hidden="true" class="ng-clear">×</span>
                    </span>
        </mat-form-field>
      </div>
    </div>
    <div class="row-fields">
      <div class="field field-20">
        <label for="gestor">Lote envase</label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input matInput formControlName="loteEnvase" id="loteEnvase" placeholder="Lote envase" type="text">
        </mat-form-field>
      </div>
      <div class="field field-20" *ngIf="canAccessEnvalora()">
        <label for="gestor">Gestor / Acondicionador </label>
        <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="gestoresEndpoint"
                                  [defaultFilter]=defaultFilter [descripcion]="getGestorDescription"
                                  [formControlNameSelect]="getFormControl('gestorAcondicionador')"
                                  [objQueryName]="getObjectQuery"
                                  [placeHolder]="'-- Gestor / Acondicionador --'"
                                  [customClass]="rightHolder('gestorAcondicionador')">
        </lib-ge-select-pagination>
      </div>
      <div class="field field-15">
        <label for="fechaDesde">Fecha solicitud desde</label>
          <mat-form-field appearance="outline" class="custom-mat-form-field">
            <input matInput [matDatepicker]="fechaDesde" formControlName="fechaDesde">
            <mat-datepicker-toggle matIconSuffix [for]="fechaDesde"></mat-datepicker-toggle>
            <mat-datepicker #fechaDesde>
            </mat-datepicker>
            <mat-error *ngIf="controlHasError('fechaDesde', 'matDatepickerParse')">Formato incorrecto
            </mat-error>
          </mat-form-field>
      </div>
      <div class="field field-15">
        <label for="fechaHasta">Fecha solicitud hasta </label>
          <mat-form-field appearance="outline" class="custom-mat-form-field">
            <input matInput [matDatepicker]="fechaHasta" formControlName="fechaHasta">
            <mat-datepicker-toggle matIconSuffix [for]="fechaHasta"></mat-datepicker-toggle>
            <mat-datepicker #fechaHasta>
            </mat-datepicker>
            <mat-error *ngIf="controlHasError('fechaHasta', 'matDatepickerParse')">Formato incorrecto
            </mat-error>
          </mat-form-field>
      </div>
      <div class="field field-grow">
        <label for="estado">Estado </label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="estado" id="estado" placeholder="-- Estado --">
              <mat-option *ngFor="let des of estados" [value]="des">
                {{ des.descripcion }}
              </mat-option>
            </mat-select>
            <span *ngIf="showClearButton('estado')" tabindex="0" class="ng-clear-wrapper"
                  (click)="clearSelection($event,'estado')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
          </mat-form-field>
      </div>
    </div>

    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" (click)="limpiar()">Limpiar</button>
      <button type="button" class="btn btn-primary" (click)="search()">Buscar</button>
    </div>
  </form>
  <!-- FIN Formulario de búsqueda -->
</div>
<div class="bg-second-table-info pt-3 pb-5">
  <div *ngIf="flagSearch; else messageClear" class="container">
    <div class="actions-table">
      <div class=""><span class="align-middle tit-table">{{ totalSearch }} registros encontrados</span></div>
      <div class="botones-tabla">
        <div class="d-inline" *ngIf="canAccessCrearAutofactura()">
          <div class="dropdown show">
            <a class="btn btn-outline-primary" role="button" aria-haspopup="true" aria-expanded="false" (click)="confirmacionAutofactura()">
              <span>Crear autofactura </span></a>
          </div>
        </div>
        <div class="d-inline" *ngIf="canAccessExportar()">
          <div class="dropdown show">
            <a class="btn btn-outline-primary" role="button" aria-haspopup="true" aria-expanded="false"
               (click)="exportarExcel()">
              <span>Exportar</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-tabla-proy">
      <div class="card-body">
        <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
          <div class="datatable-container table-responsive">
            <!-- TABLA Listado con resultados de búsqueda -->
            <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (checked)="checked($event)"
                          (actionButton)="action($event)"></lib-ge-table>
            <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
              <div class="d-flex mt-2">
                <ngb-pagination [pageSize]="pageSize" [collectionSize]="totalElements"
                                [(page)]="pageNumber"
                                (pageChange)="renderPage($event)" [maxSize]="5"
                                aria-label="Default pagination"
                                size="sm">
                </ngb-pagination>
              </div>

              <div class="mt-2 selectPage">
                <label for="itemsPerPage">Mostrar:</label>
                <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage"
                        (change)="onItemsPerPageChange()">
                  <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}
                  </option>
                </select>
              </div>
            </div>
            <!-- FIN TABLA Listado con resultados de búsqueda -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #messageClear>
    <div class="mensaje-container">
      <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
        formulario.</p>
    </div>
  </ng-template>
</div>
