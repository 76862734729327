/**
 * Docum API
 * Docum API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TipoDocumentoModel } from './tipoDocumentoModel';


export interface DocumentoModel { 
    id?: number;
    nombre?: string;
    descripcion?: string;
    contenedor?: string;
    url?: string;
    idEntidad?: number;
    tipoEntidad?: DocumentoModel.TipoEntidadEnum;
    tipoDocumento?: TipoDocumentoModel;
    fechaCreacion?: string;
    fechaModificacion?: string;
    usuarioModificacion?: string;
}
export namespace DocumentoModel {
    export type TipoEntidadEnum = 'ADHERIDO' | 'POSEEDOR' | 'GESTOR' | 'GESTOR_POSEEDOR' | 'DISTRIBUIDOR' | 'PUNTO_RECOGIDA' | 'GESTOR_PUNTO_RECOGIDA' | 'REACONDICIONADOR' | 'APORTACION_DI_POSEEDOR';
    export const TipoEntidadEnum = {
        Adherido: 'ADHERIDO' as TipoEntidadEnum,
        Poseedor: 'POSEEDOR' as TipoEntidadEnum,
        Gestor: 'GESTOR' as TipoEntidadEnum,
        GestorPoseedor: 'GESTOR_POSEEDOR' as TipoEntidadEnum,
        Distribuidor: 'DISTRIBUIDOR' as TipoEntidadEnum,
        PuntoRecogida: 'PUNTO_RECOGIDA' as TipoEntidadEnum,
        GestorPuntoRecogida: 'GESTOR_PUNTO_RECOGIDA' as TipoEntidadEnum,
        Reacondicionador: 'REACONDICIONADOR' as TipoEntidadEnum,
        AportacionDiPoseedor: 'APORTACION_DI_POSEEDOR' as TipoEntidadEnum
    };
}


