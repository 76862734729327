<keycloak-auth-manager-kam-attributes-form *ngIf="groupAttributes"
    [customAttributesRequest]="groupAttributes"
    [initialAttributesValues]="initialAttributesValues"
    (customAttributesValues)="setCustomAttributesValues($event)"
    (customAttributesValuesAreValid)="setCustomAttributesValuesAreValid($event)">
</keycloak-auth-manager-kam-attributes-form>

<div class="actions">
    <button mat-button matStepperPrevious>{{'KAM-MANAGE-GROUPS.STEP2.BACK' | translate}}</button>
    <button mat-button matStepperNext (click)="step2End()"
    [disabled]="!customAttributesValuesAreValid">{{'KAM-MANAGE-GROUPS.STEP2.NEXT' | translate}}</button>
</div>
