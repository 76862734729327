import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeycloakUserModel } from '../../../model/keycloak-user.model';
import { KamUserService } from '../../../services/kam-user.services';
import { KamUserAttributesData } from './kam-user-attributes-data.interface';

@Component({
  selector: 'keycloak-auth-manager-kam-user-attributes',
  templateUrl: './kam-user-attributes.component.html',
  styleUrls: ['./kam-user-attributes.component.css']
})
export class KamUserAttributesComponent implements OnInit {

  userAttributes = this.environment.kamUserAttributes;
  initialAttributesValues: Map<string, string[]>|undefined;
  userDataAttributesMap: Map<string, string[]> | undefined;
  customAttributesValuesAreValid:boolean=false;

  constructor(@Inject('environment') private environment:any,
          public dialogRef: MatDialogRef<KamUserAttributesData>,
          @Inject(MAT_DIALOG_DATA) public data: KamUserAttributesData,
          private kamUserService: KamUserService) { }

          
  ngOnInit(): void {
    console.log(this.data)
    this.initialAttributesValues = this.data.user.attributesMap
  }

  setCustomAttributesValues($event: any){
    console.log("setCustomAttributesValues", $event)
    this.userDataAttributesMap = $event
  }

  setCustomAttributesValuesAreValid($event: any){
    setTimeout(() => { 
      this.customAttributesValuesAreValid= $event
      console.log("this.customAttributesValuesAreValid",this.customAttributesValuesAreValid)
    }, 100);
  }

  updateAttributes(){
    //transform attibutes to json
    let kUser:KeycloakUserModel = {
      id: this.data.user.id,
      username: this.data.user.username
    }
    if (this.userDataAttributesMap){
      let jsonObject:any = {};
      this.userDataAttributesMap.forEach((value, key) => {
        jsonObject[key] = value;
      });      
      kUser.attributes = jsonObject;
    }
    this.kamUserService.putUser(kUser).subscribe({
      next: (value) => {        
      },
      error: (err) => {
        console.error(err)
        this.dialogRef.close(false)
      },
      complete: () => {
        this.dialogRef.close(true)
      },
    })
  }

  cancel(){
    this.dialogRef.close(undefined)
  }

}
