import { Injectable } from "@angular/core";
import { KeycloakPolicyConfigModel } from "../keycloak-policy-config.model";
import { Adapter } from "./keycloak.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakPolicyConfiModelAdapter implements Adapter<KeycloakPolicyConfigModel> {

    constructor() { }
    adapt(item: any): KeycloakPolicyConfigModel {
        let model:KeycloakPolicyConfigModel = {};
        if (item.applyPolicies)
            model.applyPolicies=JSON.parse(item.applyPolicies);
        if (item.code)
            model.code=item.code;
        if (item.defaultResourceType)
            model.defaultResourceType=item.defaultResourceType;
        if (item.resources)
            model.resources=JSON.parse(item.resources);
        if (item.roles)
            model.roles=JSON.parse(item.roles)
        if (item.scopes)
            model.scopes=JSON.parse(item.scopes);
        return model;
    }
}