import { Injectable } from "@angular/core";
import { KeycloakClientModel } from "../keycloak-client.model";
import { Adapter } from "./keycloak.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakClientModelAdapter implements Adapter<KeycloakClientModel> {

    constructor() { }
    adapt(item: any): KeycloakClientModel {
        let model:KeycloakClientModel = {
            clientId: item.clientId,
            id: item.id,
        };
        if (item.name) model.name=item.name;
        return model;
    }
}