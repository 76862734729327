import { NgModule } from '@angular/core';
import { InitialPageComponent } from '../pages/initial-page.component';
import { EmpusaMicroApp, EmpusaMicroAppType } from '@empusa/empusa-core';
import { InitialPageHomeModule } from './initial-page.home.module';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class InitialPageModule implements EmpusaMicroApp {
  name: string = "initial-page";
  description: string = "Initial page";
  icon: string = "initial-page";
  page: any = InitialPageHomeModule;
  homeApp: boolean = false;
  path: string = "initial-page";
  order: number = 2;
  resourceServerId: string = "keycloak-auth-manager-resources-server";
  appType: EmpusaMicroAppType = EmpusaMicroAppType.normal;

  constructor() {
    //we register ourselves
    (<any>window).empusa(this);
    console.debug("Load microapp " + this.name);
  }
 }
