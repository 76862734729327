import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { KeycloakGroupModel } from '../../model/keycloak-group.model';
import { AuthManagerState } from '../../state/auth-manager.model';
import { selectGroups } from '../../state/auth-manager.selectors';
import * as authManagementActions  from '../../state/auth-manager.actions';
import { KeycloakUserModel } from '../../model/keycloak-user.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ManageGroupComponent } from './manage-group/manage-group.component';
import { KamManageGroupData } from './manage-group/manage-group-data.interface';
import { KamQuestionDialogComponent } from '../common/kam-question-dialog/kam-question-dialog.component';
import { KeycloakGroupService } from '../../services/kam-group.services';
import { MatSort } from '@angular/material/sort';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import { JsonPipe } from '@angular/common';
import { KamGroupDataComponent } from './group-data/group-data.component';




interface ExampleFlatNode {
  expandable: boolean;
  name: KeycloakGroupModel;
  level: number;
}

@Component({
  selector: 'keycloak-auth-manager-kam-groups-tab',
  templateUrl: './kam-groups-tab.component.html',
  styleUrls: ['./kam-groups-tab.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class KamGroupsTabComponent implements OnInit, OnDestroy {

  private _transformer = (node: KeycloakGroupModel, level: number) => {
    return {
      expandable: !!node.subGroups && node.subGroups.length > 0,
      name: node,
      level: level
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.subGroups
  );
  
  groupsSubscription: Subscription | undefined;
  dataSource: MatTableDataSource<KeycloakGroupModel> = new MatTableDataSource();
  columnsToDisplay = ['name','actions'];
  expandedElement: KeycloakUserModel | null = null;
  groupDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  @ViewChild('paginatorGroups') set paginatorGroups(mp: MatPaginator) {
    this.dataSource.paginator = mp
  }


  constructor(private store: Store<AuthManagerState>,
    public dialog: MatDialog,
    private keycloakGroupService: KeycloakGroupService,
    ) {}
  

  ngOnInit(): void {
    this.store.dispatch(authManagementActions.RetrieveGroups());
    this.groupsSubscription = this.store.select(selectGroups).subscribe(groups =>{
      this.groupDataSource.data = groups;
      this.dataSource.data = groups;
    })
  }

  addNewGroup(){    
    const data:KamManageGroupData ={      
      title: 'KAM-MANAGE-GROUPS.TITLE_NEW'      
    }
    const dialogRef = this.dialog.open(ManageGroupComponent, {
      width: '500px',  
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.store.dispatch(authManagementActions.RetrieveGroups());
      }
    });
  }

  updateGroup(groupNode:ExampleFlatNode){
    const data:KamManageGroupData ={
      group:groupNode.name,      
      title: 'KAM-MANAGE-GROUPS.TITLE_UPDATE',
      parameters: {groupname:groupNode.name.path}
    }
    const dialogRef = this.dialog.open(ManageGroupComponent, {
      width: '500px',  
      data    
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.store.dispatch(authManagementActions.RetrieveGroups());
      }
    });
  }

  addSubgroup(group: ExampleFlatNode){ 
    const data:KamManageGroupData = {
      title: 'KAM-MANAGE-GROUPS.TITLE_NEW_SUBGROUP',
      parameters: {parentpath:group.name.path},
      subgroupfrom: group.name
    }
    
    const dialogRef = this.dialog.open(ManageGroupComponent, {
      width: '500px',  
      data    
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.store.dispatch(authManagementActions.RetrieveGroups());
      }
    });
  }

  showGroupData(group: ExampleFlatNode){ 
    const data:KamManageGroupData = {
      title: 'KAM-MANAGE-GROUPS.TITLE_INFO_GROUP',
      parameters: {parentpath:group.name.path},
      subgroupfrom: group.name
    }
    
    const dialogRef = this.dialog.open(KamGroupDataComponent, {
      width: '500px',  
      data    
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.store.dispatch(authManagementActions.RetrieveGroups());
      }
    });
  }

  deleteGroup(groupNode:ExampleFlatNode){
    const dialogRef = this.dialog.open(KamQuestionDialogComponent, {
      width: '500px',  
      data: {
            title: "KAM-QUESTION-DIALOG.DELETE_GROUP_TITLE",
            question: "KAM-QUESTION-DIALOG.DELETE_GROUP_QUESTION",
            parameters:{groupname:groupNode.name.path},
            icon: "delete_outline"
          } 
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.keycloakGroupService.deleteGroup(groupNode.name).subscribe({
          next: (v) => {},
          error: (e) => console.error(e),
          complete: () => {this.store.dispatch(authManagementActions.RetrieveGroups());}
        });
      }
    });
  }

  filterChanged(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.groupDataSource.data.filter((node: any) => {
      const name = node.name.toLowerCase();
      return name.indexOf(filterValue) !== -1;
    });
    if(filterValue)
    {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  ngOnDestroy(): void {
    if (this.groupsSubscription) this.groupsSubscription.unsubscribe();
  }

}