<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">Introduzca el motivo del rechazo</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pb-5 pt-4">
    <form [formGroup]="formGroup">
      <div class="col-sm flex-grow-1">
        <label for="cantidad">Motivo <span class="oblig">*</span></label>
        <div class="custom-mat-form-field-width">
          <mat-form-field appearance="outline" class="custom-mat-form-field-width">
            <input type="text" matInput formControlName="comentarioRechazar" id="comentarioRechazar"
              placeholder="Comentario">
            <mat-error *ngIf="controlHasError('comentarioRechazar', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer actions ">
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="cancelarGestor()"
        aria-label="Guardar">Rechazar solicitud</button>
    </div>
  </div>
</div>
