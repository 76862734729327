import { Component, OnInit } from '@angular/core';
import { EmpusaAppHeaderClassicInput } from '@empusa/empusa-core';
import { APP_ICON, APP_NAME } from '../../model/kam.constants';


@Component({
  selector: 'keycloak-auth-manager-keycloak-auth-manager-main',
  templateUrl: './keycloak-auth-manager-main.component.html',
  styleUrls: ['./keycloak-auth-manager-main.component.css']
})
export class KeycloakAuthManagerMainComponent implements OnInit {
  headerData: EmpusaAppHeaderClassicInput ={
    icon: APP_ICON,
    text: APP_NAME   
  }
  constructor() { }

  ngOnInit(): void {
  }

}
