import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmpusaAuthenticationService, EmpusaMicroApp, EmpusaThemeService } from '@empusa/empusa-core';
import { EmpusaPortalAuthService } from '../../../services/auth.service';
import { THEMES } from '../../../theme.config';
import { SectionItem } from './home-minimal-side-nav/sectionItem';

@Component({
  selector: 'app-home-minimal',
  templateUrl: './home-minimal.component.html',
  styleUrls: ['./home-minimal.component.scss']
})
export class HomeMinimalComponent implements OnInit {

  currentUser: string ="";
  /*
  sidenavSections = [
    {desc:'Inicio',url:'home',icon:'list_alt'},
    {desc:'Documentación y Catálogo de Obras y Marcos',url:'',icon:'list_alt'},
    {desc:'Bibliografías de Obras y Marcos',url:'',icon:'auto_stories'},
    {desc:'Restauración',url:'',icon:'brush'},
    {desc:'Fondo Gráfico',url:'',icon:'add_photo_alternate'},
    {desc:'Gestión de Cartelas',url:'',icon:'grid_view'},
    {desc:'Expedientes de entrada',url:'expedientes-entradas',icon:'input'},
    {desc:'Movimientos internos y Cambio de ubicación',url:'',icon:'move_up'},
    {desc:'Exposiciones temporales',url:'',icon:'today'},
    {desc:'Gestión de préstamos para exposiciones',url:'',icon:'published_with_changes'},
    {desc:'Gestión de depositos',url:'',icon:'inventory'}
  ];*/
  sidenavSections:SectionItem[] = []
  selectableThemes: string[]=[];    
  selectedTheme: string | null;
  havePhoto= false;
  loading: boolean = true;
  bmsGrafana: string = this.environment.grafana;
  includeGrafana: boolean = false;

  constructor( private empusaThemeService: EmpusaThemeService,
    private router: Router,
    private empusaPortalAuthervice: EmpusaPortalAuthService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment:any) {
    

    /**Theme manager */
    this.selectableThemes = environment.selectableThemes
    this.empusaThemeService.loadThemes(THEMES, this.environment.theme)
    if (this.selectableThemes && this.selectableThemes.length > 1){
        this.selectedTheme = this.empusaThemeService.setTheme(this.empusaThemeService.retrieveUserTheme())
    }else{
        this.selectedTheme = this.empusaThemeService.setTheme(this.environment.theme)
    }

    
    /** Initialize permissions and access */
    let allModules: EmpusaMicroApp[] = (<any>window).empusa.modules;

    this.empusaPortalAuthervice.checkModulesPermissions(allModules,this.getCurrentPath())
    
    this.empusaPortalAuthervice.loading.subscribe((value:boolean) =>{            
        this.loading = value
    })

    this.authService.isAuthInitialized().subscribe(init=>{
      if (init){
        this.currentUser = this.authService.user?.givenName!
      }
    })

    this.empusaPortalAuthervice.modulesInMenu.subscribe((modules:EmpusaMicroApp[]) =>{ 
        this.sidenavSections =[] 
        modules.forEach(module=>{
          const moduleMenu: SectionItem = {
            desc: module.name,
            icon: module.icon,
            href: undefined,
            url: module.path
          }
          this.sidenavSections.push(moduleMenu)          
        });

        this.empusaPortalAuthervice.includeGrafana.subscribe((value:boolean)=>{            
          const grafanaMenu: SectionItem = {
            desc: "Dashboards",
            icon: "trending_up",
            href: this.bmsGrafana,
            url: undefined,
          }
          this.sidenavSections.push(grafanaMenu)
        })
        
    })
  }

  ngOnInit(): void {
  }

  getCurrentPath(): string {
    let currentRoute = this.router.url;
    return currentRoute.split('/')[1];
  }

  profileUser(){}
}
