<form [formGroup]="groupFormGroup" *ngIf="groupFormGroup" style="text-align: center;">
    <mat-form-field>
        <mat-label>{{'KAM-MANAGE-GROUPS.STEP1.NAME' | translate}}</mat-label>
        <input matInput placeholder="{{'KAM-MANAGE-GROUPS.STEP1.NAME_PLACEHOLDER' | translate}}" formControlName="name">
    </mat-form-field>
    <div>
        <button mat-button matStepperNext (click)="step1End()"
        [disabled]="!groupFormGroup.valid">{{'KAM-MANAGE-GROUPS.STEP1.NEXT' | translate}}</button>
    </div>
</form>

