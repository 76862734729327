<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<!-- MIGAS DE PAN Y TÍTULO -->
<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Administración</li>
          <li class="breadcrumb-item">Tarifas Puntos Recogida</li>
          <li class="breadcrumb-item active"><a href="#/administration/ratesCollectionPoint/search">Búsqueda de tarifas
              de puntos de recogida</a></li>
        </ol>
      </nav>
      <div class="content-title">
        <h2 class="title-pag">Búsqueda de tarifas de puntos de recogida</h2>
        <button type="button" class="btn btn-primary" data-toggle="modal" (click)="abrirModalTarifa()"><span
            class="icon-add"></span>Nueva
          Tarifa</button>
      </div>
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->
<!-- CONTENIDO -->

<div class="container-form">
  <!-- Formulario de búsqueda -->
  <form class="container form-search py-3" [formGroup]="searchForm">
    <div class="row-fields">
      <div class="field field-15">
        <label for="fechaDesde">Fecha desde <span class="oblig">*</span></label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="datepickerDesde" formControlName="fechaDesde">
          <mat-datepicker-toggle matIconSuffix [for]="datepickerDesde"></mat-datepicker-toggle>
          <mat-datepicker #datepickerDesde>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaDesde', 'matDatepickerParse')">Formato incorrecto</mat-error>
          <mat-error *ngIf="controlHasError('fechaDesde', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="field field-15">
        <label for="fechaHasta">Fecha hasta <span class="oblig">*</span></label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="fechaHasta" formControlName="fechaHasta">
          <mat-datepicker-toggle matIconSuffix [for]="fechaHasta"></mat-datepicker-toggle>
          <mat-datepicker #fechaHasta>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaHasta', 'matDatepickerParse')">Formato incorrecto</mat-error>
          <mat-error *ngIf="controlHasError('fechaHasta', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="field field-40" style="margin-top:-4px">
        <label for="codigoLer">Código LER y descripción de ENVALORA </label>
        <div class="custom-mat-field">
          <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="codigosLerEndpoint"
            [defaultFilter]=defaultFilter [descripcion]="getLerDescription"
            [formControlNameSelect]="getFormControl('codigoLer')" [objQueryName]="getObjectQuery"
            [placeHolder]="'-- Código LER y descripción de ENVALORA --'" [customClass]="rightHolder('codigoLer')">
          </lib-ge-select-pagination>
        </div>
      </div>
      <div class="field field-20">
        <label for="provincia">Provincia </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="provincia" id="provincia" placeholder="-- Provincia --">
            <mat-option *ngFor="let provincia of provinciaArray" [value]="provincia">
              {{ provincia.nombre }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('provincia')" tabindex="0" class="ng-clear-wrapper"
            (click)="clearSelection($event,'provincia')">
            <span aria-hidden="true" class="ng-clear">×</span>
          </span>
        </mat-form-field>

      </div>
      <div class="field field-10">
        <label for="valorTarifa">€ / Kg </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input type="number" matInput formControlName="valorTarifa" id="valorTarifa" placeholder="€ / Kg" type="text"
            appTwoDigitDecimalNumber>
        </mat-form-field>
      </div>
    </div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" (click)="limpiar()">Limpiar</button>
      <button type="button" class="btn btn-primary" (click)="buscar()">Buscar</button>
    </div>
  </form>
  <!-- FIN Formulario de búsqueda -->
</div>

<div class="bg-second-table-info pt-3 pb-5">
  <div *ngIf="flagSearch; else messageClear" class="container">
    <div class="actions-table">
      <div class=""><span class="align-middle tit-table">{{ recordsTotal }} registros encontrados</span></div>
      <div class="text-right">
        <div class="d-inline">
          <div class="d-inline dropdown show">
            <a (click)="exportarExcel()" class="btn btn-outline-primary" role="button" aria-haspopup="true"
              aria-expanded="false">
              <span class="mr-3">Exportar</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-tabla-proy">
      <div class="card-body">
        <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
          <div class="datatable-container table-responsive">
            <!-- TABLA Listado con resultados de búsqueda -->
            <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (actionButton)="action($event)">
            </lib-ge-table>
            <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
              <div class="d-flex mt-2">
                <ngb-pagination [pageSize]="pageSize" [collectionSize]="recordsTotal" [(page)]="pageNumber"
                  (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
                </ngb-pagination>
              </div>

              <div class="mt-2 selectPage">
                <label for="itemsPerPage">Mostrar:</label>
                <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                  <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                </select>
              </div>
            </div>
            <!-- FIN TABLA Listado con resultados de búsqueda -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #messageClear>
    <div class="mensaje-container">
      <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
        formulario,</p>
      <p class="mensaje-linea">o cree un nuevo registro desde el botón "Nueva tarifa"</p>
    </div>
  </ng-template>
</div>
<!-- FIN CONTENIDO -->
