/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TipoEnvasesDTO } from './tipoEnvasesDTO';
import { CategoriaEnvaseDTO } from './categoriaEnvaseDTO';


export interface TarifaAdheridoSDDRAbiertoPayloadDTO { 
    id?: number;
    anio: number;
    peligrosidad: boolean;
    eurosUnidad: number;
    depositoUnidad: number;
    tipologia: TarifaAdheridoSDDRAbiertoPayloadDTO.TipologiaEnum;
    categoria: CategoriaEnvaseDTO;
    tipoEnvases: TipoEnvasesDTO;
}
export namespace TarifaAdheridoSDDRAbiertoPayloadDTO {
    export type TipologiaEnum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const TipologiaEnum = {
        Primario: 'PRIMARIO' as TipologiaEnum,
        Secundario: 'SECUNDARIO' as TipologiaEnum,
        Terciario: 'TERCIARIO' as TipologiaEnum
    };
}


