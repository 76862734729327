import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackagingReuseComponent } from '../pages/packaging-reuse.component';
import { RouterModule } from '@angular/router';

export const explorerRoutes = [
  {
    path: '', component: PackagingReuseComponent, children: []
  },
];

@NgModule({
  declarations: [
    PackagingReuseComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(explorerRoutes),
  ],
  exports: [
    PackagingReuseComponent
  ]
})
export class PackagingReuseHomeModule {
  constructor() {
    let compilationVersion = "0.0.1-R->04/10/23-12:14:49"
    console.log("Home version:" + compilationVersion)
  }
 }
