import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { database as macroappDatabase } from './fake.database';
import { EmpusaMicroApp, EmpusaAuthenticationService } from '@empusa/empusa-core';
import { deepMerge } from '../core/util';

// array in local storage for registered users
//let users = JSON.parse(localStorage.getItem('users')) || [];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

    /** list of modules availables */
    microapps: EmpusaMicroApp[];
    database: any;

    constructor(private authservice: EmpusaAuthenticationService, private http: HttpClient) {
        this.microapps = <EmpusaMicroApp[]>((<any>window).empusa.modules);
        //joining all the microapp databases into a central one
        this.database = { ...this.database, ...macroappDatabase };
        if (this.microapps && this.microapps != null) {
            this.microapps.forEach(microapp => {
                if (microapp.fakebackend && microapp.fakebackend.microappFakeData) {
                    deepMerge(this.database, microapp.fakebackend.microappFakeData);
                    //spread has a problem, it will not merge deep existing fields :(
                    //this.database = { ...this.database, ...microapp.fakebackend.microappFakeData };
                }
            });
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let mail: string | null = null;
        let user = null;
        let httpclient = this.http;
        try {
            mail = this.authservice.getCurrentUserName();
            user = this.database.users.filter((x: { mail: string | null; }) => x.mail === mail)[0];
        } catch (ex) { }


        const { url, method, headers, body } = request;

        let self = this;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(delay(500))
            .pipe(dematerialize());


        function handleRoute(): Observable<HttpEvent<any>> {

            switch (true) {
                case url.endsWith('mine') && method === 'GET':
                    return getLoggedUser();

                case url.match(/\/login\?.*$/) && method === 'GET':
                    return login();

                case url.match(/\/microappssecurity\/services\/microapps\/.*$/) && method === 'GET':
                    return getMicroappTree();


                default:
                    //lets check microapps
                    for (let i = 0; i < self.microapps.length; i++) {
                        let microapp = self.microapps[i];
                        if (microapp.fakebackend && microapp.fakebackend != null) {
                            let response = <any>microapp.fakebackend.handleRoute(url, method, headers, body, self.database);
                            if (response && response != null) {
                                return response;
                            }
                        }
                    }

                    // pass through any requests not handled above
                    return next.handle(request);
            }
        }


        /** fake login */
        function login() {
            const urlParts = url.split('?');
            let last = urlParts[1];
            let params = last.split("&");
            mail = params.filter(param => param.startsWith("mail"))[0];
            mail = mail.split("=")[1];
            let password = params.filter(param => param.startsWith("password"))[0];
            password = password.split("=")[1]; //TODO encryption
            let useri = self.database.users.filter((x: { mail: string | null; userPassword: string; }) => x.mail === mail && x.userPassword == password)[0];
            if (useri && useri != null) {
                let logindata = {
                    mail: useri.mail,
                    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"
                }
                return ok(logindata)
            } else {
                return throwError({ error: 'No user valid' })
            }
        }

        // route USERS functions
        function getLoggedUser() {
            let useri = self.database.users.filter((x: { mail: any; }) => x.mail === mail)[0];
            if (useri && useri != null) {
                return ok({ userInfo: useri });
            } else {

                return ok({ userInfo: "dummy" });

            }
        }

        /** return the microapp security tree for a certain microapp */
        function getMicroappTree() {
            let microapp = idFromUrl();
            let microappPermissions = self.database.microappSecurityTree[microapp] || {};

            return ok(microappPermissions);
        }


        // helper functions
        function ok(body?: any) {
            return of(new HttpResponse({ status: 200, body }))
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }

        function error(message: any) {
            return throwError({ error: { message } });
        }

        function isLoggedIn() {
            return true;//headers.get('Authorization') === 'Bearer fake-jwt-token';
        }

        function idFromUrl() {
            const urlParts = url.split('/');
            return urlParts[urlParts.length - 1];
        }
    }
}

export const FakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};