import { NgModule } from '@angular/core';
import { KeycloakAuthManagerMainComponent } from '../pages/keycloak-auth-manager-main/keycloak-auth-manager-main.component';
import { locale_en as english } from '../translations/translation.en';
import { locale_es as spanish } from '../translations/translation.es';
import { locale_en_GB as english_GB } from '../translations/translation.en-GB';
import { locale_es_ES as spanish_ES } from '../translations/translation.es-ES';
import { locale_es_MX as es_MX } from '../translations/translation.es-MX';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EmpusaAppHeaderClassicComponent, EmpusaSpinnerComponent, EmpusaTranslationLoaderService } from '@empusa/empusa-core';
import { TranslateModule } from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {authManagerReducer} from '../state/auth-manager.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthManagerEffects } from '../state/auth-manager.effects';
import { KamRolesTabComponent } from '../pages/kam-roles-tab/kam-roles-tab.component';
import { KamGroupsTabComponent } from '../pages/kam-groups-tab/kam-groups-tab.component';
import { KamUsersTabComponent } from '../pages/kam-users-tab/kam-users-tab.component';
import { KamPermissionsTabComponent } from '../pages/kam-permissions-tab/kam-permissions-tab.component';
import {MatTableModule} from '@angular/material/table';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {MatTreeModule} from '@angular/material/tree';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { KamManageResourceComponent } from '../pages/kam-permissions-tab/kam-manage-resource/kam-manage-resource.component';
import {MatDialogModule} from '@angular/material/dialog';
import { KamQuestionDialogComponent } from '../pages/common/kam-question-dialog/kam-question-dialog.component';
import { KamAssignPermissionsToRoleComponent } from '../pages/kam-roles-tab/permissions/kam-assign-permissions-to-role/kam-assign-permissions-to-role.component';
import { KamPermissionsTabServersComponent } from '../pages/kam-permissions-tab/kam-permissions-tab-servers/kam-permissions-tab-servers.component';
import { KamPermissionsTabResourcesComponent } from '../pages/kam-permissions-tab/kam-permissions-tab-resources/kam-permissions-tab-resources.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatPaginatorModule} from '@angular/material/paginator';
import { KamManageUserComponent } from '../pages/kam-users-tab/kam-manage-user/kam-manage-user.component';
import { KamManagePermissionComponent } from '../pages/kam-permissions-tab/kam-manage-permission/kam-manage-permission.component';
import { KamManageScopeComponent } from '../pages/kam-permissions-tab/kam-manage-scope/kam-manage-scope.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import { ManageGroupComponent } from '../pages/kam-groups-tab/manage-group/manage-group.component';
import {MatStepperModule} from '@angular/material/stepper';
import { KamMgStep1Component } from '../pages/kam-groups-tab/manage-group/kam-mg-step1/kam-mg-step1.component';
import { KamMgStep2Component } from '../pages/kam-groups-tab/manage-group/kam-mg-step2/kam-mg-step2.component';
import { KamMgStep3Component } from '../pages/kam-groups-tab/manage-group/kam-mg-step3/kam-mg-step3.component';
import { KamRoleSelectionComponent } from '../pages/common/kam-role-selection/kam-role-selection.component';
import { KamAttributesFormComponent } from '../pages/common/kam-attributes-form/kam-attributes-form.component';
import { KamMgStep4Component } from '../pages/kam-groups-tab/manage-group/kam-mg-step4/kam-mg-step4.component';
import {MatSortModule} from '@angular/material/sort';
import { KamNewUserComponent } from '../pages/kam-users-tab/kam-new-user/kam-new-user.component';
import { KamUserFormComponent } from '../pages/kam-users-tab/kam-user-form/kam-user-form.component';
import { KamNuStep1Component } from '../pages/kam-users-tab/kam-new-user/kam-nu-step1/kam-nu-step1.component';
import { KamNuStep2Component } from '../pages/kam-users-tab/kam-new-user/kam-nu-step2/kam-nu-step2.component';
import { KamNuStep3Component } from '../pages/kam-users-tab/kam-new-user/kam-nu-step3/kam-nu-step3.component';
import {MatRadioModule} from '@angular/material/radio';
import { KamGroupSelectionComponent } from '../pages/common/kam-group-selection/kam-group-selection.component';
import { KamNuStep4Component } from '../pages/kam-users-tab/kam-new-user/kam-nu-step4/kam-nu-step4.component';
import { KamNuStep5Component } from '../pages/kam-users-tab/kam-new-user/kam-nu-step5/kam-nu-step5.component';
import { KamUserRolesComponent } from '../pages/kam-users-tab/kam-user-roles/kam-user-roles.component';
import { KamUserAttributesComponent } from '../pages/kam-users-tab/kam-user-attributes/kam-user-attributes.component';
import { KamUserGroupsComponent } from '../pages/kam-users-tab/kam-user-groups/kam-user-groups.component';
import { KamGroupDataComponent } from '../pages/kam-groups-tab/group-data/group-data.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { KamManageRoleComponent } from '../pages/kam-roles-tab/kam-manage-role/kam-manage-role.component';


export const explorerRoutes = [
  {
    path: '', component: KeycloakAuthManagerMainComponent, children: []
  },
];

@NgModule({
  declarations: [    
    KamQuestionDialogComponent,
    KeycloakAuthManagerMainComponent,
    KamRolesTabComponent,
    KamGroupsTabComponent,
    KamGroupDataComponent,
    KamUsersTabComponent,
    KamPermissionsTabComponent,
    KamManageResourceComponent,
    KamManageRoleComponent,
    KamManageScopeComponent,
    KamManagePermissionComponent,
    KamAssignPermissionsToRoleComponent,
    KamPermissionsTabServersComponent,
    KamPermissionsTabResourcesComponent,
    KamManageUserComponent,
    ManageGroupComponent,
    KamRoleSelectionComponent,
    KamAttributesFormComponent,
    KamMgStep1Component,
    KamMgStep2Component,
    KamMgStep3Component,
    KamMgStep4Component,
    KamNewUserComponent,
    KamUserFormComponent,
    KamNuStep1Component,
    KamNuStep2Component,
    KamNuStep3Component,
    KamGroupSelectionComponent,
    KamNuStep4Component,
    KamNuStep5Component,
    KamUserRolesComponent,
    KamUserAttributesComponent,
    KamUserGroupsComponent,
  
  ],
  imports: [
    StoreModule.forRoot({ authManagerRdc: authManagerReducer }),
    EffectsModule.forRoot([AuthManagerEffects]),
    CommonModule,
    EmpusaSpinnerComponent,
    MatSnackBarModule,
    MatFormFieldModule,
    MatTableModule,
    MatTabsModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatTreeModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatDividerModule,
    MatStepperModule,
    MatSortModule,
    MatRadioModule,
    EmpusaAppHeaderClassicComponent,
    RouterModule.forChild(explorerRoutes),
    TranslateModule,
  ],
  exports: [
    TranslateModule,
  ],
  providers: [
    StoreModule
  ]
})
export class KeycloakAuthManagerHomeModule { 

  constructor(private translationLoader: EmpusaTranslationLoaderService) {
   let compilationVersion = "0.0.1-R->04/10/23-12:14:49"
     console.log("keycloak-auth-manager version: " + compilationVersion)
     //loading empusa core translations
     this.translationLoader.loadTranslations(english);
     this.translationLoader.loadTranslations(spanish);
     this.translationLoader.loadTranslations(english_GB);
     this.translationLoader.loadTranslations(spanish_ES);
     this.translationLoader.loadTranslations(es_MX);
   }
}
