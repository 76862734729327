import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';
import { SnackbarService } from '../../services/snackbar.service';
import {
  AportacionDIEstadoControllerService,
  AportacionDIPoseedorControllerService,
  SolicitudNimaGestoresDTO,
} from 'api-rest';
import { Subscription, map } from 'rxjs';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';

@Component({
  selector: 'app-managers-agreements-manager-selection-dialog',
  templateUrl: './managers-agreements-manager-selection-dialog.component.html',
  styleUrls: ['./managers-agreements-manager-selection-dialog.component.scss'],
})
export class ManagersAgreementsManagerSelectionDialogComponent implements OnInit {
  nimaGestorArray: any[] = [];
  entityRoles: any[] = [];

  modelForm: FormGroup;
  subscriptions: Subscription[] = [];
  roleEntities: any[] = [];

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private dialogRef: MatDialogRef<ManagersAgreementsManagerSelectionDialogComponent>,
    private estadoAportacionesService: AportacionDIEstadoControllerService,
    private aportacionesDiService: AportacionDIPoseedorControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService
  ) {
    this.modelForm = this.formBuilder.group({
      fechaAceptacion: [{ value: null, disabled: false }],
      gestorResiduo: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.roleEntities = (this.authService.user as any).roleEntities;

    this.buscarNimaGestor();
    this.estadoAportacionesService
      .findAll14()
      .pipe(map((estados) => estados.find((estado) => estado.id === 1)))
      .subscribe((estado) => {
        if (estado) {
          this.modelForm.get('estado')?.setValue(estado);
        }
      });
  }

  buscarNimaGestor() {
    const filtroNimaGestor: SolicitudNimaGestoresDTO = {
      fechaAceptacion: this.modelForm.value?.fechaAceptacion,
    };

    this.aportacionesDiService
      .nimaGestores(filtroNimaGestor)
      .subscribe((res) => (this.nimaGestorArray = res));
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  seleccionar() {
    if (this.canSelectionGestor()) {
      this.dialogRef.close(this.modelForm.value);
    } else {
      this.snackBarSrv.showSnackBar('Debe seleccionar un Nima', 'error');
    }
  }

  showClearButton(formName: string): boolean {
    return this.modelForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.modelForm.get(formName)?.setValue(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.modelForm.controls[controlName].hasError(errorName) &&
      this.modelForm.controls[controlName].touched
    );
  }

  canSelectionGestor(): boolean {
    return this.modelForm.get('gestorResiduo')?.value != null;
  }
}
