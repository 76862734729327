  <!-- MIGAS DE PAN Y TÍTULO -->
  <div class="bg-second-migas">
    <div class="container mt-cab-fixed">
      <div class="pre-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Administración</li>
            <li class="breadcrumb-item">Plantillas de documentos</li>
          </ol>
        </nav>
        <div class="content-title">
          <h2 class="title-pag">Plantillas de Documentos</h2>
          <button type="button" class="btn btn-primary" data-toggle="modal"
            (click)="getTemplates()"><span class="reload"></span>Actualizar</button>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN MIGAS DE PAN Y TÍTULO -->
  <!-- CONTENIDO -->

  <div class="bg-second-table-info pt-3 pb-5">
    <div *ngIf="flagSearch; else messageClear" class="container">
      <div class="actions-table">
        <div class=""><span class="align-middle tit-table">{{ recordsTotal }} registros</span></div>
      </div>
      <div class="card-tabla-proy">
        <div class="card-body">
          <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
            <div class="datatable-container table-responsive">
              <!-- TABLA Listado con resultados de búsqueda -->
              <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (actionButton)="action($event)">
              </lib-ge-table>
              <!-- FIN TABLA Listado con resultados de búsqueda -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <input #file type="file" (change)="file && file.files && attachAdjunto(file.files[0])" class="hide"
    id="adjuntar" name="adjuntar">
    <ng-template #messageClear>
        <div class="mensaje-container">
          <p class="mensaje-linea">No existen plantillas de documentos</p>
          <p class="mensaje-linea">Contacte al administrador de sistema para insertar nuevas plantillas</p>
        </div>
    </ng-template>
  </div>

