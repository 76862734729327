import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { KeycloakRoleModel } from '../../../../model/keycloak-role.model';
import { KeycloakGroupService } from '../../../../services/kam-group.services';


@Component({
  selector: 'keycloak-auth-manager-kam-mg-step3',
  templateUrl: './kam-mg-step3.component.html',
  styleUrls: ['./kam-mg-step3.component.css']
})
export class KamMgStep3Component implements OnInit, OnDestroy {

  roleSelection: KeycloakRoleModel[] | undefined;
  initialRolesSelected: string[]|undefined;
  subscription: Subscription | undefined;

  constructor(private keycloakGroupService: KeycloakGroupService) { }
 

  ngOnInit(): void {
    this.subscription = this.keycloakGroupService.dataSubscription().subscribe(group=>{
      if (group)
        this.initialRolesSelected = group.realmRoles;      
    })
  }

  rolesSubscriptionUpdate($event: any){
    this.roleSelection = $event
    this.step3End()
  }

  step3End(){
    if (this.roleSelection)
      this.keycloakGroupService.dataSetSelectedRoles(this.roleSelection)
    else
      this.keycloakGroupService.dataSetSelectedRoles([])
  }

  ngOnDestroy(): void {
  }

}
