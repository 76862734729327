<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>

<div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">

    <!-- Formulario de tarifa abierta-->
    <form class="form-modal py-3  flex-container" [formGroup]="formGroup">
      <div class="row-fields">
        <div class="field field-25">
          <label for="anio">Año <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input  type="number" matInput formControlName="anio" id="anio" placeholder="Año" value="" [min]="0">
            <mat-error *ngIf="formGroup.get('anio')?.invalid && formGroup.get('anio')?.touched">Año inválido (1900
              a 2500)
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field field-25">
          <label for="valorTarifa">€ / Kg <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input type="number" matInput formControlName="valorTarifa" id="valorTarifa" placeholder="€ / Kg"
              type="text" appTwoDigitDecimalNumber>
            <mat-error *ngIf="controlHasError('valorTarifa', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="field field-50">
          <label for="gestor">Gestor <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="gestoresEndpoint"
                                      [defaultFilter]=defaultFilter [descripcion]="getGestorDescription"
                                      [formControlNameSelect]="getFormControl('gestor')" [objQueryName]="getObjectQuery"
                                      [placeHolder]="'-- Gestor --'" [customClass]="rightHolder('gestor')">
            </lib-ge-select-pagination>
            <div class="ge-select-error">
              <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('gestor')">Campo
                obligatorio
              </mat-error>
            </div>
          </div>
        </div>
      </div>
      <div class="row-fields">
        <div class="field field-100">
          <label for="codigoLer">Código LER y descripción de ENVALORA <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="codigosLerEndpoint"
              [descripcion]="getLerDescription" [defaultFilter]=defaultFilter
              [formControlNameSelect]="getFormControl('codigoLer')" [objQueryName]="getObjectQuery"
              [placeHolder]="'-- Código LER y descripción de ENVALORA --'" [customClass]="rightHolder('codigoLer')">
            </lib-ge-select-pagination>
            <div class="ge-select-error">
              <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('codigoLer')">Campo
                obligatorio
              </mat-error>
            </div>
          </div>
        </div>


      </div>
    </form>
    <!-- FIN Formulario tarifa abierta -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
