/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsuarioSDDRCaDTO } from './usuarioSDDRCaDTO';
import { IdEntityDTO } from './idEntityDTO';


export interface SolicitudRetiradaFilterDTO { 
    id?: number;
    tipoUsuario?: SolicitudRetiradaFilterDTO.TipoUsuarioEnum;
    idUsuario?: number;
    nima?: string;
    usuarioSDDRCA?: UsuarioSDDRCaDTO;
    tipoEnvase?: IdEntityDTO;
    subTipoEnvase?: IdEntityDTO;
    loteEnvase?: string;
    gestorAcondicionador?: IdEntityDTO;
    fechaDesde?: string;
    fechaHasta?: string;
    estado?: IdEntityDTO;
}
export namespace SolicitudRetiradaFilterDTO {
    export type TipoUsuarioEnum = 'ADHERIDO' | 'POSEEDOR';
    export const TipoUsuarioEnum = {
        Adherido: 'ADHERIDO' as TipoUsuarioEnum,
        Poseedor: 'POSEEDOR' as TipoUsuarioEnum
    };
}


