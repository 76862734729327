import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import moment from 'moment';
import {
  TarifaGestoresControllerService,
  TarifaGestoresPayloadDTO,
  TarifaGestoresDTO,
} from 'api-rest';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';

@Component({
  selector: 'lib-managers-agreements-rates-dialog',
  templateUrl: './managers-agreements-rates-dialog.component.html',
  styleUrls: ['./managers-agreements-rates-dialog.component.scss'],
})
export class ManagersAgreementsRatesDialogComponent implements OnInit {
  titulo: String = '';
  formGroup!: FormGroup;
  tarifa: any;
  defaultFilter: boolean = true;
  codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?acuerdoGestores=true';
  gestoresFilter = '?gestoresResiduosEstado.id=2&acuerdoEnvalora=true';
  listaGestores = this.environment.urlBackCore + 'api/v1/poseedores/gestores-residuos' + this.gestoresFilter;
  updateList: boolean = false;
  flagDisabledForm: boolean = false;
  provinciaArray: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ManagersAgreementsRatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private tarifaGestoresControllerSrv: TarifaGestoresControllerService,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.tarifa = this.data.tarifa;
    this.provinciaArray = this.data.provincias;

    this.formGroup = this.formBuilder.group({
      year: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      gestor: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoLer: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      valorTarifa: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
  }
  ngOnInit(): void {
    if (this.tarifa) {
      this.titulo = 'Edición tarifa Acuerdos con gestor';
      this.cargarTarifa(this.tarifa);
    } else {
      this.titulo = 'Nueva tarifa de Acuerdos con gestor';
    }
  }
  cargarTarifa(tarifa: TarifaGestoresDTO): void {
    this.formGroup.get('year')?.setValue(tarifa?.year);
    this.formGroup.get('gestor')?.setValue(tarifa?.gestor?.razonSocial);
    this.formGroup.get('codigoLer')?.setValue(tarifa?.codigoLer?.descripcion);
    this.formGroup
      .get('valorTarifa')
      ?.setValue(this.formatCurrencyPipe.transform(tarifa?.valorTarifa));
  }

  cerrar() {
    this.dialogRef.close(null);
  }
  guardar(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }
    const form = this.formGroup.value;
    let codigoLer = form.codigoLer;
    let gestor = form.gestor;
    let body: TarifaGestoresPayloadDTO = {
      year: form.year,
      gestor: typeof gestor === 'string' ? this.tarifa?.gestor : form.gestor,
      valorTarifa: this.parseCurrencyPipe.transform(form.valorTarifa),
      codigoLer: typeof codigoLer === 'string' ? this.tarifa?.codigoLer : form.codigoLer,
    };

    let request;
    let msg = '';
    // public createTarifaPtoRecogida(tarifaPtoRecogidaPayloadDTO: TarifaPtoRecogidaPayloadDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TarifaPtoRecogidaDTO>;
    if (this.tarifa) {
      body.id = this.tarifa.id;
      request = this.tarifaGestoresControllerSrv.editById1(
        this.tarifa.id,
        body
      );
      msg = 'La tarifa se ha actualizado correctamente';
    } else {
      request =
        this.tarifaGestoresControllerSrv.create1(body);
      msg = 'La tarifa se ha creado correctamente';
    }
    this.spinnerSrv.loadingShow();
    request.subscribe({
      next: () => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(msg, 'success');
        this.dialogRef.close(true);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }
  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }
  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }
  getGestorDescription(gestor: any) {
    return gestor?.razonSocial;
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.formGroup.get('codigoLer') as FormControl;
      }
      case 'gestor': {
        return this.formGroup.get('gestor') as FormControl;
      }
      default: {
        return this.formGroup.get('') as FormControl;
      }
    }
  }
  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }
  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }
}
