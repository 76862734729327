<div class="panel">
  <form [formGroup]="sectoresFormGroup" *ngIf="sectores">
    <ul style="list-style-type: none; max-height: 270px; overflow-y: auto;">
      <li *ngFor="let el of sectores;">
        <mat-checkbox [formControlName]="el.id" color="primary">
          {{el.descripcion}}
        </mat-checkbox>
      </li>
    </ul>
  </form>
</div>
<div class="button-cont" *ngIf="canAccessSectoresEdit() && canAccessEditAllGlobal()">
  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardarSectores()"
    aria-label="Guardar">Guardar</button>
</div>
