import { Component, Input, OnInit } from '@angular/core';
import { SectionItem } from './sectionItem';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-home-minimal-side-nav',
  templateUrl: './home-minimal-side-nav.component.html',
  styleUrls: ['./home-minimal-side-nav.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate('350ms', style({opacity:1})
        )
      ]),
      transition(':leave', [
        style({opacity:1}),
        animate('350ms', style({opacity:0})
        )
      ])
    ])
  ]
})
export class HomeMinimalSideNavComponent implements OnInit {
  @Input() list_sections: Array<SectionItem> = [];

  collapsed = false;
  
  constructor() { }


  ngOnInit(): void {
  }

  toggle(){
    this.collapsed = !this.collapsed;
  }
}
