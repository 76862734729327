import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  URL_CORE: string = this.environment.urlBackCore;
  URL_DOCUM: string = this.environment.urlBackDocum;

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  putDocumento(
    file: File,
    id: number,
    containerName: string,
    entityId: number,
    documentType: number,
    entityType: string
  ): Observable<any> {
    const fullRoute = `${this.URL_DOCUM}api/v1/docum/${id}/upload?entityId=${entityId}&entityType=${entityType}&containerName=${containerName}&documentType=${documentType}`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.put(fullRoute, formData, { responseType: 'text' });
  }

  postDocumento(
    file: File,
    containerName: string,
    documentType: number,
    documentDesc: string,
    entityId: number,
    entityType: string
  ): Observable<any> {
    const fullRoute = `${this.URL_DOCUM}api/v1/docum/upload?entityId=${entityId}&entityType=${entityType}&containerName=${containerName}&documentType=${documentType}&documentDesc=${documentDesc}`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(fullRoute, formData, { responseType: 'text' });
  }

  httpDownloadOptions: Object = {
    headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
    responseType: 'blob',
  };
  descargarDocumento(id: number): Observable<any> {
    const fullRoute = `${this.URL_DOCUM}api/v1/docum/download/${id}`;
    return this.httpClient.get<any>(fullRoute, this.httpDownloadOptions);
  }
}
