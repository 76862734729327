import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appEuroCurrency]'
})
export class EuroCurrencyDirective implements OnInit {

  constructor(private el: ElementRef, private control: NgControl) {}

  ngOnInit() {
    this.formatValue();
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any): void {
    this.formatValue();
  }

  @HostListener('focus', ['$event'])
  onFocus(event: any): void {
    const inputValue = this.el.nativeElement.value;
    if (inputValue.includes('€')) {
      this.el.nativeElement.value = inputValue.replace(' €', '');
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const inputValue = event.target.value;
    if (this.control && this.control.control) {
      const numericValue = inputValue.replace(' €', '');
      this.control.control.setValue(numericValue, { emitEvent: false });
    }
  }

  private formatValue(): void {
    const inputValue = this.el.nativeElement.value;
    if (inputValue && !inputValue.includes('€')) {
      this.el.nativeElement.value = `${inputValue} €`;
    }
  }
}
