/**
 * Puntos de recogida API
 * Puntos de recogida API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaisDTO } from './paisDTO';


export interface ContactoAltaPayloadDTO { 
    nombre: string;
    apellido1: string;
    apellido2?: string;
    email: string;
    tipo_documento: ContactoAltaPayloadDTO.TipoDocumentoEnum;
    codigo_documento: string;
    tipoTelefono?: ContactoAltaPayloadDTO.TipoTelefonoEnum;
    numeroTelefono?: string;
    codigoPais?: PaisDTO;
}
export namespace ContactoAltaPayloadDTO {
    export type TipoDocumentoEnum = 'NIF' | 'DNI' | 'NIE';
    export const TipoDocumentoEnum = {
        Nif: 'NIF' as TipoDocumentoEnum,
        Dni: 'DNI' as TipoDocumentoEnum,
        Nie: 'NIE' as TipoDocumentoEnum
    };
    export type TipoTelefonoEnum = 'FIJO' | 'MOVIL';
    export const TipoTelefonoEnum = {
        Fijo: 'FIJO' as TipoTelefonoEnum,
        Movil: 'MOVIL' as TipoTelefonoEnum
    };
}


