/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BusquedaAdheridoDTO { 
    id?: Array<number>;
    estado?: Array<number>;
    registroProductor?: string;
    codigoDocumento?: string;
    razonSocial?: string;
    nombreGrupo?: string;
    cnae?: string;
    comunidadAutonoma?: number;
    cifraNegocio?: number;
    declaracionFacturada?: boolean;
    empty?: boolean;
}

