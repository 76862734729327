import { NgModule } from '@angular/core';
import { EmpusaMicroAppType } from '@empusa/empusa-core';
import { ManagerHomeModule } from './manager-home.module';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class ManagersModule {
  name: string = "managers";
  description: string = "Home Portal"
  icon: string = "managers";
  page: any = ManagerHomeModule;
  homeApp: boolean = false;
  path: string = "managers";
  order: number = 1;
  resourceServerId: string = "keycloak-auth-manager-resources-server";
  appType: EmpusaMicroAppType = EmpusaMicroAppType.home;

  constructor() {
    //we register ourselves
    (<any>window).empusa(this);
    console.debug("Load microapp " + this.name);
  }
 }
