export const locale_es = {
    "lang": "es",
    "data": {
        "MAPP-HOME": { //mandatory - microapp context
            "WELCOME_TEXT": "Bienvenido/a a",
            "HELLO-WORLD" : "Hola Mundo",
            "EXPLORER" : "Explorador",
            "HOME" : "Principal",
            "KEYCLOAK-AUTH-MANAGER" : "Gestión de identidades", 
        }
    }
}