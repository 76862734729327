import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Inject } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Router, Routes } from '@angular/router';
import { FakeAuthGuard } from './auth/fake/fake.auth.guard';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomePage } from './pages/main/home/home.page';
import { LoginPage } from './pages/login/login.page';
import { environment } from 'src/environments/environment';
import { EmpusaKeycloakService } from './auth/keycloak/keycloak.auth.service';
import { EmpusaKeycloakAuthGuard } from './auth/keycloak/keycloak.guard';
import { CustomAuthGuard } from './auth/custom/custom.guard';
import { CustomAuthService } from './auth/custom/custom.auth.service';
import { CustomJwtInterceptor } from './auth/custom/custom.jwt.interceptor';
import { CustomErrorInterceptor } from './auth/custom/custom.error.interceptor';
import { FakeBackendProvider } from './fake/fake.backend';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  EmpusaMicroApp,
  EmpusaTranslationLoaderService,
  EmpusaSpinnerComponent,
} from '@empusa/empusa-core';
import { ExternalEmpusaModule } from './external.module';
import { locale_en as english } from './translations/translation.en';
import { locale_es as spanish } from './translations/translation.es';
import { SelectableLanguaje } from './model/selectable-languaje.interface';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { OAuthStorage } from 'angular-oauth2-oidc';

/****************************************************************/
/*******************     EMPUSA REGISTER      *******************/
/** empusa will be the "class" where everything starts */
function empusa(module: EmpusaMicroApp) {
  (<any>window).empusa.register(module);
}
(<any>window).empusa = empusa;
empusa.register = function (module: EmpusaMicroApp) {
  if (!(<any>window).empusa.modules) {
    (<any>window).empusa.modules = [];
  }
  (<any>window).empusa.modules.push(module);
};

/****************************************************************/
/*******************     TRANSLATE SERVICE    *******************/
//import { FakeAuthService } from './fake/fake.auth.service';
// registrar los locales con el nombre que quieras utilizar a la hora de proveer
// import locales
import localeEn from '@angular/common/locales/en';
import { SecurePipe } from './auth/secure.pipe';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './translations/paginator.i18n';
import { initializeKeycloak } from './auth/keycloak/keycloak-init.factory';
import { FakeAuthService } from './auth/fake/fake.auth.service';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileComponent } from './pages/profile/profile.component';
import { MatButtonModule } from '@angular/material/button';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { HomeMinimalSideNavComponent } from './pages/main/home-minimal/home-minimal-side-nav/home-minimal-side-nav.component';
import { HomeMinimalComponent } from './pages/main/home-minimal/home-minimal.component';
import {
  ApiAhderidos,
  ApiPoseedores,
  ApiPuntosrecogida,
  ApiUserMgmt,
  ApiComunicaciones,
  ApiSDDRCA,
  ApiAcuerdosGestores,
} from 'api-rest';
import { ResponseHandlerInterceptor } from './core/response.handler.interceptor';

registerLocaleData(localeEn, 'en');
// registerLocaleData(localeEs, 'es');
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/****************************************************************/
/*******************      AUTH SERVICES       *******************/
//defining the login/auth services, based onconfiguration
let auth_config = {
  //defining the auth services
  auth: EmpusaKeycloakService,
  //defining the authorization interceptors
  interceptors: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [KeycloakService],
      multi: true,
    },
  ],
  //defining the guard routing
  guard: EmpusaKeycloakAuthGuard,
};

//all these based on configuration
if (environment.auth === 'keycloak') {
  console.log('******** keycloak ***********');

  //keycloak configuration
  auth_config.guard = EmpusaKeycloakAuthGuard;
  auth_config.auth = EmpusaKeycloakService;
  auth_config.interceptors = [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [KeycloakService],
      multi: true,
    },
  ];
  /*} else if (environment.auth === 'oauth2') {
    console.log("---> Oauth2 Auth config");
    (<any>auth_config).guard = AuthGuardWithForcedLogin;
    (<any>auth_config).auth = AuthService;
    auth_config.interceptors = [];//no interceptors*/
} else if (environment.auth === 'custom') {
  //custom login
  (<any>auth_config).guard = CustomAuthGuard;
  (<any>auth_config).auth = CustomAuthService;
  (<any>auth_config).interceptors = [
    { provide: HTTP_INTERCEPTORS, useClass: CustomJwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomErrorInterceptor,
      multi: true,
    },
  ];
} else if (environment.auth === 'fake') {
  console.log('---> Fake Auth config');
  (<any>auth_config).guard = FakeAuthGuard;
  (<any>auth_config).auth = FakeAuthService;
  auth_config.interceptors = []; //no interceptors
}
/****************************************************************/
/*******************    ROOT CONFIGURATION    *******************/
const basicRootConfiguration: Routes = [
  { path: 'login', component: LoginPage },
  {
    path: '',
    component: HomePage,
    //path: '', component: HomeMinimalComponent,
    canActivate: [auth_config.guard],
  },
  { path: '**', redirectTo: '' },
];

/****************************************************************/
/*******************       FAKE BACKEND       *******************/

let fakeBackend = [];
if (environment.backend === 'fake') {
  fakeBackend.push(FakeBackendProvider);
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

export const ConfigFactory = () =>
  new ApiAhderidos.Configuration(<ApiAhderidos.ConfigurationParameters>{});
export const AdheridosBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE ADHERIDOS ' + environment.urlBackAdheridos);
  const basePath = environment.urlBackAdheridos;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const UserConfigFactory = () =>
  new ApiUserMgmt.Configuration(<ApiUserMgmt.ConfigurationParameters>{});
export const UsermgmtBasePathFactory = (environment: any) => {
  console.log(
    'USANDO URL DE ADMINISTRACIÓN ' + environment.urlBackAdministration
  );
  const basePath = environment.urlBackAdministration;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const PoseedoresConfigFactory = () =>
  new ApiPoseedores.Configuration(<ApiPoseedores.ConfigurationParameters>{});

export const PoseedoresBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE POSEEDORES ' + environment.urlBackPoseedores);
  const basePath = environment.urlBackPoseedores;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};


export const PuntosRecogidaConfigFactory = () =>
  new ApiPuntosrecogida.Configuration(<ApiPuntosrecogida.ConfigurationParameters>{});

export const PuntosRecogidaBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE PUNTOSRECOGIDA' + environment.urlBackPuntoRecogida);
  const basePath = environment.urlBackPuntoRecogida;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const ComunicacionesConfigFactory = () =>
  new ApiComunicaciones.Configuration(<ApiComunicaciones.ConfigurationParameters>{});

export const ComunicacionesBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE COMUNICACIONES' + environment.urlBackComunicaciones);
  const basePath = environment.urlBackComunicaciones;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const SDDRCAConfigFactory = () =>
  new ApiSDDRCA.Configuration(<ApiSDDRCA.ConfigurationParameters>{});

export const SDDRCABasePathFactory = (environment: any) => {
  console.log('USANDO URL DE SDDR CA' + environment.urlBackSddrCa);
  const basePath = environment.urlBackSddrCa;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const AcuerdosGestoresConfigFactory = () =>
  new ApiAcuerdosGestores.Configuration(<ApiAcuerdosGestores.ConfigurationParameters>{});

export const AcuerdosGestoresBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE ACUERDOS CON GESTORES' + environment.urlBackAcuerdosConGestores);
  const basePath = environment.urlBackAcuerdosConGestores;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};


/****************************************************************/
/*******************     MODULE DEFINITION    *******************/
@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    HomeMinimalComponent,
    HomeMinimalSideNavComponent,
    LoginPage,
    SecurePipe,
    ProfileComponent,
    TermsOfUseComponent,
  ],
  imports: [
    BrowserModule,
    ExternalEmpusaModule,
    EmpusaSpinnerComponent,
    ReactiveFormsModule,
    AppRoutingModule,
    KeycloakAngularModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatDialogModule,
    MatListModule,
    MatDividerModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    //routing
    RouterModule.forRoot(basicRootConfiguration, {
      //enable in case you are having problems with routing to show debug traces
      enableTracing: false,
      useHash: true,
    }),
    //translate
    HttpClientModule,
    TranslateModule.forRoot(),
    //Auth2CoreModule.forRoot(),
    ApiAhderidos.ApiModule.forRoot(ConfigFactory),
    ApiUserMgmt.ApiModule.forRoot(UserConfigFactory),
    ApiPoseedores.ApiModule.forRoot(PoseedoresConfigFactory),
    ApiPuntosrecogida.ApiModule.forRoot(PuntosRecogidaConfigFactory),
    ApiComunicaciones.ApiModule.forRoot(ComunicacionesConfigFactory),
    ApiSDDRCA.ApiModule.forRoot(SDDRCAConfigFactory),
    ApiAcuerdosGestores.ApiModule.forRoot(AcuerdosGestoresConfigFactory),
    BrowserAnimationsModule,
  ],
  exports: [EmpusaSpinnerComponent],
  providers: [
    MatSnackBar,
    auth_config.interceptors,
    auth_config.guard,
    {
      provide: 'EmpusaAuthenticationService',
      useClass: auth_config.auth,
    },
    fakeBackend,
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorI18n(translateService).getPaginatorIntl(),
    },
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: ApiAhderidos.BASE_PATH,
      useFactory: AdheridosBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiUserMgmt.BASE_PATH,
      useFactory: UsermgmtBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiPoseedores.BASE_PATH,
      useFactory: PoseedoresBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiPuntosrecogida.BASE_PATH,
      useFactory: PuntosRecogidaBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiComunicaciones.BASE_PATH,
      useFactory: ComunicacionesBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiSDDRCA.BASE_PATH,
      useFactory: SDDRCABasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiAcuerdosGestores.BASE_PATH,
      useFactory: AcuerdosGestoresBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseHandlerInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private translationLoader: EmpusaTranslationLoaderService,
    private translate: TranslateService,
    @Inject('environment') private environment: any
  ) {
    //loading empusa core translations
    //this.translationLoader.loadTranslations(english);
    //this.translationLoader.loadTranslations(spanish);

    this.setLanguaje(this.translate);
    //loading microapps routes
    basicRootConfiguration[1].children = [];
    if ((<any>window).empusa.modules) {
      (<any>window).empusa.modules.forEach((element: any) => {
        //adding the microapps routes dynamically!
        if (basicRootConfiguration[1].children != undefined)
          basicRootConfiguration[1].children.push({
            path: element.path,
            canActivate: [auth_config.guard],
            canActivateChild: [auth_config.guard],
            //we lazy load the external microapps fat modules
            loadChildren: () => element.page,
          });
      });
    }
    this.router.resetConfig(basicRootConfiguration);
  }

  private setLanguaje(translate: TranslateService) {
    let storedLang: string | null = localStorage.getItem('empusa.defaultLang');
    let userLang: string | undefined = undefined;
    if (storedLang == null) {
      let browseLang: string | undefined = translate.getBrowserCultureLang();
      if (browseLang != null) {
        userLang = browseLang;
      }
      console.debug('Lang from browser:', userLang);
    } else {
      userLang = storedLang;
      console.debug('Lang from storage:', userLang);
    }

    let selectedLang: string | undefined = undefined;
    this.environment.selectableLanguajes.forEach(
      (languaje: SelectableLanguaje) => {
        this.translationLoader.loadTranslations(languaje.data);

        if (!selectedLang && languaje.default) {
          selectedLang = languaje.locale;
          console.debug('default Lang selected:', selectedLang);
        }
        if (languaje.locale == userLang) {
          selectedLang = languaje.locale;
          console.debug('Lang selected:', selectedLang);
        }
      }
    );

    translate.use(selectedLang!);
  }
}
