
import {  Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
  })
export class EmpusaKeycloakAuthGuard extends KeycloakAuthGuard {

    constructor(protected override router: Router, protected keycloak: KeycloakService) {
        super(router, keycloak);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
        return this.canActivate(childRoute, state);
    }


    /**
     * CanActivate checks if the user is logged in and get the full list of roles (REALM + CLIENT)
     * of the logged user. This values are set to authenticated and roles params.
     *
     * @param route
     * @param state
     */
    override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.authenticated = await this.keycloak.isLoggedIn();

                const result = await this.isAccessAllowed(route, state);
                resolve(result);
            } catch (error) {
                reject('An error happened during access validation. Details:' + error);
            }
        });
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean > {
        
        if (!this.authenticated) {
          await this.keycloak.login({
            redirectUri: window.location.origin + state.url,
          });
        }
    
        return this.authenticated;
      }
}