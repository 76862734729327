<div *ngIf="formGroup">
    <h1 mat-dialog-title>
        <mat-icon class="icon" style="vertical-align: middle;">mode_edit</mat-icon>
            {{"KAM-MANAGE-DIALOG.MANAGE_USER_TITLE" | translate}}
    </h1>
    <div mat-dialog-content>
        <form [formGroup]="formGroup">
            <mat-form-field>
                <mat-label>{{"KAM-USERS-TAB.USER_NAME" | translate}}</mat-label>
                <input type="text" matInput [formControl]="userNameCtrl">
                <mat-error *ngIf="userNameCtrl.invalid && (userNameCtrl.dirty || userNameCtrl.touched)">
                    {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"KAM-USERS-TAB.EMAIL" | translate}}</mat-label>
                <input type="text" matInput [formControl]="emailCtrl">
                <mat-error *ngIf="emailCtrl.invalid && (emailCtrl.dirty || emailCtrl.touched)">
                    {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"KAM-USERS-TAB.NAME" | translate}}</mat-label>
                <input type="text" matInput [formControl]="firstNameCtrl">
                <mat-error *ngIf="firstNameCtrl.invalid && (firstNameCtrl.dirty || firstNameCtrl.touched)">
                    {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"KAM-USERS-TAB.LAST_NAME" | translate}}</mat-label>
                <input type="text" matInput [formControl]="lastNameCtrl">
                <mat-error *ngIf="lastNameCtrl.invalid && (lastNameCtrl.dirty || lastNameCtrl.touched)">
                    {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                </mat-error>
            </mat-form-field>

            <mat-checkbox [formControl]="enabledCtrl" color="primary">{{"KAM-USERS-TAB.ENABLED" | translate}}</mat-checkbox>
            <mat-checkbox [formControl]="setPasswordCtrl" (change)="setPasswordCtrlChange()"
                *ngIf="this.update" color="primary">{{"KAM-USERS-TAB.SET_PASSWORD" | translate}}</mat-checkbox>
            <div *ngIf="showPass">
                <mat-form-field class="field-pass">
                    <mat-label>{{"KAM-USERS-TAB.PASSWORD" | translate}}</mat-label>
                    <input matInput type="text" [formControl]="newPasswordCtrl">
                    <mat-error *ngIf="newPasswordCtrl.invalid && (newPasswordCtrl.dirty || newPasswordCtrl.touched)">
                        {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="field-pass">
                    <mat-label>{{"KAM-USERS-TAB.REPEAT_PASSWORD" | translate}}</mat-label>
                    <input matInput type="text" [formControl]="repeatPasswordCtrl">                
                </mat-form-field>
                <div style='color:#ff7355' *ngIf="this.formGroup?.hasError('passwordNoMatch')">
                    {{"KAM-USERS-TAB.PASSWORD_NOT_MATCH" | translate}}</div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="userCancel()">{{'KAM-GENERAL-DIALOG.CANCEL'| translate}}</button>
        <button mat-raised-button color="primary" 
                (click)="userAcept()" 
                [disabled]="!formGroup.valid">{{'KAM-GENERAL-DIALOG.ACCEPT'| translate}}</button>
    </div>
</div>