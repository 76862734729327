import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeycloakRoleModel } from '../model/keycloak-role.model';
import { KeycloakRoleModelAdapter } from '../model/adapters/keycloak-role.model.adapter';


@Injectable({
  providedIn: 'root'
})
export class KeycloakAuthManagerService {

  constructor(private http: HttpClient,
    @Inject('environment') private environment:any,
    private keycloakRoleModelAdapter: KeycloakRoleModelAdapter,
    ) { }




  getRoles(): Observable<Array<KeycloakRoleModel>> {
    return this.http.get<KeycloakRoleModel[]>(
      this.environment.keycloak.url + this.environment.kamUrlRoles
      )
      .pipe(map((response) => {
        let items: KeycloakRoleModel[] = [];        
        response.forEach(element => {
          items.push(this.keycloakRoleModelAdapter.adapt(element));
        });
        return items;
      }));
  }


}
