import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HoldersService {
  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  private tempHoldersSearch: boolean = false;

  get getTempHoldersSearch() {
    return this.tempHoldersSearch;
  }

  setTempHoldersSearch(data: any) {
    this.tempHoldersSearch = data;
  }
}
