export * from './aportacionDiEstadoController.service';
import { AportacionDIEstadoControllerService } from './aportacionDiEstadoController.service';
export * from './aportacionDiPoseedorController.service';
import { AportacionDIPoseedorControllerService } from './aportacionDiPoseedorController.service';
export * from './codigoOperacionController.service';
import { CodigoOperacionControllerService } from './codigoOperacionController.service';
export * from './facturaPoseedorController.service';
import { FacturaPoseedorControllerService } from './facturaPoseedorController.service';
export * from './facturaPoseedorEstadosController.service';
import { FacturaPoseedorEstadosControllerService } from './facturaPoseedorEstadosController.service';
export * from './gestoresResiduosController.service';
import { GestoresResiduosControllerService } from './gestoresResiduosController.service';
export * from './gestoresResiduosEstadosController.service';
import { GestoresResiduosEstadosControllerService } from './gestoresResiduosEstadosController.service';
export * from './operacionGestionController.service';
import { OperacionGestionControllerService } from './operacionGestionController.service';
export * from './poseedorAdheridoController.service';
import { PoseedorAdheridoControllerService } from './poseedorAdheridoController.service';
export * from './poseedorController.service';
import { PoseedorControllerService } from './poseedorController.service';
export * from './poseedorEstadoController.service';
import { PoseedorEstadoControllerService } from './poseedorEstadoController.service';
export * from './residuoEnvaseController.service';
import { ResiduoEnvaseControllerService } from './residuoEnvaseController.service';
export * from './residuosGestionadosController.service';
import { ResiduosGestionadosControllerService } from './residuosGestionadosController.service';
export * from './tarifaGestoresController.service';
import { TarifaGestoresControllerService } from './tarifaGestoresController.service';
export * from './tarifaPoseedoresController.service';
import { TarifaPoseedoresControllerService } from './tarifaPoseedoresController.service';
export const APIS = [AportacionDIEstadoControllerService, AportacionDIPoseedorControllerService, CodigoOperacionControllerService, FacturaPoseedorControllerService, FacturaPoseedorEstadosControllerService, GestoresResiduosControllerService, GestoresResiduosEstadosControllerService, OperacionGestionControllerService, PoseedorAdheridoControllerService, PoseedorControllerService, PoseedorEstadoControllerService, ResiduoEnvaseControllerService, ResiduosGestionadosControllerService, TarifaGestoresControllerService, TarifaPoseedoresControllerService];
