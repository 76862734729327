import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ContributionsDisDialogComponent } from '../contributions-dis-dialog/contributions-dis-dialog.component';
import { ManagerSelectionDialogComponent } from '../manager-selection-dialog/manager-selection-dialog.component';

import {
  AportacionDIEstadoControllerService,
  AportacionDIEstadoDTO,
  AdheridoControllerService,
  BusquedaAdheridoDTO,
  AportacionDiAdheridoControllerService,
  AportacionDIAdheridoFilterDTO,
} from 'api-rest';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import moment from 'moment';

@Component({
  selector: 'app-contributions-dis',
  templateUrl: './contributions-dis.component.html',
  styleUrls: ['./contributions-dis.component.scss'],
})
export class ContributionsDisComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;
  nimaPoseedorArray: any[] = [];
  nimaGestorArray: any[] = [];
  globalIdPoseedores: any[] = [];
  adheridoArray: any[] = [];
  estadoArray: any[] = [];
  headArray: any = [
    {
      Head: 'ID. Adherido',
      FieldName: 'adherido',
      Attribute: 'id',
    },
    {
      Head: 'Adherido',
      FieldName: 'adherido',
      Attribute: 'razonSocial',
    },
    {
      Head: 'Documento',
      FieldName: 'adherido',
      Attribute: 'codigoDocumento',
    },
    {
      Head: 'NIMA del gestor',
      FieldName: 'gestorResiduo',
      Attribute: 'nima',
    },
    {
      Head: 'Fecha de aceptación',
      FieldName: 'fechaMAceptacion',
    },
    {
      Head: 'DI',
      FieldName: 'documentoIdentificacion',
    },
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'codigoLer',
      renderValue: (item: any) =>
        `${item.codigoLer.tipoCodigo} ${item.codigoLer.descripcion}`,
      Maxtext: 60,
    },
    {
      Head: 'Unidades gestionadas',
      FieldName: 'unidadesGestionadas',
    },
    {
      Head: 'Estado ',
      FieldName: 'estado',
      Attribute: 'descripcionCorta',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle ',
          show: {
            params: 'canAccessView',
            accion: 'view',
          },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Eliminar',
          show: {
            params: 'canAccessDelete',
            accion: 'delete',
          },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'send',
              name: 'Enviar aportación ',
              show: {
                params: 'canAccessSend',
                accion: 'send',
              },
            },
            {
              nameButton: 'accept',
              name: 'Aceptar aportación',
              show: { params: 'canAccessAccept', accion: 'accept' },
            },
            {
              nameButton: 'cancel',
              name: 'Cancelar aportación',
              show: { params: 'canAccessCancel', accion: 'cancel' },
            },
          ],
        },
      ],
      width: '15',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;

  updateList: boolean = false;
  adheridoEndpoint: string =
    this.environment.urlBackAdheridos + 'api/v1/adheridos/adheridos';
  defaultAdheridosParameters = { 'estado': 6, 'declaracionFacturada': true };

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    private aportacionDiAdheridoControllerService: AportacionDiAdheridoControllerService,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService | any,
    private adheridoControllerService: AdheridoControllerService,
    private AportacionDIEstadoControllerService: AportacionDIEstadoControllerService
  ) {
    const fechadesde = `01/01/${moment().format('YYYY')}`;
    const [dia, mes, año] = fechadesde.split('/').map(Number);
    const fechaInicialDesde = new Date(año, mes - 1, dia);

    const fechaActual = new Date();

    this.searchForm = this.formBuilder.group({
      adherido: [{ value: null, disabled: false }],
      idAdherido: [{ value: null, disabled: false }],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      nima_gestor: [{ value: null, disabled: false }],
      fecha_desde: [
        { value: fechaInicialDesde, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      fecha_hasta: [
        { value: fechaActual, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],

      doc_identificacion: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
      fechaAceptacion: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.comboEstados();
    this.getAdheridos();
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  private getBackendFilter() {
    const textFilter = (value: any) =>
      value?.trim() ? `ci(${value?.trim()})` : undefined;
    let formatDateDesde = moment(
      new Date(this.searchForm.get('fecha_desde')?.value)
    ).format('YYYY-MM-DD');

    formatDateDesde = `gte(${formatDateDesde})`;

    let formatDateHasta = moment(
      new Date(this.searchForm.get('fecha_hasta')?.value)
    ).format('YYYY-MM-DD');

    formatDateHasta = `and(lte(${formatDateHasta}))`;

    const selectAdheridos = this.searchForm.get('adherido')?.value;
    let selectAdheridosId: any[] = [];
    if (selectAdheridos !== undefined && selectAdheridos !== null) {
      selectAdheridosId = selectAdheridos.map((objeto: any) =>
        objeto.id !== undefined ? objeto.id : objeto
      );
    }
    const idAdheridoValue = this.searchForm.get('idAdherido')?.value;
    if (idAdheridoValue) {
      selectAdheridosId.push(idAdheridoValue);
    }
    let backendFilter: AportacionDIAdheridoFilterDTO = {
      adherido: {
        id: selectAdheridosId,
        codigoDocumento: this.searchForm.get('codigo_documento')?.value,
        razonSocial: textFilter(this.searchForm.get('razon_social')?.value),
      },
      fechaAceptacion: [formatDateDesde, formatDateHasta],
      documentoIdentificacion: this.searchForm.get('doc_identificacion')?.value,
      estado: this.searchForm.get('estado')?.value,
      nimaGestor: this.searchForm.get('nima_gestor')?.value,
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.aportacionDiAdheridoControllerService
      .exportCSV3(backendFilter, 'response')
      .subscribe((res: any) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'ExportacionDi.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  getAdheridoDescription(obj: any) {
    return obj.razonSocial;
  }

  getAdheridoObjectQuery(searchterm: string) {
    return {
      razonSocial: `${searchterm}`,
      'estado.id': 6,
    };
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'adherido': {
        return this.searchForm.get('adherido') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  searchData() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.aportacionDiAdheridoControllerService
      ?.findAllAportacionDIAdherido(backendFilter ?? {}, {
        page: page,
        size: size,
        sort: [],
      })
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos);
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openModalContributions(element);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'send') {
      this.spinnerSrv.loadingShow();
      this.aportacionDiAdheridoControllerService
        .actualizarEstado(element.id, 2)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.searchData();
            this.snackBarSrv.showSnackBar(
              'Se ha enviado la aportación correctamente',
              'success'
            );
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    } else if (element.nameButton == 'accept') {
      this.spinnerSrv.loadingShow();
      this.aportacionDiAdheridoControllerService
        .actualizarEstado(element.id, 3)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.searchData();
            this.snackBarSrv.showSnackBar(
              'Se ha aceptado la aportación correctamente',
              'success'
            );
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    } else if (element.nameButton == 'cancel') {
      this.spinnerSrv.loadingShow();
      this.aportacionDiAdheridoControllerService
        .actualizarEstado(element.id, 5)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.searchData();
            this.snackBarSrv.showSnackBar(
              'Se ha dado cancelado la aportación correctamente',
              'success'
            );
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la aportación Di?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarDis(element);
      }
    });
  }

  eliminarDis(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.aportacionDiAdheridoControllerService
        .deleteById6(element.id)
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              `La aportación Di se ha eliminado correctamente`,
              'success'
            );
            this.gridArray = this.gridArray.filter(
              (objeto) => objeto.id !== element.id
            );
            this.flagSearch = this.gridArray.length != 0 ? true : false;
          },
          error: (err: { message: any }) => {
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  getAdheridos() {
    if (this.authService.user?.roleEntities?.length > 0) {
      const adheridosIds = this.authService.user?.roleEntities
        .filter((adheridoRol: any) => adheridoRol.type === 'ADHERIDO')
        .map((adheridoRol: any) => adheridoRol.entity as number);
      const filter: BusquedaAdheridoDTO = {
        id: adheridosIds,
        estado: [6],
        declaracionFacturada: true,
      };

      this.spinnerSrv.loadingShow();
      this.adheridoControllerService
        .listarAdheridos({ page: 0, size: 100 }, filter)
        .subscribe({
          next: (response: any) => {
            this.adheridoArray = response?.content;
            this.spinnerSrv.loadingHide();
          },
          error: (err) => {
            console.log(err);
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  comboEstados() {
    this.AportacionDIEstadoControllerService.findAll14().subscribe({
      next: (data: Array<AportacionDIEstadoDTO>) => {
        this.estadoArray = data.filter((item) => item.id !== 4);
      },
      error: () => {},
    });
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  openHolderManagerSelection(adheridos: any) {
    const dialogRef = this.dialog.open(ManagerSelectionDialogComponent, {
      width: '950px',
      maxWidth: '98%',
      maxHeight: '95vh',
      disableClose: true,
      data: {
        adheridoArray: adheridos,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openModalContributions(result);
      }
    });
  }

  openModalContributions(element?: any) {
    if (!element) {
      if (this.adheridoArray?.length > 0) {
        if (this.adheridoArray?.length === 0) {
          this.snackBarSrv.showSnackBar(
            'No puede crear una Aportación DI porque no tiene ningún Adherido en estado Completado.',
            'error'
          );
        }
        if (this.adheridoArray?.length > 1) {
          this.openHolderManagerSelection(this.adheridoArray);
        } else {
          this.openModalContributions({
            adherido: {
              id: this.adheridoArray[0].id,
            },
            estado: { id: 1, descripcion: 'Abierta' },
          });
        }
      }
    } else {
      const dialogRef = this.dialog.open(ContributionsDisDialogComponent, {
        width: '950px',
        maxWidth: '98%',
        maxHeight: '95vh',
        data: element,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.searchData();
        }
      });
    }
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('adheridos-di', 'view');
    const resultDelete = this.authService.can('adheridos-di', 'delete');

    const resultSend = this.authService.can('adheridos-di', 'update-send');
    const resultAccept = this.authService.can('adheridos-di', 'update-accept');
    const resultCancel = this.authService.can('adheridos-di', 'update-cancel');

    const listPermission = list?.map((objeto: any) => {
      const estado1 = objeto.estado && objeto.estado.id === 1;
      const estado2 = objeto.estado && objeto.estado.id === 2;
      objeto.fechaMAceptacion =
        objeto.fechaAceptacion != null
          ? moment(new Date(objeto.fechaAceptacion)).format('DD/MM/YYYY')
          : null;

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete:
          resultDelete &&
          estado1 &&
          objeto.creadoPor == this.authService.getCurrentUserMail(),
        canAccessSend:
          resultSend &&
          estado1 &&
          objeto.creadoPor == this.authService.getCurrentUserMail(),
        canAccessAccept: resultAccept && estado2,
        canAccessCancel: resultCancel && estado2,
      };
    });

    return listPermission;
  }

  canAccessNuevoDis(): boolean {
    return this.authService.can('adheridos-di', 'create');
  }
}
