<div>
    <h1 mat-dialog-title>
        <mat-icon class="icon" style="vertical-align: middle;">turned_in_not</mat-icon>
        {{"KAM-UPDATE-DIALOG.UPDATE_USER_ROLES_TITLE" | translate}}
    </h1>

    <div mat-dialog-content>
        <keycloak-auth-manager-kam-role-selection    
            [initialRolesSelected]="initialRolesSelected"
            (roleSelection)="setRoleSelection($event)"
            (rolesUnselected)="setRolesUnselected($event)"> 
        </keycloak-auth-manager-kam-role-selection>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="cancel()">{{'KAM-GENERAL-DIALOG.CANCEL'| translate}}</button>
        <button mat-raised-button color="primary" (click)="updateRoles()" >{{'KAM-GENERAL-DIALOG.ACCEPT'| translate}}</button>
    </div>
</div>