import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-managers',
  templateUrl: 'managers.component.html',
  styleUrls: ['./managers.component.scss'],
})
export class ManagersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
