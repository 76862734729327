import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CodigoLerControllerService,
  CodigoOperacionFilterDTO,
  GestorPtoRecogidaCodigoControllerService,
  GestorPtoRecogidaCodigoFilterDTO,
  GestorPtoRecogidaCodigoPayloadDTO,
  OperacionesTratamientoControllerService,
  PuntoRecogidaControllerService,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { MatDialog } from '@angular/material/dialog';
import { Attribute } from '@angular/core';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { ValidateService } from '../../../services/validate.service';

@Component({
  selector: 'app-collection-points-managers-codeLer-data',
  templateUrl: './collection-points-managers-codeLer-data.component.html',
  styleUrls: ['./collection-points-managers-codeLer-data.component.scss'],
})
export class CollectionPointsManagersCodeLerDataComponent implements OnInit {
  @Input() puntoRecogidaInfo!: any;
  @Output() public refrescarPuntosRecogidaEvent = new EventEmitter<any>();

  formGroup!: FormGroup;
  codigosLerEndpoint =
    this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?ptosRecogida=true';

  grid: any[] = [];
  operacionArray: any[] = [];

  pageNumber: number = 0;
  pageSize: number = 100;
  recordsTotal: number = 0;
  gestionArray: any[] = [];

  updateList: boolean = false;

  filteredOptions: any[] = [];

  operacionTIArray: any = [];

  operacionTFArray: any = [];

  periodicidadOptionArray: any = [
    { description: 'Mensual' },
    { description: 'Trimestral' },
    { description: 'Semestral' },
    { description: 'Anual' },
  ];

  headArray: any[] = [
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'descripcionMCodLer',
      Tooltip: true,
    },
    {
      Head: 'Peligrosidad',
      FieldName: 'codigoLer',
      renderValue: (item: any) => (item.codigoLer.peligrosidad ? 'SI' : 'NO'),
    },
    {
      Head: 'Periodicidad de Traslados',
      FieldName: 'periocidadTraslados',
    },
    {
      Head: 'Operación de tratamiento (intermedia)',
      FieldName: 'opTratamientoMIntermedia',
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'Operación de tratamiento (final)',
      FieldName: 'opTratamientoMFinal',
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'Cantidad autorizada (Kg)',
      FieldName: 'cantidadAnualAutorizada',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item.cantidadAnualAutorizada),
    },
    {
      Head: 'Gestor final',
      FieldName: 'nimaGestorFinal',
    },
    {
      Head: 'Razón social gestor final',
      FieldName: 'razonGestorFinal',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private gestorPtoRecogidaCodigoControllerService: GestorPtoRecogidaCodigoControllerService,
    private parseCurrencyPipe: ParseCurrencyPipe,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private validateSrv: ValidateService,
    private operacionesTratamientoControllerService: OperacionesTratamientoControllerService
  ) {
    this.formGroup = this.formBuilder.group({
      codigoLer: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      periocidadTraslado: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      operacionTI: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      operacionTF: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      cantidadAutorizada: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      gestorFinal: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.tenDigits),
          ]),
        },
      ],
      razonSocialGestorFinal: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
  }

  ngOnInit() {
    this.canAccessCodeLerViewEdit();
    this.valueChangeCodigoLer();
  }

  valueChangeCodigoLer() {
    this.updateOptions();
    this.formGroup.get('codigoLer')?.valueChanges.subscribe((data: any) => {
      if (data) {
        this.operacionesTratamientoControllerService
          .findOperacionesByCodigoLer(
            { codigoLer: { id: data.id }, ptosRecogida: true },
            { page: this.pageNumber, size: this.pageSize }
          )
          .subscribe({
            next: (response: any) => {
              const list = response.datos.map(
                (item: any) => item.operacionGestion
              );
              this.operacionTIArray = list;
              this.operacionTFArray = list;
            },
            error: (err) => {},
          });
      } else {
        this.formGroup.get('operacionTI')?.reset();
        this.formGroup.get('operacionTF')?.reset();
        this.operacionTIArray = [];
        this.operacionTFArray = [];
      }
    });
  }

  private getBackendFilter() {
    const backendFilter: GestorPtoRecogidaCodigoFilterDTO = {
      gestorPtoRecogida: { id: this.puntoRecogidaInfo?.id },
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.puntoRecogidaInfo) {
      const { backendFilter, page, size } = this.getBackendFilter();
      this.gestorPtoRecogidaCodigoControllerService
        .findAllGestorPtoRecogidaCodigo(backendFilter, { page, size })
        .subscribe({
          next: (response: any) => {
            this.grid = this.canAccessAll(response?.datos);
          },
          error: (err) => {},
        });
    }
  }

  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }

  getOperacionDescription(op: any) {
    return op?.descripcion;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.formGroup.get('codigoLer') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }

  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  addPrevision() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }

    const dataObjCode: GestorPtoRecogidaCodigoPayloadDTO | any = {
      gestorPtoRecogida: { id: this.puntoRecogidaInfo?.id },
      codigoLer: { id: this.formGroup.get('codigoLer')?.value?.id },
      periocidadTraslados:
        this.formGroup.get('periocidadTraslado')?.value?.description,
      opTratamientoIntermedia: {
        id: this.formGroup.get('operacionTI')?.value.id,
      },
      opTratamientoFinal: { id: this.formGroup.get('operacionTF')?.value.id },
      cantidadAnualAutorizada: this.parseCurrencyPipe.transform(
        this.formGroup.get('cantidadAutorizada')?.value
      ),
      nimaGestorFinal: this.formGroup.get('gestorFinal')?.value,
      razonGestorFinal: this.formGroup.get('razonSocialGestorFinal')?.value,
    };

    this.gestorPtoRecogidaCodigoControllerService
      .createGestorPtoRecogidaCodigo(dataObjCode)
      .subscribe({
        next: (response: any) => {
          this.snackBarSrv.showSnackBar(
            'Los datos se han guardado correctamente.',
            'success'
          );
          this.refrescarPuntosRecogidaEvent.emit(true);
          this.formGroup.reset();
        },
        error: (err) => {},
      });
  }

  canAccessAgregar(): boolean {
    return this.authService.can('ptosrecogida-gestor-codigos', 'create');
  }

  action(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarPrevision(event);
    }
  }
  confirmacionEliminarPrevision(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el código LER gestionado?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.gestorPtoRecogidaCodigoControllerService
          .borrarGestorPtoRecogidaCodigo(element?.id)
          .subscribe({
            next: (response: any) => {
              this.snackBarSrv.showSnackBar(
                'Se ha eliminado correctamente el código Ler',
                'success'
              );
              this.refrescarPuntosRecogidaEvent.emit(true);
            },
            error: (err) => {},
          });
      }
    });
  }

  showClearButton(formName: string): boolean {
    return this.formGroup.get(formName)?.value !== null;
  }

  canAccessCodeLerViewEdit() {
    if (!this.authService.can('ptosrecogida-gestor-codigos', 'update')) {
      this.formGroup.disable();
    }
  }

  canAccessCodeLerViewFrom() {
    return this.authService.can('ptosrecogida-gestor-codigos', 'update');
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formGroup.get(formName)?.setValue(null);
  }

  updateOptions(): void {
    const peligrosidad = this.formGroup.get('codigoLer')?.value?.peligrosidad;
    this.formGroup.get('periocidadTraslado')?.reset();

    if (peligrosidad) {
      this.filteredOptions = this.periodicidadOptionArray.filter(
        (option: any) => option.description !== 'Anual'
      );
    } else {
      this.filteredOptions = [...this.periodicidadOptionArray];
    }
  }

  canAccessAll(list: any) {
    const resultDelete = this.authService.can(
      'ptosrecogida-gestor-codigos',
      'delete'
    );

    const listPermission = list.map((objeto: any) => {
      let descripcionMCodLer =
        objeto.codigoLer.tipoCodigo + ' ' + objeto.codigoLer.descripcion;
      let opTratamientoMFinal =
        objeto.opTratamientoFinal.codigo +
        ' ' +
        objeto.opTratamientoFinal.descripcion;
      let opTratamientoMIntermedia =
        objeto.opTratamientoIntermedia.codigo +
        ' ' +
        objeto.opTratamientoIntermedia.descripcion;

      return {
        ...objeto,
        descripcionMCodLer: descripcionMCodLer,
        opTratamientoMFinal: opTratamientoMFinal,
        opTratamientoMIntermedia: opTratamientoMIntermedia,
        canAccessDelete: resultDelete,
      };
    });

    return listPermission;
  }
}
