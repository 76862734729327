import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Inject,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { ValidateService } from '../../../services/validate.service';
import { Cnae, Combo } from '../../../models/combo';
import { CombosService } from '../../../services/combos.service';
import { PuntoRecogidaControllerService } from 'api-rest';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-collection-points-general-data',
  templateUrl: './collection-points-general-data.component.html',
  styleUrls: ['./collection-points-general-data.component.scss'],
})
export class CollectionPointsGeneralDataComponent implements OnInit {
  @Input() puntoRecogidaInfo!: any;
  @Output() public refrescarPuntosRecogidaEvent = new EventEmitter<any>();

  cnaeEndpoint = this.environment.urlBackCore + 'api/v1/core/cnae';

  datosGeneralesForm!: FormGroup;
  tiposDocumento: Combo[] = [];

  updateList: boolean = false;
  flagDisabledForm: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private validateSrv: ValidateService,
    public comboSrv: CombosService,
    private puntoRecogidaControllerService: PuntoRecogidaControllerService,
    private datePipe: DatePipe
  ) {
    this.datosGeneralesForm = this.formBuilder.group({
      razonSocial: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      denominacion: [{ value: null, disabled: false }, Validators.maxLength(255)],

      tipoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(15)]) },
      ],

      nimaPdr: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.pattern(this.validateSrv.tenDigits),
            Validators.maxLength(10)
          ]),
        },
      ],
      nimaOt: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.pattern(this.validateSrv.tenDigits),
            Validators.maxLength(10)
          ]),
        },
      ],

      n_inscripResiduos: [{ value: null, disabled: false }, Validators.maxLength(255)],

      cnae: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],

      fechaBaja: [{ value: null, disabled: false }],
      fechaBajaEnvanet: [{ value: null, disabled: true }],
      fechaComienzo: [{ value: null, disabled: true }],
    });
  }

  ngOnInit() {
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.puntoRecogidaInfo) {
      this.cargarDatos(this.puntoRecogidaInfo);
      this.controlGeneralForm();
    }
  }

  cargarDatos(puntoRecogidaInfo: any) {
    this.datosGeneralesForm
      .get('razonSocial')
      ?.setValue(puntoRecogidaInfo?.razonSocial);
    this.datosGeneralesForm
      .get('denominacion')
      ?.setValue(puntoRecogidaInfo?.denominacion);

    this.datosGeneralesForm
      .get('tipoDocumento')
      ?.setValue(puntoRecogidaInfo?.tipoDocumento);
    this.datosGeneralesForm
      .get('codigoDocumento')
      ?.setValue(puntoRecogidaInfo?.codigoDocumento);
    this.datosGeneralesForm.get('nima')?.setValue(puntoRecogidaInfo?.nima);

    this.datosGeneralesForm
      .get('nimaPdr')
      ?.setValue(puntoRecogidaInfo?.nimaPtoRecogida);
    this.datosGeneralesForm
      .get('nimaOt')
      ?.setValue(puntoRecogidaInfo?.nimaOperador);
    this.datosGeneralesForm
      .get('n_inscripResiduos')
      ?.setValue(puntoRecogidaInfo?.numInscripcionProductor);

    this.datosGeneralesForm
      .get('cnae')
      ?.setValue(
        puntoRecogidaInfo?.cnae != null
          ? this.getCnaDescription(puntoRecogidaInfo?.cnae)
          : null
      );
    this.datosGeneralesForm
      .get('fechaBaja')
      ?.setValue(puntoRecogidaInfo?.fechaBaja);

    let fechaBajaEnvanet =
      puntoRecogidaInfo?.fechaBajaEvanet != null
        ? this.datePipe.transform(
            puntoRecogidaInfo?.fechaBajaEvanet,
            'dd/MM/yyyy'
          )
        : null;

    this.datosGeneralesForm.get('fechaBajaEnvanet')?.setValue(fechaBajaEnvanet);

    let fechaComienzo =
      puntoRecogidaInfo?.fechaComienzoAct != null
        ? this.datePipe.transform(
            puntoRecogidaInfo?.fechaComienzoAct,
            'dd/MM/yyyy'
          )
        : null;
    this.datosGeneralesForm.get('fechaComienzo')?.setValue(fechaComienzo);
  }

  guardar() {
    this.datosGeneralesForm.markAllAsTouched();
    this.datosGeneralesForm.updateValueAndValidity();
    if (this.datosGeneralesForm.valid) {
      let body = this.puntoRecogidaInfo;

      body.razonSocial = this.datosGeneralesForm.get('razonSocial')?.value;

      body.denominacion = this.datosGeneralesForm.get('denominacion')?.value;

      body.tipoDocumento = this.datosGeneralesForm.get('tipoDocumento')?.value;

      body.codigoDocumento =
        this.datosGeneralesForm.get('codigoDocumento')?.value;

      body.nimaPtoRecogida = this.datosGeneralesForm.get('nimaPdr')?.value;
      body.nimaOperador = this.datosGeneralesForm.get('nimaOt')?.value;

      body.numInscripcionProductor =
        this.datosGeneralesForm.get('n_inscripResiduos')?.value;

      body.cnae =
        typeof this.datosGeneralesForm.get('cnae')?.value === 'string'
          ? this.puntoRecogidaInfo?.cnae
          : this.datosGeneralesForm.get('cnae')?.value;

      body.fechaBaja = this.datosGeneralesForm.get('fechaBaja')?.value;

      this.puntoRecogidaControllerService
        .editByIdPtoRecogida(this.puntoRecogidaInfo.id!, body)
        .subscribe({
          next: (response: any) => {
            this.snackBarSrv.showSnackBar(
              'Datos generales guardados correctamente',
              'success'
            );
            this.refrescarPuntosRecogidaEvent.emit(true);
          },
          error: (err) => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar los datos generales',
              'error'
            );
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  tipoDocumentoSel(idTipoDoc: string) {
    this.datosGeneralesForm.get('codigoDocumento')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.nifPattern),
        ]);
    } else if (idTipoDoc === 'DNI') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.dniPattern),
        ]);
    } else if (idTipoDoc === 'NIE') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.niePattern),
        ]);
    }
    this.datosGeneralesForm.get('codigoDocumento')?.updateValueAndValidity();
    this.datosGeneralesForm.get('codigoDocumento')?.markAsTouched();
  }

  controlGeneralForm() {
    if (this.canAccessContinuarAll() || this.canAccessGuardar()) {
      this.controlFormEnable();
    } else {
      this.controlFormDisable();
    }
  }

  controlFormDisable() {
    this.datosGeneralesForm.disable();
    if (this.puntoRecogidaInfo?.estado?.id <= 5) {
      this.datosGeneralesForm.get('fechaBaja')?.enable();
    }
  }

  controlFormEnable() {
    this.datosGeneralesForm.enable();
    this.datosGeneralesForm.get('fechaBajaEnvanet')?.disable();
    this.datosGeneralesForm.get('fechaComienzo')?.disable();
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.datosGeneralesForm.controls[controlName].hasError(errorName) &&
      this.datosGeneralesForm.controls[controlName].touched
    );
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.datosGeneralesForm.controls[controlName]?.touched ||
        this.datosGeneralesForm.controls[controlName]?.dirty) &&
      this.datosGeneralesForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  canAccessContinuarAll(): boolean {
    return (
      this.authService.can(
        'ptosrecogida-detalle',
        'access-b-guardar-continuar'
      ) && this.puntoRecogidaInfo?.estado?.id == 1
    );
  }

  canAccessGuardar() {
    return (
      this.authService.can('ptosrecogida-detalle', 'access-b-guardar') &&
      this.puntoRecogidaInfo?.estado &&
      [1, 2].includes(this.puntoRecogidaInfo?.estado?.id!)
    );
  }

  canAccessDate(): boolean {
    return this.authService.hasCurrentUserRole('PR-ENV-GEST');
  }

  getCnaDescription(cnae: any) {
    return cnae?.clase + ' - ' + cnae?.descripcion;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'cnae': {
        return this.datosGeneralesForm.get('cnae') as FormControl;
        break;
      }
      default: {
        return this.datosGeneralesForm.get('') as FormControl;
        break;
      }
    }
  }

  getCnaeObjectQuery(searchterm: string) {
    return {
      descripcion: `or(ci(${searchterm}))`,
      clase: `or(ci(${searchterm}))`,
    };
  }

  selPaginatorHasError(controlName: string) {
    if (
      (this.datosGeneralesForm.controls[controlName]?.touched ||
        this.datosGeneralesForm.controls[controlName]?.dirty) &&
      this.datosGeneralesForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }
}
