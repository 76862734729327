/**
 * Perform a deepmerge, this function will improve the spread and others, because it really make a deep merge.
 * i.e. in the case both targets have same child nodes, it will merge both child nodes into the first one.
 * @param target the target node where everything will be merged
 * @param source the source node that will be merged with the target node
 */
export function deepMerge(target: any, source: any) {
    if (typeof target !== 'object' || typeof source !== 'object') return false; // target or source or both ain't objects, merging doesn't make sense
    for (var prop in source) {
        if (!source.hasOwnProperty(prop)) continue; // take into consideration only object's own properties.
        if (prop in target) { // handling merging of two properties with equal names
            if (typeof target[prop] !== 'object') {
                target[prop] = source[prop];
            } else {
                if (typeof source[prop] !== 'object') {
                    target[prop] = source[prop];
                } else {
                    if (target[prop].concat && source[prop].concat) { // two arrays get concatenated
                        target[prop] = target[prop].concat(source[prop]);
                    } else { // two objects get merged recursively
                        target[prop] = deepMerge(target[prop], source[prop]);
                    }
                }
            }
        } else { // new properties get added to target
            target[prop] = source[prop];
        }
    }
    return target;
}

/**
 * util function to avoid caching, adding a timestamp to the url
 * @param url url to modify
 */
export function noCacheUrl(url: string): string {
    const timestamp = "t=" + ((new Date()).getTime());
    const prefix = ((url.indexOf("?") !== -1) ? "&" : "?");

    return (url + prefix + timestamp);
}

/**
 * Convert an arraybuffer to a base64 string
 * @param buffer 
 */
export function arrayBufferToBase64(buffer:any) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}