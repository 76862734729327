import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }
  
  downloadBlob(file: any, fileName: string) {
    var a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  downloadCSV(file: Blob, fileName: string) {
    var reader = new FileReader();
    reader.onload = function(event) {
      var data = event.target?.result;      
      var link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(data as string));
      link.setAttribute("download", fileName);
      link.click();
    };
    reader.readAsText(file);
  }

  getFileName(headers: HttpHeaders, defaultFileName?: string) {
    const contentDispositionHeader: string | null = headers.get('Content-Disposition');        
    // Extraer el nombre de archivo del encabezado Content-Disposition
    let filename: string | null = null;
    if (contentDispositionHeader) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename ?? defaultFileName;
  }
}
