import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { KamUserService } from '../../../../services/kam-user.services';

@Component({
  selector: 'keycloak-auth-manager-kam-nu-step2',
  templateUrl: './kam-nu-step2.component.html',
  styleUrls: ['./kam-nu-step2.component.css']
})
export class KamNuStep2Component implements OnInit , OnDestroy{

  userAttributes = this.environment.kamUserAttributes;
  @Output() completed = new EventEmitter<boolean>();


  customAttributesValues:Map<string, string[]>|undefined;
  customAttributesValuesAreValid:boolean=false;
  subscription: Subscription | undefined;
  initialAttributesValues: Map<string, string[]>|undefined;

  constructor(@Inject('environment') private environment:any,
          private kamUserService: KamUserService,) { }


  ngOnInit(): void {
    this.subscription = this.kamUserService.userDataSubscription().subscribe(user=>{
      if (user)
        this.initialAttributesValues = user.attributesMap;      
    })
  }
  

  setCustomAttributesValues($event: any){
      this.customAttributesValues=$event;
      setTimeout(() => {    
        this.kamUserService.setUserDataCustomAttributes($event)
      }, 100);
  }

  setCustomAttributesValuesAreValid($event: any){
    setTimeout(() => { 
      this.customAttributesValuesAreValid= $event
      this.completed.emit(this.customAttributesValuesAreValid)
      console.log("this.customAttributesValuesAreValid",this.customAttributesValuesAreValid)
    }, 100);
  }

  step2End(){
    // TODO 
    //this.keycloakGroupService.dataSetCustomAttributes(this.customAttributesValues)
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}



