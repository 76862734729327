/**
 * Poseedores API
 * Poseedores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GestoresResiduosEstadosDTO } from './gestoresResiduosEstadosDTO';


export interface GestoresResiduosPayloadDTO { 
    estado: GestoresResiduosEstadosDTO;
    razonSocial: string;
    codigoDocumento: string;
    tipoDocumento: GestoresResiduosPayloadDTO.TipoDocumentoEnum;
    centro: string;
    nima: string;
    nombreContacto: string;
    apellido1Contacto: string;
    apellido2Contacto: string;
    emailContacto: string;
    tipoTelefContacto?: GestoresResiduosPayloadDTO.TipoTelefContactoEnum;
    prefijoPaisContacto?: string;
    telfContacto?: string;
}
export namespace GestoresResiduosPayloadDTO {
    export type TipoDocumentoEnum = 'NIF' | 'DNI' | 'NIE';
    export const TipoDocumentoEnum = {
        Nif: 'NIF' as TipoDocumentoEnum,
        Dni: 'DNI' as TipoDocumentoEnum,
        Nie: 'NIE' as TipoDocumentoEnum
    };
    export type TipoTelefContactoEnum = 'FIJO' | 'MOVIL';
    export const TipoTelefContactoEnum = {
        Fijo: 'FIJO' as TipoTelefContactoEnum,
        Movil: 'MOVIL' as TipoTelefContactoEnum
    };
}


