<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>

<div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">

    <!-- Formulario de tarifa abierta-->
    <form class="form-modal py-3  flex-container" [formGroup]="formGroup">
      <div class="row-fields">
        <div class="field field-50">
          <label for="fechaDesde">Fecha desde <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="datepickerDesde" formControlName="fechaDesde">
            <mat-datepicker-toggle matIconSuffix [for]="datepickerDesde"></mat-datepicker-toggle>
            <mat-datepicker #datepickerDesde>
            </mat-datepicker>
            <mat-error *ngIf="controlHasError('fechaDesde', 'matDatepickerParse')">Formato incorrecto
            </mat-error>
            <mat-error *ngIf="controlHasError('fechaDesde', 'required')">Campo obligatorio</mat-error>
            <mat-error *ngIf="controlHasError('fechaDesde', 'fechas')">Error de rango de fechas</mat-error>
          </mat-form-field>
        </div>
        <div class="field field-50">
          <label for="fechaHasta">Fecha hasta <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="fechaHasta" formControlName="fechaHasta">
            <mat-datepicker-toggle matIconSuffix [for]="fechaHasta"></mat-datepicker-toggle>
            <mat-datepicker #fechaHasta>
            </mat-datepicker>
            <mat-error *ngIf="controlHasError('fechaHasta', 'matDatepickerParse')">Formato incorrecto
            </mat-error>
            <mat-error *ngIf="controlHasError('fechaHasta', 'required')">Campo obligatorio</mat-error>
            <mat-error *ngIf="controlHasError('fechaHasta', 'fechas')">Error de rango de fechas</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row-fields">
        <div class="field field-100">
          <label for="codigoLer">Código LER y descripción de ENVALORA <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="codigosLerEndpoint"
              [descripcion]="getLerDescription" [defaultFilter]=defaultFilter
              [formControlNameSelect]="getFormControl('codigoLer')" [objQueryName]="getObjectQuery"
              [placeHolder]="'-- Código LER y descripción de ENVALORA --'" [customClass]="rightHolder('codigoLer')">
            </lib-ge-select-pagination>
            <div class="ge-select-error">
              <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('codigoLer')">Campo
                obligatorio
              </mat-error>
            </div>
          </div>
        </div>
      </div>
      <div class="row-fields">
        <div class="field field-50">
          <label for="provincia">Provincia <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="provincia" id="provincia" placeholder="Provincia">
              <mat-option *ngFor="let provincia of provinciaArray" [value]="provincia.id">
                {{ provincia.nombre }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('provincia', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="field field-50">
          <label for="valorTarifa">€ / Kg <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input type="number" matInput formControlName="valorTarifa" id="valorTarifa" placeholder="€ / Kg"
              type="text" appTwoDigitDecimalNumber>
            <mat-error *ngIf="controlHasError('valorTarifa', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <!-- FIN Formulario tarifa abierta -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
