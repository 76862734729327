import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
 
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeycloakGroupModel } from '../model/keycloak-group.model';
import { KeycloakRoleModel } from '../model/keycloak-role.model';
import { KeycloakUserModel } from '../model/keycloak-user.model';
import { KeycloakUserModelAdapter } from '../model/adapters/keycloak-user.model.adapter';
import { KeycloakGroupModelAdapter } from '../model/adapters/keycloak-group.model.adapter';
import { KeycloakAttributeConfig } from '../model/keycloak-attribute-config.model';


@Injectable({
  providedIn: 'root'
})
export class KeycloakGroupService {

   group: BehaviorSubject<KeycloakGroupModel|undefined> = new BehaviorSubject<KeycloakGroupModel|undefined>(undefined);
   groupRoles: BehaviorSubject<KeycloakRoleModel[]> = new BehaviorSubject<KeycloakRoleModel[]>([]);
   step1Competed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   step2Competed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   step3Competed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
   

  constructor(private http: HttpClient,
    @Inject('environment') private environment:any,
    private keycloakUserModelAdapter: KeycloakUserModelAdapter,
    private keycloakGroupModelAdapter: KeycloakGroupModelAdapter,
    ) { }

  /** Share data methods */
  dataSubscription ():Observable<KeycloakGroupModel|undefined>{
    return this.group.asObservable();
  }
  groupRolesSubscription ():Observable<KeycloakRoleModel[]>{
    return this.groupRoles.asObservable();
  }

  step1CompetedSubscription ():Observable<boolean>{    
    return this.step1Competed.asObservable();
  }
  step2CompetedSubscription ():Observable<boolean>{
    return this.step2Competed.asObservable();
  }
  step3CompetedSubscription ():Observable<boolean>{
    return this.step3Competed.asObservable();
  }

  setStep1CompetedSubscription (value:boolean){
    return this.step1Competed.next(value)
  }
  setStep2CompetedSubscription (value:boolean){
    return this.step2Competed.next(value)
  }
  setStep3CompetedSubscription (value:boolean){
    return this.step3Competed.next(value)
  }

  getStep1CompetedValue ():boolean{    
    return this.step1Competed.getValue()
  }

  dataGetGroup():KeycloakGroupModel|undefined{
    return this.group.getValue();
  }

  dataGetRoles():KeycloakRoleModel[]{
    return this.groupRoles.getValue();
  }

  dataSetGroup(group: KeycloakGroupModel|undefined){    
    if (group){
      let clonedGroup = {...group}
      this.group?.next(clonedGroup);    
    }else{
      this.group?.next(undefined);    
    }    
  }

  dataSetGroupName(name: string){
    let theGroup = this.group?.getValue();
    if (theGroup){
        theGroup.name = name;
    }
    else{
        theGroup = {
            name: name,
            id:"",
            subGroups:[]
        }
    }
    this.group?.next(theGroup);
  }

  dataSetCustomAttributes(attributes?:  Map<string, string[]>){
    let theGroup =this.group?.getValue()
    if (theGroup){
        theGroup.attributesMap = attributes;
    }else{
      theGroup = {
        name: "",
        id:"",
        attributesMap: attributes,
        subGroups:[]
      }
    }
    this.group?.next(theGroup);
  }

  dataSetSelectedRoles(roles:KeycloakRoleModel[]){
    this.groupRoles.next(roles);
  }

  /** request methods */
  getGroups(): Observable<Array<KeycloakGroupModel>> {
    return this.http.get<KeycloakGroupModel[]>(
      this.environment.keycloak.url + this.environment.kamUrlGroups
      )
      .pipe(map((response) => {
        let items: KeycloakGroupModel[] = [];        
        response.forEach(element => {
          items.push(this.keycloakGroupModelAdapter.adapt(element));
        });
        return items;
      }));
  }

  getGroupDetail(groupId: string): Observable<KeycloakGroupModel> {
    return this.http.get<KeycloakGroupModel>(
      this.environment.keycloak.url + this.environment.kamUrlGroups + "/"+groupId
      )
      .pipe(map((response) => {
        let item: KeycloakGroupModel= this.keycloakGroupModelAdapter.adapt(response);        
        return item;
      }));
  }

  getGroupByName(groupName: string): Observable<KeycloakGroupModel[]> {

    return this.http.get<KeycloakGroupModel[]>(
      // TODO RETORNAR PADRE E HIJOS
      this.environment.keycloak.url + this.environment.kamUrlGroups + "?search=" + groupName + "&exat=true"
      )
      .pipe(map((response) => {
        let items: KeycloakGroupModel[] = []; 
        response.forEach(element => {
          items.push(this.keycloakGroupModelAdapter.adapt(element))
        });
        return items;
      }));
  }

  getGroupsMembers(groupId: string): Observable<Array<KeycloakUserModel>> {
    return this.http.get<KeycloakUserModel[]>(
      this.environment.keycloak.url + this.environment.kamUrlGroupMembers.replace("{{groupId}}",groupId)
      )
      .pipe(map((response) => {
        let items: KeycloakUserModel[] = [];        
        response.forEach(element => {
          items.push(this.keycloakUserModelAdapter.adapt(element));
        });
        return items;
      }));
  }
  /** new */
  postGroup(group:KeycloakGroupModel, parentGroup: KeycloakGroupModel | undefined): Observable<void> {

    if (parentGroup){
      return this.http.post<void>(
        this.environment.keycloak.url + this.environment.kamUrlSubgroups.replace("{{groupId}}", parentGroup.id),
        {name:group.name}
      )
      .pipe(map(() => {
          return;
      }));
    }else{
      return this.http.post<void>(
        this.environment.keycloak.url + this.environment.kamUrlGroups,
        {name:group.name}
      )
      .pipe(map(() => {
          return;
      }));
    }
    
  }

  postGroupRole(group:KeycloakGroupModel, theRoles:KeycloakRoleModel[]): Observable<void> {
    return this.http.post<void>(
        this.environment.keycloak.url + this.environment.kamUrlGroups + "/" +group.id +"/role-mappings/realm",
        theRoles
    )
    .pipe(map(() => {
        return;
    }));
  }


  /** update */
  putGroup(group:KeycloakGroupModel): Observable<void> {
    if (group.members && group.members.length==0){
      group.members=undefined
    }
    if (group.attributesMap){
      let newStructure:any
      
    }
    return this.http.put<void>(
        this.environment.keycloak.url + this.environment.kamUrlGroups + "/" +group.id,
        group
    )
    .pipe(map(() => {
        return;
    }));
  }

  /** delete */
  deleteGroupRole(group:KeycloakGroupModel, theRoles:KeycloakRoleModel[]): Observable<void> {
    return this.http.delete<void>(
        this.environment.keycloak.url + this.environment.kamUrlGroups + "/" +group.id +"/role-mappings/realm",
        {body:theRoles}
    )
    .pipe(map(() => {
        return;
    }));
  }

  deleteGroup(group:KeycloakGroupModel): Observable<void> {
    return this.http.delete<void>(
        this.environment.keycloak.url + this.environment.kamUrlGroups + "/" +group.id,
    )
    .pipe(map(() => {
        return;
    }));
  }

}
