import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Inject,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { ValidateService } from '../../../services/validate.service';
import { Cnae, Combo } from '../../../models/combo';
import { CombosService } from '../../../services/combos.service';
import { GestorPtoRecogidaControllerService } from 'api-rest';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'app-collection-points-managers-general-data',
  templateUrl: './collection-points-managers-general-data.component.html',
  styleUrls: ['./collection-points-managers-general-data.component.scss'],
})
export class CollectionPointsManagersGeneralDataComponent implements OnInit {
  @Input() puntoRecogidaInfo!: any;
  @Output() public refrescarPuntosRecogidaEvent = new EventEmitter<any>();

  cnaeEndpoint = this.environment.urlBackCore + 'api/v1/core/cnae';

  datosGeneralesForm!: FormGroup;
  tiposDocumento: Combo[] = [];

  updateList: boolean = false;
  flagDisabledForm: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private validateSrv: ValidateService,
    public comboSrv: CombosService,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService,
    private datePipe: DatePipe
  ) {
    this.datosGeneralesForm = this.formBuilder.group({
      razonSocial: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      denominacion: [{ value: null, disabled: false }, Validators.maxLength(255)],

      tipoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(15)]) },
      ],

      nima: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.pattern(this.validateSrv.tenDigits),
            Validators.maxLength(10)
          ]),
        },
      ],
      numInscripcion1: [{ value: null, disabled: false }, Validators.maxLength(255)],
      numInscripcion2: [{ value: null, disabled: false }, Validators.maxLength(255)],
      iban: [
        { value: null, disabled: false },
        [this.formatoValidatorIban()],
      ],
      fechaBaja: [{ value: null, disabled: false }],
      fechaBajaEnvanet: [{ value: null, disabled: true }],
      fechaComienzo: [{ value: null, disabled: true }],
    });
  }

  ngOnInit() {
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.puntoRecogidaInfo) {
      this.cargarDatos(this.puntoRecogidaInfo);
      this.controlGeneralForm();
    }
  }

  cargarDatos(puntoRecogidaInfo: any) {
    this.datosGeneralesForm
      .get('razonSocial')
      ?.setValue(puntoRecogidaInfo?.razonSocial);

    this.datosGeneralesForm
      .get('denominacion')
      ?.setValue(puntoRecogidaInfo?.denominacion);

    this.datosGeneralesForm
      .get('tipoDocumento')
      ?.setValue(puntoRecogidaInfo?.tipoDocumento);
    this.datosGeneralesForm
      .get('codigoDocumento')
      ?.setValue(puntoRecogidaInfo?.codigoDocumento);
    this.datosGeneralesForm.get('nima')?.setValue(puntoRecogidaInfo?.nima);

    this.datosGeneralesForm
      .get('numInscripcion1')
      ?.setValue(puntoRecogidaInfo?.numInscripcionRegitro1);
    this.datosGeneralesForm
      .get('numInscripcion2')
      ?.setValue(puntoRecogidaInfo?.numInscripcionRegitro1);

    this.addSpaceFormatIban(puntoRecogidaInfo?.iban, true);

    this.datosGeneralesForm
      .get('fechaBaja')
      ?.setValue(puntoRecogidaInfo?.fechaBaja);

    let fechaBajaEnvanet =
      puntoRecogidaInfo?.fechaBajaEnvanet != null
        ? this.datePipe.transform(
            puntoRecogidaInfo?.fechaBajaEnvanet,
            'dd/MM/yyyy'
          )
        : null;

    this.datosGeneralesForm.get('fechaBajaEnvanet')?.setValue(fechaBajaEnvanet);

    let fechaComienzo =
      puntoRecogidaInfo?.fechaComienzoAct != null
        ? this.datePipe.transform(
            puntoRecogidaInfo?.fechaComienzoAct,
            'dd/MM/yyyy'
          )
        : null;
    this.datosGeneralesForm.get('fechaComienzo')?.setValue(fechaComienzo);
  }

  guardar() {
    this.datosGeneralesForm.markAllAsTouched();
    this.datosGeneralesForm.updateValueAndValidity();
    if (this.datosGeneralesForm.valid) {
      let body = this.puntoRecogidaInfo;
      body.razonSocial = this.datosGeneralesForm.get('razonSocial')?.value;

      body.denominacion = this.datosGeneralesForm.get('denominacion')?.value;

      body.tipoDocumento = this.datosGeneralesForm.get('tipoDocumento')?.value;

      body.codigoDocumento =
        this.datosGeneralesForm.get('codigoDocumento')?.value;

      body.nima = this.datosGeneralesForm.get('nima')?.value;

      body.numInscripcionRegitro1 =
        this.datosGeneralesForm.get('numInscripcion1')?.value;
      body.numInscripcionRegitro2 =
        this.datosGeneralesForm.get('numInscripcion2')?.value;

      body.iban = this.datosGeneralesForm.get('iban')?.value?.replace(/\s/g, ''); // Enviamos el iban sin formato

      body.fechaBaja = moment(new Date(this.datosGeneralesForm.get('fechaBaja')?.value)).format('YYYY-MM-DD');

      this.gestorPtoRecogidaControllerService
        .editByIdGestorPtoRecogida(this.puntoRecogidaInfo.id!, body)
        .subscribe({
          next: (response: any) => {
            this.snackBarSrv.showSnackBar(
              'Datos generales guardados correctamente',
              'success'
            );
            this.refrescarPuntosRecogidaEvent.emit(true);
          },
          error: (err) => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar los datos generales',
              'error'
            );
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  tipoDocumentoSel(idTipoDoc: string) {
    this.datosGeneralesForm.get('codigoDocumento')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.nifPattern),
        ]);
    } else if (idTipoDoc === 'DNI') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.dniPattern),
        ]);
    } else if (idTipoDoc === 'NIE') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.niePattern),
        ]);
    }
    this.datosGeneralesForm.get('codigoDocumento')?.updateValueAndValidity();
    this.datosGeneralesForm.get('codigoDocumento')?.markAsTouched();
  }

  controlGeneralForm() {
    if (this.canAccessContinuarAll() || this.canAccessGuardar()) {
      this.controlFormEnable();
    } else {
      this.controlFormDisable();
    }
  }

  controlFormDisable() {
    this.datosGeneralesForm.disable();
    if (this.puntoRecogidaInfo?.estado?.id == 3) {
      this.datosGeneralesForm.get('fechaBaja')?.enable();
    }
  }

  controlFormEnable() {
    this.datosGeneralesForm.enable();
    this.datosGeneralesForm.get('fechaBajaEnvanet')?.disable();
    this.datosGeneralesForm.get('fechaComienzo')?.disable();
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.datosGeneralesForm.controls[controlName].hasError(errorName) &&
      this.datosGeneralesForm.controls[controlName].touched
    );
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.datosGeneralesForm.controls[controlName]?.touched ||
        this.datosGeneralesForm.controls[controlName]?.dirty) &&
      this.datosGeneralesForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  canAccessContinuarAll(): boolean {
    return (
      this.authService.can(
        'ptosrecogida-gestor-detalle',
        'access-b-guardar-continuar'
      ) && this.puntoRecogidaInfo?.estado?.id == 1
    );
  }

  canAccessGuardar() {
    return (
      this.authService.can('ptosrecogida-gestor-detalle', 'access-b-guardar') &&
      this.puntoRecogidaInfo?.estado &&
      [1, 2].includes(this.puntoRecogidaInfo?.estado?.id!)
    );
  }

  canAccessDate(): boolean {
    return this.authService.hasCurrentUserRole('PR-ENV-GEST');
  }

  getCnaDescription(cnae: any) {
    return cnae?.clase + ' - ' + cnae?.descripcion;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'cnae': {
        return this.datosGeneralesForm.get('cnae') as FormControl;
        break;
      }
      default: {
        return this.datosGeneralesForm.get('') as FormControl;
        break;
      }
    }
  }

  getCnaeObjectQuery(searchterm: string) {
    return {
      descripcion: `or(ci(${searchterm}))`,
      clase: `or(ci(${searchterm}))`,
    };
  }

  selPaginatorHasError(controlName: string) {
    if (
      (this.datosGeneralesForm.controls[controlName]?.touched ||
        this.datosGeneralesForm.controls[controlName]?.dirty) &&
      this.datosGeneralesForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  formatoValidatorIban() {
    return (control: FormControl) => {
      const valor = control.value;
      if (!valor) {
        return null;
      }

      // Verificar que esté correctamente formateado con espacios cada 4 caracteres
      const regex = /^ES\d{2}(?:\s?\d{4}){5}$/;
      if (!regex.test(valor)) {
        return { invalidIbanFormat: true };
      }

      // Eliminar espacios del valor
      const valorSinEspacios = valor.replace(/\s/g, '');

      if (!valorSinEspacios.startsWith('ES')) {
        return { invalidIban: true };
      }

      // Verificar que tenga exactamente 24 caracteres (excluyendo espacios)
      if (valorSinEspacios.length !== 24) {
        return { invalidLength: true };
      }

      return null;
    };
  }

  addSpaceFormatIban(event?: any, carga: boolean = false) {
    let valor = carga ? event : event.target.value;

    if (valor != null) {
      valor = valor.replace(/[^A-Za-z0-9]/g, '');

      // Asegurar que las dos primeras letras sean ES
      if (valor.length >= 2) {
        const prefix = valor.substring(0, 2).toUpperCase();
        if (prefix !== 'ES') {
          valor = 'ES' + valor.substring(2);
        }
      }

      // Asegurar que solo haya letras en las dos primeras posiciones y números en el resto
      if (valor.length > 2) {
        // Los primeros dos caracteres después de ES deben ser números
        const digitsAfterES = valor.substring(2, 4).replace(/[^0-9]/g, '');
        valor = 'ES' + digitsAfterES + valor.substring(4).replace(/[^0-9]/g, '');
      }

      // Insertar un espacio después de los dos primeros números y luego cada 4 caracteres
      if (valor.length > 4) {
        const firstPart = valor.substring(0, 4); // "ESXX"
        const rest = valor.substring(4).match(/.{1,4}/g)?.join(' ') || '';
        valor = firstPart + (rest ? ' ' + rest : '');
      }

      // Limitar a 24 caracteres en total
      if (valor.length > 29) {
        valor = valor.substring(0, 29);
      }
    }
    // Actualizar el valor del formulario
    this.datosGeneralesForm.get('iban')?.setValue(valor);
  }
}
