import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ComunidadControllerService,
  ComunidadDTO,
  DeclaracionAdheridoControllerService,
  DeclaracionAdheridoDTO,
  DeclaracionCentroControllerService,
  DeclaracionCentroDTO,
  DeclaracionCentroFilterDTO,
  DeclaracionEnvaseFilterDTO,
  SectorControllerService,
  SectorModel,
} from 'api-rest';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { zip } from 'rxjs';
import { CategoryCenterDialogComponent } from '../category-center-dialog/category-center-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';

@Component({
  selector: 'lib-package-declaration-detail',
  templateUrl: './package-declaration-detail.component.html',
  styleUrls: ['./package-declaration-detail.component.scss'],
})
export class PackageDeclarationDetailComponent implements OnInit {
  private sub: any;
  id!: number;
  declaracionAdherido!: DeclaracionAdheridoDTO;
  centrosDeclaracion!: DeclaracionCentroDTO[];
  comunidadesAutonomas!: ComunidadDTO[];
  infoAdheridoVersion: string = '';
  selectedIndex = 0;

  constructor(
    private declaracionAdheridoControllerSrv: DeclaracionAdheridoControllerService,
    private declaracionCentroControllerSrv: DeclaracionCentroControllerService,
    public spinnerSrv: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBarSrv: SnackbarService,
    public dialog: MatDialog,
    private sectorControllerSrv: SectorControllerService,
    private comunidadControllerSrv: ComunidadControllerService,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService
  ) {
    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });
  }

  ngOnInit(): void {
    this.cargarDetalleDeclaracion();
  }

  actualizarCambio(event: any) {
    if (event) {
      this.cargarDetalleDeclaracion();
    }
  }

  cargarDetalleDeclaracion(): void {
    let filtro: DeclaracionCentroFilterDTO = {
      declaracionAdherido: {
        id: [this.id.toString()],
      },
    };

    this.spinnerSrv.loadingShow();
    zip(
      this.declaracionAdheridoControllerSrv.getById2(this.id),
      this.declaracionCentroControllerSrv.findAll6(filtro),
      this.comunidadControllerSrv.listarComunidades3(
        {},
        { page: 0, size: 20, sort: [] }
      )
    ).subscribe({
      next: (responses) => {
        this.spinnerSrv.loadingHide();
        this.declaracionAdherido = responses[0];
        this.centrosDeclaracion = responses[1];
        this.comunidadesAutonomas = responses[2]?.datos!;

        switch (this.declaracionAdherido?.version) {
          case '01': {
            this.infoAdheridoVersion =
              'SUSTITUTIVA - AÑO DE LOS DATOS: ' +
              this.declaracionAdherido?.anioDatos;
            break;
          }
          case 'IN': {
            this.infoAdheridoVersion =
              'INICIAL ESTIMACIÓN - AÑO DE LOS DATOS: ' +
              this.declaracionAdherido?.anioDatos;
            break;
          }
          case '00': {
            this.infoAdheridoVersion =
              'ORDINARIA - AÑO DE LOS DATOS: ' +
              this.declaracionAdherido?.anioDatos;
            break;
          }
          default: {
            this.infoAdheridoVersion =
              'SUSTITUTIVA - AÑO DE LOS DATOS: ' +
              this.declaracionAdherido?.anioDatos;
            break;
          }
        }
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  cargarNuevoCentro(centroId: number): void {
    let filtro: DeclaracionCentroFilterDTO = {
      declaracionAdherido: {
        id: [this.id.toString()],
      },
    };
    this.spinnerSrv.loadingShow();
    this.declaracionCentroControllerSrv.findAll6(filtro).subscribe({
      next: (responses) => {
        this.spinnerSrv.loadingHide();
        this.centrosDeclaracion = responses;
        // Se carga la pestaña del centro que se acaba de crear
        let index = this.centrosDeclaracion.findIndex(x => x.id === centroId);
        this.selectedIndex = index + 1;
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  abrirModalCentro(): void {
    const dialogRef = this.dialog.open(CategoryCenterDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        declaracionAdherido: this.declaracionAdherido,
        nuevoCentro: true,
      },
    });
    dialogRef.afterClosed().subscribe((centroId) => {
      console.log('id de centro', centroId);
      if (centroId != undefined) {
        this.cargarNuevoCentro(centroId);
      }
    });
  }

  canAccessNewCenter(): boolean {
    return this.authService.can('dec_envases-detalle', 'access-b-añadir');
  }

  canAccessGeneralData(): boolean {
    return (
      this.authService.can('dec_envases-detalle', 'access') ||
      this.canEditCenterData()
    );
  }

  canAccessLinkCenter(): boolean {
    return (
      this.authService.can('dec_envases-detalle', 'access') ||
      this.canEditCenterData()
    );
  }

  canEditGeneralData(): boolean {
    return this.authService.can('dec_envases-detalle', 'access-b-generales');
  }

  canEditCenterData(): boolean {
    return this.authService.can('dec_envases-detalle', 'access-b-centro');
  }

  canAccessEditAllGlobal(): boolean {
    return (
      this.authService.can('dec_envases', 'update') &&
      this.declaracionAdherido?.estadoDeclaracion?.id == 0
    );
  }
}
