/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeclaracionEnvaseDTO } from './declaracionEnvaseDTO';
import { ComposicionMaterialDTO } from './composicionMaterialDTO';


export interface DeclaracionEnvaseMaterialDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    declaracionEnvase?: DeclaracionEnvaseDTO;
    composicionMaterial?: ComposicionMaterialDTO;
    pesoUnitario?: number;
    pesoTotal?: number;
    porcReciclado?: number;
    tipoElemento?: DeclaracionEnvaseMaterialDTO.TipoElementoEnum;
    deleted?: string;
    deletedBy?: string;
}
export namespace DeclaracionEnvaseMaterialDTO {
    export type TipoElementoEnum = 'TAPONES' | 'ETIQUETAS' | 'OTROS_ELEMENTOS';
    export const TipoElementoEnum = {
        Tapones: 'TAPONES' as TipoElementoEnum,
        Etiquetas: 'ETIQUETAS' as TipoElementoEnum,
        OtrosElementos: 'OTROS_ELEMENTOS' as TipoElementoEnum
    };
}


