/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TipoEnvasesDTO } from './tipoEnvasesDTO';
import { CategoriaEnvaseDTO } from './categoriaEnvaseDTO';


export interface TarifaAdheridoSDDRAbiertoDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    anio?: number;
    peligrosidad?: boolean;
    eurosUnidad?: number;
    depositoUnidad?: number;
    tipologia?: TarifaAdheridoSDDRAbiertoDTO.TipologiaEnum;
    categoria?: CategoriaEnvaseDTO;
    tipoEnvases?: TipoEnvasesDTO;
    deleted?: string;
    deletedBy?: string;
}
export namespace TarifaAdheridoSDDRAbiertoDTO {
    export type TipologiaEnum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const TipologiaEnum = {
        Primario: 'PRIMARIO' as TipologiaEnum,
        Secundario: 'SECUNDARIO' as TipologiaEnum,
        Terciario: 'TERCIARIO' as TipologiaEnum
    };
}


