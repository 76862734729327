import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-collection-points',
  templateUrl: 'collection-points.component.html',
  styleUrls: ['./collection-points.component.scss'],
})
export class CollectionPointsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
