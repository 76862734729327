import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  PageDTOTarifaPtoRecogidaDTO,
  ProvinciaControllerService,
  TarifaPtoRecogidaControllerService,
  TarifaPtoRecogidaFilterDTO,
} from 'api-rest';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { CollectionPointRatesDialogComponent } from '../collection-point-rates-dialog/collection-point-rates-dialog.component';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';

@Component({
  selector: 'lib-collection-point-rates-search',
  templateUrl: './collection-point-rates-search.component.html',
  styleUrls: ['./collection-point-rates-search.component.scss'],
})
export class CollectionPointRatesSearchComponent implements OnInit {
  searchForm: FormGroup;
  codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?ptosRecogida=true';
  provinciaArray: any[] = [];
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  flagSearch: boolean = false;
  totalSearch: number = 0;
  defaultFilter: boolean = true;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  headArray: any = [
    {
      Head: 'Fecha desde',
      FieldName: 'fechaDesde',
      renderValue: (item: any) =>
        this.datePipe.transform(item.fechaDesde, 'dd/MM/yyyy'),
    },
    {
      Head: 'Fecha hasta',
      FieldName: 'fechaHasta',
      renderValue: (item: any) =>
        this.datePipe.transform(item.fechaHasta, 'dd/MM/yyyy'),
    },
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'codigoLer',
      renderValue: (item: any) =>
        `${item.codigoLer.tipoCodigo} ${item.codigoLer.descripcion}`,
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'Provincia',
      FieldName: 'provincia',
      renderValue: (item: any) => item.provincia.nombre,
    },
    {
      Head: '€/kg',
      FieldName: 'valorTarifa',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item.valorTarifa),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        { nameButton: 'view', icon: 'open_in_new', toolTip: 'Editar' },
        { nameButton: 'delete', icon: 'delete', toolTip: 'Borrar' },
      ],
      width: '10',
    },
  ];
  gridArray: any[] = [];

  fechaDesde: Date = new Date(new Date().getFullYear(), 0, 1);
  fechaHasta: Date = new Date(new Date().getFullYear(), 11, 31);

  constructor(
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private downloadService: DownloadService,
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private provinciaControllerSrv: ProvinciaControllerService,
    private tarifaPtoRecogidaControllerSrv: TarifaPtoRecogidaControllerService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.searchForm = this.formBuilder.group({
      fechaDesde: [
        { value: this.fechaDesde, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      fechaHasta: [
        { value: this.fechaHasta, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoLer: [{ value: null, disabled: false }],
      provincia: [{ value: null, disabled: false }],
      valorTarifa: [{ value: null, disabled: false }],
    });
  }
  ngOnInit() {
    this.getComboProvincia();
  }
  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }

  getOperacionDescription(op: any) {
    return op?.descripcion;
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.searchForm.get('codigoLer') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }
  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }
  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  getComboProvincia() {
    this.provinciaControllerSrv
      .listarComunidades1({}, { page: 0, size: 500 })
      .subscribe({
        next: (data: any) => {
          this.provinciaArray = data.datos!;
        },
        error: () => {},
      });
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  limpiar(): void {
    this.searchForm.reset({
      fechaDesde: this.fechaDesde,
      fechaHasta: this.fechaHasta,
    });
    this.gridArray = [];
  }

  buscar(): void {
    this.obtenerTarifas();
  }

  obtenerTarifas(): void {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.tarifaPtoRecogidaControllerSrv
      .findAllTarifaPtoRecogida(backendFilter, {
        page: page,
        size: size,
        sort: ['fechaDesde,asc', 'fechaHasta,asc', 'provincia,asc'],
      })
      .subscribe({
        next: (data: PageDTOTarifaPtoRecogidaDTO) => {
          if (data) {
            this.flagSearch = data.datos?.length !== 0 ? true : false;
            this.gridArray = data.datos ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  private getBackendFilter() {
    const filter = this.searchForm.value;
    if (filter.fechaDesde) {
      filter.fechaDesde = moment(new Date(filter.fechaDesde)).format(
        'YYYY-MM-DD'
      );
    }

    if (filter.fechaHasta) {
      filter.fechaHasta = moment(new Date(filter.fechaHasta)).format(
        'YYYY-MM-DD'
      );
    }
    if (filter.provincia) {
      filter.provincia = {
        id: filter.provincia.id,
      };
    }

    const backendFilter: TarifaPtoRecogidaFilterDTO | any = {
      fechaDesde: filter.fechaDesde,
      fechaHasta: filter.fechaHasta,
      codigoLer: filter.codigoLer,
      provincia: filter.provincia,
      valorTarifa: filter.valorTarifa
        ? this.parseCurrencyPipe.transform(filter.valorTarifa)
        : undefined,
    };

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }
  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.buscar();
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.buscar();
    }
  }
  action(element: any) {
    if (element.nameButton == 'view') {
      this.abrirModalTarifa(element);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.tarifaPtoRecogidaControllerSrv
      .exportTarifaPtoRecogida(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_TarifasPtoRecogida.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: '¿Desea eliminar la tarifa?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarTarifa(element);
      }
    });
  }

  eliminarTarifa(element: any): void {
    this.spinnerSrv.loadingShow();
    this.tarifaPtoRecogidaControllerSrv
      .deleteByIdTarifaPtoRecogida(element.id)
      .subscribe({
        next: (value: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `La tarifa se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar la tarifa',
            'error'
          );
        },
      });
  }

  abrirModalTarifa(tarifa?: any): void {
    const dialogRef = this.dialog.open(CollectionPointRatesDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        tarifa: tarifa,
        provincias: this.provinciaArray,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.buscar();
      }
    });
  }
}
