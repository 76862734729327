import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialPageComponent } from '../pages/initial-page.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';

export const explorerRoutes = [
  {
    path: '', component: InitialPageComponent, children: []
  },
];

@NgModule({
  declarations: [
    InitialPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(explorerRoutes),
    MaterialModule
  ],
  exports: [
    InitialPageComponent
  ]
})
export class InitialPageHomeModule {
  constructor() {
    let compilationVersion = "0.0.1-R->04/10/23-12:14:49"
    console.log("Initial page version:" + compilationVersion)
  }
}
