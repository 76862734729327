<div>
    <h1 mat-dialog-title>
        <mat-icon class="icon" style="vertical-align: middle;">add_circle</mat-icon>
        {{"KAM-USERS-TAB.NEW_USER" | translate}}
    </h1>

    <div mat-dialog-content style="margin: 0px;">
        <mat-stepper [linear]="true" orientation="vertical">
            <mat-step label='{{"KAM-USERS-TAB.USER_DATA" | translate}}' [completed]="step1Completed">
                <keycloak-auth-manager-kam-nu-step1
                    (completed)="setStep1Completed($event)"></keycloak-auth-manager-kam-nu-step1>
            </mat-step>
            <mat-step label='{{"KAM-USERS-TAB.ATRIBUTES" | translate}}' [completed]="step2Completed">
                <keycloak-auth-manager-kam-nu-step2
                    (completed)="setStep2Completed($event)"></keycloak-auth-manager-kam-nu-step2>
            </mat-step>
            <mat-step label='{{"KAM-USERS-TAB.GROUPS" | translate}}'>
                <keycloak-auth-manager-kam-nu-step3></keycloak-auth-manager-kam-nu-step3>
            </mat-step>
            <mat-step label='{{"KAM-USERS-TAB.ROLES" | translate}}'>
                <keycloak-auth-manager-kam-nu-step4></keycloak-auth-manager-kam-nu-step4>
            </mat-step>
            <mat-step label='{{"KAM-USERS-TAB.RESUME" | translate}}'>
                <keycloak-auth-manager-kam-nu-step5
                    (completed)="setStep5Completed($event)"></keycloak-auth-manager-kam-nu-step5>
            </mat-step>
        </mat-stepper>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button [mat-dialog-close]="">{{'KAM-GENERAL-DIALOG.CANCEL'| translate}}</button>
</div>