<keycloak-auth-manager-kam-attributes-form *ngIf="userAttributes"
    [customAttributesRequest]="userAttributes"
    [initialAttributesValues]="initialAttributesValues"
    (customAttributesValues)="setCustomAttributesValues($event)"
    (customAttributesValuesAreValid)="setCustomAttributesValuesAreValid($event)">
</keycloak-auth-manager-kam-attributes-form>

<div style="text-align: center" >
    <button mat-button matStepperPrevious>{{"KAM-GENERAL-DIALOG.BACK" |translate}}</button>
    <button mat-button matStepperNext (click)="step2End()"
    [disabled]="!customAttributesValuesAreValid">{{"KAM-GENERAL-DIALOG.NEXT" |translate}}</button>
</div>
