import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAttribute'
})
export class GetAttributePipe implements PipeTransform {

  transform(value: any, attributeName: string, maxLength: number = 50): any {
    const attributeValue = this.getPropertyValue(value, attributeName);
    return this.cutText(attributeValue, maxLength);
  }

  getPropertyValue(object: any, path: string) {
    if (path !== undefined && object !== undefined) {
      return path.split('.').reduce((o, p) => (o ? o[p] : null), object);
    }
    return null;
  }

  cutText(text: string, maxLength: number): string {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

}

