<div *ngIf="user">
    <div>{{"KAM-USERS-TAB.USER" | translate}}</div>
    <ul>
        <li *ngIf="userDataAttributesMap">{{"KAM-USERS-TAB.ATRIBUTES" | translate}}</li>
        <ul *ngIf="userDataAttributesMap">
            <li *ngFor="let customAttribute of userDataAttributesMap | keyvalue">
                {{customAttribute.key}}: {{customAttribute.value}}
            </li>
        </ul>    

        <li *ngIf="groups">{{"KAM-USERS-TAB.GROUPS" | translate}}</li>
        <ul *ngIf="groups">        
            <li *ngFor="let group of groups">
                {{group.name}}
            </li>
        </ul> 

        <li *ngIf="roles">{{"KAM-USERS-TAB.ROLES" | translate}}</li>
        <ul *ngIf="roles">        
            <li *ngFor="let role of roles">
                {{role.name}}
            </li>
        </ul>
    </ul>
</div>

<div style="text-align: center" >
    <button mat-button matStepperPrevious>{{"KAM-GENERAL-DIALOG.BACK" |translate}}</button>
    <button mat-button matStepperNext (click)="step5End()">{{"KAM-GENERAL-DIALOG.CONFIRM" |translate}}</button>
</div>