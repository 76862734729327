import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttachedService } from '../../../services/attached.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import {
  CategoriaEnvaseControllerService,
  DeclaracionCentroControllerService,
  DeclaracionCentroDTO,
  DeclaracionCentroPayloadDTO,
} from 'api-rest';
import { CombosService } from '../../../services/combos.service';

export interface MappedResponse<T> {
  totalElements: number;
  recordsFiltered: number;
  datos: T[];
}
@Component({
  selector: 'lib-category-center-dialog',
  templateUrl: './category-center-dialog.component.html',
  styleUrls: ['./category-center-dialog.component.scss'],
})
export class CategoryCenterDialogComponent implements OnInit {
  flagDisabledForm: boolean = false;
  titulo = '';
  formGroup!: FormGroup;
  nuevoCentro: boolean = false;
  declaracionAdherido: any;
  declaracionCentro!: DeclaracionCentroDTO;
  categoriasEnvases: any[] = [];
  updateList: boolean = false;
  centroData: DeclaracionCentroDTO;
  centroArray: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<CategoryCenterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private attachedSrv: AttachedService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private centroService: DeclaracionCentroControllerService,
    private categoryService: CategoriaEnvaseControllerService
  ) {
    this.formGroup = this.formBuilder.group({
      centro: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      categoria: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      ventaDirecta: [
        { value: null, disabled: false },
        [Validators.min(0), Validators.max(100)],
      ],
      ventaDistribucion: [{ value: null, disabled: true }],
    });

    this.nuevoCentro = this.data.nuevoCentro;
    this.centroData = this.data.centroData;

    this.declaracionAdherido = this.data.declaracionAdherido;
    this.centroArray = this.declaracionAdherido?.adherido?.centrosEnvasado;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.getCategorias();
    if (this.nuevoCentro) {
      this.titulo = 'Nuevo centros y categoría';
    } else {
      this.titulo = 'Editar centros y categoría';
      this.formGroup.get('centro')?.disable();
      this.formGroup.get('categoria')?.disable();
      this.cargarModelo();
    }
    this.valueChangeVentaDirecta();
  }

  cargarModelo(): void {
    this.formGroup.get('centro')?.setValue(this.centroData?.centroEnvasado?.id);
    this.formGroup
      .get('categoria')
      ?.setValue(this.centroData?.categoriaEnvase?.id);
    this.formGroup
      .get('ventaDirecta')
      ?.setValue(this.centroData?.porcVentaDirecta);
    this.formGroup
      .get('ventaDistribucion')
      ?.setValue(this.centroData?.porcVentaDistribucion);
  }

  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      this.createCentro();
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  getCategorias() {
    this.spinnerSrv.loadingShow();
    this.categoryService.listarCategoriaEnvases().subscribe({
      next: (data: any) => {
        if (data) {
          this.categoriasEnvases = data?.filter((x: any) => x.id != 7);
          this.spinnerSrv.loadingHide();
        }
      },
      error: (error: any) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'centro': {
        return this.formGroup.get('centro') as FormControl;
        break;
      }
      case 'categoria': {
        return this.formGroup.get('categoria') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }

  controlHasErrorSelect(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  public filterList(data: any) {}

  private getBackendFilter() {
    const selectCategoria = this.categoriasEnvases.find(
      (element: any) => element.id == this.formGroup.get('categoria')?.value
    );
    const selectCentro = this.centroArray.find(
      (element: any) => element.id == this.formGroup.get('centro')?.value
    );

    const backendFilter: DeclaracionCentroPayloadDTO = {
      declaracionAdherido: this.declaracionAdherido,
      centroEnvasado: selectCentro,
      categoriaEnvase: selectCategoria,
      porcVentaDirecta: this.formGroup.get('ventaDirecta')?.value,
      porcVentaDistribucion: this.formGroup.get('ventaDistribucion')?.value,
    };
    return { backendFilter };
  }

  createCentro() {
    if (this.nuevoCentro) {
      const { backendFilter } = this.getBackendFilter();
      this.spinnerSrv.loadingShow();
      this.centroService.create4(backendFilter).subscribe({
        next: (data: DeclaracionCentroDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(data?.id);
            this.snackBarSrv.showSnackBar(
              'Se ha creado un nuevo centro envasado correctamente',
              'success'
            );
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
    } else {
      const backendFilter = {
        declaracionAdherido: this.declaracionAdherido,
        centroEnvasado: this.centroData.centroEnvasado,
        categoriaEnvase: this.centroData.categoriaEnvase,
        porcVentaDirecta: this.formGroup.get('ventaDirecta')?.value,
        porcVentaDistribucion: this.formGroup.get('ventaDistribucion')?.value,
      };
      this.spinnerSrv.loadingShow();
      this.centroService
        .editById4(this.centroData?.id!, backendFilter)
        .subscribe({
          next: (data: DeclaracionCentroDTO) => {
            if (data) {
              this.spinnerSrv.loadingHide();
              this.centroData.porcVentaDirecta =
                this.formGroup.get('ventaDirecta')?.value;
              (this.centroData.porcVentaDistribucion =
                this.formGroup.get('ventaDistribucion')?.value),
                this.dialogRef.close(this.centroData?.id!);
              this.snackBarSrv.showSnackBar(
                'Se ha actualizado el centro envasado correctamente',
                'success'
              );
            }
          },
          error: (error: any) => {
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  valueChangeVentaDirecta() {
    this.formGroup.get('ventaDirecta')?.valueChanges.subscribe((value: any) => {
      if (value != null) {
        this.formGroup.get('ventaDistribucion')?.setValue(100 - value);
      } else {
        this.formGroup.get('ventaDistribucion')?.setValue(null);
      }
    });
  }
}
