import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaDecimal',
})
@Injectable({
  providedIn: 'root',
})
export class CommaDecimalPipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'number') {
      return this.formatNumber(value.toFixed(5));
    } else if (typeof value === 'string' && !isNaN(parseFloat(value))) {
      return this.formatNumber(parseFloat(value).toFixed(5));
    } else {
      return '0';
    }
  }

  private formatNumber(value: string): string {
    let [integerPart, decimalPart] = value.split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    let formattedValue = decimalPart
      ? `${integerPart},${decimalPart}`
      : integerPart;

    formattedValue = formattedValue.replace(/,?0+$/, '');

    return `${formattedValue}`;
  }
}
