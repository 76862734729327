<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">Alta de la solicitud</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <form class="form-modal py-3" [formGroup]="dataForm">
      <div class="title-group">Información del Punto de Recogida </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="ptoRecogida">Punto de recogida <span class="oblig">*</span></label>
          <div class="custom-mat-form-field-width">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <mat-select formControlName="ptoRecogida" id="ptoRecogida" placeholder="Punto de recogida">
                <mat-option *ngFor="let pto of ptoArray" [value]="pto">
                  {{ pto.razonSocial }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('ptoRecogida', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="contactoPtoRecogida">Contacto</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="contactoPtoRecogida" id="contactoPtoRecogida"
                placeholder="Contacto" value="">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="direccionPtoRecogida">Dirección</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="direccionPtoRecogida" id="direccionPtoRecogida"
                placeholder="Dirección" value="">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="title-group">Información de la solicitud </div>

      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="fechaSugerida">Fecha sugerida para la recogida</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input matInput [matDatepicker]="datepicker" formControlName="fechaSugerida" placeholder="Fecha">
              <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker>
              </mat-datepicker>
              <mat-error *ngIf="controlHasError('fechaSugerida', 'matDatepickerParse')">Formato incorrecto
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <!-- <label for="estadoSolicitud">Estado </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="estadoSolicitud" id="estadoSolicitud" placeholder="Estado"
                value="">
            </mat-form-field>
          </div> -->
        </div>
        <div class="col-sm flex-grow-1">
          <!-- <label for="fechaSolicitud">Fecha de solicitud</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="fechaSolicitud" id="fechaSolicitud"
                placeholder="Fecha solicitud" value="">
            </mat-form-field>
          </div> -->
        </div>
        <div class="col-sm flex-grow-1">
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="ObservacionesPto">Observaciones Punto de Recogida</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="ObservacionesPto" id="ObservacionesPto"
                placeholder="Observaciones" value="">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="title-group pt-3">Información solicitud recogida </div>

      <div class="row form">

        <div class="col-sm flex-grow-2">
          <label for="codigoLer">Código LER y descripción de ENVALORA <span class="oblig">* </span><i
            *ngIf="dataForm.get('codigoLer')?.value?.peligrosidad === true" class="fas fa-skull-crossbones"
            style="color: red;" matTooltip="Envase de tipo peligroso"></i></label>
          <div class="custom-mat-form-field-width">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <mat-select formControlName="codigoLer" id="codigoLer" placeholder="Código LER y descripción de ENVALORA">
                <mat-option *ngFor="let item of codigoLerArray" [value]="item">
                  {{ item?.tipoCodigo }} {{ item?.descripcion }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('codigoLer', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-1">
          <label for="acondicionamiento">Acondicionamiento <span class="oblig">*</span></label>
          <div class="custom-mat-form-field-width">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <mat-select formControlName="acondicionamiento" id="acondicionamiento" placeholder="Acondicionamiento">
                <mat-option *ngFor="let item of acondicionamientoRecogidaArray" [value]="item">
                  {{ item.descripcion }}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('acondicionamiento')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'acondicionamiento')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
              <mat-error *ngIf="controlHasError('acondicionamiento', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-0">
          <label for="cantidad">Cantidad (unidades) <span class="oblig">*</span></label>
          <div class="custom-mat-field-cantidad">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="cantidad" id="cantidad" placeholder="Cantidad"
                appNumericPoint>
              <mat-error *ngIf="controlHasError('cantidad', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>

      <div class="title-group pt-3">Información solicitud suministro</div>

      <div class="row form">

        <div class="col-sm flex-grow-0">
          <label for="cantidadSuministro">Cantidad de suministro (unidades) <span class="oblig"
              *ngIf="isCantidadSuministroRequired">*</span> </label>
          <div class="custom-mat-field-cantidadUnidades">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="cantidadSuministro" id="cantidad"
                placeholder="Cantidad de suministro" appNumericPoint>
              <mat-error *ngIf="controlHasError('cantidadSuministro', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="acondicionamientoSolicitud">Acondicionamiento <span class="oblig"
              *ngIf="isAcondicionamientoSolicitudRequired">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-width-90" appearance="outline">
              <mat-select formControlName="acondicionamientoSolicitud" id="acondicionamientoSolicitud"
                placeholder="Acondicionamiento">
                <mat-option *ngFor="let item of acondicionamientoArray" [value]="item">
                  {{ item.descripcion }}
                </mat-option>

              </mat-select>
              <span *ngIf="showClearButton('acondicionamientoSolicitud')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'acondicionamientoSolicitud')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
              <mat-error *ngIf="controlHasError('acondicionamientoSolicitud', 'required')">Campo obligatorio
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-1"></div>
      </div>

    </form>
  </div>

  <div class="fixed-footer">
    <div class="modal-footer actions">
      <div class="note">* Campos Obligatorios</div>
      <div class="button-group">
        <button type="button" (click)="closeDialog()" class="btn btn-outline-primary" data-dismiss="modal"
          aria-label="Close">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close"
          (click)="saveData()">Guardar y pasar a pendiente</button>
      </div>
    </div>
  </div>

</div>
