import { Component, OnInit } from '@angular/core';
import { KamUserService } from '../../../../services/kam-user.services';
import { Subscription } from 'rxjs';
import { KeycloakGroupModel } from '../../../../model/keycloak-group.model';

@Component({
  selector: 'keycloak-auth-manager-kam-nu-step3',
  templateUrl: './kam-nu-step3.component.html',
  styleUrls: ['./kam-nu-step3.component.css']
})
export class KamNuStep3Component implements OnInit {

  groupSelection: KeycloakGroupModel[] | undefined;
  initialGroupsSelected: string[]|undefined;
  subscription: Subscription | undefined;

  constructor(private kamUserService: KamUserService,) { }

  ngOnInit(): void {
    this.subscription = this.kamUserService.userDataSubscription().subscribe(user=>{
      if (user){
        this.initialGroupsSelected = user.groups;      
      }
    })
  }


  groupSelectionUpdate($event: any){
    this.groupSelection = $event
    if (this.groupSelection)
      this.kamUserService.setUserGroup($event)
    else
      this.kamUserService.setUserGroup(undefined)
  }

  step3End(){

  }

}
