import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AuthManagerState } from './auth-manager.model';

export const selectAuthManager = createFeatureSelector<AuthManagerState>('authManagerRdc');
 

export const selectUsersList = createSelector(
  selectAuthManager,
  (state) => {
    return state.users;
  }
);

export const selectUsersCount = createSelector(
  selectAuthManager,
  (state) => {
    return state.usersCount;
  }
);

export const selectGroups = createSelector(
  selectAuthManager,
  (state) => {
    return state.groups;
  }
);

export const selectRoles = createSelector(
  selectAuthManager,
  (state) => {
    return state.roles;
  }
);

export const selectResourceServers = createSelector(
  selectAuthManager,
  (state) => {
    return state.clients;
  }
);

export const getSelectedResourceServer = createSelector(
  selectAuthManager,
  (state) => {
    return state.selectedResourceServer;
  }
);