import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class GeSelectPaginationService {

  constructor(private http: HttpClient) { }

  getSearchResultLibrary(endPointFilter: string, objQueryName: string | Function, filter: string = '', pageNumber: number, pageSize: number, anotherTypeSearch: boolean) {
    let params: any = { page: pageNumber, size: pageSize };

    if(filter != null && filter != '') {
      if(typeof objQueryName == 'function') {
        const additionalFilters = objQueryName(filter);
        params = Object.assign(params, additionalFilters);
      } else {
        params[objQueryName] = anotherTypeSearch ? filter : `ci(${filter})`;
      }
    }

    return this.http.get<any>(endPointFilter, { params });
  }
}
