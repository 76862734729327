import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RateClosedDialogComponent } from '../rate-closed-dialog/rate-closed-dialog.component';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import {
  PageDTOTarifaAdheridoSDDRCerradoDTO,
  TarifaAdheridoSDDRCerradoFilterDTO,
  TarifaAdheridoSddrCerradoControllerService,
} from 'api-rest';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';

@Component({
  selector: 'app-attached-rate-closed-search',
  templateUrl: './attached-rate-closed-search.component.html',
  styleUrls: ['./attached-rate-closed-search.component.scss'],
})
export class AttachedRateClosedSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;
  roles: any[] = [];

  headArray: any = [
    {
      Head: 'Año',
      FieldName: 'anio',
    },
    {
      Head: 'Desde',
      FieldName: 'desde',
      renderValue: (item: any) => this.formatCurrencyPipe.transform(item.desde),
    },
    {
      Head: 'Hasta',
      FieldName: 'hasta',
      renderValue: (item: any) => this.formatCurrencyPipe.transform(item.hasta),
    },
    {
      Head: 'Cuota (€)',
      FieldName: 'cuota',
      renderValue: (item: any) => this.formatCurrencyPipe.transform(item.cuota),
    },
    {
      Head: 'Fianza (€/kg)',
      FieldName: 'deposito',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item.deposito),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        { nameButton: 'view', icon: 'open_in_new', toolTip: 'Editar' },
        { nameButton: 'delete', icon: 'delete', toolTip: 'Borrar' },
      ],
      width: '10',
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;
  categorias: any[] = [];
  gruposEnvase: any[] = [];
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private attachedClosedService: TarifaAdheridoSddrCerradoControllerService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private downloadService: DownloadService,
    private formatCurrencyPipe: FormatCurrencyPipe
  ) {
    this.searchForm = this.formBuilder.group({
      anio: [{ value: null, disabled: false }, [this.customValidator()]],
    });
  }

  ngOnInit() {}

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.getClosed();
    }
  }

  checked(item: any) {}

  private getBackendFilter() {
    const filter = this.searchForm.get('anio')?.value;

    const backendFilter: TarifaAdheridoSDDRCerradoFilterDTO = {
      anio: filter,
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  getClosed() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.attachedClosedService
      .findAll1(backendFilter, {
        page: page,
        size: size,
        sort: ['anio,asc', 'desde,asc'],
      })
      .subscribe({
        next: (data: PageDTOTarifaAdheridoSDDRCerradoDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = data.datos ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.getClosed();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openModalClosed(element);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    }
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.attachedClosedService
      .exportTarifaSDDRCerrado(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_Tarifa_SDDR_Cerrado.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  exportarPdf() {}

  openModalClosed(element?: any): void {
    const dialogRef = this.dialog.open(RateClosedDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        itemClosed: element,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearSearcher();
        this.getClosed();
      }
    });
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la tarifa?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarTarifaClosed(element);
      }
    });
  }

  customValidator() {
    return (control: { value: any }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (/^\d{4}$/.test(value)) {
        const intValue = parseInt(value, 10);
        if (intValue >= 1900 && intValue <= 2500) {
          return null; // Valor válido
        }
      }
      return { invalid: true }; // Valor no válido
    };
  }

  eliminarTarifaClosed(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.attachedClosedService.deleteById1(element.id).subscribe({
        next: (value: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `La tarifa se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar la tarifa',
            'error'
          );
        },
      });
    }
  }
}
