import { Component, OnDestroy, OnInit } from '@angular/core';
import { KeycloakGroupModel } from '../../../../model/keycloak-group.model';
import { Subscription } from 'rxjs';
import { KeycloakGroupService } from '../../../../services/kam-group.services';
import { KeycloakRoleModel } from '../../../../model/keycloak-role.model';

@Component({
  selector: 'keycloak-auth-manager-kam-mg-step4',
  templateUrl: './kam-mg-step4.component.html',
  styleUrls: ['./kam-mg-step4.component.css']
})
export class KamMgStep4Component implements OnInit , OnDestroy{
  subscription: Subscription | undefined;
  groupRolesSubscription: Subscription | undefined;
  group: KeycloakGroupModel | undefined;
  groupRoles: KeycloakRoleModel[] | undefined;

  constructor( private keycloakGroupService: KeycloakGroupService) { }

  ngOnInit(): void {
    this.subscription = this.keycloakGroupService.dataSubscription()?.subscribe(group=>{
      this.group = group
    })

    this.groupRolesSubscription = this.keycloakGroupService.groupRolesSubscription().subscribe(groupRoles=>{
      this.groupRoles = groupRoles
    })

  }

  step4End(){}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
    this.groupRolesSubscription?.unsubscribe()
  }
}
