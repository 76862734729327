
<div class="row">
    <div class="col-servers">
        <keycloak-auth-manager-kam-permissions-tab-servers>            
        </keycloak-auth-manager-kam-permissions-tab-servers>
    </div>
    <div class="col-resources"> 
        <keycloak-auth-manager-kam-permissions-tab-resources>
        </keycloak-auth-manager-kam-permissions-tab-resources>
    </div>
</div>

