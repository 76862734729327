<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <div class="title-name mb-3" style="font-size: 1.3rem; font-weight: 700;"> {{gestorInfo?.razonSocial}}</div>

    <!-- Formulario contacto-->
    <form class="form-modal py-3" [formGroup]="formGroup">
      <div class="row-fields">
        <div class="field field-66">
          <label for="iban">Tipo de contacto <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="tipoContacto" id="tipoContacto" placeholder="Tipo de contacto"
              (selectionChange)="tipoContactoSel($event.value)">
              <mat-option *ngFor="let tipo of tiposContactos" [value]="tipo.id">
                {{tipo.descripcionCorta}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('tipoContacto', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="field field-33" style="margin-top: 25px;">
          <mat-checkbox *ngIf="contacto?.requerido" class="margin-left-10 custom-checkbox" formControlName="requerido"
            color="primary" style="pointer-events: none;">
            <label for="tipoDir">Requerido</label>
          </mat-checkbox>
        </div>
      </div>

      <div class="row-fields">
        <div class="field field-66">
          <label for="copiaContacto">Copiar datos de contacto de</label>
          <mat-form-field appearance="outline">
            <mat-select (selectionChange)="cargarContactos($event.value,false)" formControlName="copiaContacto"
              id="copiaContacto" placeholder="Copiar datos de contacto de">
              <mat-option *ngFor="let contacto of copiarContacto" [value]="contacto">
                {{contacto?.fullName}} ({{contacto?.tipo_contacto?.descripcionCorta}})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="field field-33">
          <label for="persona">Tipo de Entidad <span class="oblig">*</span></label>
          <mat-radio-group formControlName="persona" (change)="onTipoEntidadChange()">
            <div class="row">
              <div class="col-sm flex-grow-0">
                <mat-radio-button [value]="false" color="primary" [checked]="!formGroup.value.persona">
                  Empresa
                </mat-radio-button>
              </div>
              <div class="col-sm flex-grow-0">
                <mat-radio-button [value]="true" color="primary" [checked]="formGroup.value.persona">
                  Persona
                </mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>

      <div class="row form">
        <div class="col-sm flex-grow-0">
          <label for="nombre" *ngIf="!personType">Razón social <span class="oblig">*</span></label>
          <label for="nombre" *ngIf="personType">Nombre <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline">
              <input formControlName="nombre" id="nombre" placeholder="Nombre" matInput type="text">
              <mat-error *ngIf="controlHasError('nombre', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-1" *ngIf="personType">
          <label for="apellido1">Apellido 1<span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="apellido1" id="apellido1" placeholder="Apellido 1" matInput type="text">
              <mat-error *ngIf="controlHasError('apellido1', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="col-sm flex-grow-1" *ngIf="personType">
          <label for="apellido2">Apellido 2</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="apellido2" id="apellido2" placeholder="Apellido 2" matInput type="text">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-0">
          <label for="documentType">Tipo Documento<span class="oblig"
              *ngIf="this.formGroup.controls['tipoContacto']?.value===3||this.formGroup.controls['tipoContacto']?.value==4">*</span>
          </label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="documentType" id="documentType" placeholder="Tipo Documento"
                (selectionChange)="tipoDocumentoSel($event.value)">
                <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
                  {{tipo.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('documentType', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="document">Documento<span class="oblig"
              *ngIf="this.formGroup.controls['tipoContacto']?.value===3||this.formGroup.controls['tipoContacto']?.value==4">*</span>
          </label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="document" id="document" placeholder="Documento" matInput type="text"
                oninput="this.value = this.value.toUpperCase()">
              <mat-error *ngIf="controlHasError('document', 'required')">Campo obligatorio</mat-error>
              <mat-error *ngIf="controlHasError('document', 'pattern')">Formato incorrecto</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form justify-content-between">
        <div class="col-sm flex-grow-0">
          <label for="tipoTelefono">Tipo tlf.<span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="tipoTelefono" id="tipoTelefono" placeholder="Tipo tlf.">
                <mat-option *ngFor="let tipo of tiposTelefono" [value]="tipo.id">
                  {{tipo.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('tipoTelefono', 'required')">Campo obligatorio</mat-error>
              <mat-error *ngIf="controlHasError('tipoTelefono', 'pattern')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm flex-grow-1">
          <label for="prefijoPais">Prefijo pais<span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination class="lib-ge-select" (selectedModel)="selectedModel('prefijoPais')"
              [endPointFilter]="paisesEndpoint" (filteredList)="filterList($event,'prefijoPais')"
              [updateList]="updateList" [formControlNameSelect]="getFormControl('prefijoPais')"
              [placeHolder]="'Prefijo pais'" [objQueryName]="'prefijo'" descripcion="prefijoNombre" [defaultValue]=69
              [customClass]="rightHolder('prefijoPais')">
            </lib-ge-select-pagination>
            <div *ngIf="controlHasErrorSelect('prefijoPais')" class="mat-error">
              Campo obligatorio
            </div>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="telefono">Teléfono<span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="telefono" id="telefono" placeholder="Teléfono" matInput type="text">
              <mat-error *ngIf="controlHasError('telefono', 'required')">Campo obligatorio</mat-error>
              <mat-error
                *ngIf="!controlHasError('telefono', 'required') && controlHasError('telefono', 'invalidPhoneFormat')">
                Formato incorrecto.</mat-error>
              <mat-error
                *ngIf="!controlHasError('telefono', 'required') && !controlHasError('telefono', 'invalidPhoneFormat') && controlHasError('telefono', 'invalidTelefonoFijo')">
                Formato incorrecto para tipo Fijo con prefijo 34.
              </mat-error>
              <mat-error
                *ngIf="!controlHasError('telefono', 'required') && !controlHasError('telefono', 'invalidPhoneFormat') && !controlHasError('telefono', 'invalidTelefonoFijo') && controlHasError('telefono', 'invalidTelefonoMovil')">
                Formato incorrecto para tipo Movil con prefijo 34.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="email">Email <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="email" id="email" placeholder="Email" matInput type="text">
              <mat-error *ngIf="controlHasError('email', 'required')">Campo obligatorio</mat-error>
              <mat-error *ngIf="controlHasError('email', 'pattern')">Formato incorrecto</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm">
          <label for="recibirComunicaciones">Recibir comunicaciones generales <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-radio-group formControlName="recibirComunicaciones" id="recibirComunicaciones"
              class="custom-mat-form-radio">
              <mat-radio-button [value]="true">Sí</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
              <mat-error *ngIf="controlHasError('recibirComunicaciones', 'required')">Campo obligatorio</mat-error>
            </mat-radio-group>
          </div>
        </div>
        <div class="col-sm">

        </div>
      </div>
      <div class="row form">
        <div class="col-sm">

        </div>
        <div class="col-sm">

        </div>
      </div>
    </form>
    <!-- FIN Formulario contacto -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" *ngIf="canAccessUpdate()" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
