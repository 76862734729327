  <!-- MIGAS DE PAN Y TÍTULO -->
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="bg-second-migas">
    <div class="container mt-cab-fixed">
      <div class="pre-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Poseedores</li>
            <li class="breadcrumb-item">Búsqueda de Poseedores</li>
          </ol>
        </nav>
        <div class="content-title">
          <h2 class="title-pag">Búsqueda de Poseedores</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN MIGAS DE PAN Y TÍTULO -->
  <!-- CONTENIDO -->

  <div class="container-form">
    <!-- Formulario de búsqueda -->
    <form class="container form-search py-3" [formGroup]="searchForm">
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="idPoseedor">ID. Poseedor </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="idPoseedor" id="idPoseedor" placeholder="ID. Poseedor" type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="codigo_documento">Documento (NIE,NIF) </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="codigo_documento" id="codigo_documento" placeholder="Documento (NIE,NIF)"
                type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="razon_social">Razón Social </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="razon_social" id="razon_social" placeholder="Razón Social" type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="nima">NIMA </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="nima" id="nima" placeholder="Nima" type="text">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="comunidad_autonoma">Comunidad Autónoma (Dirección Física)</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="comunidad_autonoma" id="comunidad_autonoma" placeholder="-- Comunidad --">
                <mat-option *ngFor="let comunidad of comunidadArray" [value]="comunidad">
                  {{ comunidad.nombre }}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('comunidad_autonoma')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'comunidad_autonoma')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="estado">Estado </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="estado" id="estado" placeholder="-- Estado --">
                <mat-option *ngFor="let des of estadosArray" [value]="des">
                  {{ des.descripcionCorta }}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('estado')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'estado')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="cnae">CNAE Principal</label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination [endPointFilter]="cnaeEndpoint" [multiple]=true [updateList]="updateList"
              [descripcion]="getCnaDescription" [formControlNameSelect]="getFormControl('cnae')"
              [objQueryName]="getCnaeObjectQuery" [placeHolder]="'--CNAE Principal --'"
              [customClass]="rightHolder('cnae')">
            </lib-ge-select-pagination>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="contratoGestor">Contrato con algún gestor</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="contratoGestor" id="contratoGestor" placeholder="Seleccionar">
                <mat-option [value]="true">Sí</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="acuerdoGestorEnvalora">Acuerdo Gestor Envalora</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="acuerdoGestorEnvalora" id="acuerdoGestorEnvalora" placeholder="Seleccionar">
                <mat-option [value]="true">Sí</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>


      <div class="button-group">
        <button type="button" class="btn btn-outline-primary" (click)="clearSearcher()">Limpiar</button>
        <button type="button" class="btn btn-primary" (click)="searchData()">Buscar</button>
      </div>
    </form>
    <!-- FIN Formulario de búsqueda -->
  </div>
  <div class="bg-second-table-info pt-3 pb-5">
    <div *ngIf="flagSearch; else messageClear" class="container">
      <div class="actions-table">
        <div class=""><span class="align-middle tit-table">{{ recordsTotal }} registros encontrados</span></div>
        <div class="text-right">
          <div class="d-inline">

            <div class="d-inline dropdown show">
              <a (click)="exportarExcel()" class="btn btn-outline-primary" role="button" aria-haspopup="true"
                aria-expanded="false">
                <span class="mr-3">Exportar</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-tabla-proy">
        <div class="card-body">
          <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
            <div class="datatable-container table-responsive">
              <!-- TABLA Listado con resultados de búsqueda -->
              <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (checked)="checked($event)"
                (actionButton)="action($event)"></lib-ge-table>
              <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
                <div class="d-flex mt-2">
                  <ngb-pagination [pageSize]="pageSize" [collectionSize]="recordsTotal" [(page)]="pageNumber"
                    (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
                  </ngb-pagination>
                </div>

                <div class="mt-2 selectPage">
                  <label for="itemsPerPage">Mostrar:</label>
                  <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                    <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <!-- FIN TABLA Listado con resultados de búsqueda -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #messageClear>
      <div class="mensaje-container">
        <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
          formulario</p>
        <p class="mensaje-linea"></p>
      </div>
    </ng-template>
  </div>
