<div class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-bordered table-sm" style="overflow-x: auto;">
        <thead>
          <tr>
            <th [ngStyle]="{width: head.width+'%'}" [ngClass]="{ 'stable-column': head.permanent}" *ngFor=" let head of
              headArray">
              <div class="d-flex align-items-center justify-content-between">
                <span
                  style="font-weight: bold !important; font-family: Arial, Helvetica, sans-serif !important;">{{head.Head}}</span>
                <span *ngIf="head.showCheckedAll">
                  <input class="form-check-input" (change)="selectAllRows($event, gridArray)" [checked]="selectAllItems"
                    type="checkbox">
                </span>
                <button *ngIf="head.FieldName !== 'actions'" class="btn btn-link btn-sm ml-auto"
                  (click)="sortColumn(head)">
                  <i class="fas fa-sort"></i>
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="!isDragAndDrog">
          <tr *ngFor="let item of gridArray;let sr=index" style="height:36px !important;"
            [ngClass]="{ 'transparent-background': dataPaintRow !== null && colorResultsRow[sr] !== ''}"
            [style.background-color]="dataPaintRow !== null ? colorResultsRow[sr] : ''">
            <th *ngIf="isEmpty(item)">
            </th>
            <td *ngFor="let head of headArray" class="centered-cell " [ngClass]="{ 'stable-column': head.permanent,'transparent-background': dataPaintColumn !== null && colorResultsColumn[sr] !== '' &&
              head.FieldName==dataPaintColumn.fieldName}" [style.background-color]="dataPaintColumn
              !== null &&
              head.FieldName==dataPaintColumn.fieldName ?
              colorResultsColumn[sr] : ''">
              <div *ngIf="!head.check && (item | getAttribute: head.FieldName) !== undefined"
                [ngStyle]="{ 'cursor': head.Tooltip === true ? 'pointer' : '' }" [matTooltip]="head.Tooltip ? head.Attribute ? (item | getAttribute:
                head.FieldName:maxLengthText | getAttribute:
                head.Attribute:maxLengthText) : (item | getAttribute: head.FieldName:maxLengthText):null">
                <ng-container *ngIf="head.renderValue">
                  {{ head.renderValue(item) }}
                </ng-container>
                <ng-container *ngIf="!head.renderValue">
                  {{ head.Attribute ? (item | getAttribute: head.FieldName:head.Maxtext | getAttribute: head.Attribute:head.Maxtext) : (item | getAttribute: head.FieldName:head.Maxtext) }}
                </ng-container>
              </div>
              <div *ngIf="head.check && head.FieldName && !isEmpty(item) && head.FieldName !== 'actions'"
                class="text-center sticky-checkbox">
                <span class="mx-2">
                  <input class="form-check-input" id="{{sr}}" (change)="fieldsChange($event, item)" type="checkbox"
                    [checked]="item[head.FieldName]" [disabled]="head.disabled">
                  <label class="form-check-label" for="{{sr}}"></label>
                </span>
              </div>
              <div *ngIf="head.FieldName == 'actions' && !isEmpty(item)" class="text-center">
                <span class="mx-2" *ngIf="head.check">
                  <input class="form-check-input" id="{{sr}}" (change)="fieldsChange($event, item)" type="checkbox"
                    [checked]="item.checked">
                  <label class="form-check-label" for="{{sr}}"></label>
                </span>
                <span class="mx-2" *ngIf="head.radio">
                  <input class="form-check-input" name="radio" id="{{sr}}" (change)="fieldsChange($event, item)"
                    type="radio" [checked]="item.checked">
                  <label class="form-check-label" for="{{sr}}"></label>
                </span>
                <span style="display: flex;">
                  <ng-container *ngFor="let button of head.buttons; let last = last">
                    <div *ngIf="shouldShowButton(button, item)&& button.icon"
                      style="cursor: pointer;text-align: center;display: flex;align-items: center">
                      <a><span placement="top" ngbTooltip="{{ button.toolTip }}"
                          class="material-icons cursor-pointer icons-hover mx-2"
                          (click)="actions(item, button.nameButton)">{{ button.icon }}</span></a>
                    </div>
                    <div
                      *ngIf="last && head.buttons[head.buttons.length - 1]?.nameButton=='menu' && shouldShowModal(head.buttons[head.buttons.length - 1]?.array,item)"
                      style="cursor: pointer;padding-left: 7px;">
                      <span mat-icon-button [matMenuTriggerFor]="menu" aria-label="" style="cursor: pointer;">
                        <mat-icon class="bton-add-more">more_horiz</mat-icon>
                      </span>
                    </div>
                  </ng-container>
                  <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let button of head.buttons[head.buttons.length - 1]?.array">
                      <button *ngIf="shouldShowButton(button, item)" mat-menu-item (click)=" actions(item,
                        button.nameButton)">
                        <span style="color: white;">{{ button.name }}</span>
                      </button>
                    </ng-container>
                  </mat-menu>
                </span>

              </div>
              <div class="mx-2" *ngIf="head.img && !isEmpty(item)">
                <img *ngIf="item[head.FieldName]!=''" [src]="head.imgType + item[head.FieldName]" alt="{{sr}}">
              </div>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="isDragAndDrog" cdkDropList (cdkDropListDropped)="onDrop($event)">
          <tr *ngFor="let item of gridArray;let sr=index" cdkDrag cdkDragLockAxis="y">
            <th class="col-xs">
              <div class="drag-handle">
                <ng-container [ngTemplateOutlet]="dragHandleTmpl">
                </ng-container>
                <span *ngIf="pageNumber==null">{{(sr+1)}}</span>
                <span *ngIf="pageNumber==1">{{(sr+1)}}</span>
                <span *ngIf="pageNumber>1">{{((pageNumber-1)*pageSize)+(sr+1)}}</span>
              </div>
            </th>
            <td *ngFor="let head of headArray" class="centered-cell">
              <div [innerHTML]="item[head.FieldName]"
                *ngIf="head.FieldName != '' && head.FieldName !='actions' && !head.img">
              </div>

              <div *ngIf="head.FieldName == 'actions' && !isEmpty(item)" class="text-center">
                <span class="mx-2" *ngIf="head.check">
                  <input class="form-check-input" id="{{sr}}" (change)="fieldsChange($event, item)" type="checkbox">
                  <label class="form-check-label" for="{{sr}}"></label>
                </span>
                <span class="mx-2" *ngIf="head.radio">
                  <input class="form-check-input" name="radio" id="{{sr}}" (change)="fieldsChange($event, item)"
                    type="radio">
                  <label class="form-check-label" for="{{sr}}"></label>
                </span>
                <div style="display: inline-block; cursor: pointer;" *ngFor="let button of head.buttons">
                  <a><span placement="top" ngbTooltip="{{ button.toolTip }}"
                      class="material-icons cursor-pointer icons-hover mx-2"
                      (click)="actions(item,button.nameButton)">{{button.icon}}</span></a>
                </div>
              </div>
              <div class="mx-2" *ngIf="head.img && !isEmpty(item)">
                <img [src]="head.imgType + item[head.FieldName]" alt="{{sr}}">
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #dragHandleTmpl>
  <svg class="bi bi-list handle" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
      d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
      clip-rule="evenodd" />
  </svg>
</ng-template>
