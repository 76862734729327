<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>

  <div class="modal-body" style="max-height: 70vh;">

    <!-- Formulario dirección-->
    <form class="form-modal py-3  flex-container" [formGroup]="modelForm">
      <div class="form-row">
        <div class="form-item ">
          <label for="anio">Año <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="anio" id="anio" placeholder="Año" matInput type="number" [min]="0">
            <mat-error *ngIf="controlHasError('anio', 'required')">Campo obligatorio</mat-error>
            <mat-error *ngIf="modelForm.get('anio')?.invalid && modelForm.get('anio')?.touched">Año inválido (1900
              a 2500)
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-item">
          <label for="desde">Desde <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="desde" id="desde" placeholder="Desde" matInput type="text" appNumericPoint>
            <mat-error *ngIf="controlHasError('desde', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="form-item">
          <label for="hasta">Hasta <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="hasta" id="hasta" placeholder="Hasta" matInput type="text" appNumericPoint>
            <mat-error *ngIf="controlHasError('hasta', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row pt-3">
        <div class="form-item">
          <label for="cuota">Cuota (€) <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="cuota" id="cuota" placeholder="Cuota" matInput type="text" appTwoDigitDecimalNumber>
            <mat-error *ngIf="controlHasError('cuota', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>

        <div class="form-item">
          <label for="deposito">Fianza (€/kg) <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="deposito" id="deposito" placeholder="Deposito" matInput type="text"
            appTreeDigitDecimalNumber>
            <mat-error *ngIf="controlHasError('deposito', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>

    <!-- FIN Formulario dirección -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
