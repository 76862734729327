<div class="modal-content">
    <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
    <div class="modal-header">
        <h3 class="modal-title font">Informe de datos de facturación</h3>
        <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
        <form [formGroup]="formGroup">
            <div class="row-fields">
                <div class="field field-100">
                    <label for="declaracionMarcada">Declaraciones marcadas como <span class="oblig">*</span></label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="declaracionMarcada" id="declaracionMarcada"
                            placeholder="Declaraciones marcadas como"  (selectionChange)="actualizarValidaciones()" >
                            <mat-option *ngFor="let tipo of tiposMarcadas" [value]="tipo.id">
                                {{tipo.descripcion}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="controlHasError('declaracionMarcada', 'required')">Campo obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row-fields" *ngIf="formGroup.controls['declaracionMarcada']?.value === 'facturada'">
                <div class="field field-100">
                    <label for="anio">Año <span class="oblig">*</span></label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="anio" id="anio" placeholder="Año">
                            <mat-option *ngFor="let anio of anios" [value]="anio.id">
                                {{anio.id}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="controlHasError('anio', 'required')">Campo obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer actions">
        <div class="button-group">
            <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="closeDialog()"
                aria-label="Cancelar">Cancelar</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="descargarInformeFacturacion()"
                aria-label="Guardar">Descargar</button>
        </div>
    </div>
</div>