export const locale_es = {
    "lang": "es",
    "data": {
        "MENU": {
            "TERMS_OF_USE":"Terminos de uso",
            "PRIVACY_POLICY":"Política de privacidad",
            "MICROAPP-HOME": "Principal",
            "HOME" : "Principal",
            "MICROAPP-VMS" : "Vms",
            "KEYCLOAK-AUTH-MANAGER" : "Gestión de Identidades",
            "HELLO-WORLD" : "Hola Mundo",
        },
        "LOGIN": {
            "TITLE": "Login",
            "LABEL.USERNAME": "Usuario",
            "LABEL.PASSWORD": "Clave",
            "BUTTON.SIGNIN": "Entrar",
            "FORGOTPASSWORD": "¿Olvidó su contraseña?",
            "REQUIRED.USERNAME": "¡El Nombre de Usuario es Obligatorio!",
            "REQUIRED.PASSWORD": "¡La Clave es Obligatoria!"
        },
        "HOME": {
            "SITE_PREFIX": "Edificio:",
            "MENU.LANGUAGE": "Idioma",
            "MENU.LOGOUT": "Logout",
            "MENU.HOME": "Home",
            "MENU.LANGUAGE.SPANISH": "Español",
            "MENU.LANGUAGE.ENGLISH": "English",  
            "MENU.LANGUAGE.SPANISH_MEXICO": "Español de México",                    
            "MENU.THEMES": "Themes", 
            "MENU.THEME":{
                "DEFAULT": "Empusa",
                "MORI": "Mori"
            }
        },     
        "PROFILE": {
            "TITLE": "Perfil de usuario",
            "REQUIRED": "Campo requerido",
            "INVALID": "Campo no válido",
            "FIRSTNAME": "Nombre",
            "LASTNAME": "Apellido",
            "EMAIL": "Email",
            "ROLES": "Roles",
            "GROUPS": "Grupos",
            "PASSWORD": "Password",
            "CONFIRM": "Confirmar Password",
            "NOTCONFIRM": "las claves nocoinciden",
            "CANCEL": "Cancelar",
            "CLOSE": "Cerrar",
            "UPDATE": "Mopdificar perfil",
            "RESET": "Resetear Password"
        },
        "MATERIAL": {
            "TABLE.ITEMS_PER_PAGE_LABEL": "Filas/Página",
            "TABLE.NEXT_PAGE_LABEL": "Siguiente",
            "TABLE.PREVIOUS_PAGE_LABEL": "Anterior",
            "TABLE.FIRST_PAGE_LABEL": "Primero",
            "TABLE.LAST_PAGE_LABEL": "Último",
            "TABLE.RANGE_PAGE_LABEL_1": "0 de {{length}}",
            "TABLE.RANGE_PAGE_LABEL_2": "{{startIndex}} - {{endIndex}} de {{length}}"
        },
    }
}