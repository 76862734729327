<div *ngIf="permissionsForm">
    <h1 mat-dialog-title>
        <mat-icon class="icon" style="vertical-align: middle;">{{data.icon}}</mat-icon>
            {{data.title | translate}}
    </h1>

    <div mat-dialog-content>
        <form [formGroup]="permissionsForm" *ngIf="data.resourceServer.resourceServerSettings">
            <div class="col-resources">
                <mat-selection-list multiple="false">
                    <mat-list-option 
                        *ngFor="let resource of data.resourceServer.resourceServerSettings?.resources"
                        (click)="selectedResource = resource"><mat-icon>settings</mat-icon>{{resource.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
                
            <div class="col-scopes">
                <mat-selection-list multiple="true" *ngIf="(selectedResource != null)"  formControlName="{{selectedResource._id}}"           
                    (selectionChange)="onListSelectionChange($event,selectedResource._id)">
                    <mat-list-option *ngFor="let scope of data.resourceServer.resourceServerSettings.scopes"                                                          
                        [value]="selectedResource._id + '#' + scope.name" [selected]="searchScopes(scope.name)">
                        <mat-icon>settings</mat-icon>
                        {{scope.name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </form>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="permissionsCancel()">{{'KAM-GENERAL-DIALOG.CANCEL'| translate}}</button>
        <button mat-raised-button color="primary" 
                (click)="accept()" [disabled]="this.permissionsForm.untouched">{{'KAM-GENERAL-DIALOG.ACCEPT'| translate}}</button>
    </div>
</div>

