export const locale_en = {
    "lang": "en",
    "data": {
        "MAPP-HOME": { //mandatory - microapp context
            "WELCOME_TEXT": "Welcome to",
            "HELLO-WORLD" : "Hello World",
            "EXPLORER" : "Explorer",
            "HOME" : "Home",
            "KEYCLOAK-AUTH-MANAGER" : "Auth manager",
        }
    }
}