import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumericPoint]',
})
export class NumericPointDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private control: NgControl
  ) {}

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    let input: string = this.el.nativeElement.value;

    input = input.replace(/[^\d]/g, '');

    input = input.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    this.renderer.setProperty(this.el.nativeElement, 'value', input);

    if (this.control.control) {
      this.control.control.setValue(input, { emitEvent: false });
    }

    setTimeout(() => {
      this.el.nativeElement.setSelectionRange(
        this.el.nativeElement.value.length,
        this.el.nativeElement.value.length
      );
    }, 0);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedData = event.clipboardData?.getData('text');
    if (pastedData) {
      let pastedValue = pastedData.replace(/[^\d]/g, '');

      pastedValue = pastedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      this.renderer.setProperty(this.el.nativeElement, 'value', pastedValue);

      if (this.control.control) {
        this.control.control.setValue(pastedValue, { emitEvent: false });
      }

      event.preventDefault();
    }
  }
}
