<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <div class="title-name mb-3" style="font-size: 1.3rem; font-weight: 700;"> </div>

    <!-- Formulario -->
    <form class="form-modal py-3" [formGroup]="formGroup">
      <div class="row form">

        <div class="col-sm flex-grow-1">
          <label for="fechaBaja">Fecha de baja <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="datepicker" formControlName="fechaBajaDialog">
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker>
            </mat-datepicker>
            <mat-error *ngIf="controlHasError('fechaBajaDialog', 'matDatepickerParse')">Formato incorrecto</mat-error>
            <mat-error *ngIf="controlHasError('fechaBajaDialog', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm flex-grow-1">
        </div>
        <div class="col-sm flex-grow-1"></div>
      </div>
    </form>
    <!-- FIN Formulario  -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="aceptar()"
        aria-label="Aceptar">Aceptar</button>
    </div>
  </div>
</div>
