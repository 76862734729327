<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="col-content show-display">
  <div class="header-content mb-xl-5 mb-3">
    <div class="heading">{{ gestorInfo?.razonSocial }}</div>
    <div class="actions justify-bt" style="justify-content: normal; !important;">
      <div class="nota mb-2">Si tiene un mismo residuo autorizado con distintos tratamientos, introduzca tantos residuos
        como tratamientos tenga autorizados para el mismo.
      </div>
    </div>
  </div>

  <form [formGroup]="formGroup">
    <div class="row form">
      <div class="col-sm flex-grow-2">
        <label for="codigoLer">Código LER y descripción de ENVALORA <span class="oblig">* </span><i
          *ngIf="formGroup.get('codigoLer')?.value?.peligrosidad === true" class="fas fa-skull-crossbones"
          style="color: red;" matTooltip="Envase de tipo peligroso"></i></label>
        <div class="custom-mat-field">
          <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="codigosLerEndpoint"
            [updateList]="updateList" [descripcion]="getLerDescription"
            [formControlNameSelect]="getFormControl('codigoLer')" [objQueryName]="getObjectQuery"
            [placeHolder]="'-- Código LER y descripción de ENVALORA --'" [customClass]="rightHolder('codigoLer')">
          </lib-ge-select-pagination>
          <div class="ge-select-error">
            <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('codigoLer')">Campo
              obligatorio
            </mat-error>
          </div>
        </div>
      </div>
    </div>
    <div class="row form">
      <div class="col-sm flex-grow-2">
        <label for="operacionGestion">Operación de gestión específica <span class="oblig">*</span></label>
        <mat-form-field class="custom-mat-form-field-width" appearance="outline">
          <mat-select formControlName="operacionGestion" id="operacionGestion" placeholder="-- Operación --">
            <mat-option *ngFor="let ope of gestionArray" [value]="ope">
              {{ ope.codigo }} {{ ope.descripcion }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="controlHasError('operacionGestion', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row form">
      <div class="col-sm flex-grow-0">
        <label for="cantidadAnual">Cantidad anual autorizada (t) <span class="oblig">*</span></label>
        <mat-form-field appearance="outline" class="custom-mat-form-field-width">
          <input matInput formControlName="cantidadAnual" id="cantidadAnual" placeholder="Cantidad anual autorizada (t)"
                 type="text" appTwoDigitDecimalNumber>
          <mat-error *ngIf="controlHasError('cantidadAnual', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm flex-grow-0">
        <label for="fechaDesde">Fecha desde <span class="oblig">*</span></label>
        <mat-form-field appearance="outline" class="custom-mat-field-select">
          <input matInput [matDatepicker]="datepicker" formControlName="fechaDesde">
          <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaDesde', 'required')">Campo
            obligatorio</mat-error>
          <mat-error *ngIf="controlHasError('fechaDesde', 'matDatepickerParse')">Formato incorrecto</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm flex-grow-0">
        <div class="button-group" style="margin-top:26px;">
          <button *ngIf="canAccessAgregar()" style="float:right" type="button" (click)="addPrevision()"
            class="btn btn-primary">Agregar</button>
        </div>
      </div>

    </div>

  </form>
  <div class="datatable-container table-responsive">
    <lib-ge-table [headArray]="headArray" [gridArray]="grid" (actionButton)="action($event)">
    </lib-ge-table>
  </div>
</div>
