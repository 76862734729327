<form [formGroup]="formGroup" *ngIf="formGroup">
    <mat-selection-list  formControlName="permissionsList">
        <mat-list-option 
            *ngFor="let permission of this.data.resourceServerPermissions.permissions"
            [value]="permission.id">
            <mat-icon>settings</mat-icon>
            {{permission.name}}
        </mat-list-option>
    </mat-selection-list>
    <button mat-raised-button (click)="accept()">Ok</button>
</form>
