/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaisDTO } from './paisDTO';
import { TipoContactoDTO } from './tipoContactoDTO';


export interface ContactoDTO { 
    id?: number;
    tipo_contacto?: TipoContactoDTO;
    requerido?: boolean;
    persona?: boolean;
    nombre?: string;
    apellido1?: string;
    apellido2?: string;
    tipo_documento?: ContactoDTO.TipoDocumentoEnum;
    codigo_documento?: string;
    tipo_telefono?: ContactoDTO.TipoTelefonoEnum;
    codigoPais?: PaisDTO;
    numero_telefono?: string;
    email?: string;
    fecha_alta?: string;
    fecha_modificacion?: string;
    fecha_baja?: string;
    cargo?: string;
    recibir_comunicaciones?: boolean;
    recibir_comunicaciones_contrato?: boolean;
    organo_participa?: ContactoDTO.OrganoParticipaEnum;
    registro_mercantil_de?: string;
    escritura_de?: string;
    con_numero?: string;
    notario?: string;
    domicilio_notario?: string;
    acreditacion_poderes?: boolean;
    deleted?: string;
    deletedBy?: string;
    fullName?: string;
    de?: string;
}
export namespace ContactoDTO {
    export type TipoDocumentoEnum = 'NIF' | 'DNI' | 'NIE';
    export const TipoDocumentoEnum = {
        Nif: 'NIF' as TipoDocumentoEnum,
        Dni: 'DNI' as TipoDocumentoEnum,
        Nie: 'NIE' as TipoDocumentoEnum
    };
    export type TipoTelefonoEnum = 'FIJO' | 'MOVIL';
    export const TipoTelefonoEnum = {
        Fijo: 'FIJO' as TipoTelefonoEnum,
        Movil: 'MOVIL' as TipoTelefonoEnum
    };
    export type OrganoParticipaEnum = 'ASAMBLEA' | 'JUNTA_DIRECTIVA' | 'COMITE_OPERATIVO' | 'COMISIONES';
    export const OrganoParticipaEnum = {
        Asamblea: 'ASAMBLEA' as OrganoParticipaEnum,
        JuntaDirectiva: 'JUNTA_DIRECTIVA' as OrganoParticipaEnum,
        ComiteOperativo: 'COMITE_OPERATIVO' as OrganoParticipaEnum,
        Comisiones: 'COMISIONES' as OrganoParticipaEnum
    };
}


