<div class="col-content show-display">
  <div class="header-content mb-xl-5 mb-3">
    <div class="heading">{{adheridoInfo?.razonSocial}}</div>
    <div class="actions" [ngClass]="{'justify-bt': adheridoInfo?.estado?.id === 1}">
      <div *ngIf="adheridoInfo?.estado?.id == 1" class="nota mb-2"><span class="oblig">*</span> Revise cada uno de los
        contactos de la lista y rellene la
        información obligatoria
      </div>
      <button type="button"
        *ngIf="this.canViewRol('AD-ENV-GEST') && adheridoInfo?.estado?.id ===1 ? false : canAccessCreateContact()"
        class="btn btn-primary" data-toggle="modal" data-target="#NuevoContact" (click)="openModalContact()">Nuevo
        contacto</button>
    </div>
  </div>
  <div class="nota mb-2"><span class="oblig">*</span> Complete la información de los contactos requeridos.</div>
  <div *ngIf="gridArray?.length !== 0 else messageClear"
    class="datatable-container table-responsive overflow-table table-detail-width-100">
    <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (actionButton)="action($event)"></lib-ge-table>
  </div>
  <ng-template #messageClear>
    <div class="container-menu">
      <div class="mensaje-container">
        <p class="mensaje-linea">Sin información registrada</p>
      </div>
    </div>
  </ng-template>
</div>
