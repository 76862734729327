import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SpinnerService} from '../../../services/spinner.service';
import {DownloadService} from '../../../services/download.service';
import {SnackbarService} from '../../../services/snackbar.service';
import {EmpusaAuthenticationService} from '@empusa/empusa-core';
import {
  AcondicionamientoControllerService,
  ComunidadControllerService,
  FacturaPtoRecogidaControllerService,
  GestorPtoRecogidaControllerService,
  PuntoRecogidaControllerService,
  SolicitudControllerService,
  SolicitudEstadoControllerService,
} from 'api-rest';
import {DatePipe} from '@angular/common';
import {
  CollectionPointsRequestsRegistrationComponent
} from '../collection-points-requests-registration/collection-points-requests-registration.component';
import {
  CollectionPointsRequestsDetailComponent
} from '../collection-points-requests-detail/collection-points-requests-detail.component';
import {FormatCurrencyPipe} from '../../../share/components/pipe/FormatCurrency.pipe';
import {
  CollectionPointsRequestsDownDialogComponent
} from '../collection-points-requests-down-dialog/collection-points-requests-down-dialog.component';
import {
  CollectionPointsRequestsDeclineDialogComponent
} from '../collection-points-requests-decline-dialog/collection-points-requests-decline-dialog.component';
import moment from 'moment';

@Component({
  selector: 'app-components-collection-points-requests-search',
  templateUrl: './collection-points-requests-search.component.html',
  styleUrls: ['./collection-points-requests-search.component.scss'],
})
export class CollectionPointsRequestsSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  updateList: boolean = false;
  codigosLerEndpoint =
    this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?ptosRecogida=true';

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  comunidadArray: any[] = [];
  estadoArray: any[] = [];

  optionArray = [{ value: 'Si' }, { value: 'No' }];

  headArray: any = [
    {
      Head: 'Ref. Solicitud',
      FieldName: 'id'
    },
    {
      Head: 'Punto de recogida',
      FieldName: 'ptoRecogida',
      renderValue: (item: any) =>
        item.ptoRecogida.razonSocial + ' ' + item.ptoRecogida.denominacion,
    },
    {
      Head: 'Gestor',
      FieldName: 'gestor',
      renderValue: (item: any) => item.gestor.razonSocial,
    },
    {
      Head: 'Fecha solicitud',
      FieldName: 'fechaSolicitud',
      renderValue: (item: any) =>
        this.datePipe.transform(item.fechaSolicitud, 'dd/MM/yyyy'),
    },
    {
      Head: 'Fecha prevista',
      FieldName: 'fechaPrevista',
      renderValue: (item: any) =>
        this.datePipe.transform(item.fechaPrevista, 'dd/MM/yyyy'),
    },
    {
      Head: 'Fecha recogida ',
      FieldName: 'fechaRecogida',
      renderValue: (item: any) =>
        this.datePipe.transform(item.fechaRecogida, 'dd/MM/yyyy'),
    },
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'codigoLer',
      renderValue: (item: any) =>
        item.codigoLer.tipoCodigo + ' ' + item.codigoLer.descripcion,
    },
    {
      Head: 'Acondicionamiento',
      FieldName: 'acondicionamientoRecogida',
      Attribute: 'descripcion',
    },
    {
      Head: 'Cantidad',
      FieldName: 'cantidadRecogida',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item.cantidadRecogida),
    },
    {
      Head: 'Acondicionamiento suministrado',
      FieldName: 'acondicionamientoSuministro',
      Attribute: 'descripcion',
    },
    {
      Head: 'Cantidad suministrado',
      FieldName: 'cantidadSuministro',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item.cantidadSuministro),
    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcionCorta',
    },

    {
      Head: 'Acciones',
      FieldName: 'actions',
      check: true,
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Editar',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'cancel_request',
              name: 'Cancelar solicitud',
              show: {
                params: 'canAccessCancelRequest',
                accion: 'cancel_request',
              },
            },
            {
              nameButton: 'finish_request',
              name: 'Finalizar solicitud',
              show: {
                params: 'canAccessFinishRequest',
                accion: 'finish_request',
              },
            },
            {
              nameButton: 'decline_request',
              name: 'Rechazar solicitud',
              show: {
                params: 'canAccessDeclineRequest',
                accion: 'decline_request',
              },
            },
          ],
        },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  estadosArray: any = [];
  ptoArray: any = [];
  gestorArray: any = [];
  acondicionamientoArray: any = [];

  fechaDesde: Date = new Date(new Date().getFullYear(), 0, 1);
  fechaHasta: Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    private comunidadControllerService: ComunidadControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService | any,
    private puntoRecogidaControllerService: PuntoRecogidaControllerService,
    private acondicionamientoControllerService: AcondicionamientoControllerService,
    private solicitudControllerService: SolicitudControllerService,
    private solicitudEstadoControllerService: SolicitudEstadoControllerService,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService,
    private datePipe: DatePipe,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private facturaPtoRecogidaControllerService: FacturaPtoRecogidaControllerService
  ) {
    this.searchForm = this.formBuilder.group({
      idSolicitud: [{ value: null, disabled: false }],
      puntoRecogida: [{ value: null, disabled: false }],
      gestor: [{ value: null, disabled: false }],
      acondicionamiento: [{ value: null, disabled: false }],
      codigoLer: [{ value: null, disabled: false }],
      fechaHasta: [{ value: this.fechaHasta, disabled: false }],
      fechaDesde: [{ value: this.fechaDesde, disabled: false }],
      estado: [{ value: null, disabled: false }],
      autoFactura: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    if (!this.canViewRol('PR-ENV-GEST')) {
      this.removeCheckIfCondition(this.headArray);
    }

    this.getComboComunidad();
    this.comboEstados();
    this.comboAcondicionamiento();
    if (this.canViewResource('PR-PUNTORECOGIDA')) {
      this.getPtoRecogida();
    } else {
      this.getListarTodosPtoRecogida();
    }
    if (this.canViewResource('PR-GESTOR')) {
      this.getGestor();
    } else {
      this.getListarTodosPtoGestor();
    }
  }

  removeCheckIfCondition(list: any[]) {
    list.forEach((item) => {
      if (item.FieldName === 'actions' && item.check) {
        delete item.check;
      }
    });
    this.headArray = list;
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  comboAcondicionamiento() {
    this.acondicionamientoControllerService.findAll2().subscribe({
      next: (response: any) => {
        this.acondicionamientoArray = response;
      },
      error: (err) => {},
    });
  }

  getPtoRecogida() {
    if (this.authService.user?.roleEntities?.length > 0) {
      const ptoRecogidaIds = this.authService.user?.roleEntities
        .filter(
          (ptoRecogidaRol: any) => ptoRecogidaRol.type === 'PUNTO_RECOGIDA'
        )
        .map((ptoRecogidaRol: any) => ptoRecogidaRol.entity as number);
      const filter: any = {
        id: ptoRecogidaIds,
        estado: { id: [5, 6] },
      };

      this.spinnerSrv.loadingShow();
      this.puntoRecogidaControllerService
        .findAllPtoRecogida(filter, { page: 0, size: 100 })
        .subscribe({
          next: (response: any) => {
            this.ptoArray = response?.datos;
            this.spinnerSrv.loadingHide();
          },
          error: (err) => {
            console.log(err);
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  getListarTodosPtoRecogida() {
    const estados = { id: 5 };
    const filter: any = {
      estado: estados,
    };
    this.spinnerSrv.loadingShow();
    this.puntoRecogidaControllerService
      .findAllPtoRecogida(filter, { page: 0, size: 100 })
      .subscribe({
        next: (response: any) => {
          this.ptoArray = response?.datos;
          this.spinnerSrv.loadingHide();
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  getGestor() {
    if (this.authService.user?.roleEntities?.length > 0) {
      const ptoGestorIds = this.authService.user?.roleEntities
        .filter(
          (ptoGestorRol: any) => ptoGestorRol.type === 'GESTOR_PUNTO_RECOGIDA'
        )
        .map((ptoGestorRol: any) => ptoGestorRol.entity as number);
      const filter: any = {
        id: ptoGestorIds,
        estado: { id: [3, 4] },
      };

      this.spinnerSrv.loadingShow();
      this.gestorPtoRecogidaControllerService
        .findAllGestorPtoRecogida(filter, { page: 0, size: 100 })
        .subscribe({
          next: (response: any) => {
            this.gestorArray = response?.datos;
            this.spinnerSrv.loadingHide();
          },
          error: (err) => {
            console.log(err);
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  getListarTodosPtoGestor() {
    const estados = { id: 3 };
    const filter: any = {
      estado: estados,
    };
    this.spinnerSrv.loadingShow();
    this.gestorPtoRecogidaControllerService
      .findAllGestorPtoRecogida(filter, { page: 0, size: 100 })
      .subscribe({
        next: (response: any) => {
          this.gestorArray = response?.datos;
          this.spinnerSrv.loadingHide();
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.solicitudControllerService
      .exportCSV(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportación de solicitud.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset({
      fechaDesde: this.fechaDesde,
      fechaHasta: this.fechaHasta,
    });
    this.flagSearch = false;
    this.gridArray = [];
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.searchForm.get('codigoLer') as FormControl;
        break;
      }
      default: {
        return this.searchForm.get('') as FormControl;
        break;
      }
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    const ptoRecogidaIds = this.ptoArray.map((x: any) => x.id);
    let gestorIds = this.gestorArray.map((x: any) => x.id);

    const valorPtoRecogida = this.searchForm.get('puntoRecogida')?.value;
    const valorGestor = this.searchForm.get('gestor')?.value;

    let ptoRecogida = ptoRecogidaIds;
    if (
      valorPtoRecogida !== null &&
      Array.isArray(valorPtoRecogida) &&
      valorPtoRecogida.length > 0
    ) {
      ptoRecogida = this.searchForm.get('puntoRecogida')?.value;
    }

    let gestor = gestorIds;
    if (
      valorGestor !== null &&
      Array.isArray(valorGestor) &&
      valorGestor.length > 0
    ) {
      gestor = this.searchForm.get('gestor')?.value;
    }

    let formatDateDesde = moment(
      new Date(this.searchForm.get('fechaDesde')?.value)
    ).format('YYYY-MM-DD');

    formatDateDesde = `gte(${formatDateDesde})`;

    let formatDateHasta = moment(
      new Date(this.searchForm.get('fechaHasta')?.value)
    ).format('YYYY-MM-DD');

    formatDateHasta = `and(lte(${formatDateHasta}))`;

    const backendFilter: any = {
      id: this.searchForm.get('idSolicitud')?.value,
      ptoRecogida: {
        id: this.canViewResource('PR-ENV-GEST')
          ? valorPtoRecogida
            ? valorPtoRecogida.map((objeto: any) =>
                objeto.id !== undefined ? objeto.id : objeto
              )
            : []
          : this.canViewResource('PR-PUNTORECOGIDA')
          ? ptoRecogida.length != 0
            ? ptoRecogida?.map((objeto: any) =>
                objeto.id !== undefined ? objeto.id : objeto
              )
            : [0]
          : [],
      },
      gestor: {
        id: this.canViewResource('PR-ENV-GEST')
          ? valorGestor
            ? valorGestor.map((objeto: any) =>
                objeto.id !== undefined ? objeto.id : objeto
              )
            : []
          : this.canViewResource('PR-GESTOR')
          ? gestor.length != 0
            ? gestor?.map((objeto: any) =>
                objeto.id !== undefined ? objeto.id : objeto
              )
            : [0]
          : [],
      },
      codigoLer: this.searchForm.get('codigoLer')?.value,
      acondicionamiento: {
        id: this.searchForm.get('acondicionamiento')?.value,
      },
      fechaSolicitud: [formatDateDesde, formatDateHasta],
      estado: { id: this.searchForm.get('estado')?.value?.id },
      autofacturada:
        this.searchForm.get('autoFactura')?.value === 'Si'
          ? true
          : this.searchForm.get('autoFactura')?.value === 'No'
          ? false
          : undefined,
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }

  searchData(inicialPage?: boolean) {
    if (inicialPage) {
      this.pageNumber = 0;
    }

    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.solicitudControllerService
      .findAllSolicitud(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos);
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openModalDetailRequest(element);
    } else if (element.nameButton == 'cancel_request') {
      const dialogRef = this.dialog.open(
        CollectionPointsRequestsDownDialogComponent,
        {
          width: '750px',
          maxWidth: '95%',
          maxHeight: '95vh',
          data: {
            info: element,
          },
          disableClose: true,
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.searchData();
        }
      });
    } else if (element.nameButton == 'finish_request') {
      if (!element.documento) {
        this.snackBarSrv.showSnackBar(
          'La solicitud no puede pasar a estado Finalizada porque no tiene una Aportación DI adjunta',
          'error'
        );
        return;
      }
      this.spinnerSrv.loadingShow();
      this.solicitudControllerService.finalizarSolicitud(element.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.searchData();
          this.snackBarSrv.showSnackBar(
            'Se ha finalizado la solicitud correctamente',
            'success'
          );
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          console.log(err);
        },
      });
    } else if (element.nameButton == 'decline_request') {
      const dialogRef = this.dialog.open(
        CollectionPointsRequestsDeclineDialogComponent,
        {
          width: '750px',
          maxWidth: '95%',
          maxHeight: '95vh',
          data: {
            info: element,
          },
          disableClose: true,
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.searchData();
        }
      });
    }
  }

  comboEstados() {
    this.solicitudEstadoControllerService.findAll().subscribe({
      next: (data: any) => {
        this.estadosArray = data!;
      },
      error: () => {},
    });
  }

  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }

  getOperacionDescription(op: any) {
    return op?.descripcion;
  }

  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('ptosrecogida-solicitudes', 'view');
    const resultCancelRequest = this.authService.can(
      'ptosrecogida-solicitudes',
      'update-cancel'
    );
    const resultFinishRequest = this.authService.can(
      'ptosrecogida-solicitudes',
      'update-send'
    );
    const resultDeclineRequest = this.authService.can(
      'ptosrecogida-solicitudes',
      'update-reject'
    );

    const listPermission = list.map((objeto: any) => {
      const stateCancelRequest =
        objeto.estado && [1, 2].includes(objeto.estado.id);
      const stateFinishRequest = objeto.estado && objeto.estado.id === 2;
      const stateDeclineRequest = objeto.estado && objeto.estado.id === 1;

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessCancelRequest: resultCancelRequest && stateCancelRequest,
        canAccessFinishRequest: resultFinishRequest && stateFinishRequest,
        canAccessDeclineRequest: resultDeclineRequest && stateDeclineRequest,
      };
    });

    return listPermission;
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  getComboComunidad() {
    this.comunidadControllerService
      .listarComunidades3({}, { page: 0, size: 500, sort: [] })
      .subscribe({
        next: (data: any) => {
          this.comunidadArray = data.datos!;
        },
        error: () => {},
      });
  }

  crearAutoFactura() {
    const list = this.gridArray
      .filter((x) => x.checked === true)
      .map((x) => x.id);

    if (list.length == 0) {
      this.snackBarSrv.showSnackBar(
        `Debe seleccionar al menos una solicitud para generar una autofactura`,
        'error'
      );
      return;
    }
    this.spinnerSrv.loadingShow();
    this.facturaPtoRecogidaControllerService
      .generarAutoFactura(list)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se han generado las autofacturas correctamente',
            'success'
          );
          this.searchData();
        },
        error: (err) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
        },
      });
  }

  checked(event: any) {
    if (event.estado.id != 3) {
      this.snackBarSrv.showSnackBar(
        'Solo se permitirá solicitudes en estado Finalizado',
        'error'
      );
      event.checked = null;
    }
  }

  openModalRegistrationRequest() {
    const dialogRef = this.dialog.open(
      CollectionPointsRequestsRegistrationComponent,
      {
        width: '1460px',
        maxWidth: '95%',
        maxHeight: '95vh',
        disableClose: true,
        data: {
          ptoArray: this.ptoArray,
          gestorArray: this.gestorArray,
        },
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchData();
      }
    });
  }

  openModalDetailRequest(element: any) {
    const dialogRef = this.dialog.open(
      CollectionPointsRequestsDetailComponent,
      {
        width: '1460px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          infoRequests: element,
        },
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchData();
      }
    });
  }

  canAccessCrearSolicitud(): boolean {
    return this.authService.can('ptosrecogida-solicitud-alta', 'access');
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }
  canAccessCrearAutoFactura(): boolean {
    return this.authService.can(
      'ptosrecogida-solicitudes',
      'create-autofactura'
    );
  }
}
