<!-- Modal Alta Preliminar-->
<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">Alta preliminar</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <!-- Formulario Alta preliminar -->
    <form class="form-modal py-3" [formGroup]="dataForm">
      <div class="title-group">Datos de la empresa</div>
      <div class="row form">
        <div class="col-sm">
          <label for="name">Nombre de la empresa <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="name" id="name" placeholder="Nombre de la empresa" value="">
              <mat-error *ngIf="controlHasError('name', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="documentType">Tipo Documento <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="documentType" (selectionChange)="tipoDocumentoSel($event.value)"
                id="documentType" placeholder="Tipo documento">
                <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
                  {{tipo.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('documentType', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="documentNumber">Documento <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="documentNumber" id="documentNumber" placeholder="Documento"
                value="" oninput="this.value = this.value.toUpperCase()">
              <mat-error *ngIf="controlHasError('documentNumber', 'pattern')">Formato incorrecto</mat-error>
              <mat-error *ngIf="controlHasError('documentNumber', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="title-group pt-3">Dirección</div>
      <div class="row form justify-form">
        <div class="col-sm flex-grow-0">
          <label for="country">País <span class="oblig">*</span></label>
          <div class="custom-mat-field-select">
            <lib-ge-select-pagination class="lib-ge-select" (selectedModel)="selectedModel('country')"
              [endPointFilter]="paisesEndpoint" (filteredList)="filterList($event)" [updateList]="updateList"
              [disabled]="flagDisabledForm" [formControlNameSelect]="getFormControl('country')" [placeHolder]="'País'"
              [objQueryName]="'nombre'" descripcion="nombre" [customClass]="rightHolder('country')" [defaultValue]=69>
            </lib-ge-select-pagination>
            <div *ngIf="controlHasErrorSelect('country')" class="mat-error">
              Campo obligatorio
            </div>
          </div>
        </div>
      </div>
      <div class="row form justify-form">
        <div class="col-sm flex-grow-0">
          <label *ngIf="esES()" for="cp">CP <span class="oblig">*</span></label>
          <label *ngIf="!esES()" for="cp">CP</label>
          <div class="custom-mat-field-select">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="cp" id="cp" placeholder="CP" value="">
              <mat-error *ngIf="controlHasError('cp', 'required')">Campo obligatorio</mat-error>
              <mat-error *ngIf="controlHasError('cp', 'pattern')">Máximo 5 dígitos</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="esES()">
          <label for="county">Provincia <span class="oblig">*</span></label>
          <div class="custom-mat-form-field-width">
            <lib-ge-select-pagination class="lib-ge-select" (selectedModel)="selectedModel('county')"
              [endPointFilter]="provinciasEndpoint" (filteredList)="filterList($event)" [updateList]="updateList"
              [disabled]="flagProvDisabledForm" [formControlNameSelect]="getFormControl('county')"
              [placeHolder]="'Provincia'" [objQueryName]="'nombre'" descripcion="nombre"
              [customClass]="rightHolder('county')">
            </lib-ge-select-pagination>
            <div *ngIf="controlHasErrorSelect('county')" class="mat-error">
              Campo obligatorio
            </div>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="!esES()">
          <label for="county">Provincia</label>
          <div class="custom-mat-form-field-width">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="county" id="county" placeholder="Provincia" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label *ngIf="esES()" for="municipality">Municipio <span class="oblig">*</span></label>
          <label *ngIf="!esES()" for="municipality">Municipio</label>
          <div class="custom-mat-field-select">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="municipality" id="municipality" placeholder="Municipio" matInput type="text">
              <mat-error *ngIf="esES()?controlHasError('municipality', 'required'):false">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>
      <div class="row form justify-form">
        <div class="col-sm flex-grow-3">
          <label for="roadName">Tipo y Nombre de vía</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="roadName" id="roadName" placeholder="Tipo y Nombre de vía"
                value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="number">Número</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input type="text" matInput formControlName="number" id="number" placeholder="Nº" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="buildingBlock">Bloque</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input type="text" matInput formControlName="buildingBlock" id="buildingBlock" placeholder="Bloque"
                value="">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form justify-form">
        <div class="col-sm flex-grow-0">
          <label for="escalera">Escalera</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input type="text" matInput formControlName="escalera" id="escalera" placeholder="escalera" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="piso">Piso</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input type="text" matInput formControlName="piso" id="piso" placeholder="Piso" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="door">Puerta</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input type="text" matInput formControlName="door" id="door" placeholder="Puerta" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="otherData">Otros datos</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="otherData" id="otherData" placeholder="Otros datos" value="">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="title-group pt-3">Datos de contacto</div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="nameContac">Nombre <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <!-- Ejemplo de campo erróneo -->
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="nameContac" id="nameContac" placeholder="Nombre" value="">
              <mat-error *ngIf="controlHasError('nameContac', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="firstSurname">Apellido 1 <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="firstSurname" id="firstSurname" placeholder="Apellido 1"
                value="">
              <mat-error *ngIf="controlHasError('firstSurname', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="secondSurname">Apellido 2</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="secondSurname" id="secondSurname" placeholder="Apellido 2"
                value="">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="email">Email <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="email" id="email" placeholder="Email" value="">
              <mat-error *ngIf="controlHasError('email', 'pattern')">Formato incorrecto</mat-error>
              <mat-error *ngIf="controlHasError('email', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="tlfType">Tipo teléfono</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="tlfType" id="tlfType" placeholder="Tipo teléfono">
                <mat-option value="MOVIL">Móvil</mat-option>
                <mat-option value="FIJO">Fijo</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="countryCode">Prefijo País</label>
          <div class="custom-mat-field-select" style="width: 250px">
            <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="paisesEndpoint"
              (filteredList)="filterList($event)" [updateList]="updateList"
              [formControlNameSelect]="getFormControl('countryCode')" [placeHolder]="'Prefijo pais'"
              [objQueryName]="'prefijo'" descripcion="prefijoNombre" [disabled]="flagDisabledForm" [defaultValue]=69>
            </lib-ge-select-pagination>

          </div>
          <!--div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="countryCode" id="countryCode" placeholder="Tipo teléfono">
                <mat-option value="+34">+34</mat-option>
                <mat-option value="+35">+35</mat-option>
              </mat-select>
            </mat-form-field>
          </div-->
        </div>
        <div class="col-sm flex-grow-1">
          <label for="tlfNumber">Nº teléfono</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="tlfNumber" id="tlfNumber" placeholder="Nº teléfono" value="">
              <mat-error *ngIf="controlHasError('tlfNumber', 'invalidPhoneFormat')">
                Formato incorrecto.</mat-error>
              <mat-error *ngIf="!controlHasError('tlfNumber', 'invalidPhoneFormat') && controlHasError('tlfNumber',
               'invalidTelefonoFijo')">
                Formato incorrecto para tipo Fijo con prefijo 34.
              </mat-error>
              <mat-error *ngIf="!controlHasError('tlfNumber', 'invalidPhoneFormat') && !controlHasError('tlfNumber',
               'invalidTelefonoFijo') && controlHasError('tlfNumber', 'invalidTelefonoMovil')">
                Formato incorrecto para tipo Movil con prefijo 34.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
    <!-- FIN Formulario de Alta preliminar -->
  </div>

  <div class="fixed-footer">
    <div class="modal-footer actions">
      <div class="note">* Campos Obligatorios</div>
      <div class="button-group">
        <button type="button" (click)="closeDialog()" class="btn btn-outline-primary" data-dismiss="modal"
          aria-label="Close">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close"
          (click)="saveData()">Guardar</button>
      </div>
    </div>
  </div>

</div>
