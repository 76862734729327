export * from './aportacionDiAcuerdosGestoresController.service';
import { AportacionDiAcuerdosGestoresControllerService } from './aportacionDiAcuerdosGestoresController.service';
export * from './facturaGestorAcuerdoController.service';
import { FacturaGestorAcuerdoControllerService } from './facturaGestorAcuerdoController.service';
export * from './gestoresConAcuerdoController.service';
import { GestoresConAcuerdoControllerService } from './gestoresConAcuerdoController.service';
export * from './helloWorldController.service';
import { HelloWorldControllerService } from './helloWorldController.service';
export * from './tarifaGestoresController.service';
import { TarifaGestoresControllerService } from './tarifaGestoresController.service';
export const APIS = [AportacionDiAcuerdosGestoresControllerService, FacturaGestorAcuerdoControllerService, GestoresConAcuerdoControllerService, HelloWorldControllerService, TarifaGestoresControllerService];
