import { Injectable } from "@angular/core";
import { KeycloakScopeModel } from "../keycloak-scope.model";
import { Adapter } from "./keycloak.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakScopeModelAdapter implements Adapter<KeycloakScopeModel> {

    constructor() { }
    adapt(item: any): KeycloakScopeModel {
        let model:KeycloakScopeModel = {            
            name: item.name,            
        };
        if (item.id)
            model.id=item.id;
        return model;
    }
}