/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ComposicionMaterialPayloadDTO } from './composicionMaterialPayloadDTO';


export interface DeclaracionEnvaseMaterialPayloadDTO { 
    composicionMaterial?: ComposicionMaterialPayloadDTO;
    pesoUnitario?: number;
    pesoTotal?: number;
    porcReciclado?: number;
    tipoElemento?: DeclaracionEnvaseMaterialPayloadDTO.TipoElementoEnum;
}
export namespace DeclaracionEnvaseMaterialPayloadDTO {
    export type TipoElementoEnum = 'TAPONES' | 'ETIQUETAS' | 'OTROS_ELEMENTOS';
    export const TipoElementoEnum = {
        Tapones: 'TAPONES' as TipoElementoEnum,
        Etiquetas: 'ETIQUETAS' as TipoElementoEnum,
        OtrosElementos: 'OTROS_ELEMENTOS' as TipoElementoEnum
    };
}


