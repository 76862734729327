<!-- Modal Alta Preliminar-->
<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">Alta de aportación de DI</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <!-- Formulario Alta preliminar -->
    <form class="form-modal py-3" [formGroup]="modelForm">
      <div class="title-group">Seleccione el gestor a asociar la nueva aportación</div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="nimaGestor">NIMA del gestor </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="gestorResiduo" id="gestorResiduo" placeholder="-- NIMA del gestor --">
                <mat-option *ngFor="let des of nimaGestorArray" [value]="des">
                  {{ des.nima }} - {{ des.razonSocial }}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('gestorResiduo')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'gestorResiduo')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
    <!-- FIN Formulario de Alta preliminar -->
  </div>

  <div class="fixed-footer">
    <div class="modal-footer actions">
      <div class="note">* Campos Obligatorios</div>
      <div class="button-group">
        <button type="button" (click)="closeDialog()" class="btn btn-outline-primary" data-dismiss="modal"
          aria-label="Close">Volver</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close"
          (click)="seleccionar()">Seleccionar</button>
      </div>
    </div>
  </div>

</div>
