import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  KeycloakRoleControllerService,
  KeycloakRoleDTO,
  UserRoleEntityDTO,
  KeycloakUserDTO,
  KeycloakUserPayloadDTO,
  UserManagementControllerService,
} from 'api-rest';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComboService, Combo } from '../../../services/combo.service';
import {
  GeSelectPaginationComponent,
  MappedResponse,
} from '../../../share/components/ge-select-pagination/ge-select-pagination.component';
import { Subscription } from 'rxjs';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import Swal from 'sweetalert2';

export interface UserDetailModalData {
  editMode: boolean;
  user: KeycloakUserDTO;
}

@Component({
  selector: 'lib-user-detail-modal',
  templateUrl: './user-detail-modal.component.html',
  styleUrls: ['./user-detail-modal.component.scss'],
})
export class UserDetailModalComponent implements OnDestroy {
  @ViewChild('entidadAsociadaSelector') entidadAsociadaSelector:
    | GeSelectPaginationComponent
    | undefined;
  user: KeycloakUserDTO;
  newUser: boolean = true;
  formGroup!: FormGroup;
  userRoleEntityFormGroup!: FormGroup;
  userTypes: Combo[] = [];
  roles: KeycloakRoleDTO[] = [];
  rolesFiltered: KeycloakRoleDTO[] = [];
  editMode = false;
  adheridosEndpoint =
    this.environment.urlBackAdheridos + 'api/v1/adheridos/adheridos';
  poseedoresEndpoint =
    this.environment.urlBackPoseedores + 'api/v1/poseedores/poseedor';
  gestoresResiduosEndpoint =
    this.environment.urlBackPoseedores + 'api/v1/poseedores/gestores-residuos';
  puntosRecogidaEndpoint =
    this.environment.urlBackPuntoRecogida +
    'api/v1/puntos-recogida/puntosrecogida';
  gestorPuntosRecogidaEndpoint =
    this.environment.urlBackPuntoRecogida +
    'api/v1/puntos-recogida/gestorptorecogida';
  entidadAdociadaEndpoint = '';
  UserTypeEnum = UserRoleEntityDTO.EntityTypeEnum;
  entidadAsociada = {};
  subscriptions: Subscription[] = [];
  updateList = false;

  headArray: any = [
    {
      Head: 'Tipo de rol',
      FieldName: 'entityType',
      Tooltip: true,
    },
    {
      Head: 'Rol',
      FieldName: 'role.name',
      Tooltip: true,
    },
    {
      Head: 'Entidad asociada',
      FieldName: 'entity.name',
      Tooltip: true,
    },
    {
      Head: 'Habilitado',
      FieldName: 'enabled',
      renderValue: (item: UserRoleEntityDTO) => (item.enabled ? 'SI' : 'NO'),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [{ nameButton: 'delete', icon: 'delete', toolTip: 'Borrar' }],
      width: '8',
      permanent: true,
    },
  ];
  userRolesEntities: UserRoleEntityDTO[] = [];

  constructor(
    @Inject('environment') private environment: any,
    public dialogRef: MatDialogRef<UserDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDetailModalData,
    private formBuilder: FormBuilder,
    private userService: UserManagementControllerService,
    private roleService: KeycloakRoleControllerService,
    private el: ElementRef,
    private comboService: ComboService,
    private snackbarService: SnackbarService,
    public spinnerSrv: SpinnerService
  ) {
    this.user = this.data?.user ?? {};
    this.userRolesEntities = this.user.userRolesEntities
      ? [...this.user.userRolesEntities]
      : [];
    this.newUser = !this.user?.keycloakId;
    this.editMode = this.data?.editMode || this.newUser;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.userTypes = this.comboService
      .getUserTypes()
      .filter((item: any) => item?.name != 'Administracion');
    this.roleService.getRealRoles().subscribe((res) => (this.roles = res));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      keycloakId: [this.user ? this.user.keycloakId : ''],
      firstName: [this.user ? this.user.firstName : '', Validators.required],
      lastName: [this.user ? this.user.lastName : '', Validators.required],
      email: [this.user ? this.user.email : '', Validators.required],
      attributes: [this.user ? this.user.attributes : ''],
      enabled: [this.user ? this.user.enabled : ''],
    });

    this.userRoleEntityFormGroup = this.formBuilder.group({
      entityType: [undefined, Validators.required],
      role: [undefined, Validators.required],
      entity: [undefined],
    });

    if (!this.editMode) {
      this.formGroup.disable();
    }

    const subscription = this.userRoleEntityFormGroup.controls[
      'entityType'
    ].valueChanges.subscribe((val) => this.onUserTypeChange(val));
    this.subscriptions.push(subscription);
    this.onUserTypeChange(undefined);
  }

  onUserTypeChange(val?: UserRoleEntityDTO.EntityTypeEnum) {
    this.userRoleEntityFormGroup.controls['entity'].patchValue(null, {
      emmitEvent: false,
    });

    if (val == this.UserTypeEnum.Adherido) {
      this.userRoleEntityFormGroup.controls['entity'].enable();
      this.entidadAdociadaEndpoint = this.adheridosEndpoint;
      this.updateList = false;
      this.updateList = true;
    } else if (val == this.UserTypeEnum.Poseedor) {
      this.userRoleEntityFormGroup.controls['entity'].enable();
      this.entidadAdociadaEndpoint = this.poseedoresEndpoint;
      this.updateList = false;
      this.updateList = true;
    } else if (val == this.UserTypeEnum.GestorPoseedor) {
      this.userRoleEntityFormGroup.controls['entity'].enable();
      this.entidadAdociadaEndpoint = this.gestoresResiduosEndpoint;
      this.updateList = false;
      this.updateList = true;
    } else if (val == this.UserTypeEnum.PuntoRecogida) {
      this.userRoleEntityFormGroup.controls['entity'].enable();
      this.entidadAdociadaEndpoint = this.puntosRecogidaEndpoint;
      this.updateList = false;
      this.updateList = true;
    } else if (val == this.UserTypeEnum.GestorPuntoRecogida) {
      this.userRoleEntityFormGroup.controls['entity'].enable();
      this.entidadAdociadaEndpoint = this.gestorPuntosRecogidaEndpoint;
      this.updateList = false;
      this.updateList = true;
    } else {
      this.userRoleEntityFormGroup.controls['entity'].disable();
    }
    this.rolesFiltered = this.roles.filter(
      (role) => role?.attributes?.['user_type']?.[0] == val?.toString()
    );
  }

  separarCamelCase(texto: string) {
    return texto.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  action(item: any) {
    if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }

  confirmacionEliminar(element: UserRoleEntityDTO): void {
    var text = `¿Desea eliminar el rol ${element.role.name} asociado al usuario?`;
    if (element.entity?.name) {
      text = `¿Desea eliminar el rol y entidad ${element.role?.name} - ${element.entity.name} asociado al usuario?`;
    }
    Swal.fire({
      text: text,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        const index = this.userRolesEntities.indexOf(element);
        if (index > -1) {
          this.userRolesEntities.splice(index, 1);
        }
      }
    });
  }

  habilitarEdicion() {
    this.formGroup.enable();
    this.editMode = true;
  }

  addUserRoleEntity() {
    if (this.userRoleEntityFormGroup.valid) {
      let validRole = true;
      const roleEntity = this.userRoleEntityFormGroup.value;
      roleEntity.enabled = true;
      roleEntity.entity = {
        id: roleEntity.entity?.id,
        name: roleEntity.entity?.razonSocial,
      };

      const existingRoleEntity = this.userRolesEntities.find(
        (useRoleEntity) =>
          useRoleEntity.entityType == roleEntity.entityType &&
          useRoleEntity.role.id == roleEntity.role.id &&
          useRoleEntity.entity?.id == roleEntity.entity?.id
      );

      if (existingRoleEntity) {
        if (!existingRoleEntity.enabled) {
          existingRoleEntity.enabled = true;
        } else {
          this.snackbarService.showSnackBar(
            'Ya existe un rol con los valores seleccionados',
            'info'
          );
          validRole = false;
        }
      } else {
        if (roleEntity.role?.userRole) {
          const tieneEnvaloraRol = this.userRolesEntities
            .map((otherRole) =>
              this.roles.find((role) => role.id == otherRole.role.id)
            )
            .some((role) => role?.envaloraRole);
          if (tieneEnvaloraRol) {
            this.snackbarService.showSnackBar(
              'No se puede asignar el rol seleccionado. El usuario ya tiene uno o mas roles de Envalora asignados.',
              'error'
            );
            validRole = false;
          }
        } else if (roleEntity.role?.envaloraRole) {
          const tieneRolUser = this.userRolesEntities
            .map((otherRole) =>
              this.roles.find((role) => role.id == otherRole.role.id)
            )
            .some((role) => role?.userRole);

          if (tieneRolUser) {
            this.snackbarService.showSnackBar(
              'No se puede asignar un rol Envalora a este usuario. Seleccione otro tipo de rol de usuario',
              'error'
            );
            validRole = false;
          }
        }
      }

      if (validRole) {
        this.userRolesEntities = [...this.userRolesEntities, roleEntity];
        this.userRoleEntityFormGroup.patchValue({
          entityType: undefined,
          role: undefined,
          entity: undefined,
        });
      }
    }
  }

  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.userRolesEntities.length == 0) {
      this.snackbarService.showSnackBar(
        'Debe agregar almenos un role de usuario'
      );
      return;
    }

    if (this.formGroup.valid) {
      const formValue = this.formGroup.value;
      const userPayload: KeycloakUserPayloadDTO = formValue;
      userPayload.username = userPayload.email;
      userPayload.userRolesEntities = this.userRolesEntities;

      let request, successMsg: string, errorMsg: string;
      if (this.newUser) {
        request = this.userService.crearUsuario(userPayload);
        successMsg = 'El usuario se ha creado correctamente. En breve recibirá un correo electrónico con sus credenciales.';
        errorMsg = 'Ha ocurrido un error al crear el nuevo usuario';
      } else {
        request = this.userService.editarUsuarioPorId(
          this.user.keycloakId!,
          userPayload
        );
        successMsg = 'El usuario se ha editado correctamente';
        errorMsg = 'Ha ocurrido un error al editar el usuario';
      }

      this.spinnerSrv.loadingShow();
      request.subscribe({
        next: (response) => {
          this.dialogRef.close(true);
          this.snackbarService.showSnackBar(successMsg, 'success');
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          if (err?.error?.detail) {
            this.snackbarService.showSnackBar(
              err?.error?.detail,
              'error',
              8000
            );
          } else {
            this.snackbarService.showSnackBar(errorMsg, 'error');
          }
        },
      });
    } else {
      console.log(this.formGroup.errors);
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName]?.hasError(errorName) &&
      this.formGroup.controls[controlName]?.touched
    );
  }

  // mapAdheridosReponse(response: any): MappedResponse<any> {
  //   if(this.entidadAdociadaEndpoint == this.adheridosEndpoint) {
  //     return {
  //       datos: response.content,
  //       recordsFiltered: response.recordsTotal,
  //       totalElements: response.totalElements
  //     }
  //   } else {
  //     return {
  //       datos: response.datos,
  //       recordsFiltered: response.paginacion.numberOfElements,
  //       totalElements: response.paginacion.totalElements
  //     }
  //   }
  // }

  getFormControl(form: FormGroup, controlName: string): FormControl<any> {
    return form.controls[controlName] as FormControl<any>;
  }
}
