import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { KeycloakGroupService } from '../../../../services/kam-group.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'keycloak-auth-manager-kam-mg-step2',
  templateUrl: './kam-mg-step2.component.html',
  styleUrls: ['./kam-mg-step2.component.css']
})
export class KamMgStep2Component implements OnInit, OnDestroy{

  groupAttributes = this.environment.kamGroupAttributes;

  customAttributesValues:Map<string, string[]>|undefined;
  customAttributesValuesAreValid:boolean=false;
  subscription: Subscription | undefined;
  initialAttributesValues: Map<string, string[]>|undefined;

  constructor(@Inject('environment') private environment:any,
              private keycloakGroupService: KeycloakGroupService) { }


  ngOnInit(): void {
    this.subscription = this.keycloakGroupService.dataSubscription().subscribe(group=>{
      if (group)
        this.initialAttributesValues = group.attributesMap;      
    })
  }
  

  setCustomAttributesValues($event: any){
      this.customAttributesValues=$event;      
  }

  setCustomAttributesValuesAreValid($event: any){
    setTimeout(() => { 
      this.customAttributesValuesAreValid= $event
      this.keycloakGroupService.setStep2CompetedSubscription($event)
    }, 100);
  }

  step2End(){
    this.keycloakGroupService.dataSetCustomAttributes(this.customAttributesValues)
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
