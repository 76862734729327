import { Injectable } from "@angular/core";
import { KeycloakAssociatedPolicyModel } from "../keycloak-associated-policy.model";
import { KeycloakGroupModel } from "../keycloak-group.model";
import { Adapter } from "./keycloak.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakAssociatedPolicyModelAdapter implements Adapter<KeycloakAssociatedPolicyModel> {

    constructor() { }
    adapt(item: any): KeycloakAssociatedPolicyModel {
        let model:KeycloakAssociatedPolicyModel = {
            id: item.id,
            name: item.name,
            type: item.type,
            originalPolicyId: item.originalPolicyId,
        };
        return model;
    }
}