import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EmpusaAuthenticationService, EmpusaGroupModel, EmpusaUser } from '@empusa/empusa-core';
import { Observable, of } from 'rxjs';
import { database } from 'src/app/fake/fake.database';


@Injectable()
export class FakeAuthService extends EmpusaAuthenticationService {
    refreshToken(): void {
        throw new Error('Method not implemented.');
    }
    getGroups(): Observable<EmpusaGroupModel[]> {
        throw new Error('Method not implemented.');
    }
    getGroupsDetails(groups: EmpusaGroupModel[]): Observable<EmpusaGroupModel[]> {
        throw new Error('Method not implemented.');
    }
    getGroupByName(groupName: string): Observable<EmpusaGroupModel[]> {
        throw new Error('Method not implemented.');
    }
    getUserPhoto(): Observable<any> {
        throw new Error('Method not implemented.');
    }
    getUserImage(): Observable<any> {
        throw new Error('Method not implemented.');
    }


    loadPermissions(resourceServers: string[]): Observable<any> {
        //return Observable.of(database.roles);
        let permissionList: Map<string, Array<string>> = new Map();
        permissionList.set("hello-world",['access'])
        permissionList.set("home",['access'])
        permissionList.set("grafana",['access'])

        this.setPermissions(permissionList)
        return of(database.roles);
    }


    constructor(httpClient: HttpClient, @Inject('environment') environment:any, private router: Router) {        
        super(httpClient);        
        this.roles = database.roles;
    }

    getCurrentUserName(): string {
        return database.users[database.loggedUser].userName;
    }

    getCurrentUserMail(): string {
        return database.users[database.loggedUser].mail;
    }

    getGroupMembership(): EmpusaGroupModel []{
        return []
      }


    _logout() {
        //redirect to login
        this.router.navigateByUrl('/login');
        return true; //:D
    }

}
