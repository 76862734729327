/**
 * Acuerdos-Con-Gestores API
 * Acuerdos-Con-Gestores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactoDTO } from './contactoDTO';
import { DireccionDTO } from './direccionDTO';
import { ProvinciaDTO } from './provinciaDTO';
import { ResiduosGestionadosDTO } from './residuosGestionadosDTO';
import { GestoresResiduosEstadosDTO } from './gestoresResiduosEstadosDTO';


export interface GestoresResiduosDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    razonSocial?: string;
    tipoDocumento?: GestoresResiduosDTO.TipoDocumentoEnum;
    codigoDocumento?: string;
    nima?: string;
    gestoresResiduosEstado?: GestoresResiduosEstadosDTO;
    fechaAltaFinalizada?: string;
    fechaBaja?: string;
    fechaBajaEnvanet?: string;
    numeroAutorizacion?: string;
    centro?: string;
    numInscripcion1?: string;
    numInscripcion2?: string;
    numInscripcion3?: string;
    contactos?: Array<ContactoDTO>;
    domicilios?: Array<DireccionDTO>;
    provinciaFisica?: ProvinciaDTO;
    residuosGestionados?: Array<ResiduosGestionadosDTO>;
    acuerdoEnvalora?: boolean;
    autorizacionPeligrosos?: string;
}
export namespace GestoresResiduosDTO {
    export type TipoDocumentoEnum = 'NIF' | 'DNI' | 'NIE';
    export const TipoDocumentoEnum = {
        Nif: 'NIF' as TipoDocumentoEnum,
        Dni: 'DNI' as TipoDocumentoEnum,
        Nie: 'NIE' as TipoDocumentoEnum
    };
}


