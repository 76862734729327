import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KamResourceServerServices } from '../../../../services/kam-resource-server.services';
import { KeycloakPolicyModel, KeycloakPolicyRoleModel } from '../../../../model/keycloak-policy.model';
import { KeycloakRoleModel } from '../../../../model/keycloak-role.model';
import { ResourceServerPermissions } from '../../kam-roles-tab.component';
import { KeycloakPolicyConfigRoleModel } from '../../../../model/keycloak-policy-config.model';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'keycloak-auth-manager-kam-assign-permissions-to-role',
  templateUrl: './kam-assign-permissions-to-role.component.html',
  styleUrls: ['./kam-assign-permissions-to-role.component.css']
})
export class KamAssignPermissionsToRoleComponent implements OnInit {

  formGroup: FormGroup | undefined;
  preSelectedPermissions: string[]=[];

  constructor(
    private kamResourceServerServices: KamResourceServerServices,
    @Inject(MAT_DIALOG_DATA) public data: KamAssignPermissionsToRoleData,
  ) { }

  ngOnInit(): void {
    this.data.resourceServerPermissions.permissions.forEach(permission=>{
      permission.config?.applyPolicies?.forEach(appliedPolicyName=>{
        const foundPolicy = this.data.resourceServerPermissions.policies.find(policy=> policy.name==appliedPolicyName);      
        if (foundPolicy){          
          const hasAcccess = foundPolicy.config?.roles?.find((role:KeycloakPolicyRoleModel)=> role.id == this.data.role.name)
          if (hasAcccess && permission.id){
            this.preSelectedPermissions.push(permission.id)
          }
        }          
      })  
    })
    let permissionsListCtl = new FormControl(this.preSelectedPermissions);
    this.formGroup = new FormGroup({
      permissionsList: permissionsListCtl,
    });    
  }

  accept(){
    let control = this.formGroup?.get('permissionsList');
    if (!control) return;
    
    const policiesToAddRole:KeycloakPolicyModel[] = this.calPoliciesToAddRole(control);
    const policiesToDeleteRole:KeycloakPolicyModel[] = this.calPoliciesToDeleteRole(control);
        
    let policiesToUpdate: Observable<any>[]=[];   
    this.generatePoliciesToAddRoleRequests(policiesToAddRole, policiesToUpdate);
    this.generatePoliciesToRemoveRoleRequests(policiesToDeleteRole, policiesToUpdate);
  }


  private generatePoliciesToRemoveRoleRequests(policiesToDeleteRole: KeycloakPolicyModel[], policiesToUpdate: Observable<any>[]) {
    policiesToDeleteRole.forEach(toRemove => {

      let toRemoveRoles: KeycloakPolicyRoleModel[] = [];
      toRemove.config?.roles?.forEach(presentRole => {
        if (presentRole.id == this.data.role.name)
          return;

        const role: KeycloakPolicyRoleModel = {
          id: presentRole.id
        };
        toRemoveRoles.push(role);
      });

      const updatePolicy: KeycloakPolicyModel = {
        description: toRemove.description,
        id: toRemove.id,
        logic: toRemove.logic,
        name: toRemove.name,
        type: toRemove.type,
        decisionStrategy: toRemove.decisionStrategy,
        roles: toRemoveRoles
      };
      const updateRequest = this.kamResourceServerServices.putPolicy(this.data.resourceServerPermissions.id, updatePolicy);
      policiesToUpdate.push(updateRequest);
    });
  }

  private generatePoliciesToAddRoleRequests(policiesToAddRole: KeycloakPolicyModel[], policiesToUpdate: Observable<any>[]) {
    policiesToAddRole.forEach(toAdd => {
      const role: KeycloakPolicyRoleModel = {
        id: this.data.role.id
      };
      let toAddRoles: KeycloakPolicyRoleModel[] = [];
      toAddRoles.push(role);
      toAdd.config?.roles?.forEach(presentRole => {
        const role: KeycloakPolicyRoleModel = {
          id: presentRole.id
        };
        toAddRoles.push(role);
      });

      const updatePolicy: KeycloakPolicyModel = {
        description: toAdd.description,
        id: toAdd.id,
        logic: toAdd.logic,
        name: toAdd.name,
        type: toAdd.type,
        decisionStrategy: toAdd.decisionStrategy,
        roles: toAddRoles
      };
      const updateRequest = this.kamResourceServerServices.putPolicy(this.data.resourceServerPermissions.id, updatePolicy);
      policiesToUpdate.push(updateRequest);
    });
  }

  private calPoliciesToDeleteRole(control: any):KeycloakPolicyModel[] {
    let policiesToDeleteRole:KeycloakPolicyModel[] = []
    this.data.resourceServerPermissions.permissions.forEach(permission => {
      const found = control?.value.find((value: string) => value == permission.id);
      if (!found) {
        permission.config?.applyPolicies?.forEach(applyPolicy => {
          const foundPolicy = this.data.resourceServerPermissions.policies.find(policy => policy.name == applyPolicy);
          if (foundPolicy) {            
            const alreadyHasAcccess = foundPolicy.config?.roles?.find((role: KeycloakPolicyRoleModel) => role.id == this.data.role.name);
            if (alreadyHasAcccess) {
              policiesToDeleteRole.push(foundPolicy);
            }
          }
        });
      }
    });
    return policiesToDeleteRole
  }

  private calPoliciesToAddRole(control:any): KeycloakPolicyModel[] {
    let policiesToAddRole:KeycloakPolicyModel[] = [];
    control.value.forEach((element: string) => {
      const foundPermissions = this.data.resourceServerPermissions.permissions.find(permission => permission.id == element);
      foundPermissions?.config?.applyPolicies?.forEach(applyPolicy => {
        const foundPolicy = this.data.resourceServerPermissions.policies.find(policy => policy.name == applyPolicy);
        if (foundPolicy) {
          const alreadyHasAcccess = foundPolicy.config?.roles?.find((role: KeycloakPolicyRoleModel) => role.id == this.data.role.name);
          if (!alreadyHasAcccess) {
            policiesToAddRole.push(foundPolicy);
          }
        }
      });
    });
    return policiesToAddRole
  }

}


export interface KamAssignPermissionsToRoleData {
  resourceServerPermissions: ResourceServerPermissions,
  role: KeycloakRoleModel, 
}
