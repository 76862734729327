<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed': ''">
  
    <ul class="sidenav-nav">

      <li class="sidenav-nav-item" *ngFor="let section of list_sections">

        <!-- microapp option-->
        <a [ngClass]="collapsed && section.desc === '' ? 'sidenav-nav-link-collapsed': 'sidenav-nav-link'" 
        [routerLink]="[section.url]"
        routerLinkActive="active" 
        [routerLinkActiveOptions]="{exact: true}"
        (click)="section.desc === '' ? toggle() : null"
        *ngIf="section.url"
        >       
          <span [ngClass]="collapsed && section.desc ==='' ? 'material-symbols-outlined rotado' : 'material-symbols-outlined sidenav-link-icon'">{{section.icon}}</span>
          <span class="sidenav-link-text" *ngIf="collapsed">
              {{section.desc}}
          </span>
        </a>

        <!-- external url option-->
        <a [ngClass]="collapsed && section.desc === '' ? 'sidenav-nav-link-collapsed': 'sidenav-nav-link'" 
        href="{{section.href}}" target="_blank"
        (click)="section.desc === '' ? toggle() : null"
        *ngIf="section.href"
        >
          <span [ngClass]="collapsed && section.desc ==='' ? 'material-symbols-outlined rotado' : 'material-symbols-outlined sidenav-link-icon'">{{section.icon}}</span>
          <span class="sidenav-link-text" *ngIf="collapsed">
              {{section.desc}}
          </span>
        </a>
        
      </li>

    </ul>
  
   <div [ngClass]="collapsed ? 'arrow-nav-collapsed':'arrow-nav'">
    <a [ngClass]="collapsed ? 'arrow-nav-link-collapsed': 'arrow-nav-link'">
      <span [ngClass]="collapsed ? 'material-symbols-outlined rotado-arrow' : 'material-symbols-outlined arrow-icon'" (click)=" toggle()">play_circle</span>
    </a>
   </div>
    
  </div>
  
  