import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KamAttributesHelper } from '../../../lib/kam-attributes.helper';
import { KeycloakAttributeConfig } from '../../../model/keycloak-attribute-config.model';

@Component({
  selector: 'keycloak-auth-manager-kam-attributes-form',
  templateUrl: './kam-attributes-form.component.html',
  styleUrls: ['./kam-attributes-form.component.css']
})
export class KamAttributesFormComponent implements OnInit {

  @Input() customAttributesRequest: any;
  @Input() initialAttributesValues: Map<string, string[]>|undefined;

  @Output() customAttributesValues = new EventEmitter<Map<string, string[]>>();
  @Output() customAttributesValuesAreValid = new EventEmitter<boolean>();

  attributesFormGroup: FormGroup | undefined;
  attributeList: KeycloakAttributeConfig[]=[];

  kamAttributesHelper:KamAttributesHelper | undefined;
  constructor() { }

  ngOnInit(): void {
    this.buildForm();
    this.emitAttributesValues();
    this.customAttributesValuesAreValid.emit(this.attributesFormGroup?.valid)
  }
  
  radioChecked(value: string, controlName: string){
    const radioValue = this.attributesFormGroup?.controls[controlName].value
    return radioValue.toString() == value.toString()
  }

  private buildForm() {
    this.kamAttributesHelper = new KamAttributesHelper(this.customAttributesRequest,this.initialAttributesValues);
    this.attributeList= this.kamAttributesHelper.attributeList;
    this.attributesFormGroup = this.kamAttributesHelper.attributesFormGroup
    this.attributesFormGroup?.valueChanges.subscribe(val=>{
      this.emitAttributesValues();
      this.customAttributesValuesAreValid.emit(this.attributesFormGroup?.valid);      
    })    
  }

 
  private emitAttributesValues() {
    let valuesMap = new Map<string, string[]>();
    this.attributeList.forEach(attribute => {
      valuesMap.set(attribute.controlName, [this.attributesFormGroup?.controls[attribute.controlName].value]);
    });
    this.customAttributesValues.emit(valuesMap);
  }

  addControl(attribute:KeycloakAttributeConfig){
    this.kamAttributesHelper?.addControlAttributeToForm(attribute,this.initialAttributesValues);
  }

  removeControl(attribute:KeycloakAttributeConfig){
    this.kamAttributesHelper?.removeControl(attribute)
  }
  


}
