import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import {
  GestoresResiduosControllerService,
  GestoresResiduosDTO,
  PoseedorDTO,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { WasteManagersDetailGeneralDataComponent } from '../waste-managers-detail-general-data/waste-managers-detail-general-data.component';
import swal from 'sweetalert2';
import { WasteManagersDetailAuthorizedWasteComponent } from '../waste-managers-detail-authorized-waste/waste-managers-detail-authorized-waste.component';
import { HoldersService } from '../../../services/holders.service';
@Component({
  selector: 'app-waste-managers-detail',
  templateUrl: './waste-managers-detail.component.html',
  styleUrls: ['./waste-managers-detail.component.scss'],
})
export class WasteManagersDetailComponent implements OnInit {
  selectedMenu = 1;
  lastMenu = 1;
  id!: number;
  gestorInfo!: GestoresResiduosDTO;
  gestorEstado: number | undefined;

  gestorInicial: boolean = false;
  gestorBaja: boolean = false;
  gestorCompletado: boolean = false;

  textoEstado: string = '';

  datosCargados: boolean = false;
  flagRuta: boolean = false;

  menuEnum: any = {
    1: 'poseedores-gestor-detalle',
    2: 'poseedores-gestor-contactos',
    3: 'poseedores-gestor-direcciones',
    4: 'poseedores-gestor-prevision',
    5: 'poseedores-gestor-documentos',
  };

  @ViewChild(WasteManagersDetailGeneralDataComponent, { static: false })
  childWasteGeneralDataComponent:
    | WasteManagersDetailGeneralDataComponent
    | undefined;
  @ViewChild(WasteManagersDetailAuthorizedWasteComponent, { static: false })
  childDetailAuthorizedWasteComponent:
    | WasteManagersDetailAuthorizedWasteComponent
    | undefined;

  constructor(
    public spinnerSrv: SpinnerService,
    private router: Router,
    private snackBarSrv: SnackbarService,
    private route: ActivatedRoute,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private gestoresResiduosControllerService: GestoresResiduosControllerService,
    private holdersService: HoldersService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
    });
  }

  ngOnInit() {
    this.flagRuta = this.holdersService.getTempHoldersSearch;
    this.spinnerSrv.loadingShow();
    this.gestoresResiduosControllerService.getById3(this.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.gestorInfo = response;
        this.datosCargados = true;
        this.gestorEstado = this.gestorInfo?.gestoresResiduosEstado?.id;
        switch (this.gestorEstado) {
          case 1:
            this.gestorInicial = true;
            break;
          case 2:
            this.gestorCompletado = true;
            break;
          case 3:
            this.gestorBaja = true;
            break;
        }
        this.textoEstado =
          this.gestorInfo?.gestoresResiduosEstado?.descripcion!;
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al cargar el Gestor',
          'error'
        );
      },
    });
  }

  loadNumMenu(num: number): void {
    this.controlGeneralForm();
    this.selectedMenu = num;
    if (this.lastMenu < num) {
      this.lastMenu = num;
    }
  }
  anterior(): void {
    let aux = this.selectedMenu;
    if (aux - 1 > 0) {
      this.selectedMenu = aux - 1;
    }
  }

  siguiente(): void {
    let aux = this.selectedMenu;
    if (aux + 1 <= 4) {
      this.selectedMenu = aux + 1;
    }
  }

  volver() {
    this.router.navigate(['/holders/waste-managers-search']);
  }

  continuarName(menu: number): string {
    switch (menu) {
      case 1:
        return this.authService.can(
          this.menuEnum[menu],
          'access-b-guardar-continuar'
        ) && this.gestorInfo?.gestoresResiduosEstado?.id == 1
          ? 'Guardar y Continuar '
          : 'Guardar';
        break;
      case 2:
        return this.authService.can(
          this.menuEnum[menu],
          'access-b-continuar'
        ) && this.gestorInfo?.gestoresResiduosEstado?.id == 1
          ? 'Continuar'
          : '';
        break;
      case 3:
        return this.authService.can(
          this.menuEnum[menu],
          'access-b-continuar'
        ) && this.gestorInfo?.gestoresResiduosEstado?.id == 1
          ? 'Continuar'
          : '';
        break;
      default:
        return '';
        break;
    }
  }

  canAccessContinuarAll(menu?: number): boolean {
    switch (menu) {
      case 1:
        return (
          this.authService.can(
            this.menuEnum[menu],
            'access-b-guardar-continuar'
          ) && this.gestorInfo?.gestoresResiduosEstado?.id == 1
        );
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.gestorInfo?.gestoresResiduosEstado?.id == 1
        );
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.gestorInfo?.gestoresResiduosEstado?.id == 1
        );
      default:
        return false;
    }
  }

  controlAccionsWizard(idMenu?: number) {
    switch (idMenu) {
      case 1:
        if (this.canAccessContinuarAll(idMenu)) {
          this.childWasteGeneralDataComponent?.guardar();
        } else if (this.canAccessGuardar(idMenu)) {
          this.childWasteGeneralDataComponent?.guardar();
        }
        break;
      case 2:
        if (this.verificarObligatorioContactos()) {
          this.snackBarSrv.showSnackBar(
            'Debe completar los datos en los contactos requeridos',
            'error'
          );
        } else {
          this.snackBarSrv.showSnackBar('Contactos satisfactorios', 'success');
          this.siguiente();
        }
        break;
      case 3:
        if (this.verificarObligatorioDirecciones()) {
          this.snackBarSrv.showSnackBar(
            'Debe completar los datos en los direcciones requeridos',
            'error'
          );
        } else {
          this.snackBarSrv.showSnackBar(
            'Direcciones satisfactorios',
            'success'
          );
          this.siguiente();
        }
        break;
      case 4:
        this.childDetailAuthorizedWasteComponent?.finalizar();
        break;
      default:
        break;
    }
  }

  verificarObligatorioContactos() {
    const objetoCampoObligatorios = {
      tipoContacto: true,
      persona: true,
      nombre: true,
      apellido1: false,
      tipo_documento: false,
      codigo_documento: false,
      tipo_telefono: true,
      prefijoPais: true,
      numero_telefono: true,
      email: true,
      recibir_comunicaciones: true,
      acreditacion_poderes: false,
    };

    if (this.gestorInfo?.contactos?.length != 0) {
      const tieneCamposObligatoriosVacios = this.gestorInfo?.contactos
        ?.filter((objeto: any) => objeto.requerido)
        .map((item: any) => {
          if (item?.tipo_contacto.id === 3 || item?.tipo_contacto.id === 4) {
            objetoCampoObligatorios.tipo_documento = true;
            objetoCampoObligatorios.codigo_documento = true;
            objetoCampoObligatorios.acreditacion_poderes = true;
          } else {
            objetoCampoObligatorios.tipo_documento = false;
            objetoCampoObligatorios.codigo_documento = false;
            objetoCampoObligatorios.acreditacion_poderes = false;
          }
          if (item?.persona) {
            objetoCampoObligatorios.apellido1 = true;
          } else {
            objetoCampoObligatorios.apellido1 = false;
          }
          const data = Object.entries(objetoCampoObligatorios).some(
            ([campo, obligatorio]) =>
              obligatorio && (item[campo] === '' || item[campo] === null)
          );
          return data;
        })
        .some((resultado) => resultado);
      return tieneCamposObligatoriosVacios;
    } else {
      return false;
    }
  }

  verificarObligatorioDirecciones() {
    // Define un objeto con los campos obligatorios y los inicializa
    const objetoCampoObligatorios = {
      provincia: false,
      codigoPostal: false,
      municipio: false,
      tipoDireccion: false,
      tipoVia: false,
      nombreVia: false,
      numero: false,
      pais: true,
    };

    // Verifica si hay domicilios
    if (this.gestorInfo?.domicilios?.length != 0) {
      // Filtra los domicilios requeridos y luego verifica si algún campo obligatorio está vacío
      const tieneCamposObligatoriosVacios = this.gestorInfo?.domicilios
        ?.filter((objeto: any) => objeto.requerido) // Filtra los domicilios requeridos
        .map((item: any) => {
          // Si el país es igual a 69, los campos provincia, codigoPostal y municipio son obligatorios
          if (item.pais?.id === 69) {
            objetoCampoObligatorios.provincia = true;
            objetoCampoObligatorios.codigoPostal = true;
            objetoCampoObligatorios.municipio = true;
            objetoCampoObligatorios.nombreVia = true;
            objetoCampoObligatorios.numero = true;
          } else {
            objetoCampoObligatorios.provincia = false;
            objetoCampoObligatorios.codigoPostal = false;
            objetoCampoObligatorios.municipio = false;
            objetoCampoObligatorios.nombreVia = false;
            objetoCampoObligatorios.numero = false;
          }
          return Object.entries(objetoCampoObligatorios).some(
            ([campo, obligatorio]) =>
              obligatorio && (item[campo] === '' || item[campo] === null)
          );
        })
        .some((resultado) => resultado); // Verifica si alguno de los resultados es verdadero
      return tieneCamposObligatoriosVacios; // Devuelve si hay campos obligatorios vacíos
    } else {
      return false; // Si no hay domicilios, devuelve falso
    }
  }

  canAccessGuardar(menu: number) {
    switch (menu) {
      case 1:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-guardar') &&
          this.gestorEstado &&
          [1, 2].includes(this.gestorEstado)
        );
        break;
      default:
        return false;
        break;
    }
  }

  refrescar(menu: number): void {
    this.controlGeneralForm();
    this.selectedMenu = menu;
    this.spinnerSrv.loadingShow();
    this.gestoresResiduosControllerService.getById3(this.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.gestorInfo = response;
        this.gestorEstado = this.gestorInfo?.gestoresResiduosEstado?.id;
        if (!this.canViewRol('PO-ENV-GEST')) {
          if (this.selectedMenu == 1 && this.gestorEstado == 1) {
            this.siguiente();
          }
        }
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        console.log(err);
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al cargar el gestor',
          'error'
        );
      },
    });
  }
  controlGeneralForm() {
    if (this.canAccessContinuarAll(1) || this.canAccessGuardar(1)) {
      this.childWasteGeneralDataComponent?.controlFormEnable();
    } else {
      this.childWasteGeneralDataComponent?.controlFormDisable();
    }
  }

  canAccessFinalizar(menu: number): boolean {
    switch (menu) {
      case 4:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.gestorEstado == 1
        );
        break;
      default:
        return false;
        break;
    }
  }

  canAccessCancelar(menu: number) {
    switch (menu) {
      case 1:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.gestorEstado &&
          [1, 2].includes(this.gestorEstado)
        );
        break;
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.gestorEstado &&
          [1, 2].includes(this.gestorEstado)
        );
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.gestorEstado &&
          [1, 2].includes(this.gestorEstado)
        );
      case 4:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.gestorEstado &&
          [1, 2].includes(this.gestorEstado)
        );
      case 5:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.gestorEstado &&
          [1, 2].includes(this.gestorEstado)
        );
      default:
        return false;
        break;
    }
  }

  buttonVolver() {
    this.router.navigate(['/holders/waste-managers-search']);
  }

  canAccessVolver(): boolean | any {
    return (
      this.authService.can('poseedores-gestor-detalle', 'access-b-volver') &&
      this.gestorEstado &&
      [3].includes(this.gestorEstado)
    );
  }

  canAccessDocumentos(): boolean {
    return this.authService.can('poseedores-gestor-documentos', 'access');
  }

  canAccessFinal(): boolean {
    return this.authService.can('poseedores-gestor-documentos', 'access');
  }

  canAccessAnteriorAll(menu: number): boolean {
    switch (menu) {
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.gestorEstado == 1
        );
        break;
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.gestorEstado == 1
        );
        break;
      case 4:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.gestorEstado == 1
        );
        break;
      default:
        return false;
        break;
    }
  }

  buttonCancelar() {
    swal
      .fire({
        text: '¿Desea cancelar esta acción?. Regresará al buscador.',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.router.navigate(['/holders/waste-managers-search']);
        }
      });
  }

  mensajeValidacion() {
    this.snackBarSrv.showSnackBar(
      'Para poder desplazarse de una pantalla a otra deberá hacerlo mediante los botones de la parte inferior.'
    );
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }
}
