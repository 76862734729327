import { Injectable } from "@angular/core";
import { KeycloakUserDTO, UserRoleEntityDTO, TarifaAdheridoUnUsoDTO } from "api-rest";

export interface Combo {
  id: string | number,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class ComboService {
  // Tipos de documento de identidad
  getTiposDocumentoIdentidad(): Combo[] {
    return [
      { id: 'DNI', name: 'DNI' },
      { id: 'NIE', name: 'NIE' },
      { id: 'NIF', name: 'NIF' },
    ];
  }
  // Tipos de teléfono
  getTiposTelefono(): Combo[] {
    return [
      { id: 'FIJO', name: 'Fijo' },
      { id: 'MOVIL', name: 'Móvil' }
    ];
  }
  getOrganos(): Combo[] {
    return [
      { id: 'ASAMBLEA', name: 'Asamblea' },
      { id: 'JUNTA_DIRECTIVA', name: 'Junta Directiva' },
      { id: 'COMITE_OPERATIVO', name: 'Comité Operativo' },
      { id: 'COMISIONES', name: 'Comisiones' }
    ]
  }
}