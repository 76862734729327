/**
 * Core API
 * Core API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AgrupacionMaterialFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    id?: Array<string>;
    descripcion?: Array<string>;
}

