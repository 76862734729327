import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'lib-administration',
  templateUrl: 'administration.component.html',
  styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent {

  constructor(
  ) { }


}
