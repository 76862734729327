import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'keycloak-auth-manager-kam-permissions-tab',
  templateUrl: './kam-permissions-tab.component.html',
  styleUrls: ['./kam-permissions-tab.component.css']
})
export class KamPermissionsTabComponent implements OnInit, OnDestroy {
  constructor() { }

  ngOnInit(): void {}

  ngOnDestroy(): void {}


}
