import { Component, Inject } from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  CategoriaEnvaseControllerService,
  CategoriaEnvaseDTO,
  PageDTOTarifaAdheridoUnUsoDTO,
  TarifaAdheridoUnUsoControllerService,
  TarifaAdheridoUnUsoDTO,
  TarifaAdheridoUnUsoFilterDTO,
} from 'api-rest';
import { Combo, ComboService } from '../../../services/combo.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { AttachedRateOneUseModalComponent } from '../attached-rate-one-use-modal/attached-rate-one-use-modal.component';
import { AttachedRateOnUseModalData } from '../attached-rate-one-use-modal/attached-rate-one-use-modal.component';
import { map } from 'rxjs';
import { RateCategoryTypeEnum } from '../../models/rate-category-type.enum';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';

@Component({
  selector: 'lib-user-search',
  templateUrl: './attached-rate-one-use-search.component.html',
  styleUrls: ['./attached-rate-one-use-search.component.scss'],
})
export class AttachedRateOneUseSearchComponent {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;
  roles: TarifaAdheridoUnUsoDTO[] = [];
  grupoEnvaseEndpoint =
    this.environment.urlBackCore + 'api/v1/core/grupoEnvase';
  categoriaEnvaseEndpoint =
    this.environment.urlBackCore + 'api/v1/core/packaging-category';
  agrupacionMaterialEndpoint =
    this.environment.urlBackCore + 'api/v1/core/agrupacionMaterial';

  defaultFilter: boolean = true;
  headArray: any = [
    {
      Head: 'Año',
      FieldName: 'anio',
    },
    {
      Head: 'Categoría',
      FieldName: 'categoria.descripcion',
    },
    {
      Head: 'Grupo envase',
      FieldName: 'grupoEnvase.descripcion',
    },
    {
      Head: 'Tipología',
      FieldName: 'tipologia',
    },
    {
      Head: 'Agrupación de material',
      FieldName: 'agrupacionMaterial.descripcion',
    },
    {
      Head: 'Peligrosidad',
      FieldName: 'peligrosidad',
      renderValue: (item: TarifaAdheridoUnUsoDTO) =>
        item.peligrosidad ? 'SI' : 'NO',
    },
    {
      Head: '€/kg',
      FieldName: 'valor',
      renderValue: (item: any) => this.formatCurrencyPipe.transform(item.valor),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        { nameButton: 'edit', icon: 'open_in_new', toolTip: 'Editar' },
        { nameButton: 'delete', icon: 'delete', toolTip: 'Borrar' },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: TarifaAdheridoUnUsoDTO[] = [];

  searchForm: FormGroup;
  categorias: CategoriaEnvaseDTO[] = [];
  tipologias: Combo[] = [];
  flagSearch: boolean = false;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;

  constructor(
    @Inject('environment') private environment: any,
    private attachedOneUseService: TarifaAdheridoUnUsoControllerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private downloadService: DownloadService,
    private snackbarService: SnackbarService,
    private comboService: ComboService,
    private categoryEnvaseService: CategoriaEnvaseControllerService,
    public spinnerSrv: SpinnerService,
    private formatCurrencyPipe: FormatCurrencyPipe
  ) {
    this.searchForm = this.formBuilder.group({
      anio: [{ value: null, disabled: false }, [this.customValidator()]],
      categoria: [{ value: null, disabled: false }],
      grupoEnvase: [{ value: null, disabled: false }],
      agrupacionMaterial: [{ value: null, disabled: false }],
      tipologia: [{ value: null, disabled: false }],
      peligrosidad: [{ value: null, disabled: false }]
    });
  }

  ngOnInit(): void {
    this.tipologias = this.comboService.getComboTipologia();
    this.categoryEnvaseService
      .listarCategoriaEnvases('body')
      .pipe(
        map((res) =>
          res.filter(
            (cat) =>
              cat.id == RateCategoryTypeEnum.IndustrialUnUso ||
              cat.id == RateCategoryTypeEnum.ComercialUnUso
          )
        )
      )
      .subscribe((res) => (this.categorias = res));
  }

  renderPage(event: number) {
    this.pageNumber = event;
    this.getRateOnUseList();
  }

  customValidator() {
    return (control: { value: any }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (/^\d{4}$/.test(value)) {
        const intValue = parseInt(value, 10);
        if (intValue >= 1900 && intValue <= 2500) {
          return null;
        }
      }
      return { invalid: true };
    };
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  checked(item: any) {}

  searchData() {
    this.pageNumber = 1;
    this.getRateOnUseList();
  }

  getRateOnUseList() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.attachedOneUseService
      .findAll(backendFilter, {
        page: page,
        size: size,
        sort: ['anio,asc'],
      })
      .subscribe({
        next: (data: PageDTOTarifaAdheridoUnUsoDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.gridArray = data.datos ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
          }
        },
        error: (error) => {
          this.spinnerSrv.loadingHide();
          this.snackbarService.showSnackBar(
            'Error obteniendo listado de tarifas de envase de un solo uso',
            'error'
          );
        },
      });
  }

  private getBackendFilter() {
    //const textFilter = (value: any) => value?.trim() ? [`ci(${value?.trim()})`] : undefined;
    const filter = this.searchForm.value;

    const backendFilter: TarifaAdheridoUnUsoFilterDTO = {
      anio: filter.anio,
      categoria: filter.categoria ? { id: filter.categoria } : undefined,
      grupoEnvase: filter.grupoEnvase
        ? { id: filter.grupoEnvase.id }
        : undefined,
      agrupacionMaterial: filter.agrupacionMaterial
        ? { id: filter.agrupacionMaterial.id }
        : undefined,
      tipologia: filter.tipologia as TarifaAdheridoUnUsoFilterDTO.TipologiaEnum,
      peligrosidad: filter.peligrosidad
    };

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }

  action(item: any) {
    if (item.nameButton == 'view') {
      this.openModalUser(item, false);
    } else if (item.nameButton == 'edit') {
      this.openModalUser(item, true);
    } else if (item.nameButton == 'delete') {
      this.confirmacion(
        `¿Desea eliminar la tarifa de envase de un solo uso ${item.anio}?`,
        () => this.eliminarTarifaUnUso(item)
      );
    }
  }

  openModalUser(element?: any, editMode = false): void {
    const dialogRef = this.dialog.open(AttachedRateOneUseModalComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: <AttachedRateOnUseModalData>{
        item: element,
        editMode,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getRateOnUseList();
      }
    });
  }

  clearSearcher() {
    this.searchForm.reset();
  }

  exportarExcel() {
    const { backendFilter } = this.getBackendFilter();
    this.attachedOneUseService
      .exportCSV(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_Adheridos.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  getFormControl(controlName: string): FormControl {
    return this.searchForm.controls[controlName] as FormControl;
  }

  eliminarTarifaUnUso(item: TarifaAdheridoUnUsoDTO) {
    this.attachedOneUseService.deleteById(item.id!).subscribe({
      next: (res) => {
        this.snackbarService.showSnackBar(
          `La tarifa de envase de un solo uso ${item.anio} se ha borrado correctamente`,
          'success'
        );
        this.getRateOnUseList();
      },
      error: (error) =>
        this.snackbarService.showSnackBar(
          'Ha habido un error al eliminar la tarifa de envase de un solo uso',
          'error'
        ),
    });
  }

  confirmacion(titulo: string, callBack: Function): void {
    Swal.fire({
      text: titulo,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        callBack();
      }
    });
  }
}
