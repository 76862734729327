<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <!-- Formulario Centros y Categorias-->
    <form class="form-modal py-3" [formGroup]="formGroup">
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="centro">Centro de Envasado <span class="oblig">*</span></label>
          <div class="custom-mat-form-field-width">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="centro" id="centro" placeholder="Centro">
                <mat-option *ngFor="let tipo of centroArray" [value]="tipo.id">
                  {{tipo.denominacion}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('centro', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="categoria">Categoría <span class="oblig">*</span></label>
          <div class="custom-mat-form-field-width">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="categoria" id="prodcts" placeholder="Categoría">
                <mat-option *ngFor="let tipo of categoriasEnvases" [value]="tipo.id">
                  {{tipo.descripcion}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('categoria', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <div class="cont-tooltip">
            <label for="ventaDirecta">% de Venta Directa </label>
            <div class="tool-help" matTooltip="Venta a cliente directo que consume el producto." data-placement="top"
              title="">
              <span> ?</span>
            </div>
          </div>
          <div class="custom-mat-form-field-width">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="ventaDirecta" id="ventaDirecta" placeholder="% de Venta Directa" matInput
                type="number" [min]="0">
              <mat-error *ngIf="formGroup.get('ventaDirecta')?.touched">
                <span *ngIf="controlHasError('ventaDirecta', 'max')">El valor máximo es 100.</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <div class="cont-tooltip">
            <label for="ventaDistribucion">% Venta a Distribución </label>
            <div class="tool-help" matTooltip="Venta a través de la distribución." data-placement="top"
              title="">
              <span> ?</span>
            </div>
          </div>
          <div class="custom-mat-form-field-width">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="ventaDistribucion" id="ventaDistribucion" placeholder="% Venta a Distribución"
                matInput type="number" [min]="0">
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>

    <!-- FIN Formulario dirección -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
