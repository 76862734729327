import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { selectGroups } from '../../../state/auth-manager.selectors';
import { KeycloakGroupModel } from '../../../model/keycloak-group.model';
import { Store } from '@ngrx/store';
import { AuthManagerState } from '../../../state/auth-manager.model';
import { Subscription } from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'keycloak-auth-manager-kam-group-selection',
  templateUrl: './kam-group-selection.component.html',
  styleUrls: ['./kam-group-selection.component.css']
})
export class KamGroupSelectionComponent implements OnInit {

  @Output() groupSelection = new EventEmitter<KeycloakGroupModel[]>();
  @Output() groupsUnselected = new EventEmitter<KeycloakGroupModel[]>();

  @Input() initialGroupsSelected: string[]|undefined;

  groupSubscription: Subscription | undefined;
  dataSource: MatTableDataSource<KeycloakGroupModel> = new MatTableDataSource();
  columnsToDisplay = ['select', 'name'];
  selection = new SelectionModel<KeycloakGroupModel>(true, []);
  private initalGroups: KeycloakGroupModel[] = [];
  
  @ViewChild('paginatorGroups') set paginatorGroups(mp: MatPaginator) {
    this.dataSource.paginator = mp
  }

  constructor(private store: Store<AuthManagerState>,) { }

  ngOnInit(): void {
    this.groupSubscription = this.store.select(selectGroups).subscribe(roles =>{          
      if (this.initialGroupsSelected){
        this.initialGroupsSelected.forEach(selectedGroup=>{
            let foundGroup = roles.find(role => role.name===selectedGroup)
            if (foundGroup){
              this.selection.select(foundGroup)    
              this.initalGroups.push(foundGroup)        
            }
        })        
      }
      this.dataSource.data = roles;
      this.groupSelection.emit(this.selection.selected);
    });
  }

  update(){
    let unSelect: KeycloakGroupModel[] = []
    this.initalGroups.forEach(initialGroup=>{
      let found = this.selection.selected.findIndex(role => role.name === initialGroup.name)
      if (found<0){
        unSelect.push(initialGroup)
      }
    })
    this.groupSelection.emit(this.selection.selected);
    this.groupsUnselected.emit(unSelect)
  }

  ngOnDestroy(): void {
    if (this.groupSubscription) this.groupSubscription.unsubscribe();
  }

}
