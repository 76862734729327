import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Adherido, CentroEnvasado } from '../../../models/adherido';
import { MatDialog } from '@angular/material/dialog';
import { DetailPackagingOriginModalComponent } from '../detail-packaging-origin-modal/detail-packaging-origin-modal.component';
import { GetCentroEnvasado } from '../../../models/get_centro_envasado';
import swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { AttachedService } from '../../../services/attached.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { CentrosEnvasadoControllerService } from 'api-rest';
import { SnackbarService } from '../../../services/snackbar.service';
@Component({
  selector: 'lib-detail-packaging-centers',
  templateUrl: './detail-packaging-centers.component.html',
  styleUrls: ['./detail-packaging-centers.component.scss'],
})
export class DetailPackagingCentersComponent implements OnChanges {
  @Input() adheridoInfo!: Adherido;
  centrosEnvasado!: CentroEnvasado[] | null;
  @Output() public refrescarAdheridoEvent = new EventEmitter<any>();

  gridArray: any = [];
  headArray: any = [
    {
      Head: 'Denominacion',
      FieldName: 'denominacion',
    },
    {
      Head: 'Dirección',
      FieldName: 'domicilio',
      Attribute: 'municipio',
    },
    {
      Head: 'Contacto',
      FieldName: 'contacto',
      Attribute: 'fullName',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '8',
    },
  ];

  dataSource!: MatTableDataSource<CentroEnvasado>;
  displayedColumns: string[] = [
    'denominacion',
    'direccion',
    'contacto',
    'acciones',
  ];

  constructor(
    public dialog: MatDialog,
    private spinnerSrv: SpinnerService,
    private centroEnvasadoService: CentrosEnvasadoControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private snackBarSrv: SnackbarService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.adheridoInfo) {
      this.centrosEnvasado = this.adheridoInfo?.centrosEnvasado;
      this.gridArray = this.canAccessAll(this.centrosEnvasado);
    }
  }

  openModalPackaging(item?: any, show?: any): void {
    const dialogRef = this.dialog.open(DetailPackagingOriginModalComponent, {
      width: '955px',
      data: {
        show: show,
        item: item,
        adherido: this.adheridoInfo,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refrescarAdheridoEvent.emit(true);
      }
    });
  }

  confirmacionEliminar(element?: any): void {
    swal
      .fire({
        text: '¿Desea eliminar el Centro de Envasado?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.eliminarCentro(element);
        }
      });
  }
  eliminarCentro(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.centroEnvasadoService
        .borrarCentro(this.adheridoInfo.id!, element.id)
        .subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            this.refrescarAdheridoEvent.emit(true);
          },
          error: (error) => {
            if (error.error.detail) {
              this.snackBarSrv.showSnackBar(error.error.detail, 'error');
            } else {
              this.snackBarSrv.showSnackBar(
                'Ha ocurrido un error al borrar el centro de envasado',
                'error'
              );
            }
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  action(item: any) {
    if (item.nameButton == 'view') {
      this.openModalPackaging(item, 'view');
    } else if (item.nameButton == 'edit') {
      this.openModalPackaging(item, 'edit');
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }

  canAccessNuevoCentroEnvasados(): boolean {
    return this.authService.can('adheridos-documentos', 'create');
  }

  canAccessExportarCentro(): boolean {
    return this.authService.can('adheridos-documentos', 'access');
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('adheridos-centros', 'access');
    const resultListEdit = this.authService.can('adheridos-centros', 'update');
    const resultDelete = this.authService.can('adheridos-centros', 'delete');

    const listPermission = list.map((objeto: any) => {
      return {
        ...objeto,
        canAccessView: resultView,
        canAccessListEdit: resultListEdit,
        canAccessDelete: resultDelete && !objeto.principal,
      };
    });

    return listPermission;
  }
}
