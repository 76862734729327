import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  ApiAhderidos,
  ApiUserMgmt,
  ApiCore,
  ApiPoseedores,
  ApiDocum,
} from 'api-rest';
import { AdministrationComponent } from '../pages/administration.component';
import { AttachedRateOneUseSearchComponent } from '../pages/components/attached-rate-one-use-search/attached-rate-one-use-search.component';
import { UserDetailModalComponent } from '../pages/components/user-detail-modal/user-detail-modal.component';
import { UserSearchComponent } from '../pages/components/user-search/user-search.component';
import { GeSelectPaginationComponent } from '../share/components/ge-select-pagination/ge-select-pagination.component';
import { GeTableComponent } from '../share/components/ge-table.component';
import { GetAttributePipe } from '../share/components/pipe/get-attribute.pipe';
import { MaterialModule } from './material.module';
import { AttachedRateClosedSearchComponent } from '../pages/components/attached-rate-closed-search/attached-rate-closed-search.component';
import { RateClosedDialogComponent } from '../pages/components/rate-closed-dialog/rate-closed-dialog.component';
import { EmpusaSpinnerComponent } from '@empusa/empusa-core';
import { AttachedRateOpenSearchComponent } from '../pages/components/attached-rate-open-search/attached-rate-open-search.component';
import { RateOpenDialogComponent } from '../pages/components/rate-open-dialog/rate-open-dialog.component';
import { AttachedRateOneUseModalComponent } from '../pages/components/attached-rate-one-use-modal/attached-rate-one-use-modal.component';
import { OwnerRateComponent } from '../pages/components/owner-rate/owner-rate.component';
import { OwnerRateDialogComponent } from '../pages/components/owner-rate-dialog/owner-rate-dialog.component';
import { DocumentTemplateListComponent } from '../pages/components/document-template-list/document-template-list.component';
import { CollectionPointRatesSearchComponent } from '../pages/components/collection-point-rates-search/collection-point-rates-search.component';
import { CustomDatePickerModule } from './custom-date-picker.module';
import { CollectionPointRatesDialogComponent } from '../pages/components/collection-point-rates-dialog/collection-point-rates-dialog.component';
import { TwoDigitDecimalNumberDirective } from '../share/components/utils/TwoDigitDecimalNumberDirective.directive';
import { NumericPointDirective } from '../share/components/utils/NumericPoint.directive';
import { TreeDigitDecimalNumberDirective } from '../share/components/utils/TreeDigitDecimalNumberDirective.directive';
import { ManagerAgreementsRatesSearchComponent } from '../pages/components/managers-agreements-rates-search/managers-agreements-rates-search.component';
import { ManagersAgreementsRatesDialogComponent } from '../pages/components/managers-agreements-rates-dialog/managers-agreements-rates-dialog.component';
import { SddrCaRateSearchComponent } from '../pages/components/sddr-ca-rate-search/sddr-ca-rate-search.component';
import { SddrCaRateDialogComponent } from "../pages/components/sddr-ca-rate-dialog/sddr-ca-rate-dialog.component";

export const CoreConfigFactory = () =>
  new ApiCore.Configuration(<ApiCore.ConfigurationParameters>{});

export const CoreBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE CORE ' + environment.urlBackCore);
  const basePath = environment.urlBackCore;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const DocumConfigFactory = () =>
  new ApiDocum.Configuration(<ApiCore.ConfigurationParameters>{});

export const DocumBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE CORE ' + environment.urlBackDocum);
  const basePath = environment.urlBackDocum;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const PoseedoresConfigFactory = () =>
  new ApiPoseedores.Configuration(<ApiPoseedores.ConfigurationParameters>{});

export const PoseedoresBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE POSEEDORES ' + environment.urlBackPoseedores);
  const basePath = environment.urlBackPoseedores;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const explorerRoutes = [
  {
    path: '',
    component: AdministrationComponent,
    children: [
      { path: 'users/search', component: UserSearchComponent },
      { path: 'rates/search', component: AttachedRateOneUseSearchComponent },
      { path: 'ratesOpen/search', component: AttachedRateOpenSearchComponent },
      {
        path: 'closedRates/search',
        component: AttachedRateClosedSearchComponent,
      },
      { path: 'ratesHolders/search', component: OwnerRateComponent },
      {
        path: 'ratesCollectionPoint/search',
        component: CollectionPointRatesSearchComponent,
      },
      {
        path: 'ratesSddrCa/search',
        component: SddrCaRateSearchComponent,
      },
      { path: 'templates/list', component: DocumentTemplateListComponent },
      {
        path: 'ratesManagerAgreements/search',
        component: ManagerAgreementsRatesSearchComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    AdministrationComponent,
    UserSearchComponent,
    UserDetailModalComponent,
    AttachedRateOneUseSearchComponent,
    AttachedRateOneUseModalComponent,
    DocumentTemplateListComponent,

    // Shared components
    GeTableComponent,
    GetAttributePipe,
    GeSelectPaginationComponent,
    AttachedRateClosedSearchComponent,
    RateClosedDialogComponent,
    RateOpenDialogComponent,
    AttachedRateOpenSearchComponent,
    OwnerRateComponent,
    OwnerRateDialogComponent,
    CollectionPointRatesSearchComponent,
    CollectionPointRatesDialogComponent,
    SddrCaRateSearchComponent,
    SddrCaRateDialogComponent,
    ManagerAgreementsRatesSearchComponent,
    ManagersAgreementsRatesDialogComponent,
    TwoDigitDecimalNumberDirective,
    NumericPointDirective,
    TreeDigitDecimalNumberDirective,
  ],
  imports: [
    MaterialModule,
    NgbTooltipModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    ApiCore.ApiModule.forRoot(CoreConfigFactory),
    ApiDocum.ApiModule.forRoot(DocumConfigFactory),
    // ApiPoseedores.ApiModule.forRoot(PoseedoresConfigFactory),
    CommonModule,
    RouterModule.forChild(explorerRoutes),
    EmpusaSpinnerComponent,
    CustomDatePickerModule,
  ],
  exports: [
    AdministrationComponent,
    UserSearchComponent,
    UserDetailModalComponent,
    AttachedRateOneUseModalComponent,
    GeSelectPaginationComponent,
  ],
  providers: [
    DatePipe,
    // {
    //   provide: ApiCore.BASE_PATH,
    //   useFactory: CoreBasePathFactory,
    //   deps: ['environment'],
    // },
    {
      provide: ApiDocum.BASE_PATH,
      useFactory: DocumBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiPoseedores.BASE_PATH,
      useFactory: PoseedoresBasePathFactory,
      deps: ['environment'],
    },
  ],
})
export class AdministrationHomeModule {
  constructor() {
    let compilationVersion = '0.0.1-R->04/10/23-12:14:49';
    console.log('Home version:' + compilationVersion);
  }
}
