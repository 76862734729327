import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-managers-agreements',
  templateUrl: 'managers-agreements.component.html',
  styleUrls: ['./managers-agreements.component.scss'],
})
export class ManagersAgreementsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
