import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  OnChanges
} from '@angular/core';
import { GeSelectPaginationService } from './ge-select-pagination.service';
import { Subject, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';

export interface MappedResponse<T> {
  totalElements: number;
  recordsFiltered: number;
  datos: T[];
}

@Component({
  selector: 'lib-ge-select-pagination',
  templateUrl: './ge-select-pagination.component.html',
  styleUrls: ['./ge-select-pagination.component.scss'],
})
export class GeSelectPaginationComponent implements OnDestroy, OnInit, OnChanges {

  constructor(private geSelectPaginationService: GeSelectPaginationService) { }

  @Input() endPointFilter: string = '';
  @Input() translations: string[] = [];
  @Input() descripcion: string | Function = 'descripcion';
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() updateList: boolean = false;
  @Input() updateUntilValueFound: boolean = true;
  @Input() customClass: string = '';
  @Input() objQueryName: string | Function = 'descripcion';
  @Input() filterValues: string = '';
  @Output() selectedModel = new EventEmitter<any>();
  @Output() filteredList = new EventEmitter<any>();
  public filteredModelList: any[] = [];
  recordsFiltered: number = 0;
  public totalElements: number = 0;
  pageNumberFilter = 0;
  pageSize = 100;
  onDestroy$: Subject<boolean> = new Subject();
  @Input() modelSelected: any;
  @Input() idSelected: any;
  @ViewChild('box') boxInput: ElementRef | undefined;
  @Input() formControlNameSelect: FormControl = new FormControl();
  @Input() placeHolder: string = '';
  @Input() mapResponseFunction: (reponse: any) => MappedResponse<any> = this.defaultMapResponseFunction;
  @Input() anotherTypeSearch: boolean = false;

  input$ = new Subject<string>();

  ngOnInit(): void {
    if (!this.idSelected) {
      this.getNextBatchFiltered();
    }
  }
  async ngOnChanges(changes: any) {
    //first time
    if (changes.idSelected && !changes.idSelected.previousValue && changes.idSelected.currentValue) {

      this.pageNumberFilter = 0;
      if (!this.multiple) {
        const maxTries = 10;
        let result = false;
        for (let i = 0; i < maxTries && !result; i++) {
          // Your existing logic to find a result
          result = this.filteredModelList.find(elem => elem.id == changes.idSelected.currentValue);
          // If result is not found, call your search function and wait before retrying
          if (!result) {
            await this.callSearch("");
          } else {
            this.modelSelected = result
          }
        }
      }
    }
    if (changes.updateList && changes.updateList.currentValue) {
      this.clearFilter();
      this.getNextBatchFiltered();
    }

    if (changes.modelSelected && changes.modelSelected.currentValue) {
      if (this.multiple) {
        this.filterData(changes.modelSelected.currentValue);
      }
    }
    if (changes.modelSelected && changes.modelSelected.currentValue == undefined && changes.modelSelected.firstChange == false) {
      if (changes.modelSelected.previousValue != undefined) {
        if (this.multiple) {
          this.filterData(changes.modelSelected.previousValue);
        } else {
          if (Array.isArray(changes.modelSelected.previousValue) == false) {
            this.filterDataSingleModel(changes.modelSelected.previousValue);
          }
        }
      }

    }
  }

  filterDataSingleModel(previousValue: any) {
    if (previousValue.length > 0) {
      const result = this.filteredModelList.find(elem => this.getItemDescription(elem) == previousValue);

      if (result == undefined && this.totalElements >= this.pageSize) {
        this.findElementByModel('', previousValue)
      } else {
        this.modelSelected = result
      }
    }

  }

  filterData(data: any) {
    if (data.datos.length > 0) {
      const result = this.filteredModelList.filter(item2 =>
        this.modelSelected.some((item1: any) => {
          return this.getItemDescription(item1) == this.getItemDescription(item2)
        })
      );
      if (result.length == 0 && this.totalElements >= this.pageSize) {
        this.findElementByModel('', data)
      } else {
        this.modelSelected = result
      }
    } else {
    }
  }

  findElementByModel(searchTerm: any, data: any) {
    this.geSelectPaginationService
      .getSearchResultLibrary(
        this.endPointFilter,
        this.objQueryName,
        searchTerm,
        this.pageNumberFilter,
        this.pageSize,
        this.anotherTypeSearch
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response: any) => {
        const mappedRes = this.mapResponseFunction(response)
        this.totalElements = mappedRes.totalElements;
        this.recordsFiltered = mappedRes.recordsFiltered;
        this.filteredModelList.push(...mappedRes.datos.map((modelData: any) => {
          return modelData;
        }));
        this.filteredList.emit(this.filteredModelList);
        this.pageNumberFilter += 1;
        this.filterData(data)
      });
  }

  async getNextBatchFiltered() {
    const input = this.boxInput?.nativeElement?.value
      ? this.boxInput?.nativeElement?.value
      : '';
    await this.callSearch(input);
  }

  change(index: number) {
    this.selectedModel.emit(this.modelSelected);
  }

  getItemDescription(item: any) {
    if(typeof this.descripcion == 'function') {
      return this.descripcion(item);
    }
    return item[this.descripcion];
  }

  callSearch(searchTerm: string) {
    if (this.filterValues && !searchTerm) {
      searchTerm = this.filterValues;
    }
    return new Promise((resolve, reject) => {
      this.geSelectPaginationService
        .getSearchResultLibrary(
          this.endPointFilter,
          this.objQueryName,
          searchTerm,
          this.pageNumberFilter,
          this.pageSize,
          this.anotherTypeSearch
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (response) => {
            const mappedRes = this.mapResponseFunction(response)
            this.totalElements = mappedRes.totalElements;
            this.recordsFiltered = mappedRes.recordsFiltered;
            this.filteredModelList.push(...mappedRes.datos.map((modelData: any) => {
              return modelData;
            }));
            this.filteredList.emit(this.filteredModelList);
            this.pageNumberFilter += 1;
            resolve(this.filteredModelList);
          },
          (error) => {
            reject(error);
          }
        );
    });

  }

  async filterSearch(searchTerm: string) {
    if (!searchTerm) {
      return;
    } else {
      searchTerm = searchTerm.toLowerCase();
      this.filteredModelList = [];
      this.pageNumberFilter = 0;
      await this.callSearch(searchTerm);
    }
  }
  async clearFilter() {
    this.filteredModelList = [];
    this.pageNumberFilter = 0;
    if (this.boxInput?.nativeElement?.value) {
      this.boxInput.nativeElement.value = '';
    }
    await this.callSearch('');
  }

  defaultMapResponseFunction<T>(response: any): MappedResponse<T> {
    return <MappedResponse<T>>{
      totalElements: response.paginacion?.totalElements,
      recordsFiltered: response.paginacion?.totalPages,
      datos: response.datos
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
