/**
 * Poseedores API
 * Poseedores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GestorPtoRecogidaFacturaDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    razonSocial?: string;
    denominacion?: string;
    tipoDocumento?: string;
    codigoDocumento?: string;
    nima?: string;
    numInscripcionRegitro1?: string;
    numInscripcionRegistro2?: string;
    iban?: string;
}

