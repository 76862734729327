import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  url: string = this.environment.urlbase + 'usermanagement/services/';
  constructor(private http: HttpClient, 
              @Inject('environment') private environment: any, 
              ) {
  }



  changePassword(email: string) {
    let headers = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.put(this.url + 'user/resetpwd/' + email,null).pipe(map(resp => <any>resp));
  }

  getUser(uid: string): any {
    return this.http.get(this.url + 'users/user/' + uid).pipe(
      map(data => (<any>data).userInfo)
    );
  }


  getRoles(dn: string){
    return this.http.get(this.url+'roles/user/'+dn).pipe(
      map(data => data)
    );
  }
}
