/**
 * Poseedores API
 * Poseedores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactoDTO } from './contactoDTO';
import { DireccionDTO } from './direccionDTO';
import { ResiduosGestionadosDTO } from './residuosGestionadosDTO';
import { GestoresResiduosEstadosDTO } from './gestoresResiduosEstadosDTO';


export interface GestoresResiduosGeneralPayloadDTO { 
    razonSocial: string;
    tipoDocumento: GestoresResiduosGeneralPayloadDTO.TipoDocumentoEnum;
    codigoDocumento: string;
    nima: string;
    centro: string;
    numeroAutorizacion?: string;
    numInscripcion1: string;
    numInscripcion2?: string;
    numInscripcion3?: string;
    fechaAltaFinalizada?: string;
    fechaBaja?: string;
    fechaBajaEnvanet?: string;
    gestoresResiduosEstado?: GestoresResiduosEstadosDTO;
    contactos?: Array<ContactoDTO>;
    domicilios?: Array<DireccionDTO>;
    residuosGestionados?: Array<ResiduosGestionadosDTO>;
    acuerdoEnvalora?: boolean;
}
export namespace GestoresResiduosGeneralPayloadDTO {
    export type TipoDocumentoEnum = 'NIF' | 'DNI' | 'NIE';
    export const TipoDocumentoEnum = {
        Nif: 'NIF' as TipoDocumentoEnum,
        Dni: 'DNI' as TipoDocumentoEnum,
        Nie: 'NIE' as TipoDocumentoEnum
    };
}


