/**
 * Puntos de recogida API
 * Puntos de recogida API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CnaeFilterDTO { 
    division?: Array<string>;
    grupo?: Array<string>;
    clase?: Array<string>;
    seccion?: Array<string>;
    descripcion?: Array<string>;
}

