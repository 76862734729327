<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">Nueva factura </h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <form class="form-modal py-3" [formGroup]="formGroup">
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="fechaFactura">Fecha factura <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="datepicker" formControlName="fechaFactura">
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker>
            </mat-datepicker>
            <mat-error *ngIf="controlHasError('fechaFactura', 'matDatepickerParse')">Formato incorrecto</mat-error>
            <mat-error *ngIf="controlHasError('fechaFactura', 'required') && !controlHasError('fechaFactura', 'matDatepickerParse')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="numeroFacturaPoseedor">Número factura <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="numeroFacturaPoseedor" id="numeroFacturaPoseedor"
                placeholder="Número factura" type="number" [min]="0">
                <mat-error *ngIf="controlHasError('numeroFacturaPoseedor', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="attach">
        <div>
          <button mat-button aria-labe="Adjuntar archivo" class="adjuntar-btn" type="button" (click)="file.click()"
            [disabled]="adjuntosBlob!.length>0">
            Adjuntar archivo
          </button>
          <label class="sr-only" for="adjuntar">Adjuntar archivo </label>
          <input #file type="file" (change)="file && file.files && attachAdjunto(file.files[0])" class="hide"
            id="adjuntar" name="adjuntar">
        </div>
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let archivo of adjuntosBlob"
            class="centrar archivo">
            <div><span class="sprite-icon ico-adjuntar"></span><span class="nombre-archivo">{{archivo?.name}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()" [disabled]="botonEstado"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
