<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Puntos de Recogida</li>
          <li *ngIf="flagRuta" class="breadcrumb-item"><a href="#/collection-points/manager-search">Búsqueda de Gestores
            </a>
          </li>
          <li class="breadcrumb-item active">Datos del Gestor del Punto de Recogida</li>
        </ol>
      </nav>
      <div class="d-flex content-title">
        <div class="d-flex">
          <h2 class="title-pag">Datos del Gestor del Punto de Recogida</h2>
          <div *ngIf="datosCargados" class="custom-container" [ngStyle]="{'background-color': (pendientePuntoRecogida ? '#fff3af' : pendienteEnvalora ? '#8edbfff5' :
                pendienteCompletado ?
                '#a4ffb0f5' :'#ffffff')}">
            <div>
              <label style="font-size: larger; padding-top: 5px;">
                <mat-icon *ngIf="textoEstado!=''" class="show-icon" [ngStyle]="{'color': (pendientePuntoRecogida ? '#eb1d1d' : pendienteEnvalora ? '#004a9a' :
                pendienteCompletado ?
                'darkgreen' :'initial')}">{{ pendienteCompletado ? 'check_circle' : 'warning_amber'}}</mat-icon>
              </label>
            </div>
            <div>
              <label class="info-texto-estado" [ngStyle]="{'color': (pendientePuntoRecogida ? '#eb1d1d' : pendienteEnvalora ? '#004a9a' :
                pendienteCompletado ?
                'darkgreen' :'initial')}">
                {{textoEstado}}</label>
            </div>
            <div *ngIf="this.puntoRecogidaInfo?.estado?.infoAdicional" style="padding-top: 8px;">
              <div class="tool-help" matTooltip={{this.puntoRecogidaInfo?.estado?.infoAdicional}}>
                <span>i</span>
              </div>
            </div>
          </div>
        </div>
        <div class="nota mt-2">Los campos señalados con asterisco <span class="oblig">*</span> son obligatorios
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->

<div class="container">
  <div class="card-ficha">
    <div class="card-body">
      <div class="ficha-2col">
        <div class="col-tab-list">
          <ul class="list">
            <li [ngClass]=" {'active':(selectedMenu===1)}"
              (click)="!canAccessContinuarAll(1) ? loadNumMenu(1) : mensajeValidacion()">
              1. Datos generales
            </li>
            <li [ngClass]="{'active':(selectedMenu===2)}"
              (click)="!canAccessContinuarAll(1) ? loadNumMenu(2) : mensajeValidacion()">
              2. Datos de contacto
            </li>
            <li [ngClass]="{'active':(selectedMenu===3)}"
              (click)="!canAccessContinuarAll(1) ? loadNumMenu(3) : mensajeValidacion()">
              3. Direcciones
            </li>
            <li *ngIf="estadoPuntoRecogida!>=2" [ngClass]="{'active':(selectedMenu===4)}" (click)="loadNumMenu(4)">
              4. Códigos LER gestionados
            </li>
            <li *ngIf="estadoPuntoRecogida!>=2" [ngClass]="{'active':(selectedMenu===5)}" (click)="loadNumMenu(5)">
              5. Puntos de Recogida gestionados
            </li>
            <li *ngIf="canAccessDocumentos() && estadoPuntoRecogida!>=3" [ngClass]="{'active':(selectedMenu===6)}"
              (click)="loadNumMenu(6)">
              6. Documentos
            </li>
          </ul>
        </div>
        <div *ngIf="selectedMenu===1" class="col-content">
          <app-collection-points-managers-general-data [puntoRecogidaInfo]="puntoRecogidaInfo"
            (refrescarPuntosRecogidaEvent)="refrescar(1)">
          </app-collection-points-managers-general-data>
        </div>
        <div *ngIf="selectedMenu===2" class="col-content">
          <app-collection-points-managers-contact-data [puntoRecogidaInfo]="puntoRecogidaInfo"
            (refrescarPuntosRecogidaEvent)="refrescar(2)">
          </app-collection-points-managers-contact-data>
        </div>
        <div *ngIf="selectedMenu===3" class="col-content">
          <app-collection-points-managers-address-data [puntoRecogidaInfo]="puntoRecogidaInfo"
            (refrescarPuntosRecogidaEvent)="refrescar(3)">
          </app-collection-points-managers-address-data>
        </div>
        <div *ngIf="selectedMenu===4" class="col-content">
          <app-collection-points-managers-codeLer-data [puntoRecogidaInfo]="puntoRecogidaInfo"
            (refrescarPuntosRecogidaEvent)="refrescar(4)">
          </app-collection-points-managers-codeLer-data>
        </div>
        <div *ngIf="selectedMenu===5" class="col-content">
          <app-collection-points-managers-data [puntoRecogidaInfo]="puntoRecogidaInfo"
            (refrescarPuntosRecogidaEvent)="refrescar(5)">
          </app-collection-points-managers-data>
        </div>
        <div *ngIf="selectedMenu===6" class="col-content">
          <app-collection-points-managers-documents [puntoRecogidaInfo]="puntoRecogidaInfo"
            (refrescarPuntosRecogidaEvent)="refrescar(6)">
          </app-collection-points-managers-documents>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="button-actions">
      <!-- Guardar y Continuar -->
      <button type="button" *ngIf="canAccessCancelar(selectedMenu)" class="btn btn-outline-primary"
        (click)="cancelar()">
        <div class="text-icon">Cancelar</div>
      </button>
      <button type="button" *ngIf="canAccessVolver()" class="btn btn-outline-primary" (click)="volver()">
        <div class="text-icon">Volver</div>
      </button>
      <button type="button" *ngIf="canAccessAnteriorAll(selectedMenu) && selectedMenu!==1"
        class="btn btn-outline-primary" (click)="anterior()">
        <div class="text-icon">Anterior</div>
      </button>
      <button type="button" *ngIf="canAccessContinuarAll(selectedMenu) || canAccessGuardar(selectedMenu)"
        class="btn btn-outline-primary">
        <div class="text-icon" (click)="guardar(selectedMenu)">{{continuarName(selectedMenu)}}<span
            *ngIf="continuarName(selectedMenu)!='Guardar'" class="icon-arrow-right"></span></div>
      </button>
      <button type="button" *ngIf="canAccessFinalizar(selectedMenu) && selectedMenu===3" class="btn btn-outline-primary"
        (click)="finalizar()">
        <div class="text-icon">Finalizar</div>
      </button>
    </div>
  </div>
