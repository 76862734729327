import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {SnackbarService} from '../../../services/snackbar.service';
import {SpinnerService} from '../../../services/spinner.service';
import {FormatCurrencyPipe} from '../../../share/components/pipe/FormatCurrency.pipe';
import {ParseCurrencyPipe} from '../../../share/components/pipe/ParseCurrency.pipe';
import {
  CodigoLerControllerService,
  TarifaPoseedoresControllerService,
  TarifaPoseedoresPayloadDTO,
} from 'api-rest';

@Component({
  selector: 'app-owner-rate-dialog',
  templateUrl: './owner-rate-dialog.component.html',
  styleUrls: ['./owner-rate-dialog.component.scss'],
})
export class OwnerRateDialogComponent implements OnInit {
  titulo: String = '';
  itemOpen: any;
  formGroup!: FormGroup;
  updateList: boolean = false;

  defaultFilter: boolean = true;
  codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?poseedores=true';

  descripcionEndpoint =
    this.environment.urlBackCore + 'api/v1/core/descripcion-envase';
  grupoEnvaseEndpoint =
    this.environment.urlBackCore + 'api/v1/core/grupoEnvase';

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OwnerRateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private TarifaPoseedoresControllerService: TarifaPoseedoresControllerService,
    private codigoLerControllerService: CodigoLerControllerService,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.itemOpen = this.data.itemOpen;
    this.formGroup = this.formBuilder.group({
      year: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            this.customValidator(),
          ]),
        },
      ],
      codigoLer: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      descripcionEnvase: [
        { value: null, disabled: false },
      ],
      peligrosidad: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      valorTarifa: [null, [Validators.required, this.decimalesValidator(3)]],
    });
  }

  ngOnInit() {
    this.formGroup.get('peligrosidad')?.disable();
    this.formGroup.get('codigoLer')?.valueChanges.subscribe((val) => {
      if (val) {
        this.formGroup.get('peligrosidad')?.setValue(val?.peligrosidad);
      } else {
        this.formGroup.get('peligrosidad')?.setValue(null);
      }
    });

    if (this.itemOpen) {
      this.titulo = 'Edición tarifa Poseedor ';
      this.cargarTarifa(this.itemOpen);
    } else {
      this.titulo = 'Nueva tarifa Poseedor';
    }
  }

  // this.formGroup.get('grupoEnvase')?.setValue(item?.grupoEnvase?.id);
  cargarTarifa(item: TarifaPoseedoresPayloadDTO | any) {
    this.formGroup.get('year')?.setValue(item?.anio);
    this.formGroup.get('codigoLer')?.setValue(item?.codigoLer?.tipoCodigo + ' - ' + item?.codigoLer?.descripcion);
    this.formGroup.get('descripcionEnvase')?.setValue(item?.descripcionEnvase?.id);
    this.formGroup.get('valorTarifa')?.setValue(this.formatCurrencyPipe.transform(item?.valorTarifa));
    this.formGroup.get('peligrosidad')?.setValue(item?.codigoLer?.peligrosidad);
  }

  customValidator() {
    return (control: { value: any }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (/^\d{4}$/.test(value)) {
        const intValue = parseInt(value, 10);
        if (intValue >= 1900 && intValue <= 2500) {
          return null;
        }
      }
      return { invalid: true };
    };
  }

  cerrar() {
    this.dialogRef.close(null);
  }

  guardar() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }

    // Si es un string es porque no he modificado el valor que me ha llegado del servicio
    const form = this.formGroup.value;
    let codigoLer = form.codigoLer;
    if (typeof codigoLer === 'string') {
      codigoLer = this.itemOpen?.codigoLer;
    } else {
      codigoLer = form.codigoLer;
    }

    const objeto: TarifaPoseedoresPayloadDTO = {
      anio: this.formGroup.get('year')?.value,
      codigoLer: codigoLer,
      descripcionEnvase: {},
      valorTarifa: this.parseCurrencyPipe.transform(
        this.formGroup.get('valorTarifa')?.value
      ),
    };

    if (this.itemOpen) {
      let body = Object.assign({ id: this.itemOpen?.id }, objeto);
      this.spinnerSrv.loadingShow();
      this.TarifaPoseedoresControllerService.editById(
        this.itemOpen?.id,
        body
      ).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'La tarifa se ha actualizado correctamente',
            'success'
          );
          this.dialogRef.close(true);
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    } else {
      this.spinnerSrv.loadingShow();
      this.TarifaPoseedoresControllerService.create(objeto).subscribe({
        next: () => {
          this.snackBarSrv.showSnackBar(
            'La tarifa se ha creado correctamente',
            'success'
          );
          this.spinnerSrv.loadingHide();
          this.dialogRef.close(true);
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  decimalesValidator(maxDecimales: number) {
    return (control: any) => {
      const value = control.value;

      if (!value || value === '') {
        return null;
      }

      // Eliminar los puntos para dejar solo las comas
      const cleanedValue = value.toString().replace(/\./g, '');
      const partes = cleanedValue.split(',');
      if (partes.length > 1 && partes[1].length > maxDecimales) {
        return { maxDecimales: true };
      }

      return null;
    };
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.formGroup.get('codigoLer') as FormControl;
        break;
      }
      case 'descripcionEnvase': {
        return this.formGroup.get('descripcionEnvase') as FormControl;
        break;
      }
      case 'grupoEnvase': {
        return this.formGroup.get('grupoEnvase') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }

  showClearButton(formName: string): boolean {
    return this.formGroup.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formGroup.get(formName)?.setValue(null);
  }

  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }

  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }
}
