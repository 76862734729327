import { NgModule } from '@angular/core';
import { EmpusaMicroAppType } from '@empusa/empusa-core';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class ApiRestModule { 
  name: string = "api-rest";
  description: string = "Librería de Api Rest"
  icon: string = "api-rest";
  homeApp: boolean = false;
  path: string = "api-rest";
  order: number = 1;
  resourceServerId: string = "keycloak-auth-manager-resources-server";
  appType: EmpusaMicroAppType = EmpusaMicroAppType.hidden;

  constructor() {
    //we register ourselves
    (<any>window).empusa(this);
    console.debug("Load microapp " + this.name);
  }
}
