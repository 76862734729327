import { Injectable } from "@angular/core";
import { KeycloakGroupModel } from "../keycloak-group.model";
import { Adapter } from "./keycloak.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakGroupModelAdapter implements Adapter<KeycloakGroupModel> {

    constructor() { }
    adapt(item: any): KeycloakGroupModel {
        let model:KeycloakGroupModel = {            
            id: item.id,
            name: item.name,
            subGroups: [],
            path: item.path
        };
        if (item.realmRoles){
            model.realmRoles=item.realmRoles
        }
        if (item.attributes){
            let attributesMap: Map<string, string[]> = new Map<string, string[]>();
            Object.keys(item.attributes).forEach(attributeId=>{                
                attributesMap.set(attributeId, item.attributes[attributeId])
            })
            if (Object.keys(item.attributes).length>0){
                model.attributesMap=attributesMap
            }
        }
        if (item.subGroups)
            item.subGroups.forEach((subgroup:any) => {
                model.subGroups!.push(this.adapt(subgroup))
            })
        return model;
    }
}