import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

   public showSnackBar(message: string, tipo: 'error' | 'success' | 'info' | 'warning' = 'info', duration: number = 15000): void {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.duration = duration;
    config.panelClass = [`${tipo}-snackbar`, 'snackbar-class'];
    this.snackBar.open(message, undefined, config);
  }
}
