import {
    Injectable,
    ViewContainerRef,
    Type
} from '@angular/core'

@Injectable()
export class ModuleInjectorService {
    //factoryResolver: ComponentFactoryResolver;
    rootViewContainer: ViewContainerRef | undefined;

    /*constructor(@Inject(ComponentFactoryResolver) factoryResolver:any) {
        this.factoryResolver = factoryResolver
    }*/
    constructor(private viewContainerRef: ViewContainerRef){
    }


    setRootViewContainerRef(viewContainerRef: ViewContainerRef | undefined) {
        this.rootViewContainer = viewContainerRef
    }

    addDynamicComponent(module: Type<unknown>) {
        if (this.rootViewContainer == undefined) return;
        /*const factory = this.factoryResolver
            .resolveComponentFactory(module)
        const component = factory
            .create(this.rootViewContainer.parentInjector)*/
        const component = this.viewContainerRef.createComponent(module);
        this.rootViewContainer.insert(component.hostView)
    }
}