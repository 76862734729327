import { Injectable } from '@angular/core';
import {
  Pageable,
  SolicitudRetiradaFilterDTO,
  SolicitudReutilizacionControllerService,
  SolicitudRetiradaControllerService,
  SolicitudReutilizacionFilterDTO
} from 'api-rest';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenSddrService {

  constructor(private solicitudReutilizacionCtrSrv: SolicitudReutilizacionControllerService,
              private solicitudRetiradaCtrSrv: SolicitudRetiradaControllerService) { }


  buscarSolicitudesReutilizacion(filtro: SolicitudReutilizacionFilterDTO, pageable: Pageable): Observable<any> {
    return new Observable<any>(observer => {
      this.solicitudReutilizacionCtrSrv.listado(filtro, pageable)
        .subscribe({
          next: (respuesta: any) => {
            //Se modifica la respuesta del servicio listado de solicitudes de justificación para pintar los datos de adherido o poseedor en la tabla de resultados
            respuesta.datos?.forEach((solicitud: any) => {
              if (solicitud?.adherido) {
                solicitud.tipoUsuario = 'Adherido';
                solicitud.razonSocial = solicitud?.adherido?.razonSocial;
                const domicilio = solicitud?.adherido?.domicilios?.find((x: any) => x?.tipoDireccion?.id == 5);
                solicitud.provincia = domicilio ? domicilio?.provincia?.nombre : '';
              } else if (solicitud?.poseedor) {
                solicitud.tipoUsuario = 'Poseedor';
                solicitud.razonSocial = solicitud?.poseedor?.razonSocial;
                const domicilio = solicitud?.poseedor?.domicilios?.find((x: any) => x?.tipoDireccion?.id == 5);
                solicitud.provincia = domicilio ? domicilio?.provincia?.nombre : '';
              }
            });
            observer.next(respuesta);
            observer.complete();
          },
          error: error => {
            observer.error(error);
            observer.complete();
          }
        });
    });
  }

  buscarSolicitudesRetirada(filtro: SolicitudRetiradaFilterDTO, pageable: Pageable): Observable<any> {
    return new Observable<any>(observer => {
      this.solicitudRetiradaCtrSrv.listado1(filtro, pageable)
        .subscribe({
          next: (respuesta: any) => {
            const actualizarSolicitud = (solicitud: any, tipoUsuario: string, entidad: any) => {
              solicitud.tipoUsuario = tipoUsuario;
              solicitud.razonSocial = entidad?.razonSocial;
              const domicilio = entidad?.domicilios?.find((x: any) => x?.tipoDireccion?.id === 5);
              solicitud.provincia = domicilio ? domicilio?.provincia?.nombre : '';
            };
            respuesta.datos?.forEach((solicitud: any) => {
              if (solicitud?.adherido) {
                actualizarSolicitud(solicitud, 'Adherido', solicitud.adherido);
              } else if (solicitud?.poseedor) {
                actualizarSolicitud(solicitud, 'Poseedor', solicitud.poseedor);
              }
            });
            observer.next(respuesta);
            observer.complete();
          },
          error: (error) => {
            observer.error(error);
            observer.complete();
          }
        });
    });

  }
}
