
<div style="text-align:center">
  <mat-form-field style="width: 60%; margin-top: 10px;">
    <mat-label>{{ "KAM-GENERAL-DIALOG.SEARCH" | translate }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder='{{ "KAM-GENERAL-DIALOG.SEARCH" | translate }}' #input>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="addNewUser()" style="float: right; margin: 10px;">
    <mat-icon>playlist_add</mat-icon>
    {{"KAM-USERS-TAB.NEW_USER" | translate}}
  </button>
</div>
<table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows class="kam-users-table">
  <ng-container matColumnDef="username">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"KAM-USERS-TAB.USER_NAME" | translate | uppercase}}</th>
    <td mat-cell *matCellDef="let element">{{ element.username }}</td>
  </ng-container>
  <ng-container matColumnDef="firstName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"KAM-USERS-TAB.NAME" | translate | uppercase}}</th>
    <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
  </ng-container>
  <ng-container matColumnDef="lastName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"KAM-USERS-TAB.LAST_NAME" | translate | uppercase}}</th>
    <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"KAM-USERS-TAB.EMAIL" | translate | uppercase}}</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>
  <ng-container matColumnDef="enabled">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{"KAM-USERS-TAB.ENABLED" | translate | uppercase}}</th>
    <td mat-cell *matCellDef="let element">{{ element.enabled }}</td>
  </ng-container>
  <!-- actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="expandedElement = expandedElement === element ? null : element">
        <mat-icon>
          {{ expandedElement === element ? "expand_less" : "expand_more" }}
        </mat-icon>
      </button>
      <button matTooltip="Update resource" mat-icon-button (click)="updateUser(element)">
        <mat-icon>mode_edit</mat-icon>
      </button>

      <button matTooltip="Update roles" mat-icon-button (click)="updateUserRoles(element)">
        <mat-icon>turned_in_not</mat-icon>
      </button>
      <button matTooltip="Update groups" mat-icon-button (click)="updateUserGroups(element)">
        <mat-icon>people_outline</mat-icon>
      </button>
      <button matTooltip="Update attributes" mat-icon-button *ngIf="hasAttributes"
        (click)="updateUserAttributes(element)">
        <mat-icon>edit_attributes</mat-icon>
      </button>
      <button matTooltip="Delete resource" mat-icon-button (click)="deleteUser(element)">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="user-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <!-- roles -->
        <div class="user-element-description not-exist" *ngIf="!element.data || element.data.roles.length == 0">
          <span>{{ "KAM-USERS-TAB.NO_ROLE" | translate }}</span>
          <br>
          <mat-icon>turned_in_not</mat-icon>
        </div>
        <div class="user-element-description" *ngIf="element.data && element.data.roles.length > 0">
          <span class="user-element-description-attribution">{{"KAM-USERS-TAB.ROLES" | translate}}: </span>
          <mat-list>
            <mat-list-item *ngFor="let role of element.data.roles">
              <mat-icon>turned_in_not</mat-icon>
              {{ role.name }} {{ role.description }}
            </mat-list-item>
          </mat-list>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <!-- grupos -->
        <div class="user-element-description not-exist" *ngIf="!element.data || element.data.groups.length == 0">
          <span>{{"KAM-USERS-TAB.NOT_BELONG_GROUP" | translate}}</span>
          <br>
          <mat-icon>people_outline</mat-icon>
        </div>
        <div class="user-element-description" *ngIf="element.data && element.data.groups.length > 0">
          <span class="user-element-description-attribution">{{"KAM-USERS-TAB.GROUPS" | translate}}: </span>
          <mat-list>
            <mat-list-item *ngFor="let group of element.data.groups">
              <mat-icon>people_outline</mat-icon>
              {{ group.name }}
            </mat-list-item>
          </mat-list>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <!-- atributos -->
        <div class="user-element-description not-exist" *ngIf="!element.attributesMap">
          <span>{{"KAM-USERS-TAB.NOT_ATTR" | translate}}</span>
          <br>
          <mat-icon>edit_attributes</mat-icon>
        </div>
        <div class="user-element-description" *ngIf="element.attributesMap">
          <span class="user-element-description-attribution">{{"KAM-USERS-TAB.ATRIBUTES" | translate}}: </span>
          <mat-list>
            <mat-list-item *ngFor="let attribute of element.attributesMap | keyvalue">
              <mat-icon>edit_attributes</mat-icon>
              {{ getAttributeDescription(attribute) | translate }}:
              {{ attribute.value }}
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="user-element-row"
    [class.user-expanded-row]="expandedElement === element"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="user-detail-row"></tr>
</table>
<mat-toolbar class="toolbar-bottom">
  <mat-toolbar-row>
    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50]" [pageSize]="10" [length]="usersCount" showFirstLastButtons
      (page)="loadListUser($event)" aria-label="Seleccionar página"></mat-paginator>
  </mat-toolbar-row>
</mat-toolbar>
