import { Component, Inject, OnInit } from '@angular/core';
import { EmpusaAppHeaderClassicInput, EmpusaAuthenticationService } from '@empusa/empusa-core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-hello-world',
  templateUrl: './hello-word.component.html',
  styleUrls: [ './hello-word.component.css']
})
export class HelloWorldComponent implements OnInit {

  headerData: EmpusaAppHeaderClassicInput ={
    icon: "language",
    text:"HELLO-WORLD.NAME"
  }
  canUpdate = this.authService.can("hello-world","update")
  canDelete = this.authService.can("hello-world","delete")
  constructor(public translate: TranslateService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    ) { }

  ngOnInit(): void {
    console.log("hello world update: ", this.authService.can("hello-world","update"));
    console.log("hello world delete: ", this.authService.can("hello-world","delete"));

  }
  

}
