export const locale_en = {
    "lang": "en",
    "data": {
        "KEYCLOAK-AUTH-MANAGER-MAIN": {
                NAME: "Identity Manager",
                USERS: "Users",
                ROLES: "Roles",
                GROUPS: "Groups",
                PERMISSIONS: "Permissions",
        },
        "KAM-USERS-TAB": {
            USER: "User",
            NEW_USER: "New user",
            NO_ROLE: "No role",
            USER_NAME: "User name",
            NAME: "Name",
            LAST_NAME: "Last name",
            EMAIL: "Email",
            ENABLED: "Enabled",
            PASSWORD: "Password",
            SET_PASSWORD: "Set password",
            REPEAT_PASSWORD: "Repeat password",
            PASSWORD_NOT_MATCH: "Password does not match",
            USER_DATA: "User data",
            ATRIBUTES: "Attributes",
            GROUPS: "Groups",
            ROLES: "Roles",
            RESUME: "Resume",
            NO_ROLES: "No roles",
            NOT_BELONG_GROUP: "No Groups",
            NOT_ATTR: "No attributes",
        },
        "KAM-ROLES-TAB": {
            NEW_ROLE:"New rol",
            NAME: "Name",
            DESCRIPTION: "Description",
            ACTION: "Actions",
            NO_PERMISSIONS:"No permissions",
            RESOURCE_SERVERS:"Resource Servers",
        },
        "KAM-PERMISSIONS-RESOURCES": {
            RESOURCE_SERVERS:"Resource Servers",
            RESOURCES: "Resources",
            SCOPES:"Scopes",
            PERMISSIONS:"Permissions",
        },
        "KAM-GENERAL-DIALOG": {
            TENANT: "Tenant",
            BUILDING: "Building",
            INTERNAL: "Internal",
            CANCEL: "Cancel",
            ACCEPT: "Accept",
            CLOSE: "Close",
            NAME: "Name",
            DESCRIPTION: "Description",
            ERROR: "Error",
            NEXT:"Next",
            BACK:"Back",
            CONFIRM:"Confirm",
            TRUE: "Yes",
            FALSE: "No",
            UPDATE:"Update",
            SEARCH:"Search",
        },
        "KAM-QUESTION-DIALOG": {
            DELETE_RESOURCE_TITLE: "Delete resource",
            DELETE_RESOURCE_QUESTION: "Delete {{resource}} resource?",
            DELETE_SCOPE_TITLE: "Delete action",
            DELETE_SCOPE_QUESTION: "Delete {{scope}} action?",
            DELETE_USER_TITLE: "Delete user",
            DELETE_USER_QUESTION: "Delete {{username}} user?",
            DELETE_GROUP_TITLE: "Delete group",
            DELETE_GROUP_QUESTION: "Delete {{groupname}} group?"
        },
        "KAM-ADD-DIALOG": {
            ADD_RESOURCE_TITLE:"Add resource",
            ADD_SCOPE_TITLE:"Add Scope",
            ADD_PERMISSIONS_TITLE:"Add Permission",
            ADD_USER_TITLE:"Añadir User",
            ADD_ROLE_TITLE:"Añadir Role",
            ADD_GROUP_TITLE:"Añadir Group",
        },
        "KAM-UPDATE-DIALOG": {
            UPDATE_USER_ROLES_TITLE: "Update user roles",
            UPDATE_USER_GROUPS_TITLE: "Update user groups",
            UPDATE_USER_ATTR_TITLE: "Update user attributes",
        },
        "KAM-MANAGE-DIALOG": {
            MANAGE_RESOURCE_TITLE: "Resource modification",
            MANAGE_SCOPE_TITLE: "Action modification",
            MANAGE_USER_TITLE: "User modification",
            MANAGE_GROUP_TITLE: "Group modification",
        },
        "KAM-ATTRIBUTES-FORM":{
            TYPE_STRING: "Text field",
            TYPE_NUMBER: "Numeric field",
            ATTRIBUTE_1_DESCRIPTION: "Stuff list",
            ATTRIBUTE_2_DESCRIPTION: "a number",
            ATTRIBUTE_NO_DESCRIPTION: "",
            ATTRIBUTE_IS_DEFAULT_HOST: "Default Host",
            ATTRIBUTE_GROUP_TYPE: "Group Type",
        },
        "KAM-GROUPS-TAB": {
            NAME_COLUMN: "Group",
            NEW_GROUP: "New group",
            ATTRIBUTES_LABEL: "Attributes: ",
            NO_ATTRIBUTES: "No attriburtes",
            ROLES_LABEL: "Roles: ",
            NO_ROLES: "No roles",
        },
        "KAM-MANAGE-GROUPS": {
            TITLE_NEW: "New group",
            TITLE_UPDATE: "Update {{groupname}} group",
            TITLE_INFO_GROUP: "Group information",
            TITLE_NEW_SUBGROUP: "New subgroup in {{parentpath}}",
            "STEP1":{
                TITLE:"Group Name",
                NAME:"Name",
                NAME_PLACEHOLDER:"Group name",
                NEXT:"Next"
            },
            "STEP2":{
                TITLE:"Attributes",
                BACK:"Back",
                NEXT:"Next"
            },
            "STEP3":{
                TITLE:"Roles",
                BACK:"Back",
                NEXT:"Next"
            },
            "STEP4":{
                TITLE:"Resume",
                NAME_LABEL:"Name",
                ATTRIBUTE_LABEL:"Attributes",
                ROLE_LABEL:"Roles",
                BACK:"Back",
                NEXT:"Accept"
            }
        },
        "KAM-ROLE-SELECTION": {
            TABLE_ROLE:"Role",
            TABLE_DESCRIPTION:"Description"
        }
    },
    
}
