import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import moment from 'moment';
import {
  TarifaPtoRecogidaControllerService,
  TarifaPtoRecogidaDTO,
  TarifaPtoRecogidaPayloadDTO,
} from 'api-rest';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';

@Component({
  selector: 'lib-collection-point-rates-dialog',
  templateUrl: './collection-point-rates-dialog.component.html',
  styleUrls: ['./collection-point-rates-dialog.component.scss'],
})
export class CollectionPointRatesDialogComponent implements OnInit {
  titulo: String = '';
  formGroup!: FormGroup;
  tarifa: any;
  defaultFilter: boolean = true;
  codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?ptosRecogida=true';
  updateList: boolean = false;
  flagDisabledForm: boolean = false;
  provinciaArray: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CollectionPointRatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private tarifaPtoRecogidaControllerSrv: TarifaPtoRecogidaControllerService,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.tarifa = this.data.tarifa;
    this.provinciaArray = this.data.provincias;

    this.formGroup = this.formBuilder.group({
      fechaDesde: [
        { value: null, disabled: false },
        {
          validators: [
            this.validacionFechas(),
            Validators.compose([Validators.required]),
          ],
        },
      ],
      fechaHasta: [
        { value: null, disabled: false },
        {
          validators: [
            this.validacionFechas(),
            Validators.compose([Validators.required]),
          ],
        },
      ],
      codigoLer: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      provincia: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      valorTarifa: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
  }
  ngOnInit(): void {
    if (this.tarifa) {
      this.titulo = 'Edición tarifa de Puntos de Recogida';
      this.cargarTarifa(this.tarifa);
    } else {
      this.titulo = 'Nueva tarifa de Puntos de recogida';
    }
  }
  cargarTarifa(tarifa: TarifaPtoRecogidaDTO): void {
    this.formGroup.get('fechaDesde')?.setValue(tarifa?.fechaDesde);
    this.formGroup.get('fechaHasta')?.setValue(tarifa?.fechaHasta);
    this.formGroup.get('codigoLer')?.setValue(tarifa?.codigoLer?.tipoCodigo + ' - ' + tarifa?.codigoLer?.descripcion);
    this.formGroup.get('provincia')?.setValue(tarifa?.provincia?.id);
    this.formGroup
      .get('valorTarifa')
      ?.setValue(this.formatCurrencyPipe.transform(tarifa?.valorTarifa));
  }

  cerrar() {
    this.dialogRef.close(null);
  }
  guardar(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }
    const form = this.formGroup.value;
    let body: TarifaPtoRecogidaPayloadDTO = {
      valorTarifa: this.parseCurrencyPipe.transform(form.valorTarifa),
    };

    // Si es un string es porque no he modificado el valor que me ha llegado del servicio
    let codigoLer = form.codigoLer;
    if (typeof codigoLer === 'string') {
      body.codigoLer = this.tarifa?.codigoLer;
    } else {
      body.codigoLer = form.codigoLer;
    }

    if (form.fechaDesde) {
      body.fechaDesde = moment(new Date(form.fechaDesde)).format('YYYY-MM-DD');
    }
    if (form.fechaHasta) {
      body.fechaHasta = moment(new Date(form.fechaHasta)).format('YYYY-MM-DD');
    }
    if (form.provincia) {
      body.provincia = this.provinciaArray.find((x) => x.id === form.provincia);
    }

    let request;
    let msg = '';
    // public createTarifaPtoRecogida(tarifaPtoRecogidaPayloadDTO: TarifaPtoRecogidaPayloadDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TarifaPtoRecogidaDTO>;
    if (this.tarifa) {
      body.id = this.tarifa.id;
      request = this.tarifaPtoRecogidaControllerSrv.editByIdTarifaPtoRecogida(
        this.tarifa.id,
        body
      );
      msg = 'La tarifa se ha actualizado correctamente';
    } else {
      request =
        this.tarifaPtoRecogidaControllerSrv.createTarifaPtoRecogida(body);
      msg = 'La tarifa se ha creado correctamente';
    }
    this.spinnerSrv.loadingShow();
    request.subscribe({
      next: () => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(msg, 'success');
        this.dialogRef.close(true);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }
  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }
  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.formGroup.get('codigoLer') as FormControl;
      }
      default: {
        return this.formGroup.get('') as FormControl;
      }
    }
  }
  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }
  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  validacionFechas(): ValidatorFn {
    return (controls: AbstractControl): ValidationErrors | null => {
      let fechaDesde;
      let fechaHasta;
      if (this.formGroup?.controls['fechaDesde']?.value) {
        fechaDesde = new Date(this.formGroup?.controls['fechaDesde']?.value);
      }
      if (this.formGroup?.controls['fechaHasta']?.value) {
        fechaHasta = new Date(this.formGroup?.controls['fechaHasta']?.value);
      }

      if (fechaHasta && fechaDesde) {
        if (fechaDesde <= fechaHasta) {
          return null;
        } else {
          return { fechas: controls.value };
        }
      }
      return null;
    };
  }
}
