import { NgModule } from '@angular/core';
import { EmpusaMicroAppType } from '@empusa/empusa-core';
import { EmpusaMicroApp } from '@empusa/empusa-core';
import { ComunicationsHomeModule } from './comunications.home.module';


@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class ComunicationsModule implements EmpusaMicroApp{
  name: string = "comunications";
  description: string = "comunications";
  icon: string = "language";
  page: any = ComunicationsHomeModule;
  homeApp: boolean = false;
  path: string = "comunications";
  order: number = 10;
  resourceServerId: string = "keycloak-auth-manager-resources-server";
  appType: EmpusaMicroAppType = EmpusaMicroAppType.home;

  constructor() {
    //we register ourselves
    (<any>window).empusa(this);    
    console.debug("Load microapp ", this.name);
  }
}
