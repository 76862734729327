import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InitialPageService {


  URL_ADHERIDOS: string = this.environment.urlBackAdheridos;
  URL_CORE: string = this.environment.urlBackCore;
  URL_DOCUM: string = this.environment.urlBackDocum;

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
  ) { }


  httpDownloadOptions: Object = {
    headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
    responseType: 'blob'
  }

  exportarAdheridoExcel(): Observable<any> {
    const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/report/xls`;
    return this.httpClient.get<any>(fullRoute, this.httpDownloadOptions);
  }
  exportarAdheridoPdf(): Observable<any> {
    const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/report/pdf`;
    return this.httpClient.get<any>(fullRoute, this.httpDownloadOptions);
  }
}
