import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EmpusaAuthenticationService,EmpusaGroupModel } from '@empusa/empusa-core';
import { database } from '../../fake/fake.database';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class CustomAuthService extends EmpusaAuthenticationService {
    refreshToken(): void {
        throw new Error('Method not implemented.');
    }
    getGroups(): Observable<EmpusaGroupModel[]> {
        throw new Error('Method not implemented.');
    }
    getGroupsDetails(groups: EmpusaGroupModel[]): Observable<EmpusaGroupModel[]> {
        throw new Error('Method not implemented.');
    }
    getGroupByName(groupName: string): Observable<EmpusaGroupModel[]> {
        throw new Error('Method not implemented.');
    }
    getUserPhoto(): Observable<any> {
        throw new Error('Method not implemented.');
    }
    loadPermissions(resourceServers: string[]): Observable<any> {
        throw new Error('Method not implemented.');
    }


    constructor(httpClient: HttpClient, private router: Router) {
        super(httpClient);
        this.roles = database.roles;
    }

    getCurrentUserName(): string {
        let login = "";
        let storedLogin =localStorage.getItem('curentLogin');
        if (storedLogin != null)
            login = storedLogin;
        return JSON.parse(login).username;        
    }

    getCurrentUserMail(): string {
        let login = "";
        let storedLogin =localStorage.getItem('curentLogin');
        if (storedLogin != null)
            login = storedLogin;
        return JSON.parse(login).mail;
    }

    getGroupMembership(): EmpusaGroupModel []{
        return []
      }



    _logout() {
        localStorage.removeItem('curentLogin');

        //redirect to login
        this.router.navigateByUrl('/login');
        return true; //:D
    }

}
