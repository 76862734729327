
import { createReducer, on } from '@ngrx/store';
import * as authManagementActions  from './auth-manager.actions';
import { AuthManagerState } from './auth-manager.model';

export const initialState: AuthManagerState = {
    clients:[],
    groups: [],
    loading: false,
    users:[],
    usersCount: 0,
    roles:[],
    selectedResourceServer: undefined,
};

export const authManagerReducer = createReducer(
  initialState,
  on(authManagementActions.RetrieveResourceServes, (state, action) => ({
    ...state,
    loading: true
  })),
  on(authManagementActions.StoreResourceServes, (state, action) => ({
    ...state,
    clients: action.clients,
  })),
  on(authManagementActions.AllData_StoreResourceServerSettings_Step2, (state, action) => ({
    ...state,
    clients: action.clients,
  })),
  
  on(authManagementActions.RetrieveUsers, (state, action) => ({
    ...state,    
    loading: true
  })),
  on(authManagementActions.StoreUsers, (state, action) => ({
    ...state,
    users: action.users,
    usersCount: action.usersCount,
    loading: false
  })),

  on(authManagementActions.RetrieveRoles, (state, action) => ({
    ...state,    
    loading: true
  })),
  on(authManagementActions.StoreRoles, (state, action) => ({
    ...state,
    roles: action.roles,
    loading: false
  })),

  on(authManagementActions.RetrieveGroups, (state, action) => ({
    ...state,    
    loading: true
  })),
  on(authManagementActions.StoreGroupMembers, (state, action) => ({
    ...state,
    groups: action.groups,
    loading: false
  })),
  on(authManagementActions.storeAllData, (state, action) => ({
    ...state,
    groups: action.groups,
    loading: false,
    users: action.users,
    clients: action.clients,
    roles: action.roles,
  })),
  on(authManagementActions.retrieveUsers, (state, action) => ({
    ...state,
    loading: true
  })),
  on(authManagementActions.storeUsers, (state, action) => ({
    ...state,
    loading: false,
    users:action.users,
  })),

  on(authManagementActions.setSelectedResourceServer, (state, action) => ({
    ...state,
    selectedResourceServer:action.selectedResourceServer,
  })),
);