<!-- <div class="container-text-center">
    <div class="bg-second justify-content-center mt-5">

    </div>
  </div> -->



<div class="bg-second-initial">
  <div class="container-initial mt-cab-fixed jus">
    <span class="italic-text ">Seleccione una opción en el menú superior...</span>

    <!-- <span class="italic-text-frase">" <b>Envanet</b>, nos comprometemos a facilitar y optimizar la gestión de residuos
      de
      envases
      para productores y recicladores, asegurando el cumplimiento de la Responsabilidad Ampliada del Productor (RAP).
      Nuestra plataforma ofrece herramientas avanzadas para el seguimiento, la clasificación y el reciclaje de
      envases, promoviendo la sostenibilidad y la economía circular. "</span> -->
  </div>

</div>




<!-- FIN CONTENIDO -->
