/**
 * Comunicaciones API
 * Comunicaciones API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdEntityFilterDTO } from './idEntityFilterDTO';


export interface ComunicacionFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    email?: string;
    tipo?: ComunicacionFilterDTO.TipoEnum;
    estado?: IdEntityFilterDTO;
    prioridad?: ComunicacionFilterDTO.PrioridadEnum;
    categoria?: ComunicacionFilterDTO.CategoriaEnum;
    fechaIncidencia?: Array<string>;
    asunto?: Array<string>;
}
export namespace ComunicacionFilterDTO {
    export type TipoEnum = 'INCIDENCIA' | 'SUGERENCIA' | 'RECLAMACION';
    export const TipoEnum = {
        Incidencia: 'INCIDENCIA' as TipoEnum,
        Sugerencia: 'SUGERENCIA' as TipoEnum,
        Reclamacion: 'RECLAMACION' as TipoEnum
    };
    export type PrioridadEnum = 'BAJA' | 'MEDIA' | 'ALTA';
    export const PrioridadEnum = {
        Baja: 'BAJA' as PrioridadEnum,
        Media: 'MEDIA' as PrioridadEnum,
        Alta: 'ALTA' as PrioridadEnum
    };
    export type CategoriaEnum = 'TECNICA' | 'FUNCIONAL' | 'FUNCIONAL_Y_TECNICA';
    export const CategoriaEnum = {
        Tecnica: 'TECNICA' as CategoriaEnum,
        Funcional: 'FUNCIONAL' as CategoriaEnum,
        FuncionalYTecnica: 'FUNCIONAL_Y_TECNICA' as CategoriaEnum
    };
}


