import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  AportacionDIEstadoControllerService
} from 'api-rest';
import { Subscription, map } from 'rxjs';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';

@Component({
  selector: 'app-holders-manager-selection-dialog',
  templateUrl: './manager-selection-dialog.component.html',
  styleUrls: ['./manager-selection-dialog.component.scss'],
})
export class ManagerSelectionDialogComponent implements OnInit {
  adheridoArray: any[] = [];
  entityRoles: any[] = [];

  modelForm: FormGroup;
  subscriptions: Subscription[] = [];
  roleEntities: any[] = [];

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private dialogRef: MatDialogRef<ManagerSelectionDialogComponent>,
    private estadoAportacionesService: AportacionDIEstadoControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.modelForm = this.formBuilder.group({
      fechaAceptacion: [{ value: null, disabled: false }],
      adherido: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.adheridoArray = this.data.adheridoArray;
    this.estadoAportacionesService
      .findAll14()
      .pipe(map((estados) => estados.find((estado) => estado.id === 1)))
      .subscribe((estado) => {
        if (estado) {
          this.modelForm.get('estado')?.setValue(estado);
        }
      });
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

  seleccionar() {
    if (this.canSelectionAdherido()) {
      this.dialogRef.close(this.modelForm.value);
    } else {
      this.snackBarSrv.showSnackBar('Debe seleccionar un Adherido', 'error');
    }
  }

  showClearButton(formName: string): boolean {
    return this.modelForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.modelForm.get(formName)?.setValue(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.modelForm.controls[controlName].hasError(errorName) &&
      this.modelForm.controls[controlName].touched
    );
  }

  canSelectionAdherido(): boolean {
    return this.modelForm.get('adherido')?.value != null;
  }

  canSelectionGestor(): boolean {
    return this.modelForm.get('gestorResiduo')?.value != null;
  }
}
