<div style="text-align:center">
  <mat-form-field style="width: 60%; margin-top: 10px;">
    <mat-label>{{ "KAM-GENERAL-DIALOG.SEARCH" | translate }}</mat-label>
    <input matInput (keyup)="filterChanged($event)" placeholder='{{ "KAM-GENERAL-DIALOG.SEARCH" | translate }}' #input>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="addNewGroup()" style="float: right; margin: 10px;">
    <mat-icon>playlist_add</mat-icon>
    {{'KAM-GROUPS-TAB.NEW_GROUP' | translate}}
  </button>
</div>
<mat-tree [dataSource]="groupDataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled></button>
    {{node.name.name}}
    <button matTooltip="Update resource" style="margin-left: 40px;" mat-icon-button (click)="showGroupData(node)">
      <mat-icon>description</mat-icon>
    </button>
    <button matTooltip="Update resource" mat-icon-button (click)="addSubgroup(node)">
      <mat-icon>addSubgroup</mat-icon>
    </button>
    <button matTooltip="Update resource"  mat-icon-button (click)="updateGroup(node)">
      <mat-icon>mode_edit</mat-icon>
  </button>
  <button matTooltip="Delete resource" mat-icon-button (click)="deleteGroup(node)">
      <mat-icon>delete_outline</mat-icon>
  </button>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    {{node.name.name}}
    <button matTooltip="Update resource" style="margin-left: 40px;" mat-icon-button (click)="showGroupData(node)">
      <mat-icon>description</mat-icon>
    </button>
    <button matTooltip="Update resource" mat-icon-button (click)="addSubgroup(node)">
      <mat-icon>addSubgroup</mat-icon>
    </button>
    <button matTooltip="Update resource" mat-icon-button (click)="updateGroup(node)">
      <mat-icon>mode_edit</mat-icon>
    </button>
    <button matTooltip="Delete resource" mat-icon-button (click)="deleteGroup(node)">
        <mat-icon>delete_outline</mat-icon>
    </button>
  </mat-tree-node>
</mat-tree>
<mat-toolbar class="toolbar-bottom">
  <mat-toolbar-row>
    <mat-paginator [pageSizeOptions]="[10, 20, 40]" [pageSize]="10" 
    #paginatorGroups="matPaginator" 
    showFirstLastButtons 
    aria-label="Seleccionar página"></mat-paginator>
  </mat-toolbar-row>
</mat-toolbar>