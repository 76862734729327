<form [formGroup]="attributesFormGroup" *ngIf="attributesFormGroup">
  <div *ngFor="let attribute of attributeList">
    <div *ngIf="attribute.type !== 'enum'">
      <mat-form-field appearance="fill">
        <mat-label style="text-align:left">{{ attribute.description | translate }}
          {{ attribute.indexInList }}</mat-label>
        <input matInput placeholder="{{ attribute.description | translate }} {{
            'KAM-ATTRIBUTES-FORM.TYPE_' + attribute.type.toUpperCase()
              | translate
          }}" formControlName="{{ attribute.controlName }}" />
        <span matSuffix *ngIf="attribute.isList">
          <button mat-raised-button color="primary" *ngIf="attribute.isLastInList && attribute.indexInList > 1"
            (click)="removeControl(attribute)">
            <mat-icon>remove</mat-icon>
          </button>
          <button mat-raised-button color="primary" *ngIf="attribute.isLastInList" (click)="addControl(attribute)">
            <mat-icon>add</mat-icon>
          </button>
        </span>
      </mat-form-field>
    </div>
    <div *ngIf="attribute.type == 'enum'" class="selects">
      <mat-label style="text-align:left">{{ attribute.description | translate }}
        {{ attribute.indexInList }}:</mat-label>
      <mat-radio-group aria-label="Select an option" formControlName="{{ attribute.controlName }}">
        <mat-radio-button class="kam-attribute-radio-button" color="primary" *ngFor="let value of attribute.values"
          [checked]="radioChecked(value, attribute.controlName)" [value]="value">
          {{ "KAM-GENERAL-DIALOG." + value | uppercase | translate }}
        </mat-radio-button>
      </mat-radio-group>
      <span matSuffix *ngIf="attribute.isList">
        <button mat-raised-button color="primary" *ngIf="attribute.isLastInList && attribute.indexInList > 1"
          (click)="removeControl(attribute)">
          <mat-icon>remove</mat-icon>
        </button>
        <button mat-raised-button color="primary" *ngIf="attribute.isLastInList" (click)="addControl(attribute)">
          <mat-icon>add</mat-icon>
        </button>
      </span>
    </div>
  </div>
  <br />
</form>
