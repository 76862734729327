<div>
    <h1 mat-dialog-title>
        <mat-icon class="icon" style="vertical-align: middle;">turned_in_not</mat-icon>
        {{"KAM-UPDATE-DIALOG.UPDATE_USER_ATTR_TITLE" | translate}}
    </h1>

    <div mat-dialog-content>
        <keycloak-auth-manager-kam-attributes-form *ngIf="userAttributes"
        [customAttributesRequest]="userAttributes"
        [initialAttributesValues]="initialAttributesValues"
        (customAttributesValues)="setCustomAttributesValues($event)"
        (customAttributesValuesAreValid)="setCustomAttributesValuesAreValid($event)">
    </keycloak-auth-manager-kam-attributes-form>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="cancel()">{{'KAM-GENERAL-DIALOG.CANCEL'| translate}}</button>
        <button mat-raised-button color="primary" (click)="updateAttributes()" >{{'KAM-GENERAL-DIALOG.ACCEPT'| translate}}</button>
    </div>
</div>