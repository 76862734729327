import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NextPageService {
  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) {}

  private tempNextSearch: boolean = false;

  get getTempNextSearch() {
    return this.tempNextSearch;
  }

  setTempNextSearch(data: any) {
    this.tempNextSearch = data;
  }
}
