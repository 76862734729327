import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TarifaAdheridoSddrAbiertoControllerService,
  TarifaAdheridoSDDRAbiertoPayloadDTO,
  TipoEnvasesDTO,
  CategoriaEnvaseDTO,
  CategoriaEnvaseControllerService,
  TarifaAdheridoSDDRAbiertoDTO,
} from 'api-rest';
import { SnackbarService } from '../../../services/snackbar.service';
import { AttachedRateOpenSearchComponent } from '../attached-rate-open-search/attached-rate-open-search.component';
import { SpinnerService } from '../../../services/spinner.service';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';

@Component({
  selector: 'app-rate-open-dialog',
  templateUrl: './rate-open-dialog.component.html',
  styleUrls: ['./rate-open-dialog.component.scss'],
})
export class RateOpenDialogComponent implements OnInit {
  titulo: String = '';
  itemOpen: any;
  modelForm: FormGroup;
  updateList: boolean = false;
  categorias: CategoriaEnvaseDTO[] = [];
  tipoEnvases: TipoEnvasesDTO[] = [];
  tipoEnvase!: TipoEnvasesDTO;
  categoria!: CategoriaEnvaseDTO;
  tipoEnvasesEndpoint =
    this.environment.urlBackCore + 'api/v1/core/tipoEnvases';
  defaultFilter: boolean = true;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AttachedRateOpenSearchComponent>,
    private attachedOpenService: TarifaAdheridoSddrAbiertoControllerService,
    private categoriaEnvaseControllerService: CategoriaEnvaseControllerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.itemOpen = this.data.itemOpen;
    this.modelForm = this.formBuilder.group({
      anio: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            this.customValidator(),
          ]),
        },
      ],
      categoria: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      tipoEnvase: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      tipologia: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      peligrosidad: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      eurosUnidad: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      depositoUnidad: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
  }

  ngOnInit() {
    this.cargarCombos();
    if (this.itemOpen) {
      this.titulo = 'Edición tarifa envases SDDR Abierto ';
      this.cargarTarifaOpen(this.itemOpen);
    } else {
      this.titulo = 'Nueva tarifa envases SDDR Abierto';
      //this.tipo ='nueva';
    }
  }

  cargarTarifaOpen(itemOpen: TarifaAdheridoSDDRAbiertoDTO) {
    this.tipoEnvase = this.itemOpen.tipoEnvases;
    this.categoria = this.itemOpen.categoria;
    this.modelForm.get('anio')?.setValue(itemOpen?.anio),
      this.modelForm.get('categoria')?.setValue(itemOpen?.categoria?.id),
      this.modelForm
        .get('tipoEnvase')
        ?.setValue(itemOpen?.tipoEnvases?.descripcion),
      this.modelForm.get('tipologia')?.setValue(itemOpen?.tipologia),
      this.modelForm.get('peligrosidad')?.setValue(itemOpen?.peligrosidad);
    this.modelForm
      .get('eurosUnidad')
      ?.setValue(this.formatCurrencyPipe.transform(itemOpen?.eurosUnidad));
    this.modelForm
      .get('depositoUnidad')
      ?.setValue(this.formatCurrencyPipe.transform(itemOpen?.depositoUnidad));
  }

  cargarCombos() {
    this.categoriaEnvaseControllerService
      .listarCategoriaEnvases()
      .subscribe((response) => {
        this.categorias = response;
      });
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  guardar() {
    if (this.modelForm.invalid) {
      this.modelForm.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }

    let tipoEnvase = this.modelForm.get('tipoEnvase')?.value;

    const objeto: TarifaAdheridoSDDRAbiertoPayloadDTO = {
      anio: this.modelForm.get('anio')?.value,
      categoria: {
        id: this.modelForm.get('categoria')?.value,
        descripcion: this.categoria?.descripcion,
      },
      tipoEnvases:
        typeof tipoEnvase === 'string'
          ? this.tipoEnvase
          : this.modelForm.get('tipoEnvase')?.value,
      tipologia: this.modelForm.get('tipologia')?.value,
      peligrosidad: this.modelForm.get('peligrosidad')?.value,
      eurosUnidad: this.parseCurrencyPipe.transform(
        this.modelForm.get('eurosUnidad')?.value
      ),
      depositoUnidad: this.parseCurrencyPipe.transform(
        this.modelForm.get('depositoUnidad')?.value
      ),
    };

    if (this.itemOpen) {
      let body = Object.assign({ id: this.itemOpen?.id }, objeto);
      this.spinnerSrv.loadingShow();
      this.attachedOpenService.editById2(this.itemOpen?.id, body).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'La tarifa se ha actualizado correctamente',
            'success'
          );
          this.dialogRef.close(true);
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    } else {
      this.spinnerSrv.loadingShow();
      this.attachedOpenService.create2(objeto).subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
        complete: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  customValidator() {
    return (control: { value: any }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (/^\d{4}$/.test(value)) {
        const intValue = parseInt(value, 10);
        if (intValue >= 1900 && intValue <= 2500) {
          return null;
        }
      }
      return { invalid: true };
    };
  }

  filterList(selectForm: any) {
    switch (selectForm) {
      case 'tipoEnvase': {
        return this.modelForm.get('tipoEnvase') as FormControl;
      }
      default: {
        return this.modelForm.get('') as FormControl;
      }
    }
  }

  selectedModel(selectForm: any) {
    switch (selectForm) {
      case 'tipoEnvase': {
        break;
      }
      default: {
        break;
      }
    }
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'tipoEnvase': {
        return this.modelForm.get('tipoEnvase') as FormControl;
      }
      default: {
        return this.modelForm.get('') as FormControl;
      }
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.modelForm.controls[controlName].hasError(errorName) &&
      this.modelForm.controls[controlName].touched
    );
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.modelForm.controls[controlName]?.touched ||
        this.modelForm.controls[controlName]?.dirty) &&
      this.modelForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
}
