import { Component, Inject, OnInit } from '@angular/core';
import { Router, UrlHandlingStrategy } from '@angular/router';
import {
  Adherido,
  Cnae,
  Domicilio,
  listAdheridos,
} from '../../../models/adherido';
import { AttachedService } from '../../../services/attached.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreliminaryRegistrationComponent } from '../preliminary-registration/preliminary-registration.component';
import { DownloadService } from '../../../services/download.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { Combo } from '../../../models/combo';
import { CombosService } from '../../../services/combos.service';
import { Tramo_cuota } from '../../../models/tramo_cuota';
import { zip } from 'rxjs';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  ContratoControllerService,
  FacturaAdheridoControllerService,
} from 'api-rest';

@Component({
  selector: 'lib-attached-search',
  templateUrl: './attached-search.component.html',
  styleUrls: ['./attached-search.component.scss'],
})
export class AttachedSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  tiposDocumento: Combo[] = [];
  cifrasNegocio: Tramo_cuota[] = [];
  estados: any[] = [];
  headArray: any = [
    {
      Head: 'Id. Adherido',
      FieldName: 'id',
    },
    {
      Head: 'Documento',
      FieldName: 'codigoDocumento',
    },
    {
      Head: 'Razón social',
      FieldName: 'razonSocial',
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'Grupo empresarial',
      FieldName: 'nombreGrupo',
    },
    {
      Head: 'Nº registro de productor',
      FieldName: 'registroProductor',
    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcion_corta',
      Tooltip: true,
    },
    {
      Head: 'CNAE principal',
      FieldName: 'cnae1',
      Attribute: 'descripcion',
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'CNAE secundario',
      FieldName: 'cnae2',
      Attribute: 'descripcion',
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'Comunidad autónoma',
      FieldName: 'domicilios',
      renderValue: (items: { domicilios: Domicilio[] }) => {
        const domicilioSocial = items.domicilios.find(
          (domicilio) => domicilio.tipoDireccion?.descripcion === 'Social'
        );
        return domicilioSocial?.provinciaRef?.comunidad?.nombre;
      },
    },
    {
      Head: 'Cifra de negocio',
      FieldName: 'cifra_de_negocio',
      Attribute: 'descripcion',
    },
    {
      Head: 'Tipo de uso',
      FieldName: 'tipoUsoEnvasesTabla',
      Tooltip: true,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        // { 'nameButton': "edit", "icon": "edit_square", 'toolTip': 'Editar', 'show': { 'params': 'canAccessListEdit', 'accion': 'edit' } },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'content_paste',
              name: 'Generar contrato',
              show: {
                params: 'canAccessUpdatedContact',
                accion: 'content_paste',
              },
            },
            // {
            //   nameButton: 'receipt_long',
            //   name: 'Generar factura proforma',
            //   show: { params: 'canAccessReceiptLong', accion: 'receipt_long' },
            // },
            {
              nameButton: 'cancel',
              name: 'Baja de adherido',
              show: { params: 'canAccessCancel', accion: 'cancel' },
            },
          ],
        },
      ],
      width: '12',
      permanent: true,
    },
  ];

  gridArray: Adherido[] = [];

  listDocumentType: any[] = [];

  searchForm: FormGroup;

  selectModelCnae: any = {
    id: null,
    iso: null,
    nombre: null,
  };

  updateList: boolean = false;
  cnaeEndpoint = this.environment.urlBackCore + 'api/v1/core/cnae';
  comunidadesEndpoint =
    this.environment.urlBackCore + 'api/v1/core/communities';
  flagDisabledForm: boolean = false;
  totalSearch: any = 0;

  nameMappings: any = [
    { id: 'UN_SOLO_USO', name: 'Un solo uso' },
    { id: 'REUTILIZABLE', name: 'Envase reutilizable' },
    { id: 'UN_SOLO_USO_REUTILIZABLE', name: 'Un solo uso y reutilizable' },
  ];

  constructor(
    private attachedService: AttachedService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private downloadSrv: DownloadService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    public comboSrv: CombosService,
    private snackBarSrv: SnackbarService,
    private facturaService: FacturaAdheridoControllerService,
    private contratoController: ContratoControllerService
  ) {
    this.searchForm = this.formBuilder.group({
      identificador: [{ value: null, disabled: false }],
      documentType: [{ value: null, disabled: false }],
      numRegistro: [{ value: null, disabled: false }],
      nombreGrupo: [{ value: null, disabled: false }],
      autonomia: [{ value: null, disabled: false }],
      document: [{ value: null, disabled: false }],
      rsocial: [{ value: null, disabled: false }],
      cifra: [{ value: null, disabled: false }],
      cnae: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
    });
  }

  getCneaFormControl(): FormControl {
    return this.searchForm.get('cnae') as FormControl;
  }

  abrirPantalla(id: number): void {
    this.attachedService.setTempRutaSearch(true);
    this.attachedService.searchForm = this.searchForm;
    this.router.navigate(['/attached-app/detail', id]);
  }

  ngOnInit(): void {
    this.cargarCombos();
    if (this.attachedService.searchForm)
      this.searchForm = this.attachedService.searchForm;
  }

  cargarCombos(): void {
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    zip(
      this.attachedService.getEstadosAdheridos(),
      this.attachedService.getTramos_cuota()
    ).subscribe({
      next: (responses: any[]) => {
        console.log(responses);
        this.estados = responses[0].content;
        this.cifrasNegocio = responses[1].content;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getCnaDescription(cnae: Cnae) {
    return cnae.clase + ' - ' + cnae.descripcion;
  }

  getCnaeObjectQuery(searchterm: string) {
    return {
      descripcion: `or(ci(${searchterm}))`,
      clase: `or(ci(${searchterm}))`,
    };
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  checked(item: any) {}

  action(item: any) {
    if (item.nameButton == 'view') {
      this.abrirPantalla(item.id);
    }
    if (item.nameButton == 'edit') {
      this.abrirPantalla(item.id);
    }
    if (item.nameButton == 'cancel') {
      this.attachedService
        .bajaAdherido(item.id)
        .pipe()
        .subscribe({
          next: (value: any) => {},
          error: (err: { message: any }) => {},
        });
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    } else if (item.nameButton == 'content_paste') {
      this.spinnerSrv.loadingShow();
      this.contratoController.generarContrato(item.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.searchData();
          this.snackBarSrv.showSnackBar(
            'El contrato del Adherido ha sido generado correctamente. Se le ha enviado un email al Adherido con instrucciones para la firma del mismo.',
            'success'
          );
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          if (err?.error?.detail) {
            this.snackBarSrv.showSnackBar(err?.error?.detail, 'error');
          } else {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error durante la generación del contrato del Adherido',
              'error'
            );
          }
        },
      });
    } else if (item.nameButton == 'receipt_long') {
      this.spinnerSrv.loadingShow();
      this.facturaService.generarFactura(item.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha generado la factura proforma correctamente',
            'success'
          );
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          if (err?.error?.detail) {
            this.snackBarSrv.showSnackBar(err?.error?.detail, 'error');
          } else {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al generar la factura proforma',
              'error'
            );
          }
        },
      });
    }
  }

  openModalPreliminaryRegistration() {
    const dialogRef = this.dialog.open(PreliminaryRegistrationComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getAdheridos(
    identificador?: string,
    numRegistro?: string,
    nombreGrupo?: string,
    autonomia?: string,
    documentType?: string,
    document?: string,
    rsocial?: string,
    cifra?: string,
    cnae?: string,
    estado?: string
  ) {
    const pageNumber = this.pageNumber < 0 ? 0 : this.pageNumber;
    this.attachedService
      .getAdheridos(
        pageNumber,
        this.pageSize,
        identificador,
        numRegistro,
        nombreGrupo,
        autonomia,
        documentType,
        document,
        rsocial,
        cifra,
        cnae,
        estado
      )
      .subscribe({
        next: (data: listAdheridos) => {
          if (data) {
            this.gridArray = this.canAccessAll(data.content);
            this.flagSearch = true;
            this.totalSearch = this.gridArray.length;
            this.totalElements = data.totalElements;
          }
        },
        complete: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  loadLocationData() {
    this.attachedService
      .getTiposCnae()
      .pipe()
      .subscribe({
        next: (data: any) => {
          this.listDocumentType = data.datos;
        },
      });
  }

  searchData() {
    this.spinnerSrv.loadingShow();
    let filtro = this.getAdheridosFilter();
    this.getAdheridos(
      filtro?.identificador,
      filtro?.registroProductor,
      filtro?.nombreGrupo,
      filtro?.comunidadAutonoma,
      filtro?.documentType,
      filtro?.codigoDocumento,
      filtro?.razonSocial,
      filtro?.cifraNegocio,
      filtro?.cnae,
      filtro?.estado
    );
  }

  private getAdheridosFilter() {
    let identificador = this.searchForm.controls['identificador'].value;
    let registroProductor = this.searchForm.controls['numRegistro'].value;
    let nombreGrupo = this.searchForm.controls['nombreGrupo'].value;
    let comunidadAutonoma = this.searchForm.controls['autonomia'].value;
    if (comunidadAutonoma) comunidadAutonoma = comunidadAutonoma.id;
    let documentType = this.searchForm.controls['documentType'].value;
    let codigoDocumento = this.searchForm.controls['document'].value;
    let razonSocial = this.searchForm.controls['rsocial'].value;
    let cifraNegocio = this.searchForm.controls['cifra'].value;
    let cnae = this.searchForm.controls['cnae'].value;
    if (cnae) cnae = cnae.descripcion;
    let estado = this.searchForm.controls['estado'].value;

    return {
      identificador,
      registroProductor,
      nombreGrupo,
      comunidadAutonoma,
      documentType,
      codigoDocumento,
      razonSocial,
      cifraNegocio,
      cnae,
      estado,
    };
  }

  clearSearcher() {
    this.searchForm.reset();
  }
  exportarCsv() {
    const textFilter = (value: any) =>
      value?.trim() ? `ci(${value?.trim()})` : undefined;

    let filter = this.getAdheridosFilter();
    filter.identificador = textFilter(filter.identificador);
    filter.registroProductor = textFilter(filter.registroProductor);
    filter.codigoDocumento = textFilter(filter.codigoDocumento);
    filter.razonSocial = textFilter(filter.razonSocial);
    filter.nombreGrupo = textFilter(filter.nombreGrupo);

    this.attachedService.exportCSV(filter, 'response').subscribe({
      next: (response) => {
        const filename = this.downloadSrv.getFileName(
          response.headers,
          'Exportacion_Adheridos.csv'
        );
        this.downloadSrv.downloadCSV(response.body!, filename!);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  exportarPdf() {
    this.attachedService.exportarAdheridoPdf().subscribe({
      next: (response) => {
        this.downloadSrv.downloadBlob(response, 'adheridos.pdf');
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  public filterList(data: any) {}

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'autonomia': {
        return this.searchForm.get('autonomia') as FormControl;
        break;
      }
      case 'cnae': {
        return this.searchForm.get('cnae') as FormControl;
        break;
      }
      default: {
        return this.searchForm.get('') as FormControl;
        break;
      }
    }
  }

  selectedModel() {}

  selectedModelCnae() {}

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  canAccessPreliminaryDischarge(): boolean {
    return this.authService.can('adherido', 'create');
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('adherido', 'view');
    const resultListEdit = this.authService.can('adherido', 'update');
    const resultDelete = this.authService.can('adherido', 'delete');
    const resultUpdatedContact = this.authService.can(
      'adherido',
      'update-contract'
    );
    const resultReceiptLong = this.authService.can('adherido', 'update-bill');
    const resultCancel = this.authService.can('adherido', 'update-unregister');

    const listPermission = list.map((objeto: any) => {
      const estadoDelete =
        objeto.estado && [1, 2, 3, 8].includes(objeto.estado.id);
      const estadoUpdatedContact =
        objeto.estado && (objeto.estado.id === 2 || objeto.estado.id === 8);
      const estadoReceiptLong = objeto.estado && objeto.estado.id === 4;
      const estadoCancel = objeto.estado && objeto.estado.id === 6;

      const nameMapping = this.nameMappings.find(
        (mapping: any) => mapping.id === objeto.tipoUsoEnvases
      );

      if (nameMapping) {
        return {
          ...objeto,
          tipoUsoEnvasesTabla: nameMapping.name,
          canAccessView: resultView,
          canAccessListEdit: resultListEdit,
          canAccessDelete: estadoDelete && resultDelete,
          canAccessUpdatedContact: estadoUpdatedContact && resultUpdatedContact,
          // canAccessReceiptLong: estadoReceiptLong && resultReceiptLong,
          canAccessCancel: estadoCancel && resultCancel,
        };
      } else {
        return {
          ...objeto,
          tipoUsoEnvasesTabla: null,
          canAccessView: resultView,
          canAccessListEdit: resultListEdit,
          canAccessDelete: estadoDelete && resultDelete,
          canAccessUpdatedContact: estadoUpdatedContact && resultUpdatedContact,
          // canAccessReceiptLong: estadoReceiptLong && resultReceiptLong,
          canAccessCancel: estadoCancel && resultCancel,
        };
      }
    });

    return listPermission;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el adherido:"${element.id} - ${element.razonSocial}"?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarAdherido(element);
      }
    });
  }

  eliminarAdherido(element?: any) {
    if (element?.id) {
      this.snackBarSrv.showSnackBar(
        `Adherido eliminado correctamente. El usuario ha sido dado de baja correctamente`,
        'success'
      );
      this.gridArray = this.gridArray.filter(
        (objeto) => objeto.id !== element.id
      );
      this.attachedService
        .deleteAdherido(element.id)
        .pipe()
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            // nota revisar servicio eliminar con back
            // this.snackBarSrv.showSnackBar('Adherido eliminado correctamente, Adherido dado de baja correctamente', 'success');
            // this.gridArray = this.gridArray.filter(objeto => objeto.id !== element.id);
          },
          error: (err: { message: any }) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al eliminar el Adherido',
              'error'
            );
          },
        });
    }
  }
}
