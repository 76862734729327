import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { KeycloakScopeModel } from '../../../model/keycloak-scope.model';
import { KeycloakClientModel } from '../../../model/keycloak-client.model';
import { KamResourceServerServices } from '../../../services/kam-resource-server.services';
import { KeycloakResourceModel } from '../../../model/keycloak-resource.model';
import { FormControl, FormGroup } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { KeycloakPolicyModel } from '../../../model/keycloak-policy.model';
import { KeycloakAssociatedPolicyModel } from '../../../model/keycloak-associated-policy.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'keycloak-auth-manager-kam-manage-permission',
  templateUrl: './kam-manage-permission.component.html',
  styleUrls: ['./kam-manage-permission.component.css']
})
export class KamManagePermissionComponent implements OnInit {

  permissionsForm: FormGroup = new FormGroup({});
  update: boolean = false;
  associatedPolicies: KeycloakAssociatedPolicyModel[]=[];
  preselectedScopes: string[]=[];
  selectedResource: KeycloakResourceModel | null = null;

  constructor(public dialogRef: MatDialogRef<KamManagePermissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KamManagePermissionData,
    private kamResourceServerServices: KamResourceServerServices,
    ) { }

  ngOnInit(): void {
    let associatedPoliciesRequests:Observable<KeycloakAssociatedPolicyModel[]>[] = [];
    this.data.resourceServer.resourceServerSettings?.policies.forEach(policy=>{
        const policyId =  policy.id?policy.id:"";
        const request = this.kamResourceServerServices.getAssociatedPolicies(this.data.resourceServer.id,policyId);
        associatedPoliciesRequests.push(request);
    })
    forkJoin(associatedPoliciesRequests).subscribe(responses=>{
      responses.forEach(response =>{
        this.associatedPolicies =  this.associatedPolicies.concat(response);
      })
    });

    this.data.resourceServer.resourceServerSettings?.resources.forEach(resource=>{
      
      resource.scopes?.forEach(scope=>{
        this.preselectedScopes.push(resource._id+ "#" + scope.name);
      })
      let control = new FormControl(this.preselectedScopes)
      this.permissionsForm.addControl(resource._id?resource._id:"",control)
    })
    
  }

  accept(){
    if (this.permissionsForm.untouched){
      return;
    }

    let resourceRequests:Observable<any>[] = [];
    let policiesToDelete: Observable<any>[]=[];
    let permissionsToDelete: Observable<any>[]=[];
    let permissionsToAdd: Observable<any>[]=[]
    let policiesToAdd: Observable<any>[]=[]

    this.data.resourceServer.resourceServerSettings?.resources.forEach(resource=>{ 
      // retrieve selection for each resource                    
      let scopeSelection:KeycloakScopeModel[]=[];
      this.permissionsForm.get(resource._id?resource._id:"")?.value.forEach((one:string)=>{
        const scopetoAdd: KeycloakScopeModel={
          name: one.split('#')[1]
        }
        scopeSelection.push(scopetoAdd)
      })

      // check if selection has change
      let touched= false;
      
      if (!(!resource.scopes && scopeSelection.length==0))
        if (resource.scopes?.length== scopeSelection.length){
          resource.scopes?.forEach(scope=>{
            if (scopeSelection.findIndex(selScope => selScope.name == scope.name)==-1)
              touched= true
          })
        }else{
          touched= true;
        }

      if (!touched){
        return
      }

      // prepare resource update
      let updateResource: KeycloakResourceModel = {
        name: resource.name,
        displayName: resource.displayName,
        type: resource.type,
        _id: resource._id,
        scopes:scopeSelection,      
      }

      const request =  this.kamResourceServerServices.putResource(this.data.resourceServer.id, updateResource);
      resourceRequests.push(request)

      //** permissions */
      
      this.data.resourceServer.resourceServerSettings?.policies.forEach(existingPoicy =>{          
          const isResourcePermission = existingPoicy.config?.resources?.findIndex(res=> res === resource.name)==-1?false:true;
          if (isResourcePermission){
            //not touched scope?
            existingPoicy.config?.scopes?.forEach(scopeInResource=>{
              const found = scopeSelection.find(ss=> ss.name == scopeInResource)
              if (!found){
                const policyId = existingPoicy.id?existingPoicy.id:"";
                const request = this.kamResourceServerServices.deletePermission(this.data.resourceServer.id,policyId)
                permissionsToDelete.push(request)                

                let associatedPolicy = this.associatedPolicies.find(assoPolicy => assoPolicy.originalPolicyId == policyId)
                if (associatedPolicy){
                  const delAssociatedPolicyRequest = this.kamResourceServerServices.deletePolicies(this.data.resourceServer.id,associatedPolicy.id)
                  policiesToDelete.push(delAssociatedPolicyRequest)

                }
              }
            })
          }            
      })

      scopeSelection.forEach(scopeSelected => {
        const existPermission = this.data.resourceServer.resourceServerSettings?.policies.find(policy => {
            const findResource = policy.config?.resources?.find(pRes => pRes == resource.name)
            if (!findResource){
              return undefined
            }
            const findScope = policy.config?.scopes?.find(pScope => pScope == scopeSelected.name)
            if (findScope)
              return policy;
            return undefined;
        });
        if (!existPermission){
          const newPolicyId = uuidv4();
          const permissionToAdd:KeycloakPolicyModel = {
            description: 'permission for '+resource.name + " to " + scopeSelected.name,
            logic: 'POSITIVE',
            name: 'permission-'+resource.name + "-" + scopeSelected.name,
            type: 'scope',
            decisionStrategy: 'UNANIMOUS',
            resources: [resource.name],
            scopes:[scopeSelected.name],
            policies:[newPolicyId]
          }
          const request =  this.kamResourceServerServices.postPermission(this.data.resourceServer.id, permissionToAdd);
          permissionsToAdd.push(request)

          const policyToAdd:KeycloakPolicyModel = {
            description: 'policy for '+resource.name + " to " + scopeSelected.name,
            logic: 'POSITIVE',
            name: 'policy-'+resource.name + "-" + scopeSelected.name,
            type: 'role',
            decisionStrategy: 'UNANIMOUS',
            roles:[],
            id:newPolicyId,     
          }
          const requestPolicies =  this.kamResourceServerServices.postPolicy(this.data.resourceServer.id, policyToAdd);
          policiesToAdd.push(requestPolicies)          

        }
      })
    })

    // No need to delete permissions, when deleting policies the associated permissions are deleted
    // 1 - delete policies (and associated permissions)
    forkJoin(policiesToDelete).subscribe({
      next: (v) => {},
      error: (e) => console.error(e),
      complete: () => {
        //2 - let's update resource  
        forkJoin(resourceRequests).subscribe({
          next: (v) => { },
          error: (e) => console.error(e),
          complete: () => {
              // 3 - create policies
              forkJoin(policiesToAdd).subscribe({
              next: (v) => { },
              error: (e) => console.error(e),
              complete: () => {
                // 4 - create permissions associated to policies
                forkJoin(permissionsToAdd).subscribe({
                  next: (v) => {},
                  error: (e) => console.error(e),
                  complete: () => {
                    this.dialogRef.close(true)
                  }
                })
              }
            })
          }
        })
      }
    })

  }

  searchScopes(nombre : string){
    if(this.selectedResource?.scopes?.find(allScopes => allScopes.name === nombre)){
      return true
    }
    return false
  }

  onListSelectionChange(ob: MatSelectionListChange, controlName: string | undefined){    
    if(!controlName) return;
    
    console.log("**",this.permissionsForm.get(controlName)?.value);
  }

  permissionsCancel(){
    this.dialogRef.close(undefined);
  }
}

export interface KamManagePermissionData {
  resourceServer: KeycloakClientModel,
  icon:string,
  title:string,
}
