import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeclaracionAdheridoControllerService, DeclaracionAdheridoFilterDTO } from 'api-rest';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'lib-billing-report-dialog',
  templateUrl: './billing-report-dialog.component.html',
  styleUrls: ['./billing-report-dialog.component.scss']
})
export class BillingReportDialogComponent implements OnInit {
  formGroup!: FormGroup;
  anios: any[] = [];

  tiposMarcadas = [
    { id: "facturar", descripcion: "A facturar" },
    { id: "facturada", descripcion: "Facturadas" }
  ];

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BillingReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private declaracionAdheridoControllerSrv: DeclaracionAdheridoControllerService,
    private downloadSrv: DownloadService
  ) {
    this.formGroup = this.formBuilder.group({
      declaracionMarcada: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      anio: [{ value: null, disabled: false }]
    });

  }
  ngOnInit(): void {
    let anioActual = new Date().getFullYear();
    for (let i = anioActual - 3; i <= anioActual; i++) {
      this.anios.push({ id: i })
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  controlHasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName)
      && this.formGroup.controls[controlName].touched;
  }
  actualizarValidaciones(): void {
    if (this.formGroup.controls['declaracionMarcada']?.value === 'facturar') {
      this.formGroup.get('anio')?.clearValidators();
      this.formGroup.get('anio')?.updateValueAndValidity();
    } else if (this.formGroup.controls['declaracionMarcada']?.value === 'facturada') {
      this.formGroup.get('anio')?.setValidators([Validators.required]);
      this.formGroup.get('anio')?.updateValueAndValidity();
    }
  }
  descargarInformeFacturacion(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {
      let body: DeclaracionAdheridoFilterDTO = {
        facturar: this.formGroup.controls['declaracionMarcada']?.value === 'facturar' ? true : false,
        facturada: this.formGroup.controls['declaracionMarcada']?.value === 'facturada' ? true : false,
        anioDatos: this.formGroup.controls['anio']?.value
      }

      this.spinnerSrv.loadingShow();
      this.declaracionAdheridoControllerSrv.informeFacturacion(body, 'response')
        .subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            const filename = this.downloadSrv.getFileName(
              response.headers,
              'Informe_Facturacion.csv'
            );
            this.downloadSrv.downloadCSV(response.body!, filename!);
            this.closeDialog();
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al descargar el informe de facturación.',
              'error'
            );
          },
        });
    }

  }
}