import {
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appPercentNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PercentNumberDirective,
      multi: true,
    },
  ],
})
export class PercentNumberDirective implements ControlValueAccessor, OnInit {
  private regex: RegExp = new RegExp(
    /^(100(\,0{1,2})?|[0-9]{0,2}(\,\d{0,2})?)$/
  );

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.setProperty(
      this.el.nativeElement,
      'value',
      this.formatValue(this.el.nativeElement.value)
    );
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (value && !this.regex.test(value)) {
      value = value.substring(0, value.length - 1);
      this.onChange(this.parseValue(value));
    } else {
      this.onChange(this.parseValue(value));
    }
    this.renderer.setProperty(
      this.el.nativeElement,
      'value',
      this.formatValue(value)
    );
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedData = event.clipboardData?.getData('text');
    if (pastedData) {
      let pastedValue = pastedData.replace('.', ',');
      if (!pastedValue.match(this.regex)) {
        event.preventDefault();
      } else {
        const decimalIndex = pastedValue.indexOf(',');
        if (decimalIndex !== -1 && pastedValue.length - decimalIndex - 1 > 5) {
          pastedValue = pastedValue.slice(0, decimalIndex + 6);
        }
        const numericValue = parseFloat(pastedValue.replace(',', '.'));
        if (numericValue >= 100) {
          event.preventDefault();
        } else {
          this.onChange(this.parseValue(pastedValue));
          this.renderer.setProperty(
            this.el.nativeElement,
            'value',
            this.formatValue(pastedValue)
          );
        }
      }
    }
  }

  writeValue(value: any): void {
    this.renderer.setProperty(
      this.el.nativeElement,
      'value',
      this.formatValue(value)
    );
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private parseValue(value: string): number {
    return parseFloat(value.replace(',', '.'));
  }

  private formatValue(value: any): string {
    if (value === null || value === undefined) {
      return '';
    }
    return value.toString().replace('.', ',');
  }
}
