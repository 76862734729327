<table mat-table [dataSource]="dataSource">

 <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null; update()"
                        [checked]="selection.isSelected(element)"
                        color="primary">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{'KAM-USERS-TAB.GROUPS' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.name}} </td>        
    </ng-container>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{'KAM-GENERAL-DIALOG.DESCRIPTION' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>      
<mat-paginator [pageSizeOptions]="[5, 10]" [pageSize]="5" 
    #paginatorGroups="matPaginator" 
    showFirstLastButtons 
    aria-label="Seleccionar página"></mat-paginator>


