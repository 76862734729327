/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeclaracionEnvaseMaterialPayloadDTO } from './declaracionEnvaseMaterialPayloadDTO';
import { TipoEnvasesPayloadDTO } from './tipoEnvasesPayloadDTO';
import { DeclaracionCentroDTO } from './declaracionCentroDTO';
import { DocumentoDTO } from './documentoDTO';


export interface DeclaracionEnvasePayloadDTO { 
    id?: number;
    peligrosidad?: boolean;
    denominacion?: string;
    tipoEnvase?: TipoEnvasesPayloadDTO;
    tipologia?: DeclaracionEnvasePayloadDTO.TipologiaEnum;
    pesoUnitario?: number;
    unidadesTotales?: number;
    pesoTotal?: number;
    vidaUtil?: number;
    ciclosReutilizacion?: number;
    contriRapAnioCurso?: number;
    depoRapAnioCurso?: number;
    contriRapAnioAnterior?: number;
    depoRapAnioAnterior?: number;
    envaseMaterial?: Array<DeclaracionEnvaseMaterialPayloadDTO>;
    declaracionCentro?: DeclaracionCentroDTO;
    acuerdoDeposito?: boolean;
    acuerdoCesion?: boolean;
    importeDeposito?: number;
    docModeloCesion?: DocumentoDTO;
    fabricanteProductor?: boolean;
    prestadorServicios?: boolean;
    docCertificadoReutilizables?: DocumentoDTO;
    simboloSddrcerrado?: boolean;
    envasesSddrcerrado?: boolean;
    unidadesCirculacion?: number;
    unidadesNuevas?: number;
    unidadesResiduos?: number;
    unidadesNoRetornadas?: number;
    unidadesExportadas?: number;
    unidadesEnvaseResiduo?: number;
    tarifaRapAnioCurso?: number;
    tarifaRapAnioAnterior?: number;
    tarifaDepoAnioCurso?: number;
    tarifaDepoAnioAnterior?: number;
}
export namespace DeclaracionEnvasePayloadDTO {
    export type TipologiaEnum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const TipologiaEnum = {
        Primario: 'PRIMARIO' as TipologiaEnum,
        Secundario: 'SECUNDARIO' as TipologiaEnum,
        Terciario: 'TERCIARIO' as TipologiaEnum
    };
}


