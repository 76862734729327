import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'lib-holders',
  templateUrl: 'holders.component.html',
  styleUrls: ['./holders.component.scss'],
})
export class HoldersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
