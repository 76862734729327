<empusa-core-spinner *ngIf="loading" [overlay]="true"></empusa-core-spinner>
<div *ngIf="resourceServer">
    <!-- RESOURCES -->
    <div class="container">
        <div class="row" style="display: flex; height: calc(100vh - 280px)">
            <mat-card class="example-card">
                <mat-card-header>
                    <mat-card-title>
                        <span>{{'KAM-PERMISSIONS-RESOURCES.RESOURCES'| translate}}</span> 
                        <button matTooltip="Update resource" mat-icon-button (click)="addNewResource()" class="button-add" align="end">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="resourcesSource">
                        <!-- resource Column -->
                        <ng-container matColumnDef="resource">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"> 
                            <span class="title">{{element.name}}</span>
                            <span class="subtitle">{{element.displayName}}</span>
                        </td>
                        </ng-container>
                
                        <!-- actions Column -->
                        <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> 
                            <button matTooltip="Update resource" mat-icon-button (click)="updateResource(element)">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                            <button matTooltip="Delete resource" mat-icon-button (click)="deleteResource(element)">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="resourcesdisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: resourcesdisplayedColumns;"></tr>
                    </table>
                </mat-card-content>
                <mat-card-actions *ngIf="resourcesSource.data.length > 5">
                    <mat-paginator [pageSizeOptions]="[5, 10]" [pageSize]="5"
                        #paginatorResources="matPaginator" 
                        [length]="resourcesPaginatorLength" showFirstLastButtons 
                        aria-label="Seleccionar página"></mat-paginator>
                </mat-card-actions>
            </mat-card>
            <!-- scopes -->
            <mat-card class="example-card mat-card-center" >
                <mat-card-header>
                    <mat-card-title>
                        <span>{{'KAM-PERMISSIONS-RESOURCES.SCOPES'| translate}}</span> 
                        <button matTooltip="Update resource" mat-icon-button (click)="addNewScope()" class="button-add">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="scopesSource">
                        <!-- resource Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let scope"> 
                                <span class="title">{{scope.name}}</span>
                            </td>
                        </ng-container>
                    
                        <!-- actions Column -->
                        <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let scope"> 
                            <button matTooltip="Update scope" mat-icon-button (click)="updateScope(scope)">
                                <mat-icon>mode_edit</mat-icon>
                            </button>
                            <button matTooltip="Delete scope" mat-icon-button (click)="deleteScope(scope)">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="scopesdisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: scopesdisplayedColumns;"></tr>
                    </table>
                </mat-card-content>
                <mat-card-actions *ngIf="scopesSource.data.length > 5">
                    <mat-paginator [pageSizeOptions]="[5, 10]" [pageSize]="5" 
                        #paginatorScopes="matPaginator" 
                        [length]="scopesPaginatorLength" showFirstLastButtons 
                        aria-label="Seleccionar página"></mat-paginator>
                </mat-card-actions>
            </mat-card>
            <!-- permissions -->
            <mat-card class="example-card">
                <mat-card-header>
                <mat-card-title>
                    <span>{{'KAM-PERMISSIONS-RESOURCES.PERMISSIONS'| translate}}</span>
                    <button matTooltip="Update resource" mat-icon-button (click)="addNewPermission()" class="button-add">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="permissionsSource">
                        <!-- resource Column -->
                        <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let permission"> 
                            <span class="title">{{permission.resource}} -> {{permission.scope}}</span>
                        </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="permissionsplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: permissionsplayedColumns;"></tr>
                    </table>
                </mat-card-content>
                <mat-card-actions *ngIf="permissionsSource.data.length > 5">
                    <mat-paginator [pageSizeOptions]="[5, 10]"
                        #paginatorPermissions="matPaginator" 
                        [length]="permissionsPaginatorLength" showFirstLastButtons 
                        aria-label="Seleccionar página"></mat-paginator>
                </mat-card-actions>
            </mat-card>
        </div>
      </div>
</div>