export * from './acondicionamientoController.service';
import { AcondicionamientoControllerService } from './acondicionamientoController.service';
export * from './facturaPtoRecogidaController.service';
import { FacturaPtoRecogidaControllerService } from './facturaPtoRecogidaController.service';
export * from './gestorPtoRecogidaCodigoController.service';
import { GestorPtoRecogidaCodigoControllerService } from './gestorPtoRecogidaCodigoController.service';
export * from './gestorPtoRecogidaController.service';
import { GestorPtoRecogidaControllerService } from './gestorPtoRecogidaController.service';
export * from './gestorPtoRecogidaEstadoController.service';
import { GestorPtoRecogidaEstadoControllerService } from './gestorPtoRecogidaEstadoController.service';
export * from './helloWorldController.service';
import { HelloWorldControllerService } from './helloWorldController.service';
export * from './operacionGestionController.service';
import { OperacionGestionControllerService } from './operacionGestionController.service';
export * from './operacionesTratamientoController.service';
import { OperacionesTratamientoControllerService } from './operacionesTratamientoController.service';
export * from './ptoRecogidaCodigoController.service';
import { PtoRecogidaCodigoControllerService } from './ptoRecogidaCodigoController.service';
export * from './ptoRecogidaEstadoController.service';
import { PtoRecogidaEstadoControllerService } from './ptoRecogidaEstadoController.service';
export * from './ptoRecogidaGestorController.service';
import { PtoRecogidaGestorControllerService } from './ptoRecogidaGestorController.service';
export * from './puntoRecogidaController.service';
import { PuntoRecogidaControllerService } from './puntoRecogidaController.service';
export * from './solicitudController.service';
import { SolicitudControllerService } from './solicitudController.service';
export * from './solicitudEstadoController.service';
import { SolicitudEstadoControllerService } from './solicitudEstadoController.service';
export * from './tarifaPtoRecogidaController.service';
import { TarifaPtoRecogidaControllerService } from './tarifaPtoRecogidaController.service';
export const APIS = [AcondicionamientoControllerService, FacturaPtoRecogidaControllerService, GestorPtoRecogidaCodigoControllerService, GestorPtoRecogidaControllerService, GestorPtoRecogidaEstadoControllerService, HelloWorldControllerService, OperacionGestionControllerService, OperacionesTratamientoControllerService, PtoRecogidaCodigoControllerService, PtoRecogidaEstadoControllerService, PtoRecogidaGestorControllerService, PuntoRecogidaControllerService, SolicitudControllerService, SolicitudEstadoControllerService, TarifaPtoRecogidaControllerService];
