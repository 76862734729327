
<div class="homme-minimal-contenedor ">
    <div class="homme-minimal-header">    
      <div class="header-container">
        <div class="row">
          <div class="col">
            <div class="logo-right"></div>
          </div>
          
          <div class="col">
            <div class="userInfo">
              <span class="material-icons">notifications</span>
              Hola {{currentUser}}
              <div (click)="profileUser()" [ngClass]="havePhoto?'profile-image-back-ground':'profile-image'">
                <img #userPicture [ngClass]="havePhoto?'userpicture':'userpicture-hidden'">                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
  <ng-container>
    <app-home-minimal-side-nav [list_sections]="sidenavSections"></app-home-minimal-side-nav>
  </ng-container>
  
  <div class="homme-minimal-content">
     <router-outlet></router-outlet>
   </div>
  <!--
   <footer class="homme-minimal-footer mt-auto py-0">   
     <div>
        <div class="footer-container">
            <div class="row">
              <div class="col">
                <img class="imgSize" src="../../assets/img/home-minimal/unionEuropea.svg">
              </div>
              <div class="col">
                <img class="imgSize"  src="../../assets/img/home-minimal/gobiernoEspaña.svg">
              </div>
              <div class="col">
                <img class="imgSize" src="../../assets/img/home-minimal/logo-prtr.svg">
              </div>
            </div>            
          </div>   
     </div>
   </footer>
   -->
  </div>