

<keycloak-auth-manager-kam-user-form
    [userDataIn]="initialUserValues"
    (userFormCompleted)="isUserFormCompleted($event)"
    (userData)="setUserData($event)"
    ></keycloak-auth-manager-kam-user-form>
<div style="text-align: center" >
    <button mat-button matStepperNext (click)="step1End()"
        [disabled]="!step1Completed">{{"KAM-GENERAL-DIALOG.NEXT" |translate}}</button>
</div>