import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EmpusaAppHeaderClassicInput, EmpusaAuthenticationService, EmpusaMicroApp } from '@empusa/empusa-core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'microapp-home-main-page',
  templateUrl: './main-page.component.html',
  //styleUrls: ['./main-page.component.css']
  styleUrls: ['../microapp-home.scss']

})
export class MainPageComponent implements OnInit {

  headerData: EmpusaAppHeaderClassicInput ={
    icon: "home",
    text:"MAPP-HOME.WELCOME_TEXT",    
    sufix: this.environment.site
  }


  /** list of modules availables */
  modules: EmpusaMicroApp[] = [];

  /** the current page selected */
  currentDynamicPage: string = "";

  /** the current selected lang */
  currentLang: string = "";
  bmsGrafana: string;
  incluideGrafana: boolean = false;

  selectedSiteControl = new FormControl();
  siteName: string = "";

  constructor(private translate: TranslateService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any) {

    this.bmsGrafana = this.environment.grafana;
    if(this.bmsGrafana != ''){
      this.authService.canAccessMicroapp('grafana')? this.incluideGrafana = true:this.incluideGrafana = false;
    }
    console.log("incluideGrafana",this.incluideGrafana)
    let allModules: EmpusaMicroApp[] = (<any>window).empusa.modules;
    if (allModules && allModules != null) {
      allModules.forEach(module => {
          if (this.authService.canAccessMicroapp(module.name)){            
              if (module.name != "home"){
                this.modules.push(module);
                this.modules.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 :
                        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
                );             
            }
          }
      });
    }

  }

  ngOnInit(): void {
    this.siteName = this.environment.site;
  }

}
