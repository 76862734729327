import { createAction, props } from '@ngrx/store';
import { KeycloakClientModel } from '../model/keycloak-client.model';
import { KeycloakGroupModel } from '../model/keycloak-group.model';
import { KeycloakRoleModel } from '../model/keycloak-role.model';
import { KeycloakUserModel } from '../model/keycloak-user.model';
import { KamUserFilter } from '../services/kam-user.filter';
 

export const RetrieveResourceServes = createAction(
  '[Auth Manager] Step 1 - Retrieve resource servers',
  props<{ clients: string[] }>()
);

export const StoreResourceServes = createAction(
  '[Auth Manager] Step 1 - Store resource servers',
  props<{ clients: KeycloakClientModel[]}>()
);

export const AllData_RetrieveResourceServerSettings_Step2 = createAction(
  '[Auth Manager] Step 2 - Retrieve resource server settings',
  props<{ clients: KeycloakClientModel[]}>()
);

export const AllData_StoreResourceServerSettings_Step2 = createAction(
  '[Auth Manager] Step 2 - Store resource server settings',
  props<{ clients: KeycloakClientModel[]}>()
);


export const RetrieveRoles = createAction(
  '[Auth Manager] Retrieve Roles'
);

export const StoreRoles = createAction(
  '[Auth Manager] Store Roles',
  props<{ roles: KeycloakRoleModel[]}>()
);

export const RetrieveUsers = createAction(
  '[Auth Manager] Retrieve Users',
  props<{ filter: KamUserFilter}>()
);

export const RetrieveUsersRolesGroups = createAction(
  '[Auth Manager] Retrieve Users Roles GRoups',
  props<{ users: KeycloakUserModel[],usersCount: number}>()
);

export const StoreUsers = createAction(
  '[Auth Manager] Store Users',
  props<{ users: KeycloakUserModel[], usersCount: number}>()
);

export const RetrieveGroups = createAction(
  '[Auth Manager] Retrieve groups'
);

export const RetrieveGroupMembers = createAction(
  '[Auth Manager] Retrieve groups members',
  props<{ groups: KeycloakGroupModel []}>()
);

export const StoreGroupMembers = createAction(
  '[Auth Manager] Store group members',
  props<{ groups: KeycloakGroupModel[]}>()
);

export const retrieveUsers = createAction(
  '[Auth Manager] retrieve users',
  //props<{ users: KeycloakUserModel[] }>()
);

export const storeAllData = createAction(
  '[Auth Manager] store All Data',
  props<{ clients: KeycloakClientModel[], 
          users: KeycloakUserModel [],
          roles: KeycloakRoleModel [],
          groups: KeycloakGroupModel []}>()
);

export const storeUsers = createAction(
    '[Auth Manager] store users',
    props<{ users: KeycloakUserModel[] }>()
  );

export const storeClients = createAction(
  '[Auth Manager] store Clients',
  props<{ clients: KeycloakClientModel[] }>()
);

export const setSelectedResourceServer = createAction(
  '[Auth Manager] set selected resource server',
  props<{ selectedResourceServer: KeycloakClientModel|undefined }>()
);