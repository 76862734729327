import { Component, Inject } from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MappedResponse } from '../../../share/components/ge-select-pagination/ge-select-pagination.component';
import {
  CategoriaEnvaseControllerService,
  CategoriaEnvaseDTO,
  PageDTOTarifaAdheridoSDDRAbiertoDTO,
  TarifaAdheridoSDDRAbiertoDTO,
  TarifaAdheridoSDDRAbiertoFilterDTO,
  TarifaAdheridoSddrAbiertoControllerService,
  TarifaAdheridoUnUsoFilterDTO,
  TipoEnvasesDTO,
} from 'api-rest';
import { RateOpenDialogComponent } from '../rate-open-dialog/rate-open-dialog.component';
import Swal from 'sweetalert2';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';

@Component({
  selector: 'lib-user-search',
  templateUrl: './attached-rate-open-search.component.html',
  styleUrls: ['./attached-rate-open-search.component.scss'],
})
export class AttachedRateOpenSearchComponent {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;
  tarifaAbierta: TarifaAdheridoSDDRAbiertoDTO[] = [];
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalSearch: number = 0;
  updateList: boolean = false;
  defaultFilter: boolean = true;
  headArray: any = [
    {
      Head: 'Año',
      FieldName: 'anio',
    },
    {
      Head: 'Categoría',
      FieldName: 'categoria',
      Attribute: 'descripcion',
      Tooltip: true,
      Maxtext: 30,
    },
    {
      Head: 'Tipo envase',
      FieldName: 'tipoEnvases',
      Attribute: 'descripcion',
      Tooltip: true,
      Maxtext: 30,
    },
    {
      Head: 'Tipología',
      FieldName: 'tipologia',
    },
    {
      Head: 'Peligrosidad',
      FieldName: 'peligrosidad',
      renderValue: (item: TarifaAdheridoSDDRAbiertoDTO) =>
        item.peligrosidad ? 'SI' : 'NO',
    },
    {
      Head: '€/ud',
      FieldName: 'eurosUnidad',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item.eurosUnidad),
    },
    {
      Head: 'Depósito (€/Ud) ',
      FieldName: 'depositoUnidad',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item.depositoUnidad),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        { nameButton: 'view', icon: 'open_in_new', toolTip: 'Editar' },
        { nameButton: 'delete', icon: 'delete', toolTip: 'Borrar' },
      ],
      width: '10',
    },
  ];
  gridArray: TarifaAdheridoSDDRAbiertoDTO[] = [];

  searchForm: FormGroup;
  categorias: CategoriaEnvaseDTO[] = [];
  tipoEnvasesEndpoint =
    this.environment.urlBackCore + 'api/v1/core/tipoEnvases';

  constructor(
    private ratesOpenService: TarifaAdheridoSddrAbiertoControllerService,
    private categoriaEnvaseControllerService: CategoriaEnvaseControllerService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private downloadService: DownloadService,
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private formatCurrencyPipe: FormatCurrencyPipe
  ) {
    this.searchForm = this.formBuilder.group({
      anio: [{ value: null, disabled: false }, [this.customValidator()]],
      categoria: [{ value: null, disabled: false }],
      tipoEnvase: [{ value: null, disabled: false }],
      tipologia: [{ value: null, disabled: false }],
      peligrosidad: [{ value: null, disabled: false }]
    });
  }

  ngOnInit(): void {
    this.cargarCombos();
  }

  cargarCombos() {
    this.categoriaEnvaseControllerService
      .listarCategoriaEnvases()
      .subscribe((response) => {
        this.categorias = response;
      });
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.getRatesOpenService();
    }
  }

  checked(item: any) {}

  searchData() {
    this.pageNumber = 1;
    this.getRatesOpenService();
  }

  customValidator() {
    return (control: { value: any }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (/^\d{4}$/.test(value)) {
        const intValue = parseInt(value, 10);
        if (intValue >= 1900 && intValue <= 2500) {
          return null;
        }
      }
      return { invalid: true };
    };
  }

  getRatesOpenService() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    this.spinnerSrv.loadingShow();
    const { backendFilter, page, size } = this.getBackendFilter();
    this.ratesOpenService
      .findAll2(backendFilter, {
        page: page,
        size: size,
        sort: ['anio,asc'],
      })
      .subscribe({
        next: (data: PageDTOTarifaAdheridoSDDRAbiertoDTO) => {
          if (data) {
            this.flagSearch = data.datos?.length !== 0 ? true : false;
            this.gridArray = data.datos ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  private getBackendFilter() {
    const filter = this.searchForm.value;

    if (filter.categoria) {
      filter.categoria = {
        id: filter.categoria.id,
      };
    }

    if (filter.tipoEnvase) {
      filter.tipoEnvase = {
        id: filter.tipoEnvase.id,
      };
    }

    if (filter.enabled == false) {
      delete filter.enabled;
    }

    const backendFilter: TarifaAdheridoSDDRAbiertoFilterDTO = {
      anio: filter.anio,
      categoria: filter.categoria,
      tipoEnvases: filter.tipoEnvase,
      tipologia: filter.tipologia as TarifaAdheridoUnUsoFilterDTO.TipologiaEnum,
      peligrosidad: filter.peligrosidad
    };

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openModalRateOpen(element);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la tarifa?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarTarifaOpen(element);
      }
    });
  }

  eliminarTarifaOpen(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.ratesOpenService.deleteById2(element.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `La tarifa se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar la tarifa',
            'error'
          );
        },
      });
    }
  }

  openModalRateOpen(element?: any): void {
    const dialogRef = this.dialog.open(RateOpenDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        itemOpen: element,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearSearcher();
        this.getRatesOpenService();
      }
    });
  }

  clearSearcher() {
    this.searchForm.reset();
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.ratesOpenService
      .exportTarifaSDDRAbierto(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_Tarifa_SDDR_Abierto.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  mapAdheridosReponse(response: any): MappedResponse<any> {
    return {
      datos: response.content,
      recordsFiltered: response.recordsTotal,
      totalElements: response.totalElements,
    };
  }

  getTipoEnvaseDescription(tipoEnvase: TipoEnvasesDTO) {
    return tipoEnvase.descripcion;
  }

  filterList(selectForm: any) {
    switch (selectForm) {
      case 'tipoEnvase': {
        return this.searchForm.get('tipoEnvase') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'tipoEnvase': {
        return this.searchForm.get('tipoEnvase') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  selectedModel(selectForm: any) {
    switch (selectForm) {
      case 'tipoEnvase': {
        break;
      }
      default: {
        break;
      }
    }
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.getRatesOpenService();
  }
}
