<div *ngIf="formGroup">
        <form [formGroup]="formGroup" style="text-align: center;">
            <mat-form-field>
                <mat-label>{{"KAM-USERS-TAB.USER_NAME" | translate}}</mat-label>
                <input type="text" matInput [formControl]="userNameCtrl">
                <mat-error *ngIf="userNameCtrl.invalid && (userNameCtrl.dirty || userNameCtrl.touched)">
                    {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"KAM-USERS-TAB.EMAIL" | translate}}</mat-label>
                <input type="text" matInput [formControl]="emailCtrl">
                <mat-error *ngIf="emailCtrl.invalid && (emailCtrl.dirty || emailCtrl.touched)">
                    {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"KAM-USERS-TAB.NAME" | translate}}</mat-label>
                <input type="text" matInput [formControl]="firstNameCtrl">
                <mat-error *ngIf="firstNameCtrl.invalid && (firstNameCtrl.dirty || firstNameCtrl.touched)">
                    {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{"KAM-USERS-TAB.LAST_NAME" | translate}}</mat-label>
                <input type="text" matInput [formControl]="lastNameCtrl">
                <mat-error *ngIf="lastNameCtrl.invalid && (lastNameCtrl.dirty || lastNameCtrl.touched)">
                    {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                </mat-error>
            </mat-form-field>
            <br>
            <mat-checkbox [formControl]="enabledCtrl" color="primary">{{"KAM-USERS-TAB.ENABLED" | translate}}</mat-checkbox>
            <div>
                <br>
                <mat-checkbox [formControl]="setPasswordCtrl" (change)="setPasswordCtrlChange()"
                    *ngIf="this.update" color="primary">{{"KAM-USERS-TAB.SET_PASSWORD" | translate}}</mat-checkbox>
            </div>
            <div *ngIf="showPass">
                <mat-form-field>
                    <mat-label>{{"KAM-USERS-TAB.PASSWORD" | translate}}</mat-label>
                    <input type="text" matInput [formControl]="newPasswordCtrl">
                    <mat-error *ngIf="newPasswordCtrl.invalid && (newPasswordCtrl.dirty || newPasswordCtrl.touched)">
                        {{"KAM-GENERAL-DIALOG.ERROR" | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{"KAM-USERS-TAB.REPEAT_PASSWORD" | translate}}</mat-label>
                    <input type="text" matInput [formControl]="repeatPasswordCtrl">                
                </mat-form-field>
                <mat-form-field style='color:#ff7355' *ngIf="this.formGroup?.hasError('passwordNoMatch')">
                        {{"KAM-USERS-TAB.PASSWORD_NOT_MATCH" | translate}}
                </mat-form-field>
            </div>
        </form>
</div>