import { NgModule } from '@angular/core';
import { CollectionPointsHomeModule } from './collection-points-home.module';
import { EmpusaMicroAppType } from '@empusa/empusa-core';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class CollectionPointsModule { 
  name: string = "collection-points";
  description: string = "Puntos de recogida"
  icon: string = "collection-points";
  page: any = CollectionPointsHomeModule;
  homeApp: boolean = false;
  path: string = "collection-points";
  order: number = 1;
  resourceServerId: string = "keycloak-auth-manager-resources-server";
  appType: EmpusaMicroAppType = EmpusaMicroAppType.home;

  constructor() {
    //we register ourselves
    (<any>window).empusa(this);
    console.debug("Load microapp " + this.name);
  }
}
