import { Injectable } from '@angular/core';
import { Combo } from '../models/combo';

@Injectable({
  providedIn: 'root'
})
export class CombosService {

  constructor() { }

  // Tipos de empresa
  getTiposEmpresa(): Combo[] {
    return [
      { id: 'JURIDICA', name: 'Jurídica' },
      { id: 'FISICA', name: 'Física' }
    ];
  }

  // Tipos de documento de identidad
  getTiposDocumentoIdentidad(): Combo[] {
    return [
      { id: 'DNI', name: 'DNI' },
      { id: 'NIE', name: 'NIE' },
      { id: 'NIF', name: 'NIF' },
    ];
  }

  // Tipos de teléfono
  getTiposTelefono(): Combo[] {
    return [
      { id: 'FIJO', name: 'Fijo' },
      { id: 'MOVIL', name: 'Móvil' }
    ];
  }

  // Tipos de puesta en mercado
  getTiposPuestaEnMercado(): Combo[] {
    return [
      { id: 'INDUSTRIAL', name: 'Industrial' },
      { id: 'COMERCIAL', name: 'Comercial' },
      { id: 'COMERCIAL_INDUSTRIAL', name: 'Comercial e industrial' }
    ];
  }


  getCategoriaDeclaracion(): Combo[] {
    return [
      { id: 2, name: "Comerciales de un solo uso" },
      { id: 3, name: "Industriales reutilizables en SDDR circuito cerrado" },
      { id: 4, name: "Comerciales reutilizables en SDDR circuito cerrado" },
      { id: 5, name: "Industriales reutilizables en SDDR circuito abierto" },
      { id: 6, name: "Comerciales reutilizables en SDDR circuito abierto" },
      { id: 7, name: "Domésticos" }
    ];
  }

  //Tipos de categorías de envases
  getCategoriasEnvases(): Combo[] {
    return [
      { id: "UN_SOLO_USO", name: "Un solo uso" },
      { id: "REUTILIZABLE", name: "Envase reutilizable" },
      { id: "UN_SOLO_USO_REUTILIZABLE", name: "Un solo uso y reutilizable" }
    ];
  }


  // Tipos de órganos participantes
  getOrganosParticipantes(): Combo[] {
    return [
      { id: 'ASAMBLEA', name: 'Asamblea' },
      { id: 'JUNTA_DIRECTIVA', name: 'Junta directiva' },
      { id: 'COMITE_OPERATIVO', name: 'Comité operativo' },
      { id: 'COMISIONES', name: 'Comisiones' }
    ];
  }

  // Tipos de usuario
  getTiposUsuario(): Combo[] {
    return [
      { id: 'ADHERIDO', name: 'Adherido' },
      { id: 'POSEEDOR', name: 'Poseedor' },
      { id: 'GESTOR', name: 'Gestor' },
      { id: 'DISTRIBUIDOR', name: 'Distribuidor' },
      { id: 'PUNTO_RECOGIDA', name: 'Punto de recogida' },
      { id: 'REACONDICIONADOR', name: 'Reacondicionador' }
    ];
  }

  getOrganos(): Combo[] {
    return [
      { id: 'ASAMBLEA', name: 'Asamblea' },
      { id: 'JUNTA_DIRECTIVA', name: 'Junta Directiva' },
      { id: 'COMITE_OPERATIVO', name: 'Comité Operativo' },
      { id: 'COMISIONES', name: 'Comisiones' }
    ]
  }


}
