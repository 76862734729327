/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdheridoEstadoModel } from './adheridoEstadoModel';
import { ContactoModel } from './contactoModel';
import { DireccionModel } from './direccionModel';
import { TramosCuotaModel } from './tramosCuotaModel';
import { AdheridosPrevisionResiduos } from './adheridosPrevisionResiduos';
import { CnaeModel } from './cnaeModel';
import { CentroEnvasadoModel } from './centroEnvasadoModel';
import { PaisModel } from './paisModel';


export interface AdheridoModel { 
    id?: number;
    domicilios?: Array<DireccionModel>;
    contactos?: Array<ContactoModel>;
    centrosEnvasado?: Array<CentroEnvasadoModel>;
    estado?: AdheridoEstadoModel;
    cifra_de_negocio?: TramosCuotaModel;
    puestaEnMercado?: AdheridoModel.PuestaEnMercadoEnum;
    tipoUsoEnvases?: AdheridoModel.TipoUsoEnvasesEnum;
    grupoEmpresarial?: boolean;
    razonSocial?: string;
    tipoDocumento?: AdheridoModel.TipoDocumentoEnum;
    codigoDocumento?: string;
    cnae1?: CnaeModel;
    cnae2?: CnaeModel;
    registroProductor?: string;
    asociado?: boolean;
    fechaAlta?: string;
    fechaModificacion?: string;
    fechaBaja?: string;
    fechaBajaEnvanet?: string;
    descripcionActividad?: string;
    tipoTelefono?: AdheridoModel.TipoTelefonoEnum;
    codigoPais?: PaisModel;
    telefono?: string;
    email?: string;
    paginaWeb?: string;
    tipoEmpresa?: AdheridoModel.TipoEmpresaEnum;
    fabricante?: boolean;
    conAdenda?: boolean;
    descripcionAdenda?: string;
    planEmpresarial?: boolean;
    deleted?: string;
    deletedBy?: string;
    nombreGrupo?: string;
    remitirInformacion?: boolean;
    fechaFirmaContrato?: string;
    fechaAdhesionFinalizada?: string;
    adheridoInicial?: boolean;
    previsionResiduos?: Array<AdheridosPrevisionResiduos>;
}
export namespace AdheridoModel {
    export type PuestaEnMercadoEnum = 'INDUSTRIAL' | 'COMERCIAL' | 'COMERCIAL_INDUSTRIAL';
    export const PuestaEnMercadoEnum = {
        Industrial: 'INDUSTRIAL' as PuestaEnMercadoEnum,
        Comercial: 'COMERCIAL' as PuestaEnMercadoEnum,
        ComercialIndustrial: 'COMERCIAL_INDUSTRIAL' as PuestaEnMercadoEnum
    };
    export type TipoUsoEnvasesEnum = 'UN_SOLO_USO' | 'REUTILIZABLE' | 'UN_SOLO_USO_REUTILIZABLE';
    export const TipoUsoEnvasesEnum = {
        UnSoloUso: 'UN_SOLO_USO' as TipoUsoEnvasesEnum,
        Reutilizable: 'REUTILIZABLE' as TipoUsoEnvasesEnum,
        UnSoloUsoReutilizable: 'UN_SOLO_USO_REUTILIZABLE' as TipoUsoEnvasesEnum
    };
    export type TipoDocumentoEnum = 'NIF' | 'DNI' | 'NIE';
    export const TipoDocumentoEnum = {
        Nif: 'NIF' as TipoDocumentoEnum,
        Dni: 'DNI' as TipoDocumentoEnum,
        Nie: 'NIE' as TipoDocumentoEnum
    };
    export type TipoTelefonoEnum = 'FIJO' | 'MOVIL';
    export const TipoTelefonoEnum = {
        Fijo: 'FIJO' as TipoTelefonoEnum,
        Movil: 'MOVIL' as TipoTelefonoEnum
    };
    export type TipoEmpresaEnum = 'JURIDICA' | 'FISICA';
    export const TipoEmpresaEnum = {
        Juridica: 'JURIDICA' as TipoEmpresaEnum,
        Fisica: 'FISICA' as TipoEmpresaEnum
    };
}


