import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { KeycloakRoleDTO, KeycloakUserDTO, PlantillaDocumControllerService, PlantillaDocumModel, UserRoleEntityDTO } from 'api-rest';
import Swal from 'sweetalert2';
import { Combo, ComboService } from '../../../services/combo.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'lib-document-template-list',
  templateUrl: './document-template-list.component.html',
  styleUrls: ['./document-template-list.component.scss']
})

export class DocumentTemplateListComponent implements OnDestroy {

  @ViewChild("file") fileInput: ElementRef<any> | undefined;
  recordsTotal: number = 0;
  adjuntosBlob: Array<File> = [];
  selectedItem: PlantillaDocumModel | undefined;

  headArray: any = [
    {
      Head: 'Plantilla',
      FieldName: 'nombre',
    },
    {
      Head: 'Fecha última modificación',
      FieldName: 'fechaModificacion',
      renderValue: (item: PlantillaDocumModel) => this.datePipe.transform(item.fechaModificacion, 'dd/MM/yyyy HH:mm:ss')
    },
    {
      Head: 'Usuario última modificación',
      FieldName: 'usuarioModificacion',
    },
    {
      'Head': 'Acciones', 
      'FieldName': 'actions',
      'buttons': [
        { 'nameButton': "upload", "icon": "upload", 'toolTip': 'Subir' },
        { 'nameButton': "download", "icon": "download", 'toolTip': 'Descargar', 'show': (item: PlantillaDocumModel) => item.documento != null }
      ], 
      'width': '8',
      permanent: true
    },
  ];
  gridArray: KeycloakUserDTO[] = [];

  flagSearch: boolean = false;


  constructor(
    @Inject('environment') private environment: any,
    private documService: PlantillaDocumControllerService,
    private router: Router,
    public dialog: MatDialog,
    public comboService: ComboService,
    private downloadService: DownloadService,
    private snackbarService: SnackbarService,
    private spinnerService: SpinnerService,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit(): void {    
    this.getTemplates();
  }

  ngOnDestroy(): void {
    
  }

  searchData() {
    this.getTemplates();
  }

  getTemplates() {
    this.spinnerService.loadingShow();
    this.documService
      .findAll( )
      .pipe()
      .subscribe({
        next: (data: PlantillaDocumModel[] ) => {
          this.spinnerService.loadingHide();        
          if (data){
            this.gridArray = data ?? [];
            this.recordsTotal = data.length ?? 0;
          }
          this.flagSearch = this.gridArray.length > 0;
        },
        error: (error) => {
          this.spinnerService.loadingHide();
           this.snackbarService.showSnackBar('Error al obtener el listado de plantillas de documentos', 'error');
        }
      });
  }

  action(item: any) {
    if(item.nameButton == 'upload') {
      this.selectedItem = item;
      this.fileInput?.nativeElement?.click();
    } else if (item.nameButton == 'download') {
      this.descargarPlantilla(item);
    } 
  }
  
  
  attachAdjunto(archivo: File | null ): void {
    if(archivo != null) {
      this.adjuntosBlob = [archivo];
      this.actualizarPlantilla();
    }
  }

  actualizarPlantilla() {
    if(this.selectedItem && this.adjuntosBlob.length > 0) {
      Swal.fire({
        text: `¿Está seguro que desea reemplazar la plantilla ${ this.selectedItem.nombre }?`,
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.spinnerService.loadingShow();
          this.documService.editarPlantilla(this.selectedItem?.id!, this.selectedItem?.nombre!, this.adjuntosBlob[0]).subscribe({
            next: response => {
              this.spinnerService.loadingHide();       
              this.snackbarService.showSnackBar('La plantilla se ha actualizado correctamente', 'success');
              this.getTemplates();
              this.clearSelectedItem();
            },
            error: err => {
              this.spinnerService.loadingHide();
              if(err?.error?.detail) {
                this.snackbarService.showSnackBar(err?.error?.detail, 'error');
              } else {
                this.snackbarService.showSnackBar('Ha ocurrido un error al actualizar la plantilla', 'error');
              }
              this.clearSelectedItem();
            }
          });
        } else {
          this.clearSelectedItem();
        }
      });
    }
  }

  clearSelectedItem() {
    this.selectedItem = undefined;
    this.adjuntosBlob = [];
  }

  descargarPlantilla(item: PlantillaDocumModel) {
    let byteString: any = item.documento;
    let fileType = item.contentType ?? 'application/octet-stream';
    let blob = this.b64toBlob(byteString, fileType)
    this.downloadService.downloadBlob(blob, item.filename!);
  }

  b64toBlob (b64Data: string, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    return new Blob(byteArrays, {type: contentType});
  }
}

