import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EmpusaAuthenticationService, EmpusaMicroApp, EmpusaMicroAppType } from "@empusa/empusa-core";
import { KeycloakService } from "keycloak-angular";
import { BehaviorSubject, Observable, Subject, filter, retry, takeUntil } from "rxjs";
import jwt_decode from "jwt-decode";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class EmpusaPortalAuthService {
  homeInitialized: Subject<boolean> = new Subject<boolean>();
  modulesInMenu: BehaviorSubject<EmpusaMicroApp[]> = new BehaviorSubject<EmpusaMicroApp[]>([]);
  includeGrafana: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  URL_ADHERIDOS: string = this.environment.urlBackAdheridos;
  urlDefault: string = "/initial-page";

  constructor(
    private router: Router,
    protected keycloak: KeycloakService,
    private httpClient: HttpClient,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
  ) { }

  private tempIdData$: BehaviorSubject<any> =
    new BehaviorSubject<any>({ stateId: null });
  get getTempIdDataObservable() {
    return this.tempIdData$.asObservable();
  }
  set setTempIdData(data: any) {
    this.tempIdData$.next(data);
  }

  getAdherido(id: number): Observable<any> {
    let fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/adheridos/${id}`;

    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    );
  }

  checkModulesPermissions(allModules: EmpusaMicroApp[] | undefined, currentPath: string) {
    if (allModules && allModules != null) {
      this.modulesInMenu.next([])
      this.homeInitialized.next(false)
      this.loading.next(true)
      // retrieve permissions for each module
      let resourceServers: Array<string> = [];
      allModules.forEach(module => {

        if (module.resourceServerId) {
          if (!(resourceServers.some(e => e === module.resourceServerId))) {
            resourceServers.push(module.resourceServerId.trimEnd())
          } else {
          }
        } else {
          console.error("Module ", module.name, " has not resourceServerId")
        }
      });

      this.authService.loadPermissions(resourceServers).subscribe({
        next: () => {
          if (this.environment.grafana != '') {
            this.authService.canAccessMicroapp('grafana') ? this.includeGrafana.next(true) : this.includeGrafana.next(false);
          }
          this.authService.isAuthInitialized().pipe(takeUntil(this.homeInitialized)).subscribe({
            next: (authInitialized: boolean) => {
              if (authInitialized && authInitialized == true) {
                let modules: EmpusaMicroApp[] = [];
                this.homeInitialized.next(true)
                this.keycloak.getToken().then(token => {
                  const decoded: any = jwt_decode(token);
                  // AÑADIR IF PARA COMPROBAR SI PERTENECE A UN ADHERIDO
                  const userRoleEntities = decoded.role_entities
                  if(userRoleEntities) {
                    this.setTempIdData = {
                      entityRoles: userRoleEntities,
                    };                    
                  }
                  this.router.navigate([this.urlDefault]);
                });
                allModules.forEach(module => {
                  if (this.authService.canAccessMicroapp(module.name)) {
                    if (module.path === currentPath) {
                      setTimeout(() => {
                        this.router.navigate([this.urlDefault]);
                      }, 0);
                    } else if (module.appType === EmpusaMicroAppType.home && currentPath === '') {
                      setTimeout(() => {
                        this.router.navigate([this.urlDefault]);
                      }, 0);

                    }
                    modules.push(module);
                    modules.sort(function (a, b) {
                      return (a.order - b.order);
                    });
                  };
                });
                this.modulesInMenu.next(modules)
              }
            },
            error: (e: any) => {
              console.error(e)
              this.loading.next(false)
            },
            complete: () => {
              this.loading.next(false)
            }
          })
        },
        error: (e: any) => {
          console.error(e)
          this.loading.next(false)
        },
        complete: () => { }
      });

    }
  }
}
