<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">Seleccionar el adherido</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" >
    <form [formGroup]="formGroup">
        <div class="col-sm flex-grow-0">
          <mat-form-field appearance="outline">
            <mat-select formControlName="adheridoForm" id="adherido" placeholder="Adherido">
              <mat-option *ngFor="let adherido of adheridos" [value]="adherido.id">
                {{adherido.razonSocial}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('adheridoForm', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
    </form>
  </div>


  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
