<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="col-content show-display">
  <div class="header-content mb-xl-5 mb-3">
    <div class="heading">{{gestorInfo?.razonSocial}}</div>
    <div class="actions">
      <button type="button" class="btn btn-primary" data-toggle="modal" (click)="abrirNuevoDocumento()">
        Nuevo Documento
      </button>
    </div>
  </div>
  <div *ngIf="gridArray.length !== 0 else messageClear"
    class="datatable-container table-responsive overflow-table table-detail-width-100">
    <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (actionButton)="action($event)"></lib-ge-table>
  </div>
  <ng-template #messageClear>
    <div class="container">
      <div class="mensaje-container">
        <p class="mensaje-linea">Sin información registrada</p>
      </div>
    </div>
  </ng-template>
