import { Injectable } from "@angular/core";

export interface Combo {
  id: string | number,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class ComboService {
  // Tipos de comunicaciones
  getTiposComunicaciones(): Combo[] {
    return [
      { id: 'INCIDENCIA', name: 'Incidencia' },
      { id: 'SUGERENCIA', name: 'Sugerencia' },
      { id: 'RECLAMACION', name: 'Reclamación' },
    ];
  }
  // Tipos de teléfono
  getTiposPrioridadesComs(): Combo[] {
    return [
      { id: 'BAJA', name: 'Baja' },
      { id: 'MEDIA', name: 'Media' },
      { id: 'ALTA', name: 'Alta' }
    ];
  }
  getCategoriasComs(): Combo[] {
    return [
      { id: 'TECNICA', name: 'Técnica' },
      { id: 'FUNCIONAL', name: 'Funcional' },
      { id: 'FUNCIONAL_Y_TECNICA', name: 'Funcional y técnica' }
    ]
  }
}