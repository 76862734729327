import { NgModule } from '@angular/core';
import {OpenSddrComponent} from '../pages/open-sddr.component';
import { RouterModule } from '@angular/router';
import {CommonModule, DatePipe} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from './material.module';
import {
  OpenSddrRequestsWithdrawnSearchComponent
} from "../pages/components-open-sddr-requests-withdrawn/open-sddr-requests-withdrawn-search/open-sddr-requests-withdrawn-search.component";
import {ApiCore, ApiDocum} from "api-rest";
import {NgbPaginationModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EmpusaSpinnerComponent} from "@empusa/empusa-core";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {CustomDatePickerModule} from "./custom-date-picker.module";
import {GeTableComponent} from "../share/ge-table.component";
import {MatInputModule} from "@angular/material/input";
import {GetAttributePipe} from "../share/pipe/get-attribute.pipe";
import {GeSelectPaginationComponent} from "../share/ge-select-pagination/ge-select-pagination.component";
import {NgSelectModule} from "@ng-select/ng-select";
import { OpenSddrReuseSearchComponent } from '../pages/components-open-sddr-reuse/open-sddr-reuse-search/open-sddr-reuse-search.component';
import { OpenSddrRequestsWithdrawnDetailComponent } from '../pages/components-open-sddr-requests-withdrawn/open-sddr-requests-withdrawn-detail/open-sddr-requests-withdrawn-detail.component';

export const CoreConfigFactory = () =>
  new ApiCore.Configuration(<ApiCore.ConfigurationParameters>{});

export const CoreBasePathFactory = (environment: any) => {
  const basePath = environment.urlBackCore;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const DocumConfigFactory = () =>
  new ApiDocum.Configuration(<ApiDocum.ConfigurationParameters>{});

export const DocumBasePathFactory = (environment: any) => {
  const basePath = environment.urlBackDocum;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const explorerRoutes = [
  {
    path: '',
    component: OpenSddrComponent,
    children: [
      { path: 'reuse-search', component: OpenSddrReuseSearchComponent },
      //{path: 'requests-reuse-search', component: OpenSddrReuseSearchComponent},
      {path: 'requests-withdrawn-search', component: OpenSddrRequestsWithdrawnSearchComponent}, // todo fix
      //{path: 'detail/:id', component: CollectionPointsDetailComponent}
    ]
  },
];

@NgModule({
  declarations: [
    OpenSddrComponent,
    OpenSddrRequestsWithdrawnSearchComponent,
    GeTableComponent,
    GetAttributePipe,
    GeSelectPaginationComponent,
    OpenSddrReuseSearchComponent,
    OpenSddrRequestsWithdrawnDetailComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    RouterModule.forChild(explorerRoutes),
    NgbTooltipModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    ApiCore.ApiModule.forRoot(CoreConfigFactory),
    ApiDocum.ApiModule.forRoot(DocumConfigFactory),
    RouterModule.forChild(explorerRoutes),
    MaterialModule,
    EmpusaSpinnerComponent,
    CustomDatePickerModule,
    NgxMatSelectSearchModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [TranslateModule, OpenSddrComponent],
  providers: [
    DatePipe,
    {
      provide: ApiCore.BASE_PATH,
      useFactory: CoreBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiDocum.BASE_PATH,
      useFactory: DocumBasePathFactory,
      deps: ['environment'],
    },
  ],
})
export class OpenSddrHomeModule {
  constructor() {
   let compilationVersion = ""
    console.log('open-sddr version: ' + compilationVersion);
  }
}
