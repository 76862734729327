/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ComposicionMaterialFilterDTO } from './composicionMaterialFilterDTO';
import { DeclaracionEnvaseFilterDTO } from './declaracionEnvaseFilterDTO';


export interface DeclaracionEnvaseMaterialFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    id?: Array<string>;
    declaracionEnvase?: DeclaracionEnvaseFilterDTO;
    composicionMaterial?: ComposicionMaterialFilterDTO;
    pesoUnitario?: Array<string>;
    pesoTotal?: Array<string>;
    porcReciclado?: Array<string>;
    tipoElemento?: DeclaracionEnvaseMaterialFilterDTO.TipoElementoEnum;
}
export namespace DeclaracionEnvaseMaterialFilterDTO {
    export type TipoElementoEnum = 'TAPONES' | 'ETIQUETAS' | 'OTROS_ELEMENTOS';
    export const TipoElementoEnum = {
        Tapones: 'TAPONES' as TipoElementoEnum,
        Etiquetas: 'ETIQUETAS' as TipoElementoEnum,
        OtrosElementos: 'OTROS_ELEMENTOS' as TipoElementoEnum
    };
}


