import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { KamUserService } from '../../../../services/kam-user.services';
import { KeycloakUserModel } from '../../../../model/keycloak-user.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'keycloak-auth-manager-kam-nu-step1',
  templateUrl: './kam-nu-step1.component.html',
  styleUrls: ['./kam-nu-step1.component.css']
})
export class KamNuStep1Component implements OnInit , OnDestroy {

  @Output() completed = new EventEmitter<boolean>();

  step1Completed: boolean=false;
  subscription: Subscription|undefined;
  initialUserValues: KeycloakUserModel | undefined;

  constructor(private kamUserService: KamUserService,) { }

  ngOnInit(): void {
    this.subscription = this.kamUserService.userDataSubscription().subscribe(user=>{
      if (user){
        this.initialUserValues = user;      
      }
    })
  }

  isUserFormCompleted($event:any){
    setTimeout(() => { 
      this.step1Completed=$event;
      this.completed.emit($event)
    }, 100);
  }

  setUserData($event:any){
    setTimeout(() => { 
      this.kamUserService.setUserData($event)
    }, 100);
  }

  step1End(){

  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription?.unsubscribe()
  }

}
