<!-- MIGAS DE PAN Y TÍTULO -->
<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Adheridos</li>
          <li class="breadcrumb-item active"><a>Búsqueda declaración envases</a></li>
        </ol>
      </nav>
      <div class="content-title">
        <h2 class="title-pag">Búsqueda declaración envases</h2>
        <button *ngIf="this.authService.can('dec_envases', 'create')" (click)="confirmacionCreacionDeclaracion()"
          type="button" class="btn btn-primary" data-toggle="modal"><span class="icon-add"></span>Nueva
          declaración</button>
      </div>
    </div>
  </div>
</div>

<!-- FIN MIGAS DE PAN Y TÍTULO -->

<!-- CONTENIDO - FICHA -->
<div class="container-form">
  <!-- Formulario de búsqueda -->
  <form class="container form-search py-3 padding-form" [formGroup]="searchForm">
    <div class="row-fields">
      <div class="field field-grow" *ngIf="canViewResource('DE-ENV-GEST')">
        <label for="adherido">Adherido</label>
        <lib-ge-select-pagination (selectedModel)="selectedModel()" [endPointFilter]="adheridosEndpoint" [multiple]=true
          (filteredList)="filterList($event)" [updateList]="updateList" [disabled]="flagDisabledForm"
          [placeHolder]="'--Adheridos--'" [formControlNameSelect]="getFormControl('adherido')"
          [objQueryName]="'razonSocial'" descripcion="razonSocial">
        </lib-ge-select-pagination>
      </div>
      <div class="field field-grow" *ngIf="!canViewResource('DE-ENV-GEST')">
        <label for="adherido">Adherido</label>
        <mat-form-field appearance="outline" class="custom-mat-form-field">
          <mat-select formControlName="adherido" id="adherido" placeholder="Adherido" multiple>
            <mat-option *ngFor="let adherido of adheridosAll" [value]="adherido.id">
              {{adherido.razonSocial}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field field-20">
        <label for="estado">Estado</label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="estadoDeclaracion" id="estadoDeclaracion" placeholder="--Estado--">
            <mat-option *ngFor="let estado of estados" [value]="estado.id">
              {{estado.descripcion}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field field-15">
        <label for="anio">Año de los datos</label>
        <mat-form-field appearance="outline">
          <input matInput type="number" formControlName="anio" id="anio" placeholder="Año de los datos">
        </mat-form-field>
      </div>
      <div class="field field-10" *ngIf="this.authService.can('dec_envases', 'access-facturar')">
        <ng-container>
          <mat-checkbox class="margin-left-10" color="primary" formControlName="afacturar"><label>A facturar</label>
          </mat-checkbox>
        </ng-container>
      </div>
      <div class="field field-10" *ngIf="this.authService.can('dec_envases', 'access-facturar')">
        <ng-container>
          <mat-checkbox class="margin-left-10" color="primary" formControlName="facturada"><label>Facturada</label>
          </mat-checkbox>
        </ng-container>
      </div>
    </div>

    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" (click)="clearSearcher()">Limpiar</button>
      <button type="button" class="btn btn-primary" (click)="searchData(true)">Buscar</button>
    </div>
  </form>
  <!-- FIN Formulario de búsqueda -->
</div>
<div class="bg-second-table-info pt-3 pb-5 ">
  <div *ngIf="flagSearch; else messageClear" class="container">
    <div class="actions-table">
      <div class=""><span class="align-middle tit-table">{{totalSearch}} registros
          encontrados</span></div>
      <div class="botones-tabla">
        <div class="d-inline" *ngIf="this.authService.can('dec_envases', 'access-facturar')">
          <div class="dropdown show">
            <a class="btn btn-outline-primary" role="button" aria-haspopup="true" aria-expanded="false"
              (click)="abrirModalInforme()">
              <span>Informe facturación</span></a>
          </div>
        </div>
        <div class="d-inline" *ngIf="canAccessExport()">
          <div class="dropdown show">
            <a class="btn btn-outline-primary" role="button" aria-haspopup="true" aria-expanded="false"
              (click)="exportarCsv()">
              <span>Exportar</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-tabla-proy">
      <div class="card-body">
        <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
          <div class="datatable-container table-responsive">
            <!-- TABLA Listado con resultados de búsqueda -->
            <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (checked)="checked($event)"
              (actionButton)="action($event)"></lib-ge-table>
            <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
              <div class="d-flex mt-2">
                <ngb-pagination [pageSize]="pageSize" [collectionSize]="recordsTotal" [(page)]="pageNumber"
                  (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
                </ngb-pagination>
              </div>

              <div class="mt-2 selectPage">
                <label for="itemsPerPage">Mostrar:</label>
                <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                  <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                </select>
              </div>
            </div>
            <!-- FIN TABLA Listado con resultados de búsqueda -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #messageClear>
    <div class="mensaje-container">
      <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
        formulario,</p>
      <p class="mensaje-linea">o cree una nueva declaración de envase en "Nueva declaración"</p>
    </div>
  </ng-template>
</div>


<!-- FIN CONTENIDO -->
