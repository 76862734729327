import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { KeycloakGroupModel } from '../../../model/keycloak-group.model';
import { KamUserService } from '../../../services/kam-user.services';
import { KamUserRolesData } from '../kam-user-roles/kam-user-roles-data.interface';
import { KamUserGroupsData } from './kam-user-groups-data.interface';

@Component({
  selector: 'keycloak-auth-manager-kam-user-groups',
  templateUrl: './kam-user-groups.component.html',
  styleUrls: ['./kam-user-groups.component.css']
})
export class KamUserGroupsComponent implements OnInit {

  groupSelection: KeycloakGroupModel[]=[];
  initialGroupsSelected: string[]|undefined;
  subscription: Subscription | undefined;
  groupUnselect: KeycloakGroupModel[]=[];

  constructor(public dialogRef: MatDialogRef<KamUserGroupsData>,
    @Inject(MAT_DIALOG_DATA) public data: KamUserRolesData,
    private kamUserService: KamUserService) { 
    console.log(data)
    let initialGroups:string[] = []
    data.user.data?.groups.forEach(group=>{
      initialGroups.push(group.name)
    })
    if (initialGroups.length>0){
      this.initialGroupsSelected = initialGroups
    }
  }

  ngOnInit(): void {
  }

  groupSelectionUpdate($event: any){
    this.groupSelection = $event
    console.log(this.groupSelection)
  }

  groupsUnselectUpdate($event: KeycloakGroupModel[]){
    this.groupUnselect = $event
  }

  cancel(){
    this.dialogRef.close(undefined);
  }
  
  updateGroups(){
    let groupsToUpdateAndDelete: Observable<any>[]=[];
    
    this.groupSelection.forEach(aGroup => {
      groupsToUpdateAndDelete.push(this.kamUserService.putUserGroup(this.data.user.id,aGroup.id))
    })
        
    this.groupUnselect.forEach(aGroup =>{
      groupsToUpdateAndDelete.push(this.kamUserService.deleteUserGroup(this.data.user.id,aGroup.id))
    })
        
    forkJoin(groupsToUpdateAndDelete).subscribe({
      next: (response)  => {},
      error:(e) =>{
        console.error(e)
        this.dialogRef.close(false);
      },
      complete:()=>{
        this.dialogRef.close(true);
      },
    })

  }
}
