export const locale_es = {
    "lang": "es",
    "data": {        
        "KEYCLOAK-AUTH-MANAGER-MAIN": {
                NAME: "Gestión de Identidades",
                USERS: "Usuarios",
                ROLES: "Roles",
                GROUPS: "Grupos",
                PERMISSIONS: "Permisos",
        },
        "KAM-USERS-TAB": {
            USER: "Usuario",
            NEW_USER: "Nuevo usuario",
            NO_ROLE: "Sin roles asignados",
            USER_NAME: "Nombre de usuario",
            NAME: "Nombre",
            LAST_NAME: "Apellidos",
            EMAIL: "Correo",
            ENABLED: "Activo",
            PASSWORD: "Contraseña",
            SET_PASSWORD: "Establecer contraseña",
            REPEAT_PASSWORD: "Repetir contraseña",
            PASSWORD_NOT_MATCH: "Las contraseñas no coinciden",
            USER_DATA: "Datos de Usuario",
            ATRIBUTES:"Atributos",
            GROUPS: "Grupos",
            ROLES:" Roles",
            RESUME: "Resumen",
            NO_ROLES: "Sin pernetenecia a roles",
            NOT_BELONG_GROUP: "Sin pertenece a ningun grupo",
            NOT_ATTR: "Sin Atributos",
        },
        "KAM-ROLES-TAB": {
            NEW_ROLE:"Nuevo rol",
            NAME: "Nombre",
            DESCRIPTION: "Descripcion",
            ACTION: "Acciones",
            NO_PERMISSIONS:"Sin permisos",
            RESOURCE_SERVERS:"Servidores de Recursos",
        },
        "KAM-PERMISSIONS-RESOURCES": {
            RESOURCE_SERVERS:"Servidores de Recursos",
            RESOURCES: "Recursos",
            SCOPES:"ACCIONES",
            PERMISSIONS:"PERMISOS",
        },
        "KAM-GENERAL-DIALOG": {
            TENANT: "Propietario",
            BUILDING: "Edificio",
            INTERNAL: "Interno",
            CANCEL: "Cancelar",
            ACCEPT: "Aceptar",
            CLOSE: "Cerrar",
            NAME: "Nombre",
            DESCRIPTION: "Descripción",
            ERROR: "Error",
            NEXT:"Siguiente",
            BACK:"Anterior",
            CONFIRM:"Confirmar",
            TRUE: "Sí",
            FALSE: "No",
            UPDATE:"Actualizar",
            SEARCH:"Buscar",
        },
        "KAM-QUESTION-DIALOG": {
            DELETE_RESOURCE_TITLE: "Borrado de recurso",
            DELETE_RESOURCE_QUESTION: "Confirme la eliminación del recurso {{resource}}",
            DELETE_SCOPE_TITLE: "Borrar Acción",
            DELETE_SCOPE_QUESTION: "Confirme la eliminación de la acción {{scope}}",
            DELETE_USER_TITLE: "Borrar ususario",
            DELETE_USER_QUESTION: "Confirme la eliminación del usuario {{username}}",
            DELETE_GROUP_TITLE: "Borrar grupo",
            DELETE_GROUP_QUESTION: "Confirme la eliminación del grupo {{groupname}}"
        },
        "KAM-ADD-DIALOG": {
            ADD_RESOURCE_TITLE:"Añadir recurso",
            ADD_SCOPE_TITLE:"Añadir Acción",
            ADD_PERMISSIONS_TITLE:"Añadir Permiso",
            ADD_USER_TITLE:"Añadir Usuario",
            ADD_ROLE_TITLE:"Añadir Rol",
            ADD_GROUP_TITLE:"Añadir Grupo",
        },
        "KAM-UPDATE-DIALOG": {
            UPDATE_USER_ROLES_TITLE: "Actualización roles de usuario",
            UPDATE_USER_GROUPS_TITLE: "Actualización grupos de usuario",
            UPDATE_USER_ATTR_TITLE: "Actualización atributos de usuario",
        },
        "KAM-MANAGE-DIALOG": {
            MANAGE_RESOURCE_TITLE: "Modificacion de recurso",
            MANAGE_SCOPE_TITLE: "Modificacion Acción",
            MANAGE_USER_TITLE: "Modificacion ususario",
            MANAGE_GROUP_TITLE: "Modificacion grupo",
        },
        "KAM-ATTRIBUTES-FORM":{
            TYPE_STRING:"Campo de texto",
            TYPE_NUMBER:"Campo numérico",
            ATTRIBUTE_1_DESCRIPTION: "Lista de cosas",
            ATTRIBUTE_2_DESCRIPTION: "Un número",
            ATTRIBUTE_NO_DESCRIPTION: "",
            ATTRIBUTE_IS_DEFAULT_HOST: "Host por defecto",
            ATTRIBUTE_GROUP_TYPE: "Tipo de grupo",
        },
        "KAM-GROUPS-TAB": {
            NAME_COLUMN: "Grupo",
            NEW_GROUP: "Nuevo grupo",
            ATTRIBUTES_LABEL: "Atributos: ",
            NO_ATTRIBUTES: "Sin atributos",
            ROLES_LABEL: "Roles: ",
            NO_ROLES: "Sin pernetenecia a roles",
        },
        "KAM-MANAGE-GROUPS": {
            TITLE_NEW: "Nuevo grupo",
            TITLE_UPDATE: "Modificación del grupo {{groupname}}",
            TITLE_INFO_GROUP: "Información del grupo",
            TITLE_NEW_SUBGROUP: "Nuevo grupo en {{parentpath}}",
            "STEP1":{
                TITLE:"Nombre del grupo",
                NAME:"Nombre",
                NAME_PLACEHOLDER:"Nombre del grupo",
                NEXT:"Siguiente"
            },
            "STEP2":{
                TITLE:"Atributos",
                BACK:"Anterior",
                NEXT:"Siguiente"
            },
            "STEP3":{
                TITLE:"Roles",
                BACK:"Anterior",
                NEXT:"Siguiente"
            },
            "STEP4":{
                TITLE:"Resumen",
                NAME_LABEL:"Nombre",
                ATTRIBUTE_LABEL:"Atributos",
                ROLE_LABEL:"Roles",
                BACK:"Anterior",
                NEXT:"Confirmar"
            }
        },
        "KAM-ROLE-SELECTION": {
            TABLE_ROLE:"Rol",
            TABLE_DESCRIPTION:"Descripción"
        }
    },
    
}
