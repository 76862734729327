import { Component, Inject, OnInit } from '@angular/core';
import { KamManageGroupData } from '../manage-group/manage-group-data.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeycloakUserModel } from '../../../../../keycloak-auth-manager/src/model/keycloak-user.model';
import { KeycloakGroupModel } from '../../../../../keycloak-auth-manager/src/model/keycloak-group.model';
import { KeycloakRoleModel } from '../../../../../keycloak-auth-manager/src/model/keycloak-role.model';

@Component({
  selector: 'keycloak-auth-manager-group-data',
  templateUrl: './group-data.component.html',
  styleUrls: ['./group-data.component.css'],
})
export class KamGroupDataComponent implements OnInit {
  expandedElement: KeycloakUserModel | null = null;
  columnsToDisplay = ['name', 'actions'];
  group: KeycloakGroupModel | undefined;
  groupRoles: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: KamManageGroupData) {}

  ngOnInit(): void {
    this.group = this.data.subgroupfrom;
    if (this.data.subgroupfrom?.realmRoles)
      this.groupRoles = this.data.subgroupfrom?.realmRoles;
  }
}
