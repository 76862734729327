/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeclaracionEnvaseMaterialDTO } from './declaracionEnvaseMaterialDTO';
import { TipoEnvasesDTO } from './tipoEnvasesDTO';
import { DeclaracionCentroDTO } from './declaracionCentroDTO';
import { DocumentoDTO } from './documentoDTO';


export interface DeclaracionEnvaseDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    declaracionCentro?: DeclaracionCentroDTO;
    peligrosidad?: boolean;
    denominacion?: string;
    tipoEnvase?: TipoEnvasesDTO;
    tipologia?: DeclaracionEnvaseDTO.TipologiaEnum;
    pesoUnitario?: number;
    unidadesTotales?: number;
    pesoTotal?: number;
    vidaUtil?: number;
    ciclosReutilizacion?: number;
    contriRapAnioCurso?: number;
    depoRapAnioCurso?: number;
    contriRapAnioAnterior?: number;
    depoRapAnioAnterior?: number;
    envaseMaterial?: Array<DeclaracionEnvaseMaterialDTO>;
    deleted?: string;
    deletedBy?: string;
    acuerdoDeposito?: boolean;
    acuerdoCesion?: boolean;
    importeDeposito?: number;
    docModeloCesion?: DocumentoDTO;
    fabricanteProductor?: boolean;
    prestadorServicios?: boolean;
    docCertificadoReutilizables?: DocumentoDTO;
    simboloSddrcerrado?: boolean;
    envasesSddrcerrado?: boolean;
    unidadesCirculacion?: number;
    unidadesNuevas?: number;
    unidadesResiduos?: number;
    unidadesNoRetornadas?: number;
    unidadesExportadas?: number;
    unidadesEnvaseResiduo?: number;
    totalEnvasesAnterior?: number;
    tarifaRapAnioCurso?: number;
    tarifaRapAnioAnterior?: number;
    tarifaDepoAnioCurso?: number;
    tarifaDepoAnioAnterior?: number;
}
export namespace DeclaracionEnvaseDTO {
    export type TipologiaEnum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const TipologiaEnum = {
        Primario: 'PRIMARIO' as TipologiaEnum,
        Secundario: 'SECUNDARIO' as TipologiaEnum,
        Terciario: 'TERCIARIO' as TipologiaEnum
    };
}


