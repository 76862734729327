import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CombosService } from '../../../services/combos.service';
import { Adherido } from '../../../models/adherido';
import { AttachedService } from '../../../services/attached.service';
import { ValidateService } from '../../../services/validate.service';
import { Combo } from '../../../models/combo';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  KeycloakUserFilterDTO,
  UserManagementControllerService,
  UserRoleEntityDTO,
} from 'api-rest';
import Swal from 'sweetalert2';

@Component({
  selector: 'lib-preliminary-registration',
  templateUrl: './preliminary-registration.component.html',
  styleUrls: ['./preliminary-registration.component.scss'],
})
export class PreliminaryRegistrationComponent implements OnInit {
  dataForm: FormGroup;
  tiposDocumento: Combo[] = [];
  pageNumber: number = 0;
  pageSize: number = 100;
  flagProvDisabledForm: boolean = false;

  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';
  provinciasEndpoint = this.environment.urlBackCore + 'api/v1/core/provinces';
  codigoPostalEndpoint =
    this.environment.urlBackCore + 'api/v1/core/postalcodes';
  tipoViaEndpoint = this.environment.urlBackCore + 'api/v1/core/via-types';

  flagDisabledForm: boolean = false;
  updateList: boolean = false;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private attachedService: AttachedService,
    private validateSrv: ValidateService,
    private dialogRef: MatDialogRef<PreliminaryRegistrationComponent>,
    private comboSrv: CombosService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private userService: UserManagementControllerService
  ) {
    this.dataForm = this.formBuilder.group({
      name: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      documentType: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      documentNumber: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.documentNumber),
          ]),
        },
      ],
      country: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      county: [{ value: null, disabled: false }],
      municipality: [{ value: null, disabled: false }],
      cp: [{ value: null, disabled: false }],
      roadType: [{ value: null, disabled: false }],
      roadName: [{ value: null, disabled: false }],
      number: [{ value: null, disabled: false }],
      buildingBlock: [{ value: null, disabled: false }],
      escalera: [{ value: null, disabled: false }],
      piso: [{ value: null, disabled: false }],
      door: [{ value: null, disabled: false }],
      otherData: [{ value: null, disabled: false }],
      nameContac: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      firstSurname: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      secondSurname: [{ value: null, disabled: false }],
      email: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.emailPattern),
          ]),
        },
      ],
      tlfType: [{ value: null, disabled: false }],
      countryCode: [{ value: null, disabled: false }],
      tlfNumber: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([this.telefonoValidator.bind(this)]),
        },
      ],
    });
  }
  provinceEs: any;
  actionExecuted: boolean = false;
  selectProvince: any;
  ngOnInit(): void {
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    this.getProvinceByCp();

    this.dataForm.get('cp')?.valueChanges.subscribe((value: any) => {
      this.actionExecuted = false;
      this.dataForm.get('county')?.enable();
      if (
        value !== null &&
        !this.actionExecuted &&
        this.dataForm.get('country')?.value?.id == 69
      ) {
        const cpValue = value.toString();
        if (/^\d{5}$/.test(cpValue)) {
          const twoDigits = parseInt(cpValue.substring(0, 2), 10);
          this.selectProvince = this.provinceEs.find(
            (objeto: any) => objeto.id == twoDigits
          );
          if (this.selectProvince) {
            this.dataForm.get('county')?.setValue(this.selectProvince?.nombre);
            this.dataForm.get('county')?.disable();
          }
          this.actionExecuted = true;
        }
      }
    });

    this.dataForm.get('tlfType')?.valueChanges.subscribe(() => {
      this.dataForm.get('tlfNumber')?.updateValueAndValidity();
    });

    this.dataForm.get('countryCode')?.valueChanges.subscribe(() => {
      this.dataForm.get('tlfNumber')?.updateValueAndValidity();
    });
  }

  telefonoValidator(control: AbstractControl): { [key: string]: any } | null {
    const tipoTelefono = this.dataForm?.get('tlfType')?.value;
    const prefijoPais = this.dataForm?.get('countryCode')?.value;

    const phonePattern =
      /^(\+?\d{1,3})?[-. (]?(\d{1,4})[-. )]?(\d{1,4})[-. ]?(\d{1,4})[-. ]?(\d{1,9})$/;
    const isGeneralValid = phonePattern.test(control.value);

    if (!control.value) {
      return null;
    }

    if (!isGeneralValid) {
      return { invalidPhoneFormat: true };
    }

    if (
      prefijoPais?.prefijo == '34' ||
      (typeof prefijoPais === 'string' && prefijoPais == '34 (ESPAÑA)')
    ) {
      if (tipoTelefono === 'FIJO') {
        const valid = /^[89][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoFijo: true };
      } else if (tipoTelefono === 'MOVIL') {
        const valid = /^[67][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoMovil: true };
      }
    }

    return null;
  }

  // Se comprueba si el pais selecionado es España.
  esES(): boolean {
    let esEs = false;
    if (this.dataForm.get('country')?.value) {
      let pais = this.dataForm.get('country')?.value;
      esEs = pais === 'España' || pais?.iso === 'ES' ? true : false;
    }
    if (esEs) {
      this.dataForm.get('county')?.setValidators([Validators.required]);
      this.dataForm
        .get('cp')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.fiveDigitsPattern),
        ]);
      this.dataForm.get('municipality')?.setValidators([Validators.required]);
      this.dataForm.get('county')?.updateValueAndValidity();
      this.dataForm.get('cp')?.updateValueAndValidity();
      this.dataForm.get('municipality')?.updateValueAndValidity();
    } else {
      this.dataForm.get('municipality')?.clearValidators();
      this.dataForm.get('county')?.clearValidators();
      this.dataForm
        .get('cp')
        ?.setValidators([
          Validators.pattern(this.validateSrv.fiveDigitsPattern),
        ]);
      this.dataForm.get('municipality')?.updateValueAndValidity();
      this.dataForm.get('county')?.updateValueAndValidity();
      this.dataForm.get('cp')?.updateValueAndValidity();
    }
    return esEs;
  }

  public filterList(data: any) {}

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'country': {
        return this.dataForm.get('country') as FormControl;
        break;
      }
      case 'county': {
        return this.dataForm.get('county') as FormControl;
        break;
      }
      case 'cp': {
        return this.dataForm.get('cp') as FormControl;
        break;
      }
      case 'roadType': {
        return this.dataForm.get('roadType') as FormControl;
        break;
      }
      case 'countryCode': {
        return this.dataForm.get('countryCode') as FormControl;
        break;
      }
      default: {
        return this.dataForm.get('') as FormControl;
        break;
      }
    }
  }
  selectedModel(selectForm: any) {
    switch (selectForm) {
      case 'country': {
        if (this.dataForm.controls['country']?.value == null) {
          this.dataForm.controls['county']?.setValue(null);
          this.dataForm.controls['cp']?.setValue(null);
          this.dataForm.controls['municipality']?.setValue(null);
          this.dataForm.get('county')?.enable();
        }
        this.selectProvince = null;
        this.updateDocumentValidators();
        break;
      }
      case 'county': {
        break;
      }
      case 'cp': {
        break;
      }
      case 'roadType': {
        break;
      }
      default: {
        break;
      }
    }
  }

  saveData() {
    this.dataForm.markAllAsTouched();
    this.dataForm.updateValueAndValidity();

    if (this.dataForm.valid) {
      let cpp;
      let pais = this.dataForm.controls['country']?.value;
      let cp = { id: null, codigoPostal: this.dataForm.controls['cp']?.value };
      let provincia = this.selectProvince ?? this.dataForm.get('county')?.value;
      if (pais?.iso !== 'ES') {
        provincia = { nombre: this.dataForm.get('county')?.value };
        cpp = { nombre: this.dataForm.controls['cp'].value };
      }

      const adherido: any = {
        id: null,
        domicilios: [
          {
            id: null,
            tipoDireccion: {
              id: 1,
              descripcion: 'Social',
            },
            requerido: false,
            pais: this.dataForm.controls['country'].value,
            provincia: provincia,
            codigoPostal: pais?.iso !== 'ES' ? cpp : cp,
            municipio: this.dataForm.controls['municipality'].value,
            tipoVia: this.dataForm.controls['roadType'].value,
            nombreVia: this.dataForm.controls['roadName'].value,
            numero: this.dataForm.controls['number'].value,
            bloque: this.dataForm.controls['buildingBlock'].value,
            escalera: this.dataForm.controls['escalera'].value,
            piso: this.dataForm.controls['piso'].value,
            puerta: this.dataForm.controls['door'].value,
            otrosDatos: this.dataForm.controls['otherData'].value,
            fechaAlta: null,
            fechaModificacion: null,
            fechaBaja: null,
          },
        ],
        contactos: [
          {
            id: null,
            tipo_contacto: {
              id: 1,
              descripcionCorta: 'Gestión',
              descripcion: 'Contacto de Gestion',
            },
            requerido: false,
            nombre: this.dataForm.controls['nameContac'].value,
            apellido1: this.dataForm.controls['firstSurname'].value,
            apellido2: this.dataForm.controls['secondSurname'].value,
            tipo_documento: 'NIF',
            codigo_documento: '',
            tipo_telefono: this.dataForm.controls['tlfType'].value,
            codigoPais: this.dataForm.controls['countryCode'].value,
            numero_telefono: this.dataForm.controls['tlfNumber'].value,
            email: this.dataForm.controls['email'].value,
            fecha_alta: null,
            fecha_modificacion: null,
            fecha_baja: null,
            cargo: null,
            recibir_comunicaciones: false,
            recibir_comunicaciones_contrato: false,
            organo_participa: null,
            registro_mercantil_de: '',
            escritura_de: '',
            con_numero: '',
            notario: '',
            domicilio_notario: '',
            acreditacion_poderes: false,
            de: '',
          },
        ],
        centrosEnvasado: null,
        estado: null,
        // cifra_de_negocio: { id: 0, descripcion: '', importe_cuota: 0 },
        grupoEmpresarial: false,
        cifGrupo: '',
        razonSocial: this.dataForm.controls['name'].value,
        tipoDocumento: this.dataForm.controls['documentType'].value,
        codigoDocumento: this.dataForm.controls['documentNumber'].value,
        registroProductor: null,
        asociado: false,
        puestaEnMercado: null,
        fechaAlta: null,
        fechaModificacion: null,
        fechaBaja: null,
        empresaExtranjera: false,
        descripcionActividad: null,
        tipoTelefono: null,
        codigoPais: null,
        telefono: null,
        email: this.dataForm.controls['email'].value,
        paginaWeb: null,
        numeroFax: null,
        tipoEmpresa: null,
        fabricante: false,
        conAdenda: false,
        descripcionAdenda: null,
        planEmpresarial: false,
        remitirInformacion: null,
        fechaFirmaContrato: null,
        fechaAdhesionFinalizada: null,
        fechaBajaEnvanet: null,
      };

      const filtroUsuario: KeycloakUserFilterDTO = {
        email: [adherido.contactos[0].email],
      };
      this.userService
        .getUsuarios(filtroUsuario, { page: 0, size: 10 })
        .subscribe((existingUsers) => {
          if (existingUsers.datos && existingUsers.datos.length > 0) {
            const existingUser = existingUsers.datos[0];
            const usuarioEnvalora = existingUser.userRolesEntities?.some(
              (roleEntity) =>
                roleEntity.entityType == UserRoleEntityDTO.EntityTypeEnum.Gestor
            );
            if (usuarioEnvalora) {
              this.snackBarSrv.showSnackBar(
                'El email de contacto pertence a un usuario existente con rol Envalora',
                'success'
              );
            } else {
              this.confirmacion(
                `Ya existe un usuario con email ${adherido.contactos[0].email} ¿Quiere asignar este mismo usuario al nuevo registro de adherido?`,
                () => this.performSave(adherido)
              );
            }
          } else {
            this.performSave(adherido);
          }
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  performSave(adherido: any) {
    this.spinnerSrv.loadingShow();
    this.attachedService.createAdherido(adherido).subscribe({
      next: (value: any) => {
        this.spinnerSrv.loadingHide();
        this.closeDialog();
        this.snackBarSrv.showSnackBar(
          'Alta preliminar realizada correctamente',
          'success'
        );
      },
      error: (error: any) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.dataForm.controls[controlName].hasError(errorName) &&
      this.dataForm.controls[controlName].touched
    );
  }

  controlHasErrorSelect(controlName: string) {
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  getProvinceByCp() {
    this.spinnerSrv.loadingShow();
    this.attachedService
      .getProvinciasByCp(this.pageNumber, this.pageSize)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.provinceEs = data.datos;
            this.spinnerSrv.loadingHide();
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmacion(titulo: string, callBack: Function): void {
    Swal.fire({
      text: titulo,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        callBack();
      }
    });
  }

  updateDocumentValidators() {
    const pais = this.dataForm.controls['country']?.value;
    const idTipoDoc = this.dataForm.get('documentType')?.value;
    const documentNumberControl = this.dataForm.get('documentNumber');

    if (pais?.iso && pais.iso !== 'ES') {
      documentNumberControl?.clearValidators();
    } else {
      this.setDocumentValidators(idTipoDoc);
    }

    documentNumberControl?.updateValueAndValidity();
  }

  tipoDocumentoSel(idTipoDoc: string) {
    this.setDocumentValidators(idTipoDoc);
    const documentNumberControl = this.dataForm.get('documentNumber');
    this.updateDocumentValidators();
    documentNumberControl?.updateValueAndValidity();
    documentNumberControl?.markAsTouched();
  }

  setDocumentValidators(idTipoDoc: string) {
    const documentNumberControl = this.dataForm.get('documentNumber');
    documentNumberControl?.clearValidators();

    const validators = [Validators.required];
    switch (idTipoDoc) {
      case 'NIF':
        validators.push(Validators.pattern(this.validateSrv.nifPattern));
        break;
      case 'DNI':
        validators.push(Validators.pattern(this.validateSrv.dniPattern));
        break;
      case 'NIE':
        validators.push(Validators.pattern(this.validateSrv.niePattern));
        break;
    }

    documentNumberControl?.setValidators(validators);
  }
}
