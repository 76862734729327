import { Component, OnInit } from '@angular/core';
import { KamUserService } from '../../../../services/kam-user.services';
import { KeycloakRoleModel } from '../../../../model/keycloak-role.model';

@Component({
  selector: 'keycloak-auth-manager-kam-nu-step4',
  templateUrl: './kam-nu-step4.component.html',
  styleUrls: ['./kam-nu-step4.component.css']
})
export class KamNuStep4Component implements OnInit {
  
  initialRolesSelected: string[]|undefined;
  roleSelection: KeycloakRoleModel[] | undefined;
  
  constructor(private kamUserService: KamUserService,) { }

  ngOnInit(): void {
  }


  rolesSubscriptionUpdate($event: any){
    this.roleSelection = $event
    if (this.roleSelection)
      this.kamUserService.setUserSelectedRoles(this.roleSelection)
    else
      this.kamUserService.setUserSelectedRoles([])
  }

  step4End(){
    
  }
}
