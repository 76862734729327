<div class="panel">
  <div class="div-total"><span class="color-inf">% Total: </span><span
      class="color-inf-porciento">{{ total | commaDecimal }}%</span></div>
  <div style="max-height: 270px; overflow-y: auto;">
    <form [formGroup]="comerFormGroup">
      <div style="display:flex;flex-wrap: wrap;">
        <div *ngFor="let el of comunidadesAutonomas;" class="cont-ccaa">
          <div class="ccaa-name"><span>{{el.nombre}}</span></div>
          <div>
            <mat-form-field appearance="outline" class="ccaa-value">
              <input [formControlName]="el.id" matInput type="text" appPercentNumber>
            </mat-form-field>
            %
          </div>
        </div>
      </div>
    </form>
  </div>


</div>
<div class="button-cont" *ngIf="canAccessComunidadesEdit() && canAccessEditAllGlobal()">
  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardarComercializacion()"
    aria-label="Guardar">Guardar</button>
</div>
