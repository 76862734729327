import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaMicroAppType } from '@empusa/empusa-core';
import { KeycloakAuthManagerHomeModule } from './keycloak-auth-manager.home.module';


@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class KeycloakAuthManagerModule implements EmpusaMicroApp{
  name: string = "keycloak-auth-manager";
  description: string = "keycloak-auth-manager";
  icon: string = "perm_identity";
  page: any = KeycloakAuthManagerHomeModule;
  homeApp: boolean = false;
  path: string = "keycloak-auth-manager";
  order: number = 10;
  resourceServerId: string = "keycloak-auth-manager-resources-server";
  appType: EmpusaMicroAppType = EmpusaMicroAppType.normal;

  constructor() {
    //we register ourselves
    (<any>window).empusa(this);    
    console.debug("Load microapp ", this.name);
  }

}
