/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CategoriaEnvaseFilterDTO } from './categoriaEnvaseFilterDTO';
import { TipoEnvasesFilterDTO } from './tipoEnvasesFilterDTO';


export interface TarifaAdheridoSDDRAbiertoFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    anio?: Array<string>;
    categoria?: CategoriaEnvaseFilterDTO;
    tipoEnvases?: TipoEnvasesFilterDTO;
    peligrosidad?: boolean;
    tipologia?: TarifaAdheridoSDDRAbiertoFilterDTO.TipologiaEnum;
}
export namespace TarifaAdheridoSDDRAbiertoFilterDTO {
    export type TipologiaEnum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const TipologiaEnum = {
        Primario: 'PRIMARIO' as TipologiaEnum,
        Secundario: 'SECUNDARIO' as TipologiaEnum,
        Terciario: 'TERCIARIO' as TipologiaEnum
    };
}


