import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { KamUserService } from '../../../services/kam-user.services';
import { KeycloakUserCredentials, KeycloakUserModel } from '../../../model/keycloak-user.model';

@Component({
  selector: 'keycloak-auth-manager-kam-user-form',
  templateUrl: './kam-user-form.component.html',
  styleUrls: ['./kam-user-form.component.css']
})
export class KamUserFormComponent implements OnInit {

  @Input() update: boolean|undefined;
  @Input() userDataIn: KeycloakUserModel|undefined;
  
  @Output() userData = new EventEmitter<KeycloakUserModel>();
  @Output() userCredentials = new EventEmitter<KeycloakUserCredentials|undefined>();
  @Output() userFormCompleted = new EventEmitter<boolean>();
  
  formGroup: FormGroup | undefined;
  emailCtrl = new FormControl('', [Validators.required,Validators.email]);
  userNameCtrl = new FormControl({value: '', disabled: false}, [Validators.required]);
  firstNameCtrl = new FormControl('');
  lastNameCtrl = new FormControl('');
  enabledCtrl = new FormControl(true);
  setPasswordCtrl = new FormControl(false);
  newPasswordCtrl = new FormControl('');
  repeatPasswordCtrl = new FormControl('');  
  showPass: boolean = false;
  

  constructor() { }

    ngOnInit(): void {
      if (this.update)
        this.update=true;
      this.buildForm();
    }
  
    private buildForm() {
      if (this.update){
        this.emailCtrl.setValue(this.userDataIn?.email?this.userDataIn?.email:null);
        if (this.userDataIn?.username)
          this.userNameCtrl.setValue(this.userDataIn?.username);
        this.userNameCtrl.disable(),
        this.firstNameCtrl.setValue(this.userDataIn?.firstName?this.userDataIn?.firstName:null);
        this.lastNameCtrl.setValue(this.userDataIn?.lastName?this.userDataIn?.lastName:null);
        this.enabledCtrl.setValue(this.userDataIn?.enabled?this.userDataIn?.enabled:false);
      }
      this.formGroup = new FormGroup({
        userEmail: this.emailCtrl,
        userName: this.userNameCtrl,
        firstName: this.firstNameCtrl,
        lastName: this.lastNameCtrl,
        enabled:this.enabledCtrl,
        setPassword: this.setPasswordCtrl,
        newPassword: this.newPasswordCtrl,
        repeatPasswordCtrl: this.repeatPasswordCtrl
      });    

      
      this.userFormCompleted.emit(this.formGroup?.valid); 
      this.emitUser();
      this.formGroup.valueChanges.subscribe(val=>{
        this.emitUser();
        this.userFormCompleted.emit(this.formGroup?.valid);      
      })
    }

    emitUser(){
      let user:KeycloakUserModel = {
        id: "",
        username: this.userNameCtrl.value?this.userNameCtrl.value:"",
        email: this.emailCtrl.value?this.emailCtrl.value:"",
        firstName: this.firstNameCtrl.value?this.firstNameCtrl.value:"",
        lastName: this.lastNameCtrl.value?this.lastNameCtrl.value:"",
        enabled:this.enabledCtrl.value?this.enabledCtrl.value:false,       
      }
      this.userData.emit(user)
      if (this.setPasswordCtrl.value){    
        let credentials: KeycloakUserCredentials ={
          temporary: false,
          type: 'password',
          value: this.newPasswordCtrl.value?this.newPasswordCtrl.value:"",
        }
        this.userCredentials.emit(credentials);      
      }else{
        this.userCredentials.emit(undefined);   
      }
    }

  passwordConfirming(c: AbstractControl): { passwordNoMatch: boolean }|null {    
    if (c.get('newPassword')?.value !== c.get('repeatPasswordCtrl')?.value) {
      return {passwordNoMatch: true};
    }
    return null;
  }


  setPasswordCtrlChange(){
    if (this.setPasswordCtrl.value){      
      this.repeatPasswordCtrl.setValidators([Validators.required])     
      this.newPasswordCtrl.setValidators([Validators.required])
      this.formGroup?.setValidators(this.passwordConfirming)
      this.evaluateForm();
      this.showPass = true;
    }else{
      this.repeatPasswordCtrl.clearValidators()
      this.newPasswordCtrl.clearValidators()
      this.formGroup?.clearValidators()
      this.evaluateForm();
      this.showPass = false;
    }
  }
  
  private evaluateForm() {
    if (this.formGroup){
      Object.keys(this.formGroup.controls).forEach(key => {
        //if (this.formGroup)
        this.formGroup?.get(key)?.updateValueAndValidity();
      });
      this.formGroup.updateValueAndValidity()
    }
  }

}
