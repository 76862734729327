import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { ContactoControllerService, ContactoDTO } from 'api-rest';
import { CollectionPointsManagersContactDialogComponent } from '../collection-points-managers-contact-dialog/collection-points-managers-contact-dialog.component';

@Component({
  selector: 'app-collection-points-managers-contact-data',
  templateUrl: './collection-points-managers-contact-data.component.html',
  styleUrls: ['./collection-points-managers-contact-data.component.scss'],
})
export class CollectionPointsManagersContactDataComponent implements OnChanges {
  @Input() puntoRecogidaInfo!: any;
  @Output() public refrescarPuntosRecogidaEvent = new EventEmitter<any>();

  contactos: ContactoDTO[] = [];

  gridArray: any[] = [];

  headArray: any = [
    {
      Head: 'Requerido',
      FieldName: 'requerido',
      check: true,
      disabled: true,
    },
    {
      Head: 'Tipo contacto',
      FieldName: 'tipo_contacto',
      Attribute: 'descripcionCorta',
      Tooltip: true,
    },
    {
      Head: 'Nombre',
      FieldName: 'fullName',
      Tooltip: true,
    },
    {
      Head: 'Tipo telf.',
      FieldName: 'tipo_telefono',
    },
    {
      Head: 'Teléfono',
      FieldName: 'numero_telefono',
    },
    {
      Head: 'Email',
      FieldName: 'email',
      Tooltip: true,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '8',
      permanent: true,
    },
  ];

  constructor(
    public dialog: MatDialog,
    private spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private contactoControllerSrv: ContactoControllerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.puntoRecogidaInfo?.contactos) {
      this.contactos = this.puntoRecogidaInfo?.contactos;
      //TODO aplicar permisos
      this.gridArray = this.canAccessAll(this.contactos);
    }
  }

  canAccessAll(list: any) {
    const resultListShow = this.authService.can(
      'ptosrecogida-gestor-contactos',
      'access'
    );
    const resultDelete = this.authService.can(
      'ptosrecogida-gestor-contactos',
      'delete'
    );

    const listPermission = list.map((objeto: any) => {
      const noRequerido = !objeto.requerido || objeto.requerido === null;
      return {
        ...objeto,
        canAccessView: resultListShow,
        //canAccessListEdit: resultListEdit,
        canAccessDelete: resultDelete && noRequerido,
      };
    });

    return listPermission;
  }
  action(item: any) {
    if (item.nameButton == 'view') {
      this.abrirModalContacto(item);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }
  confirmacionEliminar(element?: ContactoDTO): void {
    swal
      .fire({
        text: '¿Desea eliminar el contacto?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.eliminarContacto(element);
        }
      });
  }
  eliminarContacto(element?: ContactoDTO) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.contactoControllerSrv.borrarContacto(element.id).subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El contacto se ha eliminado correctamente',
            'success'
          );
          this.refrescarPuntosRecogidaEvent.emit(true);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar el contacto',
            'error'
          );
        },
      });
    }
  }
  abrirModalContacto(element?: any): void {
    const dialogRef = this.dialog.open(
      CollectionPointsManagersContactDialogComponent,
      {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          contacto: element,
          puntoRecogidaInfo: this.puntoRecogidaInfo,
        },
        disableClose: true,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refrescarPuntosRecogidaEvent.emit(true);
      }
    });
  }

  canAccessCreateContact(): boolean {
    return (
      this.authService.can('ptosrecogida-gestor-contactos', 'create') &&
      this.puntoRecogidaInfo?.estado?.id != 4
    );
  }
}
