import { FormControl, FormGroup, Validators } from "@angular/forms";
import { KeycloakAttributeConfig } from "../model/keycloak-attribute-config.model";

export class KamAttributesHelper{
    public attributeList: KeycloakAttributeConfig[]=[];
    public attributesFormGroup: FormGroup<any> | undefined;
    
    constructor(entityAttributes:any, initialAttributesValues:Map<string, string[]>|undefined){
        this.attributesFormGroup = new FormGroup({})
        Object.keys(entityAttributes).forEach(attributeId=>{
            let oneAttribute:KeycloakAttributeConfig=entityAttributes[attributeId]      
            oneAttribute.id =  attributeId;
            
            let initialValue:string|undefined
            let initialValues:string[] | undefined = initialAttributesValues?.get(attributeId);
            if (initialValues){
              initialValue=initialValues[0];
            }
            
            //check is multiple and has more values
            if (oneAttribute.isList){
              let oneAdded=false;
              initialAttributesValues?.forEach((value: string[], key: string) => {
                if (key.startsWith(attributeId+"#")){
                  this.addControlAttributeToForm(oneAttribute,initialAttributesValues);
                  oneAdded=true;
                }
              });
              if (!oneAdded){
                this.addControlAttributeToForm(oneAttribute,initialAttributesValues);
              }
            }else{
              this.addControlAttributeToForm(oneAttribute,initialAttributesValues);
            }
        })
    }


    public addControlAttributeToForm(attribute: KeycloakAttributeConfig,initialAttributesValues:Map<string, string[]>|undefined) {
        let oneAttribute = {...attribute}
        let control: FormControl | undefined = undefined;        
        if (!oneAttribute.description)
          oneAttribute.description = "KAM-ATTRIBUTES-FORM.ATTRIBUTE_NO_DESCRIPTION";
    
        let validators = [];
        if (oneAttribute.mandatory) {
          validators.push(Validators.required);
        }
    
        let controlName = attribute.id;
        if (oneAttribute.isList) {
          let index = 0
          //calculate index
          this.attributeList.forEach(attribute=>{
            if (attribute.id == oneAttribute.id){
              if (attribute.indexInList){
                if (attribute.indexInList>index){
                  index=attribute.indexInList
                }
              }
            }
          })
          
          oneAttribute.indexInList = index + 1
          controlName = controlName + "#" +  oneAttribute.indexInList 
        }
        oneAttribute.controlName = controlName;

        let initialValue: string = "";
        if (initialAttributesValues){
          const initialValueList = initialAttributesValues.get(oneAttribute.controlName)
          initialValue =initialValueList?initialValueList[0]:""
        }
        switch (oneAttribute.type) {
          case 'string': {
            control = new FormControl(initialValue, validators);
            this.attributeList.push(oneAttribute);
            break;
          } //string
          case 'number': {
            validators.push(Validators.pattern("^[0-9]*$"));
            control = new FormControl(initialValue, validators);
            this.attributeList.push(oneAttribute);
            break;
          } //string
          case 'enum':{            
            control = new FormControl(initialValue, validators);
            this.attributeList.push(oneAttribute);
            break;
          }
          default: {
            console.error("attribute not valid", oneAttribute.id, oneAttribute);
          }
        } //switch     
    
        if (control){
          this.attributeList.sort((a,b)=> a.id.localeCompare(b.id))
          this.calculateLast(attribute);
          this.attributesFormGroup?.addControl(controlName, control);
        }
      }
    

    removeControl(attribute:KeycloakAttributeConfig){
        const whereIs =this.attributeList.findIndex((attr:KeycloakAttributeConfig) => attr.controlName==attribute.controlName)
        if (whereIs> -1){
            this.attributeList.splice(whereIs,1);
            this.calculateLast(attribute);
            this.attributesFormGroup?.removeControl(attribute.controlName);
            this.attributesFormGroup?.updateValueAndValidity();
        }
    }

    private calculateLast(attribute:KeycloakAttributeConfig){
        const max = Math.max(...this.attributeList.map(o => {
            if (o.id==attribute.id)
                return o.indexInList
            else return 0
        }));
        this.attributeList.forEach(attr=>{
            if (attr.id == attribute.id)
                if (attr.indexInList == max){
                    attr.isLastInList = true
                }else{
                    attr.isLastInList = false
                }
        })
    }
}