export const THEMES = {
  default: {
    baseColor: "#04e100ad",
    secondaryColor: "#10252C",
    letfLogo: "url(./assets/img/logo_white.png)",    
    letfLogoheight: "55px",
    leftLogoWidth: "207px", 
    letfLogoMarginLeft: "10px",
    letfLogoTextColor: "#fff",
    letfLogoTextMarginTop: "-4px",
    letfLogoTextMarginLeft: "96px",
    leftLogoToMenuSpace: "30px",
    sideMenuButtonColor:"#646D82",
    sideMenuWidth: "350px",
    sideMenuBackgroundImage: "url(./assets/img/bg_menu.jpg)",
    sideMenuOptionsTextColor: "#fff",
    sideMenuOptionsTextHoverColor: "#fff",
    sideMenuOptionsBackHoverColor: "#04e100ad",
    sideMenuOptionSelectedTextColor: "#fff",
    sideMenuOptionSelectedBackColor: "#04e100ad",
    sideMenuCopyRightColor : "#fff",

    primaryHeaderTextColor: '#10252C',
    primaryHeaderBackColor : "#fff",
    rightLogo: "url(./assets/img/logo.png)",
    defaultUserImage: "url(./assets/img/default_user_empusa.png)",

    empusaAppHeaderClassicBackGroup: "#10252C",
    empusaAppHeaderClassicTextColor:"#fff",
    empusaAppMaxSize: "77vh",
    secondHeaderToContentSpace: "40px",

    gbaMh_HelloWorldImage: "url('./assets/img/home/vms.jpg')",
    gbaMh_AuthManagerImage: "url('./assets/img/bg_profile_off.jpg')",
    gbaMh_DashBoardsImage: "url('./assets/img/bg_dashboard_off.jpg')",
  },
  mori: {
    baseColor: "#2366B6",
    secondaryColor: "white",
    letfLogo: "url(./assets/img/logo_login_mori.png)",
    letfLogoheight: "45px",
    leftLogoWidth: "150px",
    letfLogoMarginLeft: "35px",
    letfLogoTextColor: "black",
    letfLogoTextMarginTop: "5px",
    letfLogoTextMarginLeft: "5px",
    leftLogoToMenuSpace: "60px",
    sideMenuButtonColor:"#646D82",
    sideMenuWidth: "250px",
    sideMenuBackgroundImage: "none",
    sideMenuOptionsTextColor: "#444444",
    sideMenuOptionsTextHoverColor: "#444444",
    sideMenuOptionsBackHoverColor: "#eaeaea",
    sideMenuOptionSelectedTextColor: "#2366B6",
    sideMenuOptionSelectedBackColor: "#dcebf9",
    sideMenuCopyRightColor : "#444444",

    primaryHeaderTextColor: '#10252C',
    primaryHeaderBackColor : "#fff",
    rightLogo: "url(./assets/img/logo_login_mori.png)",
    defaultUserImage: "url(./assets/img/default_user_blue.png)", 

    empusaAppHeaderClassicBackGroup: "#2366B6",
    empusaAppHeaderClassicTextColor:"#fff",
    empusaAppMaxSize: "100vh",
    secondHeaderToContentSpace: "40px",

    gbaMh_HelloWorldImage: "url('./assets/img/home/vms.jpg')",
    gbaMh_AuthManagerImage: "url('./assets/img/home/ums.jpg')",
    gbaMh_DashBoardsImage: "url('./assets/img/home/security.jpg')",
  },
};