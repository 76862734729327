import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmpusaMicroApp, SnackBarService } from '@empusa/empusa-core';
import { Store } from '@ngrx/store';
import { KeycloakClientModel } from '../../../model/keycloak-client.model';
import { KeycloakResourceModel } from '../../../model/keycloak-resource.model';
import { KeycloakScopeModel } from '../../../model/keycloak-scope.model';
import { KamResourceServerServices } from '../../../services/kam-resource-server.services';
import { AuthManagerState } from '../../../state/auth-manager.model';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { KamQuestionDialogComponent } from '../../common/kam-question-dialog/kam-question-dialog.component';
import { KamManagePermissionComponent } from '../kam-manage-permission/kam-manage-permission.component';
import { KamManageResourceComponent } from '../kam-manage-resource/kam-manage-resource.component';
import { KamManageScopeComponent } from '../kam-manage-scope/kam-manage-scope.component';
import * as authManagementActions from '../../../state/auth-manager.actions';
import { getSelectedResourceServer } from '../../../state/auth-manager.selectors';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'keycloak-auth-manager-kam-permissions-tab-resources',
  templateUrl: './kam-permissions-tab-resources.component.html',
  styleUrls: ['./kam-permissions-tab-resources.component.css']
})
export class KamPermissionsTabResourcesComponent implements OnInit {

  resourceServersSubscription: Subscription | undefined;
  resourceServer: KeycloakClientModel | undefined;

  resourcesSource: MatTableDataSource<KeycloakResourceModel> = new MatTableDataSource<KeycloakResourceModel>();
  resourcesdisplayedColumns: string[] = ['resource', 'actions'];
  resourcesPaginatorLength: number = 0;

  scopesSource: MatTableDataSource<KeycloakScopeModel> = new MatTableDataSource<KeycloakScopeModel>();
  scopesdisplayedColumns: string[] = ['name', 'actions'];
  scopesPaginatorLength: number = 0;

  permissionsSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  permissionsplayedColumns: string[] = ['name'];
  permissionsPaginatorLength: number = 0;

  loading = false;

  tallestCardHeight: number = 0;

  @ViewChild('paginatorResources') set paginatorResources(mp: MatPaginator) {
    this.resourcesSource.paginator = mp
  }

  @ViewChild('paginatorScopes') set paginatorScopes(mp: MatPaginator) {
    this.scopesSource.paginator = mp
  }

  @ViewChild('paginatorPermissions') set paginatorPermissions(mp: MatPaginator) {
    this.permissionsSource.paginator = mp
  }

  constructor(private store: Store<AuthManagerState>,
    public dialog: MatDialog,
    private kamResourceServerServices: KamResourceServerServices,
    private snackBarService: SnackBarService,
    @Inject('environment') private environment: any) { }

  ngOnInit(): void {
    this.loading = true;
    this.retrieveResourceServers();
    this.resourceServersSubscription = this.store.select(getSelectedResourceServer).subscribe(resourceServer => {
      this.resourceServer = resourceServer;
      //resources
      if (resourceServer?.resourceServerSettings?.resources) {
        this.resourcesSource.data = resourceServer?.resourceServerSettings?.resources;
        this.resourcesPaginatorLength = resourceServer?.resourceServerSettings?.resources.length
      } else {
        this.resourcesSource.data = [];
        this.resourcesPaginatorLength = 0;
      }

      //scopes
      if (resourceServer?.resourceServerSettings?.scopes) {
        this.scopesSource.data = resourceServer?.resourceServerSettings?.scopes;
        this.scopesPaginatorLength = resourceServer?.resourceServerSettings?.scopes.length
      } else {
        this.scopesSource.data = [];
        this.scopesPaginatorLength = 0
      }

      //permissions
      let permissionList: any[] = [];
      resourceServer?.resourceServerSettings?.resources.forEach(resource => {
        resource.scopes?.forEach(scopePermission => {
          const permission = {
            resource: resource.name,
            scope: scopePermission.name,
          };
          permissionList.push(permission);
        });
      })
      this.permissionsSource.data = permissionList
    })
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.addEventListener('change',this.updateTallestCardHeight as EventListener, true);
  }

  updateTallestCardHeight = (): void => {
    const cards = document.querySelectorAll('.example-card');
    const heights = Array.from(cards).map(card => card.clientHeight);
    this.tallestCardHeight = Math.max(...heights);
  }

  private retrieveResourceServers() {
    let allModules: EmpusaMicroApp[] = (<any>window).empusa.modules;
    let resourceServers: Array<string> = [this.environment.empusaPermissionServer];
    allModules.forEach(module => {
      if (module.resourceServerId) {
        resourceServers.push(module.resourceServerId.trimEnd());
      }
    });
    this.store.dispatch(authManagementActions.RetrieveResourceServes({ clients: resourceServers }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    if (this.resourceServersSubscription) this.resourceServersSubscription.unsubscribe();
  }

  /** Resources */
  addNewResource(): void {
    const dialogRef = this.dialog.open(KamManageResourceComponent, {
      width: '500px',
      data: { 
        title: "KAM-ADD-DIALOG.ADD_RESOURCE_TITLE",
        resourceServer: this.resourceServer,
        icon: "add_circle",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      if (result) {
        this.retrieveResourceServers()
      } else
        this.loading = false;
    });
  }


  updateResource(resource: KeycloakResourceModel): void {
    const dialogRef = this.dialog.open(KamManageResourceComponent, {
      width: '500px',  
      data: {
        title: "KAM-MANAGE-DIALOG.MANAGE_RESOURCE_TITLE",
        resourceServer: this.resourceServer,
        resource: resource,
        icon: "mode_edit",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      if (result) {
        this.retrieveResourceServers()
      } else
        this.loading = false;
    });
  }

  deleteResource(resource: KeycloakResourceModel) {
    if (!this.resourceServer) return;
    const resourceServerId: string = this.resourceServer.id;
    let applyPoliciesNames: string[] = [];
    this.resourceServer?.resourceServerSettings?.policies.forEach(policy => {
      const found = policy.config?.resources?.find(resName => resName == resource.name)
      if (found) {
        const ap = policy.config?.applyPolicies;
        if (ap) {
          applyPoliciesNames = applyPoliciesNames.concat(ap);
        }
      }
    })
    let policiesToDelete: Observable<any>[] = [];
    applyPoliciesNames.forEach(policyName => {
      const found = this.resourceServer?.resourceServerSettings?.policies.find(policy => policy.name == policyName);
      if (found && found.id) {
        const delAssociatedPolicyRequest = this.kamResourceServerServices.deletePolicies(resourceServerId, found.id)
        policiesToDelete.push(delAssociatedPolicyRequest)
      }
    })


    const dialogRef = this.dialog.open(KamQuestionDialogComponent, {
      width: '500px',
      data: {
        title: "KAM-QUESTION-DIALOG.DELETE_RESOURCE_TITLE",
        question: "KAM-QUESTION-DIALOG.DELETE_RESOURCE_QUESTION",
        parameters: { resource: resource.displayName },
        icon: "delete_outline"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;

      if (result) {
        this.kamResourceServerServices.deleteResource(resourceServerId, resource._id).subscribe({
          next: (v) => {
            forkJoin(policiesToDelete).subscribe({
              next: (v) => { },
              error: (e) => console.error(e),
              complete: () => {
                this.retrieveResourceServers();
              }
            })
            this.loading = false;
          },
          error: (e) => {
            console.error(e);
            this.loading = false;
            this.snackBarService.openSnackBar("La operación no se ha podido completar", "X");
          },
          complete: () => {
            console.info('complete');
            this.loading = false;
            this.snackBarService.openSnackBar("Operación realizada con exito", "X");
          }
        });

      } else
        this.loading = false;
    });
  }

  /** Actions */
  addNewScope(): void {
    const dialogRef = this.dialog.open(KamManageScopeComponent, {
      width: '500px',
      data: { 
        title: "KAM-ADD-DIALOG.ADD_SCOPE_TITLE",
        resourceServer: this.resourceServer,
        icon: "add_circle"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.retrieveResourceServers()
      }
    });
  }

  updateScope(scope: KeycloakScopeModel): void {
    const dialogRef = this.dialog.open(KamManageScopeComponent, {
      width: '500px',
      data: {
        title: "KAM-MANAGE-DIALOG.MANAGE_SCOPE_TITLE",
        resourceServer: this.resourceServer,
        scope: scope,
        icon: "mode_edit",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.retrieveResourceServers()
      }
    });
  }

  deleteScope(scope: KeycloakScopeModel) {
    if (!this.resourceServer?.id) return
    const resourceServerId = this.resourceServer?.id
    const dialogRef = this.dialog.open(KamQuestionDialogComponent, {
      width: '500px',
      data: {
        title: "KAM-QUESTION-DIALOG.DELETE_SCOPE_TITLE",
        question: "KAM-QUESTION-DIALOG.DELETE_SCOPE_QUESTION",
        parameters: { scope: scope.name },
        icon: "delete_outline"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.kamResourceServerServices.deleteScope(resourceServerId, scope.id).subscribe({
          next: (v) => {
            this.retrieveResourceServers();
          },
          error: (e) => console.error(e),
          complete: () => { console.info('complete') }
        });

      }
    });
  }

  /** Permissions */
  addNewPermission(): void {
    const dialogRef = this.dialog.open(KamManagePermissionComponent, {
      width: '500px',
      data: { 
        title: "KAM-ADD-DIALOG.ADD_PERMISSIONS_TITLE",
        resourceServer: this.resourceServer,
        icon: "add_circle",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.retrieveResourceServers()
      }
    });
  }

}
