import { Component } from '@angular/core';
import { InitialPageService } from '../lib/initial-page.service';
import { DownloadService } from '../services/download.service';


@Component({
  selector: 'lib-initial-page',
  templateUrl: './initial-page.component.html',
  styleUrls: ['./initial-page.component.scss'],
})
export class InitialPageComponent {


  constructor(
    private attachedService: InitialPageService,
    private downloadSrv: DownloadService
  ) { }


  exportarExcel() {
    this.attachedService.exportarAdheridoExcel().subscribe({
      next: response => {
        this.downloadSrv.downloadBlob(response, 'adheridos.xls');
      },
      error: err => {
        console.log(err);
      }
    });

  }
  exportarPdf() {
    this.attachedService.exportarAdheridoPdf().subscribe({
      next: response => {
        this.downloadSrv.downloadBlob(response, 'adheridos.pdf');
      },
      error: err => {
        console.log(err);
      }
    });

  }

}
