import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { compose, Store } from '@ngrx/store';
import { selectRoles } from '../../../state/auth-manager.selectors';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { KeycloakGroupModel } from '../../../model/keycloak-group.model';
import { KeycloakGroupService } from '../../../services/kam-group.services';
import { AuthManagerState } from '../../../state/auth-manager.model';
import { KeycloakRoleModel } from '../../../model/keycloak-role.model';
import { KamManageGroupData } from './manage-group-data.interface';

@Component({
  selector: 'keycloak-auth-manager-manage-group',
  templateUrl: './manage-group.component.html',
  styleUrls: ['./manage-group.component.css']
})
export class ManageGroupComponent implements OnInit, OnDestroy {
  isLinear=true;
  groupAttributes = this.environment.kamGroupAttributes;

  step1CompetedSubscriptionSubscription: Subscription|undefined;
  step1CompetedSubscription: boolean= false;
  step2CompetedSubscriptionSubscription: Subscription|undefined;
  step2CompetedSubscription: boolean= false;
  step3CompetedSubscriptionSubscription: Subscription|undefined;
  step3CompetedSubscription: boolean= false;
  rolesSubscription: Subscription | undefined;

  update: boolean = false;
  roles: KeycloakRoleModel[]|undefined;

  constructor(public dialogRef: MatDialogRef<ManageGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KamManageGroupData,
    private keycloakGroupService: KeycloakGroupService,
    private store: Store<AuthManagerState>,
    @Inject('environment') private environment:any,
    ) { }


  ngOnInit(): void {
    let groupToUpdate = undefined

    if (this.data.group){
      this.update=true
      groupToUpdate={...this.data.group}
      
      if (!this.groupAttributes){
        groupToUpdate.attributes=undefined;
        groupToUpdate.attributesMap=undefined;
      }
    }
    this.keycloakGroupService.dataSetGroup(groupToUpdate)
    this.rolesSubscription = this.store.select(selectRoles).subscribe(roles =>{          
      this.roles = roles;
    });

    setTimeout(() => { //set timeout to solve error value changed vefore checked
      this.step1CompetedSubscriptionSubscription = this.keycloakGroupService.step1CompetedSubscription().subscribe(value=>{
        this.step1CompetedSubscription = value
      })
      this.step2CompetedSubscriptionSubscription = this.keycloakGroupService.step2CompetedSubscription().subscribe(value=>{
        this.step2CompetedSubscription = value
      })
      this.step3CompetedSubscriptionSubscription = this.keycloakGroupService.step3CompetedSubscription().subscribe(value=>{
        this.step3CompetedSubscription = value
      })
    }, 500);
  }

  ngOnDestroy(): void {
    if (this.step1CompetedSubscriptionSubscription) this.step1CompetedSubscriptionSubscription.unsubscribe();
    if (this.step2CompetedSubscriptionSubscription) this.step2CompetedSubscriptionSubscription.unsubscribe();
    if (this.step3CompetedSubscriptionSubscription) this.step3CompetedSubscriptionSubscription.unsubscribe();
    if (this.rolesSubscription) this.rolesSubscription.unsubscribe()
  }

  manageRoleEnd(){
    let theGroup = this.keycloakGroupService.dataGetGroup()
    let theRoles = this.keycloakGroupService.dataGetRoles()

    if (!theGroup) return
    //transform attibutes to json
    if (theGroup.attributesMap){
      let jsonObject:any = {};
      theGroup.attributesMap.forEach((value, key) => {
        jsonObject[key] = value;
      });      
      theGroup.attributes=jsonObject
      theGroup.attributesMap=undefined
    }

    theGroup.members= undefined 
   
    if (this.update){
      let roleAddDeleteRequests: Observable<any>[] = this.calculateRoleRequests(theGroup, theRoles);
      this.keycloakGroupService.putGroup(theGroup).subscribe({
        next: (value) => {
          //update roles
          forkJoin(roleAddDeleteRequests).subscribe({
            next: (v) => {},
            error: (e) => console.error(e),
            complete: () => {
            }
          })
        },
        error:(e) =>console.error(e),
        complete:()=>{
          this.dialogRef.close(true)
        },
      })
    }else{
      this.keycloakGroupService.postGroup(theGroup, this.data.subgroupfrom).subscribe({
        next: (value) => {
          //update attributes
          if (theGroup)
            //search id
            this.keycloakGroupService.getGroupByName(theGroup.name).subscribe({
              next: (value) => {
                  let groupPath = "/" + theGroup!.name;
                  if (this.data.subgroupfrom){
                    groupPath = this.data.subgroupfrom!.path! + "/" + theGroup!.name;
                  }
                  const foundgroup=this.searchGroupByPath(groupPath,value)
                  console.log(groupPath, foundgroup)
                  if (theGroup && foundgroup){
                    theGroup.id=foundgroup.id
                    //update roles and attributes
                    let updateRequests: Observable<any>[] = this.calculateRoleRequests(theGroup, theRoles);
                    updateRequests.push(this.keycloakGroupService.putGroup(theGroup))
                    forkJoin(updateRequests).subscribe({
                      next: (v) => {},
                      error: (e) => console.error(e),
                      complete: () => {
                        this.dialogRef.close(true)
                      }
                    })
                      
                  }
              },
              error:(e) => console.error(e),
              complete: () =>{}
            })
            
        },
        error:(e) =>{},
        complete:()=>{         
        },
      })
    }

  }

  private searchGroupByPath (path: string, groups: KeycloakGroupModel[]){
    var splitted = path.split("/")
    splitted.shift()
    let searchInGroups = groups;
    let searchPath: string = "";
    let groupFound: KeycloakGroupModel|undefined;
    splitted.forEach(path =>{
      searchPath = searchPath + "/" + path
      groupFound = searchInGroups.find(a => a.path == searchPath)
      if (groupFound){          
        searchInGroups = groupFound.subGroups
      }
    }) 

    let returnObj: KeycloakGroupModel|undefined;
    if (groupFound){
      returnObj = {...groupFound}
      returnObj.subGroups=[]
    }
    return returnObj
}


  private calculateRoleRequests(theGroup: KeycloakGroupModel, theRoles: KeycloakRoleModel[]) {
    let roleAddDeleteRequests: Observable<any>[] = [];
    let rolesToDeleteList: KeycloakRoleModel[] = [];
    theGroup.realmRoles?.forEach(actualRole => {
      const found = theRoles.find(newRole => newRole.name == actualRole);
      if (!found && theGroup) {
        //to delete
        const roleToDelete = this.roles?.find(role => role.name == actualRole);
        if (roleToDelete) {
          rolesToDeleteList.push(roleToDelete);
        }
      }
    });
    if (rolesToDeleteList.length > 0) {
      roleAddDeleteRequests.push(this.keycloakGroupService.deleteGroupRole(theGroup, rolesToDeleteList));
    }


    let rolesToAddList: KeycloakRoleModel[] = [];
    theRoles.forEach(roleActived => {
      const found = theGroup?.realmRoles?.find(role => role == roleActived.name);
      if (!found && theGroup) {
        rolesToAddList.push(roleActived);
      }
    });
    if (rolesToAddList.length > 0) {
      roleAddDeleteRequests.push(this.keycloakGroupService.postGroupRole(theGroup, rolesToAddList));
    }
    return roleAddDeleteRequests;
  }
}


