/**
 * Poseedores API
 * Poseedores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PoseedorFilterDTO } from './poseedorFilterDTO';
import { FacturaEstadoDTO } from './facturaEstadoDTO';


export interface FacturaPoseedorFilterDTO { 
    id?: number;
    poseedor?: PoseedorFilterDTO;
    numeroAutofactura?: number;
    fechaAutofactura?: string;
    numeroFactura?: number;
    fechaFactura?: string;
    estado?: FacturaEstadoDTO;
    origen?: FacturaPoseedorFilterDTO.OrigenEnum;
}
export namespace FacturaPoseedorFilterDTO {
    export type OrigenEnum = 'POSEEDORES' | 'PUNTOSRECOGIDA' | 'SDDRCC' | 'SDDRCA';
    export const OrigenEnum = {
        Poseedores: 'POSEEDORES' as OrigenEnum,
        Puntosrecogida: 'PUNTOSRECOGIDA' as OrigenEnum,
        Sddrcc: 'SDDRCC' as OrigenEnum,
        Sddrca: 'SDDRCA' as OrigenEnum
    };
}


