/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CalculoTarifaDeclaracionEnvaseDTO { 
    id?: number;
    contriRapAnioCurso?: number;
    depoRapAnioCurso?: number;
    contriRapAnioAnterior?: number;
    depoRapAnioAnterior?: number;
    tarifaRapAnioCurso?: number;
    tarifaRapAnioAnterior?: number;
    tarifaDepoAnioCurso?: number;
    tarifaDepoAnioAnterior?: number;
}

