import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { KeycloakClientModel } from '../../../model/keycloak-client.model';
import { AuthManagerState } from '../../../state/auth-manager.model';
import { selectResourceServers } from '../../../state/auth-manager.selectors';
import { Subscription } from 'rxjs';
import * as authManagementActions  from '../../../state/auth-manager.actions';

@Component({
  selector: 'keycloak-auth-manager-kam-permissions-tab-servers',
  templateUrl: './kam-permissions-tab-servers.component.html',
  styleUrls: ['./kam-permissions-tab-servers.component.scss']
})
export class KamPermissionsTabServersComponent implements OnInit {


  resourceServersSubscription: Subscription | undefined;
  resourceServers: KeycloakClientModel[] = [];
  openenPanel: string = "";
  selectedResourceServer: KeycloakClientModel| undefined;

  constructor(private store: Store<AuthManagerState>,
    public dialog: MatDialog,
    @Inject('environment') private environment:any) { }

  ngOnInit(): void {
    this.resourceServersSubscription = this.store.select(selectResourceServers).subscribe(resourceServers =>{
      this.resourceServers = resourceServers;
      if (this.selectedResourceServer){
        this.selectedResourceServer = resourceServers.find(resourceServer=> resourceServer.id == this.selectedResourceServer?.id)
        this.store.dispatch(authManagementActions.setSelectedResourceServer({selectedResourceServer:this.selectedResourceServer}));
      }
    })
  }

  selectResourceServer(resourceServer:KeycloakClientModel){
    this.selectedResourceServer = resourceServer;
    this.store.dispatch(authManagementActions.setSelectedResourceServer({selectedResourceServer:resourceServer}));
  }

  ngOnDestroy(): void {
    if (this.resourceServersSubscription) this.resourceServersSubscription.unsubscribe();
  }
}