import {
  Attribute,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CodigoLerControllerService,
  CodigoOperacionFilterDTO,
  GestorPtoRecogidaCodigoControllerService,
  GestorPtoRecogidaControllerService,
  PtoRecogidaCodigoControllerService,
  PtoRecogidaCreatePayloadDTO,
  PtoRecogidaGestorControllerService,
  PtoRecogidaGestorFilterDTO,
  PtoRecogidaGestorPayloadDTO,
  PuntoRecogidaControllerService,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-collection-points-associates-manager',
  templateUrl: './collection-points-associates-manager.component.html',
  styleUrls: ['./collection-points-associates-manager.component.scss'],
})
export class CollectionPointsAssociatesManagerComponent implements OnInit {
  @Input() puntoRecogidaInfo!: any;
  @Output() public refrescarPuntosRecogidaEvent = new EventEmitter<any>();

  formGroup!: FormGroup;
  //codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler';

  provinciasEndpoint = this.environment.urlBackCore + 'api/v1/core/provinces';

  grid: any[] = [];
  operacionArray: any[] = [];

  pageNumber: number = 0;
  pageSize: number = 100;
  recordsTotal: number = 0;
  ptoGestorArray: any[] = [];
  codigoLerArray: any[] = [];
  codigoLerArrayTemporal: any[] = [];

  updateList: boolean = false;

  periodicidadOptionArray: any = [
    { value: 'Mensual' },
    { value: 'Trimestral' },
    { value: 'Semestral' },
    { value: 'Anual' },
  ];

  headArray: any[] = [
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'descripcionMCodLer',
      Tooltip: true,
      Maxtext: 70,
      width: 60,
    },
    {
      Head: 'Gestor',
      FieldName: 'gestorPtoRecogida',
      Attribute: 'razonSocial',
      width: 60,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private puntoRecogidaControllerService: PuntoRecogidaControllerService,
    private ptoRecogidaGestorControllerService: PtoRecogidaGestorControllerService,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService,
    private gestorPtoRecogidaCodigoControllerService: GestorPtoRecogidaCodigoControllerService,
    private ptoRecogidaCodigoControllerService: PtoRecogidaCodigoControllerService
  ) {
    this.formGroup = this.formBuilder.group({
      codigoLer: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      provincia: [{ value: null, disabled: false }],
      gestor: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
  }

  ngOnInit() {
    this.valueChangeProvincia();
    this.valueChangeGestor();
    this.canAccessAssociatesViewEdit();
    this.inicialComboCodigoLer();
  }

  valueChangeProvincia() {
    this.formGroup.get('provincia')?.valueChanges.subscribe((value: any) => {
      if (value) {
        this.gestorPtoRecogidaControllerService
          .findAllGestorPtoRecogida(
            { provincia: value?.id },
            { page: this.pageNumber, size: this.pageSize }
          )
          .subscribe({
            next: (response: any) => {
              this.ptoGestorArray = response.datos.filter(
                (item: any) => item?.estado?.id == 3
              );
            },
            error: (err) => {},
          });
      } else {
        this.formGroup.get('gestor')?.reset();
        this.formGroup.get('codigoLer')?.reset();
        this.ptoGestorArray = [];
        this.codigoLerArray = [];
      }
    });
  }

  valueChangeGestor() {
    this.formGroup.get('gestor')?.valueChanges.subscribe((value: any) => {
      if (value) {
        this.gestorPtoRecogidaCodigoControllerService
          .findAllGestorPtoRecogidaCodigo(
            { gestorPtoRecogida: { id: value?.id } },
            { page: this.pageNumber, size: this.pageSize }
          )
          .subscribe({
            next: (response: any) => {
              let result = response.datos.map((item: any) => item.codigoLer);

              const codigoLerSet = new Set(result.map((item: any) => item.id));
              const coincidentes = this.codigoLerArrayTemporal.filter((item) =>
                codigoLerSet.has(item.id)
              );
              const coincidentesDistinct = Array.from(
                new Set(coincidentes.map((item) => item.id))
              ).map((id) => coincidentes.find((item) => item.id === id));
              this.codigoLerArray = coincidentesDistinct;
            },
            error: (err) => {},
          });
      } else {
        this.formGroup.get('codigoLer')?.reset();
        this.codigoLerArray = [];
      }
    });
  }

  inicialComboCodigoLer() {
    this.ptoRecogidaCodigoControllerService
      .findAllPtoRecogidaCodigo(
        { ptoRecogida: { id: this.puntoRecogidaInfo?.id } },
        { page: this.pageNumber, size: this.pageSize }
      )
      .subscribe({
        next: (response: any) => {
          this.codigoLerArrayTemporal = response.datos.map(
            (item: any) => item.codigoLer
          );
        },
        error: (err) => {},
      });
  }

  private getBackendFilter() {
    const backendFilter: PtoRecogidaGestorFilterDTO = {
      ptoRecogida: { id: this.puntoRecogidaInfo?.id },
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.puntoRecogidaInfo) {
      const { backendFilter, page, size } = this.getBackendFilter();
      this.ptoRecogidaGestorControllerService
        .findAllPtoRecogidaGestor(backendFilter, { page, size })
        .subscribe({
          next: (response: any) => {
            this.grid = this.canAccessAll(response?.datos);
          },
          error: (err) => {},
        });
    }
  }

  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }

  getOperacionDescription(op: any) {
    return op?.descripcion;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'provincia': {
        return this.formGroup.get('provincia') as FormControl;
        break;
      }
      case 'codigoLer': {
        return this.formGroup.get('codigoLer') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  addPrevision() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }

    const dataDuplicate = this.grid.find(
      (x) => x.codigoLer.id == this.formGroup.get('codigoLer')?.value?.id
    );

    if (dataDuplicate) {
      this.snackBarSrv.showSnackBar(
        'El código LER introducido ya tiene un gestor asociado. Si desea modificar el gestor, debe eliminar el registro y volver a asociarlo con el gestor deseado',
        'error'
      );
      return;
    }

    const dataObjAssociate: PtoRecogidaGestorPayloadDTO = {
      codigoLer: { id: this.formGroup.get('codigoLer')?.value?.id },
      gestorPtoRecogida: { id: this.formGroup.get('gestor')?.value?.id },
      ptoRecogida: { id: this.puntoRecogidaInfo.id },
    };

    this.ptoRecogidaGestorControllerService
      .createPtoRecogidaGestor(dataObjAssociate)
      .subscribe({
        next: (response: any) => {
          this.snackBarSrv.showSnackBar(
            'Los datos se han guardado correctamente.',
            'success'
          );
          this.refrescarPuntosRecogidaEvent.emit(true);
          this.formGroup.reset();
          this.codigoLerArray = [];
          this.ptoGestorArray = [];
        },
        error: (err) => {},
      });
  }

  canAccessAgregar(): boolean {
    return this.authService.can('ptosrecogida-gestores', 'create');
  }

  canAccessAssociatesViewEdit() {
    if (!this.authService.can('ptosrecogida-gestores', 'update')) {
      this.formGroup.disable();
    }
  }

  canAccessAssociatesViewForm() {
    return this.authService.can('ptosrecogida-gestores', 'update');
  }

  action(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarPrevision(event);
    }
  }
  confirmacionEliminarPrevision(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el gestor asociado?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.ptoRecogidaGestorControllerService
          .borrarPtoRecogidaGestor(element.id)
          .subscribe({
            next: (response: any) => {
              this.snackBarSrv.showSnackBar(
                'Se ha eliminado correctamente el gestor',
                'success'
              );
              this.refrescarPuntosRecogidaEvent.emit(true);
            },
            error: (err) => {},
          });
      }
    });
  }

  showClearButton(formName: string): boolean {
    return this.formGroup.get(formName)?.value !== null;
  }

  controlHasErrorSelect(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formGroup.get(formName)?.setValue(null);
  }

  canAccessAll(list: any) {
    const resultDelete = this.authService.can(
      'ptosrecogida-gestores',
      'delete'
    );

    const listPermission = list.map((objeto: any) => {
      let descripcionMCodLer =
        objeto.codigoLer.tipoCodigo + ' ' + objeto.codigoLer.descripcion;

      return {
        ...objeto,
        descripcionMCodLer: descripcionMCodLer,
        canAccessDelete: resultDelete,
      };
    });

    return listPermission;
  }
}
