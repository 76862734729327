/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CategoriaEnvaseFilterDTO } from './categoriaEnvaseFilterDTO';
import { GrupoEnvaseFiltroDTO } from './grupoEnvaseFiltroDTO';
import { AgrupacionMaterialDTO } from './agrupacionMaterialDTO';


export interface TarifaAdheridoUnUsoFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    id?: Array<string>;
    anio?: Array<string>;
    peligrosidad?: boolean;
    valor?: Array<string>;
    tipologia?: TarifaAdheridoUnUsoFilterDTO.TipologiaEnum;
    categoria?: CategoriaEnvaseFilterDTO;
    grupoEnvase?: GrupoEnvaseFiltroDTO;
    agrupacionMaterial?: AgrupacionMaterialDTO;
}
export namespace TarifaAdheridoUnUsoFilterDTO {
    export type TipologiaEnum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const TipologiaEnum = {
        Primario: 'PRIMARIO' as TipologiaEnum,
        Secundario: 'SECUNDARIO' as TipologiaEnum,
        Terciario: 'TERCIARIO' as TipologiaEnum
    };
}


