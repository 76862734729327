import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {


  public loading = 0; 
  private loadingCount = 0;
  private loadingTimeoutId:any; 

  constructor() { }

  public loadingShow = () => {
    if (this.loading === 0) {
      this.loadingCount++;
      if (this.loadingTimeoutId === undefined) {
        this.loadingTimeoutId = setTimeout(() => {
          this.loading += this.loadingCount;
          if (this.loadingTimeoutId !== undefined) {
            clearTimeout(this.loadingTimeoutId);
            this.loadingTimeoutId = undefined;
            this.loadingCount = 0;
          }
        }, 250);
      }
    }
    else {
      this.loading++;
    }
  }
  public loadingHide = () => {
    if (this.loadingTimeoutId !== undefined) {
      if (this.loadingCount === 1) {
        clearTimeout(this.loadingTimeoutId);
        this.loadingTimeoutId = undefined;
        this.loadingCount = 0;
      }
      else {
        this.loadingCount--;
      }
    }

    if (this.loading > 0) {
      this.loading--;
    }
    else {
      this.loading = 0;
    }
  }
  public loadingKill = () => this.loading = 0;
}
