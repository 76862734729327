/**
 * Core API
 * Core API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface KeycloakRoleDTO { 
    id?: string;
    name?: string;
    attributes?: { [key: string]: Array<string>; };
    userRole?: boolean;
    envaloraRole?: boolean;
}

