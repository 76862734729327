<div class="center-cont">
  <div class="yellow-cont">
    <div>
      <div class="title">Centro envasado</div>
      <div class="value">{{centro?.centroEnvasado?.denominacion}}</div>
    </div>
    <div class="element">
      <div class="title">Categoría</div>
      <div class="value">{{centro?.categoriaEnvase?.descripcion}}</div>
    </div>
    <div class="element">
      <div class="title">Venta directa</div>
      <div class="value">{{centro?.porcVentaDirecta}} %</div>
    </div>
    <div class="element">
      <div class="title">Venta distribuida</div>
      <div class="value">{{centro?.porcVentaDistribucion}} %</div>
    </div>
    <div class="element edit-button">


      <div [matTooltip]="matTooltipImport" *ngIf="isCategoriaSoloUso()">
        <button type="button" class="btn edit" style="margin-left: 5px" (click)="updateUploadCenter()"
          [disabled]="!this.flagImportar">
          <mat-icon aria-hidden="false" aria-label="importar" fontIcon="file_upload"></mat-icon>
        </button>
      </div>
      <button *ngIf="canAccessEditCenter() && canAccessEditAllGlobal()" type="button" class="btn edit"
        style="margin-left: 5px" matTooltip="Editar porcentajes de venta">
        <mat-icon aria-hidden="false" aria-label="Borrar" fontIcon="edit" (click)="abrirModalCentro()"></mat-icon>
      </button>
      <button *ngIf="canAccessDeleteCenter() && canAccessEditAllGlobal()" type="button" class="btn edit"
        style="margin-left: 5px" (click)="confirmacionEliminarCentro()" matTooltip="Eliminar centro">
        <mat-icon aria-hidden="false" aria-label="Eliminar" fontIcon="delete"></mat-icon>
      </button>
    </div>
    <div class="element edit-button">

      <div class="button-cont">
        <button type="button" matTooltip="Salir Declaración" class="btn btn-outline-primary margin-left-button"
          data-dismiss="modal" (click)="buttonSalir()" aria-label="Salir">Salir</button>
        <!-- <button type="button" class="btn edit" style="margin-left: 5px" (click)="buttonSalir()"
          matTooltip="Salir Declaración">
          <mat-icon aria-hidden="false" aria-label="Salir" fontIcon="logout"></mat-icon>
        </button> -->
      </div>
    </div>

  </div>


  <div class="tabs-cont">
    <mat-tab-group style="width:100%">
      <mat-tab *ngIf="canAccessEnvases()">
        <ng-template mat-tab-label>
          <mat-icon>fire_hydrant</mat-icon>
          <span class="txt-tab">Envases</span>
        </ng-template>
        <lib-package-center-packaging #packageCenterList [centro]="centro" [declaracionAdherido]="declaracionAdherido"
          (actualizarDeclaracion)="onActualizarDeclaracion()" (actualizarImportar)="mostarImportar($event)">
        </lib-package-center-packaging>
      </mat-tab>
      <mat-tab *ngIf="canAccessSectores()">
        <ng-template mat-tab-label>
          <div matTooltip="Sectores de venta de la actividad" class="tooltip-wrapper">
            <mat-icon>av_timer</mat-icon>
            <span class="txt-tab">Sectores de destino</span>
          </div>
        </ng-template>
        <lib-package-center-sectors [centro]="centro" [declaracionAdherido]="declaracionAdherido">
        </lib-package-center-sectors>
      </mat-tab>
      <mat-tab *ngIf="canAccessComunidades()">
        <ng-template mat-tab-label>
          <div matTooltip="% Comercialización en destino CCAA" class="tooltip-wrapper">
            <mat-icon>percent</mat-icon>
            <span class="txt-tab">Comercialización</span>
          </div>
        </ng-template>
        <lib-package-center-commercialization [centro]="centro" [declaracionAdherido]="declaracionAdherido"
          [comunidadesAutonomas]="comunidadesAutonomas">
        </lib-package-center-commercialization>
      </mat-tab>
    </mat-tab-group>


  </div>
</div>
