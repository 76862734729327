<empusa-core-spinner *ngIf="loading" [overlay]="true"></empusa-core-spinner>

<mat-drawer-container class="container" [hasBackdrop]="false">
  <mat-drawer #drawer [mode]="'side'" class="side-menu-back">
    <div class="head-drawer-title">
      <mat-icon class="back" (click)="drawer.toggle()">keyboard_backspace</mat-icon>
      <div class="logo-left"></div>
      <div *ngIf="site" class="site">{{site}}</div>
    </div>
    <nav class="side-menu-nav">
      <ul>
        <li *ngFor="let module of modules; let i = index" routerLinkActive="active" [routerLink]="['/'+ module.path]"
          class="animated fadeInUp delay1">
          <a class="side-menu-options">
            <mat-icon>{{module.icon}}</mat-icon>
            <span>{{'MENU.' + module.name.toUpperCase() | translate}}</span>
          </a>
          <mat-icon class="arrow_box">keyboard_arrow_right</mat-icon>
        </li>
        <li *ngIf="includeGrafana" class="animated fadeInUp delay1">
          <a class="side-menu-options" href="{{bmsGrafana}}" target="_blank">
            <mat-icon>trending_up</mat-icon>
            <span>Dashboards</span>
          </a>
        </li>
      </ul>
    </nav>
    <div class="terms-and-privacy">
      <a mat-button href="{{privacyPolicyUrl}}" target="_blank" *ngIf="privacyPolicyUrl">{{'MENU.PRIVACY_POLICY' |
        translate}}</a>
      <button mat-button *ngIf="termsOfUse" (click)="openTermsOfUse()">{{'MENU.TERMS_OF_USE' |
        translate}}</button>
    </div>
    <div class="divider"></div>
    <span class="copyright">© 2022 Getronics - Smart Spaces</span>
  </mat-drawer>

  <mat-drawer-content>
    <div class="horizontal-apli-menu horizontal-menu">
      <!-- CABECERA CON MENÚ -->
      <div class="header">
        <div class="header-inner">
          <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse"
            [attr.data-target]="navbarTarget" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span></button>
          <h1 class="logo-name cursor-hand" (click)="goInitialPage();">
            <img src="../assets/images/logo-envalora.svg" height="24" class="d-inline-block align-middle"
              alt="Getronics" />
            <span class="line-vert"></span>
            <div class="name-apli d-inline-block align-middle font-weight-normal">ENVANET</div>
          </h1>
          <div class="float-com-user">
            <div class="text-center align-middle cont-user">
              <div class="cont-mail" (click)="openComunications()">
                <div *ngIf="comunicacionesPendientes" class="number-msg">{{comunicacionesPendientes}}</div>
                <div class="mail-icon">
                  <mat-icon>mail</mat-icon>
                </div>
              </div>
              <div class="nav">
                <div class="nav-item dropdown">
                  <a class="nav-link p-0 dropdown-toggle dropdown-toggle-user" data-toggle="dropdown" href="#"
                    role="button" aria-haspopup="true" aria-expanded="false"><span class="icon-user"></span></a>
                  <div class="dropdown-menu dropdown-menu-user text-center bs-popover-bottom p-4"
                    x-placement="bottom-start">
                    <div class="dropdown-item user-dropdown-item">
                      <span class="icon-user"></span>
                      <div class="datos-user">{{ authService.user?.givenName }}</div>
                      <button type="button" class="btn btn-secondary" (click)="logout()">Salir</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <!-- Menú principal de navegación -->
          <nav class="navbar navbar-expand-xl d-block">
            <div
              class="navbar-collapse justify-content-md-center justify-content-between justify-content-center justify-content-lg-center justify-content-xl-center justify-content-sm-center collapse"
              id="navbarSupportedContent">
              <ul class="navbar-nav">
                <li *ngIf="canAccessAdministration()" class="nav-item dropdown menu-down">
                  <a class="nav-link dropdown-toggle mr-lg-2" href="#" id="navbarDropdown1" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Administración</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
                    <div class="dropdown-item-title text-primary">Administración</div>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['administration', 'users', 'search']">Usuarios</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['administration', 'rates', 'search']">Tarifas un solo uso</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['administration', 'closedRates', 'search']">Tarifas envases SDDR cerrado</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['administration', 'ratesOpen', 'search']">Tarifas envases SDDR abierto</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['administration', 'ratesHolders', 'search']">Tarifas Poseedores</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['administration', 'ratesCollectionPoint', 'search']">Tarifas Puntos Recogida</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['administration', 'ratesSddrCa', 'search']">Tarifas SDDR CA</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['administration', 'ratesManagerAgreements', 'search']">Tarifas Acuerdos con
                      Gestor</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['administration', 'templates', 'list']">Plantillas de documentos</a>
                  </div>
                </li>
                <li *ngIf="this.canViewMenu('AD-ADHERIDO')
                  || this.canViewMenu('DE-ENV-GEST')
                  || this.canViewMenu('AD-ENV-CONS')
                  || this.canViewMenu('DE-ADHERIDO')"
                    class="nav-item dropdown menu-down">
                  <a class="nav-link dropdown-toggle mr-lg-2" href="#" id="navbarDropdown2" role="button"
                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Adheridos</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
                    <div class="dropdown-item-title text-primary">Adheridos</div>
                    <a *ngIf="canAccessAttachedAppSearch()" class="dropdown-item" data-toggle="collapse"
                      [attr.data-target]="navbarTarget" [routerLink]="['attached-app', 'search']">Búsqueda de
                      Adheridos</a>
                    <!-- <a *ngIf="entityRoles && entityRoles.length > 0" class="dropdown-item" [href]="'#/attached-app/detail/'+viewMyData">Mis
                      Datos</a> -->
                    <ng-container *ngIf="entityRoles && entityRoles.length > 0">
                      <ng-container *ngFor="let adheridoRol of adheridosRoles">
                        <a *ngIf="adheridoRol?.entity && adheridoRol?.entityObject" class="dropdown-item"
                          data-toggle="collapse" [attr.data-target]="navbarTarget"
                          (click)="redirectTo('/attached-app/detail/' + adheridoRol?.entity)">
                          Mis Datos - {{ adheridoRol.entityObject?.razonSocial }}
                        </a>
                      </ng-container>
                    </ng-container>
                    <a *ngIf="canAccessPackagingDeclaration()" class="dropdown-item" data-toggle="collapse"
                      [attr.data-target]="navbarTarget" [routerLink]="['attached-app', 'packaging-declaration-search']">
                      Declaración de envases</a>
                  </div>
                </li>
                <li *ngIf="canAccessPoseedoresAppMenu()" class="nav-item dropdown menu-down">
                  <a class="nav-link dropdown-toggle mr-lg-2" href="#" id="navbarDropdown3" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Poseedores / Gestores</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown3">
                    <div class="dropdown-item-title text-primary">Poseedores / Gestores</div>
                    <ng-container *ngIf="entityRoles && entityRoles.length > 0">
                      <ng-container *ngFor="let poseedorRoles of poseedoresRoles">
                        <a *ngIf="poseedorRoles?.entity && poseedorRoles?.entityObject" class="dropdown-item"
                          data-toggle="collapse" [attr.data-target]="navbarTarget"
                          (click)="redirectTo('/holders/detail/' + poseedorRoles?.entity)">
                          Mis Datos - {{ poseedorRoles.entityObject?.razonSocial }}
                        </a>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="entityRoles && entityRoles.length > 0">
                      <ng-container *ngFor="let gestorRoles of gestoresRoles">
                        <a *ngIf="gestorRoles?.entity && gestorRoles?.entityObject" class="dropdown-item"
                          data-toggle="collapse" [attr.data-target]="navbarTarget"
                          (click)="redirectTo('/holders/waste-managers-detail/' + gestorRoles?.entity)">
                          Mis Datos - {{ gestorRoles.entityObject?.razonSocial }}
                        </a>
                      </ng-container>
                    </ng-container>
                    <a class="dropdown-item" *ngIf="canAccessPoseedoresSearchAccess()" data-toggle="collapse"
                      [attr.data-target]="navbarTarget" [routerLink]="['holders', 'search']">Búsqueda
                      de
                      poseedores</a>
                    <a class="dropdown-item" *ngIf="canAccessPoseedoresGestorSearchAccess()" data-toggle="collapse"
                      [attr.data-target]="navbarTarget" [routerLink]="['holders', 'waste-managers-search']">Búsqueda
                      de gestores</a>
                    <a *ngIf="canAccessPoseedoresDiSearchAccess()" class="dropdown-item" data-toggle="collapse"
                      [attr.data-target]="navbarTarget"
                      [routerLink]="['holders', 'contributions-dis-search']">Aportaciones de DIs</a>
                    <a *ngIf="canAccessPoseedoresAutoFacturaSearchAccess()" class="dropdown-item" data-toggle="collapse"
                      [attr.data-target]="navbarTarget" [routerLink]="['holders', 'invoices-search']">Autofacturas y
                      facturas</a>
                  </div>
                </li>
                <li *ngIf="canAccessCollectionPoints()"
                  class="nav-item dropdown menu-down">
                  <a class="nav-link dropdown-toggle mr-lg-2" href="#" id="navbarDropdown6" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Puntos de recogida</a>

                  <div class="dropdown-menu" aria-labelledby="navbarDropdown6">
                    <div class="dropdown-item-title text-primary">Puntos de recogida </div>

                    <ng-container *ngIf="entityRoles && entityRoles.length > 0">
                      <ng-container *ngFor="let ptoRoles of puntosRecogidaRoles">
                        <a *ngIf="ptoRoles?.entity && ptoRoles?.entityObject" class="dropdown-item"
                          data-toggle="collapse" [attr.data-target]="navbarTarget"
                          (click)="redirectTo('/collection-points/detail/' + ptoRoles?.entity)">
                          Mis Datos - {{ ptoRoles.entityObject?.razonSocial }}
                        </a>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="entityRoles && entityRoles.length > 0">
                      <ng-container *ngFor="let gestorPtoRoles of gestorPuntosRecogidaRoles">
                        <a *ngIf="gestorPtoRoles?.entity && gestorPtoRoles?.entityObject" class="dropdown-item"
                          data-toggle="collapse" [attr.data-target]="navbarTarget"
                          (click)="redirectTo('/collection-points/manager-detail/' + gestorPtoRoles?.entity)">
                          Mis Datos - {{ gestorPtoRoles.entityObject?.razonSocial }}
                        </a>
                      </ng-container>
                    </ng-container>

                    <a class="dropdown-item" *ngIf="canAccessCollectionPointsSearch()" data-toggle="collapse"
                      [attr.data-target]="navbarTarget" [routerLink]="['collection-points', 'search']">Búsqueda de
                      puntos de recogida</a>
                    <a class="dropdown-item" *ngIf="canAccessCollectionPointsManagerSearch()" data-toggle="collapse"
                      [attr.data-target]="navbarTarget" [routerLink]="['collection-points', 'manager-search']">Búsqueda
                      de gestores de puntos de
                      recogida</a>
                    <a class="dropdown-item" *ngIf="canAccessCollectionPointsRequestsSearch()" data-toggle="collapse"
                      [attr.data-target]="navbarTarget" [routerLink]="['collection-points',
                      'requests-search']">
                      <ng-container
                        *ngIf="canViewMenu('PR-ENV-GEST') || canViewMenu('PR-ENV-CONS'); else misSolicitudes">
                        Búsqueda de solicitudes
                      </ng-container>
                      <ng-template #misSolicitudes>
                        Mis solicitudes
                      </ng-template>
                    </a>
                    <a class="dropdown-item" *ngIf="canAccessCPSelfInvoicesRequestsSearch()" data-toggle="collapse"
                      [attr.data-target]="navbarTarget"
                      [routerLink]="['collection-points', 'cp-invoices-search']">Autofacturas y
                      facturas</a>
                  </div>
                </li>
                <li *ngIf="canAccessOpenSddrReuseRequest() || canAccessOpenSddrWithDrawnRequest()"
                  class="nav-item dropdown menu-down">
                  <a class="nav-link dropdown-toggle mr-lg-2" href="#" id="navbarDropdown8" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">SDDR Abierto</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown8">
                    <div class="dropdown-item-title text-primary">SDDR Abierto</div>
                    <a *ngIf="canAccessOpenSddrReuseRequest()" class="dropdown-item" data-toggle="collapse"
                      [attr.data-target]="navbarTarget" [routerLink]="['open-sddr', 'reuse-search']">Solicitudes de
                      reutilización</a>
                    <a *ngIf="canAccessOpenSddrWithDrawnRequest()" class="dropdown-item" data-toggle="collapse"
                      [attr.data-target]="navbarTarget"
                      [routerLink]="['open-sddr', 'requests-withdrawn-search']">Solicitudes de retirada</a>
                    <!--
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['/']">Solicitudes de envase SDDR CA</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['/']">Aportaciones DI</a>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['/']">Autofacturas y facturas</a>
                    -->
                  </div>
                </li>
                <li *ngIf="canAccessAdheridosDiSearchAccess()" class="nav-item dropdown menu-down">
                  <a class="nav-link dropdown-toggle mr-lg-2" href="#" id="navbarDropdown4" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">SDDR Cerrado</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown4">
                    <div class="dropdown-item-title text-primary">SDDR Cerrado</div>
                    <a class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['attached-app', 'contributions-dis-search']">Aportaciones de DIs</a>
                  </div>
                </li>
                <li *ngIf="this.canAccessAcuerdoGestores()" class="nav-item dropdown menu-down">
                  <a class="nav-link dropdown-toggle mr-lg-2" href="#" id="navbarDropdown8" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Acuerdos con Gestores</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown8">
                    <div class="dropdown-item-title text-primary">Acuerdos con Gestores</div>
                    <a *ngIf="canViewMenu('AG-POSEEDOR') || canViewMenu('AG-ENV-CONS') || canViewMenu('AG-ENV-GEST')"
                      class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['managers-agreements', 'search']">Búsqueda Gestores con Acuerdo</a>
                    <a *ngIf="canViewMenu('AG-GESTOR') || canViewMenu('AG-ENV-CONS') || canViewMenu('AG-ENV-GEST')"
                      class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['managers-agreements', 'contributions-dis-search']">Aportaciones de DIs</a>
                    <a *ngIf="canViewMenu('AG-GESTOR') || canViewMenu('AG-ENV-CONS') || canViewMenu('AG-ENV-GEST')"
                      class="dropdown-item" data-toggle="collapse" [attr.data-target]="navbarTarget"
                      [routerLink]="['managers-agreements', 'invoices-search']">Autofacturas y facturas</a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          <!-- FIN Menú principal de navegación -->
        </div>
      </div>

      <!-- FIN CABECERA CON MENÚ -->
    </div>

    <div class="empusa-container">
      <router-outlet></router-outlet>
    </div>

    <div class="container-fluid">
      <div class="footer my-flo d-flex align-items-center justify-content-between">
        <div class="text-footer">ENVANET v1.1</div>
        <div class="text-right font-weight-normal text-footer"><span class="pr-2">© Copyright 2023</span>·<a href="#"
            class="px-2">Envalora</a>·<a href="https://envalora.es/aviso-legal/" class="px-2">Aviso Legal</a>·<a
            href="https://envalora.es/politica-privacidad/" class="px-2">Política de
            Privacidad</a>·<a href="#" class="px-2">Política de cookies</a></div>
      </div>
    </div>


  </mat-drawer-content>

</mat-drawer-container>
