import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AttachedService } from '../../../services/attached.service';
import { Adherido, Domicilio } from '../../../models/adherido';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';
import swal from 'sweetalert2'
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'lib-detail-address',
  templateUrl: './detail-address.component.html',
  styleUrls: ['./detail-address.component.scss']
})

export class DetailAddressComponent implements OnChanges {
  @Input() adheridoInfo!: Adherido;
  @Output() public refrescarAdheridoEvent = new EventEmitter<any>();
  direcciones: Domicilio[] = [];

  gridArray!: Domicilio[];
  headArray: any = [
    {
      Head: 'Requerida',
      FieldName: 'requerido',
      check: true,
      disabled: true,
      width: '1'
    },
    {
      Head: 'Tipo direccion',
      FieldName: 'tipoDireccion',
      Attribute: 'descripcion',
      width: '2'
    },
    {
      Head: 'Dirección',
      FieldName: 'direccionCompleta',
      Tooltip: true,
      width: '8',
      Maxtext: 50
    },
    {
      'Head': 'Acciones', 'FieldName': 'actions', 'buttons':
        [
          { 'nameButton': "view", "icon": "open_in_new", 'toolTip': 'Detalle', 'show': { 'params': 'canAccessView', 'accion': 'view' } },
          // { 'nameButton': "edit", "icon": "edit_square", 'toolTip': 'Editar', 'show': { 'params': 'canAccessListEdit', 'accion': 'edit' } },
          { 'nameButton': "delete", "icon": "delete", 'toolTip': 'Borrar', 'show': { 'params': 'canAccessDelete', 'accion': 'delete' } },
        ], width: '2'
    },
  ];

  constructor(
    private dialog: MatDialog,
    private attachedSrv: AttachedService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    private spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.adheridoInfo) {
      this.direcciones = this.adheridoInfo?.domicilios;
      this.gridArray = this.canAccessAll(this.direcciones);
    }
  }

  canAccessCreateDireccion(): boolean {
    return this.authService.can('adheridos-direcciones', 'create');
  }

  canAccessExportar(): boolean {
    return this.authService.can('adheridos-direcciones', 'access-b-exportar');
  }

  canAccessAll(list: any) {
    const resultListShow = this.authService.can('adheridos-direcciones', 'access');
    const resultListEdit = this.authService.can('adheridos-direcciones', 'update');
    const resultDelete = this.authService.can('adheridos-direcciones', 'delete');

    const listPermission = list.map((objeto: any) => {
      const estadoUpdatedAddress = this.adheridoInfo.estado && [2, 3, 4, 5, 6].includes(this.adheridoInfo.estado!.id);
      const estadoEliminar = this.adheridoInfo.estado && [1, 2, 3, 4, 5, 6].includes(this.adheridoInfo.estado!.id) && (!objeto.requerido || objeto.requerido === null );;

      return { ...objeto, canAccessView: resultListShow, canAccessListEdit: resultListEdit && estadoUpdatedAddress, canAccessDelete: resultDelete && estadoEliminar };

    });

    return listPermission;
  }

  abrirModalDirecciones(element?: Domicilio): void {
    const dialogRef = this.dialog.open(
      AddressDialogComponent,
      {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          direccion: element,
          adherido: this.adheridoInfo
        },
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.refrescarAdheridoEvent.emit(true);
        }
      }
    );
  }

  confirmacionEliminar(element?: Domicilio): void {
    swal.fire({
      text: '¿Desea eliminar la dirección?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarDireccion(element);
      }

    });
  }
  eliminarDireccion(element?: Domicilio) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.attachedSrv.eliminarDireccion(element.id).subscribe({
        next: response => {
          this.spinnerSrv.loadingHide();
          this.refrescarAdheridoEvent.emit(true);
          this.snackBarSrv.showSnackBar('La dirección se ha eliminado correctamente', 'success');
        },
        error: err => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar('Ha ocurrido un error al eliminar la dirección', 'error');
          console.log(err);
        }
      });
    }
  }

  action(item: any) {
    if (item.nameButton == 'view') {
      this.abrirModalDirecciones(item);
    } else if (item.nameButton == 'edit') {
      this.abrirModalDirecciones(item);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }
}
