import { Injectable } from "@angular/core";
import { KeycloakResourceModel } from "../keycloak-resource.model";
import { KeycloakScopeModelAdapter } from "./keycloak-scope.model.adapter";
import { Adapter } from "./keycloak.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakResourceModelAdapter implements Adapter<KeycloakResourceModel> {

    constructor(private keycloakScopeModelAdapter: KeycloakScopeModelAdapter,) { }
    adapt(item: any): KeycloakResourceModel {
        let model:KeycloakResourceModel = {
            _id: item._id,
            name: item.name,
            displayName: item.displayName,            
        };
        if (item.scopes){
            model.scopes=[]
            item.scopes.forEach((element: any) => {
                if (model.scopes)
                    model.scopes.push(this.keycloakScopeModelAdapter.adapt(element))
            });
        }
        return model;
    }
}