import { Injectable } from "@angular/core";
import { KeycloakPolicyConfiModelAdapter } from "./keycloak-policy-config.model.adapter";
import { KeycloakPolicyModel } from "../keycloak-policy.model";
import { Adapter } from "./keycloak.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakPolicyModelAdapter implements Adapter<KeycloakPolicyModel> {

    constructor(private keycloakPolicyConfiModelAdapter: KeycloakPolicyConfiModelAdapter,) { }
    
    adapt(item: any): KeycloakPolicyModel {
        let model:KeycloakPolicyModel = {
            description: item.description,
            id: item.id,
            logic: item.logic,
            name: item.name,
            type: item.type,
            decisionStrategy: item.decisionStrategy,
        };
        if (item.config){
            model.config= this.keycloakPolicyConfiModelAdapter.adapt(item.config)
        }
        return model;
    }
}