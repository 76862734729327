import { Injectable } from "@angular/core";
import { KeycloakUserDTO, UserRoleEntityDTO, TarifaAdheridoUnUsoDTO } from "api-rest";

export interface Combo {
    id: string | number,
    name: string
}

@Injectable({
    providedIn: 'root'
})
export class ComboService {


    getUserTypes(): Combo[] {
        const TipoUsuario = UserRoleEntityDTO.EntityTypeEnum;
        const comboOptions: Combo[] = Object.keys(TipoUsuario).map(key => ({
            id: TipoUsuario[key as keyof typeof TipoUsuario],
            name: key
        }));
       return comboOptions;
    }

    getComboTipologia(): Combo[] {
        const TipologiaEnum = TarifaAdheridoUnUsoDTO.TipologiaEnum;
        const comboOptions: Combo[] = Object.keys(TipologiaEnum).map(key => ({
            id: TipologiaEnum[key as keyof typeof TipologiaEnum],
            name: key
        }));
       return comboOptions;
    }
}