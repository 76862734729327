import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {
  FacturaPtoRecogidaControllerService,
  SolicitudRetiradaControllerService,
  SolicitudRetiradaEstadoControllerService,
  SolicitudRetiradaFilterDTO,
  SolicitudReutilizacionControllerService,
  SolicitudReutilizacionEstadoDTO,
  SubTipoEnvaseControllerService,
  SubTipoEnvaseDTO,
  SubTipoEnvaseFilterDTO,
  TipoEnvasesControllerService,
  TipoEnvasesDTO,
  UsuarioSDDRCaDTO
} from "api-rest";
import {EmpusaAuthenticationService} from "@empusa/empusa-core";
import {DatePipe} from "@angular/common";
import {SpinnerService} from "../../../services/spinner.service";
import {DownloadService} from "../../../services/download.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {FormatCurrencyPipe} from "../../../share/pipe/FormatCurrency.pipe";
import {Combo, ComboService} from "../../../services/combo.service";
import {zip} from "rxjs";
import {OpenSddrService} from "../../../services/open-sddr.service";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { OpenSddrRequestsWithdrawnDetailComponent } from '../open-sddr-requests-withdrawn-detail/open-sddr-requests-withdrawn-detail.component';

@Component({
  selector: 'app-components-open-sddr-requests-withdrawn-search',
  templateUrl: './open-sddr-requests-withdrawn-search.component.html',
  styleUrls: ['./open-sddr-requests-withdrawn-search.component.scss']
})
export class OpenSddrRequestsWithdrawnSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  totalSearch: number = 0;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  defaultFilter: boolean = true;
  ocultarBuscador: boolean = true;

  headArray: any = [
    {
      Head: 'Fecha solicitud',
      FieldName: 'fechaSolicitud',
      renderValue: (item: any) => this.datePipe.transform(item.fechaSolicitud, 'dd/MM/yyyy'),
    },
    {
      Head: 'Razón social',
      renderValue: (item: any) => item.adherido?.razonSocial ?? item.poseedor?.razonSocial,
    },
    {
      Head: 'Provincia usuario',
      FieldName: 'provincia',
      renderValue: (item: any) => {
        const domicilio = item?.adherido?.domicilios?.find((dom: any) => dom.tipoDireccion.descripcion === "Social");
        return domicilio?.provincia?.nombre || '';
      }
    },
    {
      Head: 'Gest/Acondic.',
      FieldName: 'gestorAcondicionador',
      Attribute: 'razonSocial'
    },
    {
      Head: 'NIMA Acondic.',
      FieldName: 'gestorAcondicionador',
      Attribute: 'nima'
    },
    {
      Head: 'Provincia gestor',
      FieldName: 'provincia',
      renderValue: (item: any) => {
        const domicilio = item?.gestorAcondicionador?.domicilios?.find((dom: any) => dom.tipoDireccion.descripcion === "Física");
        return domicilio?.provincia?.nombre || '';
      }
    },
    {
      Head: 'Tipo envase',
      FieldName: 'tipoEnvase',
      Attribute: 'descripcion'
    },
    {
      Head: 'Subtipo envase',
      FieldName: 'subTipoEnvase',
      Attribute: 'descripcion'
    },
    {
      Head: 'Lote envase',
      FieldName: 'loteEnvase',
    },
    {
      Head: 'Cantidad a retirar (Ud)',
      FieldName: 'cantidadRetirarUd',
      renderValue: (item: any) => this.formatCurrencyPipe.transform(item.cantidadRetirarUd),
    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcion'
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      check: true,
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Editar',
          show: {params: 'canAccessView', accion: 'view'},
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: {
            params: 'canAccessDelete',
            accion: 'delete',
          },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'send_request',
              name: 'Enviar solicitud',
              show: {
                params: 'canAccessSendRequest',
                accion: 'send_request',
              },
            },
            {
              nameButton: 'accept_request',
              name: 'Aceptar solicitud',
              show: {
                params: 'canAccessAcceptRequest',
                accion: 'accept_request',
              },
            },
            {
              nameButton: 'reject_request',
              name: 'Rechazar solicitud',
              show: {
                params: 'canAccessRejectRequest',
                accion: 'reject_request',
              },
            },
          ],
        },
      ],
      width: '12',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;
  tiposUsuariosSddr!: UsuarioSDDRCaDTO[];
  tiposUsuarios!: Combo[];
  tiposEnvases!: TipoEnvasesDTO[];
  subtiposEnvases!: SubTipoEnvaseDTO[];
  estados!: SolicitudReutilizacionEstadoDTO[];
  gestores!: any;
  gestoresEndpoint = this.environment.urlBackCore + 'api/v1/poseedores/gestores-residuos/completados';

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private comboService: ComboService,
    private tipoEnvasesCtrSrv: TipoEnvasesControllerService,
    private solicitudReutilizacionCtrSrv: SolicitudReutilizacionControllerService,
    private solicitudRetiradaCtrSrv: SolicitudRetiradaControllerService,
    private solicitudRetiradaEstadoCtrSrv: SolicitudRetiradaEstadoControllerService,
    private subtipoEnvaseCtrSrv: SubTipoEnvaseControllerService,
    private datePipe: DatePipe,
    private openSddrSrv: OpenSddrService,
    private formatCurrencyPipe: FormatCurrencyPipe,
  ) {
    this.searchForm = this.formBuilder.group({
      tipoUsuario: [{value: null, disabled: false}],
      idUsuario: [{value: null, disabled: false}, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      usuarioSDDRCA: [{value: null, disabled: false}],
      tipoEnvase: [{value: null, disabled: false}],
      subtipoEnvase: [{value: null, disabled: false}],
      loteEnvase: [{value: null, disabled: false}],
      gestorAcondicionador: [{value: null, disabled: false}],
      fechaDesde: [{value: null, disabled: false}],
      fechaHasta: [{value: null, disabled: false}],
      estado: [{value: null, disabled: false}]
    });
  }

  ngOnInit() {
    this.cargarCombos();
    this.headArray = !this.canViewResource('SDDR-CA-ENV-GEST') || !this.canViewResource('SDDR-CA-ENV-CONS')
      ? this.headArray
      : this.headArray.filter(
        (head: any) =>
          head.FieldName !== 'provincia'
      );
    this.search();
  }

  cargarCombos(): void {
    this.tiposUsuarios = this.comboService.getTiposUsuarioSolicitud();

    const tipoEnvaseFilter = {sddrca: true};
    const subtipoEnvaseFilter: SubTipoEnvaseFilterDTO = {tipoEnvase: {sddrca: true}};
    this.spinnerSrv.loadingShow();
    zip(
      this.tipoEnvasesCtrSrv.findAll(tipoEnvaseFilter, {page: 0, size: 100}),
      this.solicitudReutilizacionCtrSrv.findAllUsuariosAsociados(),
      this.subtipoEnvaseCtrSrv.listarSubTipoEnvases(subtipoEnvaseFilter, {page: 0, size: 100}),
      this.solicitudRetiradaEstadoCtrSrv.listarComunidades({}, {page: 0, size: 500})
    ).subscribe({
      next: (responses: any[]) => {
        this.spinnerSrv.loadingHide();
        this.tiposEnvases = responses[0].datos;
        this.tiposUsuariosSddr = responses[1];
        this.subtiposEnvases = responses[2].datos;
        this.estados = responses[3].datos
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  getGestorDescription(gestor: any) {
    return gestor?.razonSocial;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'gestorAcondicionador': {
        return this.searchForm.get('gestorAcondicionador') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if ((this.searchForm.controls[controlName]?.touched || this.searchForm.controls[controlName]?.dirty)
      && this.searchForm.controls[controlName]?.errors) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.search();
    }
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.search();
  }

  limpiar(): void {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  canAccessSDDRCAUser(): boolean {
    return this.canViewResource('PO-POSEEDOR') || this.canViewResource('AD-ADHERIDO');
  }

  canAccessGestor(): boolean {
    return this.canViewResource('PO-GESTOR');
  }

  canAccessEnvalora(): boolean {
    return this.canViewResource('SDDR-CA-ENV-GEST') || this.canViewResource('SDDR-CA-ENV-CONS');
  }

  canAccessCrearSolicitud(): boolean {
    return this.authService.can('sddr-ca-solicitudes-retirada', 'create');
  }

  canAccessExportar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-retirada', 'update-exportar');
  }

  canAccessCrearAutofactura(): boolean {
    return this.authService.can('sddr-ca-solicitudes-retirada', 'create-autofactura');
  }

  canAccessAll(list: any) {
    const permissions = {
      view: this.authService.can('sddr-ca-solicitudes-retirada', 'view'),
      delete: this.authService.can('sddr-ca-solicitudes-retirada', 'update-delete'),
      send: this.authService.can('sddr-ca-solicitudes-retirada', 'update-send'),
      accept: this.authService.can('sddr-ca-solicitudes-retirada', 'update-accept'),
      reject: this.authService.can('sddr-ca-solicitudes-retirada', 'update-reject'),
    };

    return list.map((objeto: any) => {
      const user =  objeto.creadoPor == this.authService.getCurrentUserMail();
      const gestorEnvalora = this.canViewResource('SDDR-CA-ENV-GEST');
      const deleteCondition = user || gestorEnvalora;

      return {
        ...objeto,
        canAccessView: permissions.view,
        canAccessDelete: permissions.delete && objeto?.estado?.id === 1 && deleteCondition,
        canAccessSendRequest: permissions.send && objeto?.estado?.id === 1,
        canAccessAcceptRequest: permissions.accept && objeto?.estado?.id === 3,
        canAccessRejectRequest: permissions.reject && objeto?.estado?.id === 3,
      };
    });
  }

  private getBackendFilter() {
    const backendFilter: SolicitudRetiradaFilterDTO = {
      tipoUsuario: this.searchForm.get('tipoUsuario')?.value,
      idUsuario: this.searchForm.get('idUsuario')?.value ? this.searchForm.get('idUsuario')?.value : undefined,
      usuarioSDDRCA: {id: this.searchForm.get('usuarioSddr')?.value},
      tipoEnvase: {id: this.searchForm.get('tipoEnvase')?.value},
      subTipoEnvase: {id: this.searchForm.get('subtipoEnvase')?.value},
      loteEnvase: this.searchForm.get('loteEnvase')?.value ? this.searchForm.get('loteEnvase')?.value : undefined,
      gestorAcondicionador: {id: this.searchForm.get('gestorAcondicionador')?.value},
      estado: {id: this.searchForm.get('estado')?.value}
    };

    if (this.searchForm.get('fechaDesde')?.value) {
      backendFilter.fechaDesde = moment(new Date(this.searchForm.get('fechaDesde')?.value)).format('YYYY-MM-DD')
    }
    if (this.searchForm.get('fechaHasta')?.value) {
      backendFilter.fechaHasta = moment(new Date(this.searchForm.get('fechaHasta')?.value)).format('YYYY-MM-DD')
    }
    return backendFilter;
  }

  search() {
    let filtro: SolicitudRetiradaFilterDTO = this.getBackendFilter();
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    this.spinnerSrv.loadingShow();
    this.openSddrSrv.buscarSolicitudesRetirada(filtro, {
      page: page,
      size: this.pageSize,
      sort: ['id,desc']
    }).subscribe({
      next: (data: any) => {
        this.spinnerSrv.loadingHide();
        if (data) {
          this.flagSearch = data.datos?.length != 0;
          this.ocultarBuscador = ((this.canViewResource('AD-ADHERIDO') || this.canViewResource('PO-POSEEDOR')) && !this.flagSearch);
          this.gridArray = this.canAccessAll(data.datos);
          this.totalElements = data.paginacion?.totalElements ?? 0;
          this.totalSearch = this.gridArray.length;
        }
      },
      error: (error: any) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al buscar las solicitudes de reutilización',
          'error'
        );
      },
    });
  }

  exportarExcel() {
    let filter = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.solicitudRetiradaCtrSrv.exportCSV2(filter)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.downloadService.downloadBlob(response, 'solicitudesRetirada_SDDR_CA.csv');
        },
        error: (err: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al exportar las solicitudes de retirada',
            'error'
          );
        },
      });
  }

  confirmacionAutofactura(): void {
    Swal
      .fire({
        text: '¿Desea crear autofactura de las solicitudes seleccionadas en la tabla?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.crearAutofactura();
        }
      });
  }
  crearAutofactura() {
    const list = this.gridArray.filter((x) => x.checked === true);

    if (list.length == 0) {
      this.snackBarSrv.showSnackBar(
        `Debe seleccionar al menos una solicitud para generar una autofactura`,
        'error'
      );
      return;
    }
    this.spinnerSrv.loadingShow();
    this.solicitudRetiradaCtrSrv.crearAutofactura1(list)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha generado la autofactura correctamente.',
            'success'
          );
          this.search();
        },
        error: (err) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al generar la autofactura.',
            'error'
          );
        },
      });
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openModalDetailRequest(element);
    } else if (element.nameButton == 'delete') {
      this.confirmDelete(element);
    } else if (element.nameButton == 'send_request') {
      this.confirmSend(element);
    } else if (element.nameButton == 'accept_request') {
      this.confirmAccept(element);
    } else if (element.nameButton == 'reject_request') {
      this.confirmReject(element);
    }
  }

  checked(event: any) {
    if (event.estado.id != 4) {
      this.snackBarSrv.showSnackBar(
        'Solo se permitirá autofacturar solicitudes en estado Aceptada',
        'error'
      );
      event.checked = null;
    }
  }

  openModalDetailRequest(solicitud?: any): void {
    const dialogRef = this.dialog.open(
      OpenSddrRequestsWithdrawnDetailComponent,
      {
        width: '1460px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          infoRequests: solicitud,
        },
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }

  confirmDelete(element?: any): void {
    Swal.fire({
      text: '¿Desea eliminar la solicitud de Retirada de envases?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.deleteRequest(element?.id!);
        }
      });
  }

  confirmReject(element?: any): void {
    Swal.fire({
      text: '¿Desea rechazar la solicitud de Retirada de envases?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.rejectRequest(element?.id!);
        }
      });
  }

  confirmSend(element?: any): void {
    Swal.fire({
      text: '¿Desea rechazar la solicitud de Retirada de envases?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.sendRequest(element?.id!);
        }
      });
  }

  confirmAccept(element?: any): void {
    Swal.fire({
      text: '¿Desea aceptar la solicitud de Retirada de envases?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.acceptRequest(element?.id!);
        }
      });
  }

  deleteRequest(id: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudRetiradaCtrSrv.deleteByIdSolicitudRetirada(id)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha eliminado la solicitud de retirada correctamente',
            'success'
          );
          this.search();
        },
        error: (err) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
        },
      });
  }

  rejectRequest(id: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudRetiradaCtrSrv.rechazarSolicitud1(id)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha rechazado la solicitud de retirada de envases correctamente',
            'success'
          );
          this.search();
        },
        error: (err) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
        },
      });
  }

  sendRequest(id: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudRetiradaCtrSrv.enviarSolicitud1(id)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha enviado la solicitud de retirada de envases correctamente',
            'success'
          );
          this.search();
        },
        error: (err) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
        },
      });
  }

  acceptRequest(id: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudRetiradaCtrSrv.aceptarSolicitud1(id)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha aceptado la solicitud de retirada de envases correctamente',
            'success'
          );
          this.search();
        },
        error: (err) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
