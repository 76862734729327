import { EventEmitter, Inject, OnChanges, Output } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ComunidadDTO,
  DeclaracionAdheridoDTO,
  DeclaracionCentroControllerService,
  DeclaracionCentroDTO,
} from 'api-rest';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { ValidateService } from '../../../services/validate.service';

@Component({
  selector: 'lib-package-center-commercialization',
  templateUrl: './package-center-commercialization.component.html',
  styleUrls: ['./package-center-commercialization.component.scss'],
})
export class PackageCenterCommercializationComponent implements OnChanges {
  comerFormGroup!: FormGroup;
  @Input() centro!: DeclaracionCentroDTO;
  @Input() comunidadesAutonomas!: any[];
  @Output() actualizarDeclaracion: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() declaracionAdherido!: DeclaracionAdheridoDTO;

  total: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private declaracionCentroControllerSrv: DeclaracionCentroControllerService,
    public spinnerSrv: SpinnerService,
    public snackBarSrv: SnackbarService,
    private validateSrv: ValidateService,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.comunidadesAutonomas && this.centro) {
      this.comunidadesAutonomas.forEach((comunidad) => {
        let match = this.centro.centroComunidad?.find(
          (x) => x?.comunidad?.id === comunidad.id
        );
        comunidad.valor = match?.porcCCAA;
        comunidad.check = match ? true : false;
      });
      this.comerFormGroup = this.formBuilder.group(this.crearCamposForm());
      if (
        !this.authService.can('dec_envases', 'update') ||
        !(this.declaracionAdherido?.estadoDeclaracion?.id === 0)
      ) {
        this.comerFormGroup.disable();
      }
    }
  }

  crearCamposForm(): any {
    let camposFormulario: any = {};
    this.comunidadesAutonomas.forEach((el) => {
      const control = this.formBuilder.control(el.valor);
      control.valueChanges.subscribe(() => {
        this.total = 0;
        Object.values(this.comerFormGroup.controls).forEach((ctrl) => {
          const value = parseFloat(ctrl.value);
          if (!isNaN(value)) {
            this.total += value;
          }
        });
      });
      camposFormulario[el.id] = control;
    });
    return camposFormulario;
  }

  obtenerFormularioChecked() {
    let comerRellenos: any[] = [];
    let total: number = 0;
    this.comunidadesAutonomas.forEach((el) => {
      if (this.comerFormGroup.controls[el.id]?.value) {
        const comunidad = this.comunidadesAutonomas.find((x) => x.id == el.id);
        comerRellenos.push({
          id: comunidad.id,
          comunidad: comunidad,
          declaracionCentro: this.centro,
          porcCCAA: this.comerFormGroup.controls[el.id]?.value,
        });
        total = total + this.comerFormGroup.controls[el.id]?.value;
      }
    });
    return { arrayComunidades: comerRellenos, totales: total };
  }

  guardarComercializacion(): void {
    console.log('obtenerFormularioChecked', this.obtenerFormularioChecked());
    const arrayCheck = this.obtenerFormularioChecked();
    const percenteOk = Math.floor(arrayCheck.totales);
    if (percenteOk === 100 && this.comerFormGroup.valid) {
      this.spinnerSrv.loadingShow();
      this.declaracionCentroControllerSrv
        .guardarComunidades(this.centro?.id!, arrayCheck.arrayComunidades)
        .subscribe({
          next: (responses) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Se han guardado la comercialización correctamente',
              'success'
            );
            this.actualizarDeclaracion.emit(true);
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar comercialización',
              'error'
            );
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Todos los porcentajes de comercialización deben de sumar 100.',
        'error'
      );
    }
  }

  canAccessComunidadesEdit(): boolean {
    return this.authService.can('dec_envases-ccaa', 'update');
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.comerFormGroup.controls[controlName].hasError(errorName) &&
      this.comerFormGroup.controls[controlName].touched
    );
  }

  canAccessEditAllGlobal(): boolean {
    return (
      this.authService.can('dec_envases', 'update') &&
      this.declaracionAdherido?.estadoDeclaracion?.id == 0
    );
  }
}
