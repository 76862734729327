import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KamNewUserData } from './kam-new-user-data.interface';

@Component({
  selector: 'keycloak-auth-manager-kam-new-user',
  templateUrl: './kam-new-user.component.html',
  styleUrls: ['./kam-new-user.component.css']
})
export class KamNewUserComponent implements OnInit {
  step1Completed: boolean = true;
  step2Completed: boolean = true;

  constructor(public dialogRef: MatDialogRef<KamNewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KamNewUserData,
    ) { }


  ngOnInit(): void {    
  }

  setStep1Completed($event:any){
    this.step1Completed=$event
  }
  setStep2Completed($event:any){    
    this.step2Completed=$event
  }

  setStep5Completed($event:any){    
    console.log("step5Completed",this.step2Completed)    
    this.dialogRef.close($event)
  }
}
