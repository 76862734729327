<div>
    <h1 mat-dialog-title>
        <mat-icon class="icon" style="vertical-align: middle;">people_outline</mat-icon>
        {{"KAM-UPDATE-DIALOG.UPDATE_USER_GROUPS_TITLE" | translate}}
    </h1>

    <div mat-dialog-content>
        <keycloak-auth-manager-kam-group-selection
            [initialGroupsSelected]="initialGroupsSelected"
            (groupSelection)="groupSelectionUpdate($event)"
            (groupsUnselected)="groupsUnselectUpdate($event)"> 
        </keycloak-auth-manager-kam-group-selection>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="cancel()">{{'KAM-GENERAL-DIALOG.CANCEL'| translate}}</button>
        <button mat-raised-button color="primary" (click)="updateGroups()" >{{'KAM-GENERAL-DIALOG.ACCEPT'| translate}}</button>
    </div>
</div>