import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { KeycloakRoleModel } from '../../model/keycloak-role.model';
import { AuthManagerState } from '../../state/auth-manager.model';
import { selectResourceServers, selectRoles } from '../../state/auth-manager.selectors';
import * as authManagementActions from '../../state/auth-manager.actions';
import { KeycloakClientModel } from '../../model/keycloak-client.model';
import { KeycloakPolicyModel, KeycloakPolicyRoleModel } from '../../model/keycloak-policy.model';
import { KamResourceServerSimplePermissionModel, KamSimplePermissionModel } from '../../model/kam-simple-permission.model';
import { MatDialog } from '@angular/material/dialog';
import { KamAssignPermissionsToRoleComponent } from './permissions/kam-assign-permissions-to-role/kam-assign-permissions-to-role.component';
import { EmpusaMicroApp, SnackBarService } from '@empusa/empusa-core';
import { FormControl, FormGroup } from '@angular/forms';
import { KamResourceServerServices } from '../../services/kam-resource-server.services';
import { KamQuestionDialogComponent } from '../common/kam-question-dialog/kam-question-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { KamManageResourceComponent } from '../kam-permissions-tab/kam-manage-resource/kam-manage-resource.component';
import { KamManageRoleComponent } from './kam-manage-role/kam-manage-role.component';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'keycloak-auth-manager-kam-roles-tab',
  templateUrl: './kam-roles-tab.component.html',
  styleUrls: ['./kam-roles-tab.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0', overflow : 'hidden'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})

export class KamRolesTabComponent implements OnInit {

  rolesSubscription: Subscription | undefined;
  resourceServersSubscription: Subscription | undefined;
  dataSource: MatTableDataSource<KeycloakRoleModel> = new MatTableDataSource();
  columnsToDisplay = ['name', 'description', 'action'];
  expandedElement: KeycloakRoleModel | null = null;
  roles: KeycloakRoleModel[] = [];
  resourceServers: KeycloakClientModel[] = [];
  resourceServerPermissions: ResourceServerPermissions[] = [];
  preSelectedPermissions: string[] = [];
  openenPanel: string = "";
  ready = false;
  loading = false;
  usersCount: number=0;
  allRowsExpanded: boolean = false;

  formGroup: FormGroup | undefined;
  data: KamAssignPermissionsToRoleData | undefined = undefined;


  constructor(private store: Store<AuthManagerState>,
    public dialog: MatDialog,
    private kamResourceServerServices: KamResourceServerServices,
    private snackBarService: SnackBarService,
    @Inject('environment') private environment: any
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.store.dispatch(authManagementActions.RetrieveRoles());
    this.getDataSource();
    this.resourceServersSubscription = this.store.select(selectResourceServers).subscribe(resourceServers => {
      this.resourceServers = resourceServers;
      this.getPermissions();
      this.addPermnissionsToRoles();
      this.dataSource.data = this.roles;
    })
    console.log("//////// dataSource: ", this.dataSource);
    this.loading = false;
  }

  getDataSource() {
   
    this.rolesSubscription = this.store.select(selectRoles).subscribe(roles => {
      this.roles = [];
      roles.forEach(role => {
        console.log("ROLE: " , role);
        
        this.roles.push({ ...role })
      })
      this.addPermnissionsToRoles();
      this.dataSource.data = this.roles;
    });


  }

  event(){
    console.log("ENTRA EVENT");
    this.formGroup = undefined;
  }

  private getPermissions() {
    this.resourceServerPermissions = [];
    let permissions: KeycloakPolicyModel[] = [];
    let policies: KeycloakPolicyModel[] = [];
    this.resourceServers.forEach(resourceServer => {
      resourceServer.resourceServerSettings?.policies.forEach(policy => {
        if (policy.config?.resources && policy.config.scopes) {
          permissions.push(policy);
        } else if (policy.config?.roles) {
          policies.push(policy)
        }
      });

      let aResourceServerPermission: ResourceServerPermissions = {
        name: resourceServer.name,
        id: resourceServer.id,
        clientId: resourceServer.clientId,
        permissions: permissions,
        policies: policies,
      }
      this.resourceServerPermissions.push(aResourceServerPermission);
      permissions = [];
      policies = [];
    });
  }

  updateResourceService(element: MatTableDataSource<any>) {
    console.log("element: ", element);

  }

  addRole(){
    const dialogRef = this.dialog.open(KamManageRoleComponent, {
      width: '500px',  
      data: { 
        title: "KAM-ROLES-TAB.NEW_ROLE",
        icon: "add_circle",
       }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      if (result) {
        console.log("ENTRAAAAAAAA 1");
        
        //this.retrieveResourceServers()
      } else{
        this.store.dispatch(authManagementActions.RetrieveRoles());
        this.loading = false;
      }
    });
  }

  loadListUser(event : PageEvent){
    /*this.paginator.pageSize = event.pageSize;
    this.paginator.pageIndex = event.pageIndex;
    let userFilter: KamUserFilter = {
      offset: event.pageIndex,
      limit: event.pageSize
    }

    this.getUsers(userFilter);*/
  }  

  deleteResource(resourceId: any) {
    const dialogRef = this.dialog.open(KamQuestionDialogComponent, {
      width: '500px',  
      data: {
            title: "KAM-QUESTION-DIALOG.DELETE_RESOURCE_TITLE",
            question: "KAM-QUESTION-DIALOG.DELETE_RESOURCE_QUESTION",
            parameters:{resource: "prueba"},
            icon: "delete_outline"
          } 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.loading = true;
        this.kamResourceServerServices.deleteRole(resourceId).subscribe(groups => {
          this.store.dispatch(authManagementActions.RetrieveRoles());
          this.snackBarService.openSnackBar("Operación realizada con exito", "X");
          this.loading = false;
        }, error => {
          this.loading = false;
          this.snackBarService.openSnackBar("La operación no se ha podido completar", "X");
        });
      }
    });
    
    console.log("////////////////////////////////////////");
    console.log("resourceId: ", resourceId);

    

  }

  dataReload(){
    console.log("ENTRAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");
    
    this.store.dispatch(authManagementActions.RetrieveRoles());
  }

  public toggle() {
    this.allRowsExpanded = !this.allRowsExpanded;
    this.expandedElement = null;
  }

  updatePermissions(role: KeycloakRoleModel, resourceServerId: string) {
    this.loading = true;
    console.log("/////////////////////////////////");

    console.log("ROLE: ", role);
    console.log("resourceServerId: " + resourceServerId);


    const found: ResourceServerPermissions | undefined = this.resourceServerPermissions.find(rsp => rsp.clientId == resourceServerId)
    console.log("found: ", found);
    this.data = undefined;
    this.data = {
      role: role,
      resourceServerPermissions: found
    }

    this.ready = true;
    this.preSelectedPermissions = [];
    this.data.resourceServerPermissions!.permissions.forEach(permission => {
      permission.config?.applyPolicies?.forEach(appliedPolicyName => {
        const foundPolicy = this.data!.resourceServerPermissions!.policies.find(policy => policy.name == appliedPolicyName);
        if (foundPolicy) {
          const hasAcccess = foundPolicy.config?.roles?.find((role: KeycloakPolicyRoleModel) => role.id == this.data!.role.name)
          if (hasAcccess && permission.id) {
            this.preSelectedPermissions!.push(permission.id)
           
          }
        }
      })
    })
    let permissionsListCtl = new FormControl(this.preSelectedPermissions);
    this.formGroup = new FormGroup({
      permissionsList: permissionsListCtl,
    });

    /*dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.retrieveResourceServes();
      }
    });*/
    this.loading = false;

  }
  private addPermnissionsToRoles() {
    this.roles.forEach(role => {
      let rolePolicies: KeycloakPolicyModel[] = [];
      //Search policies applied to the role
      this.resourceServers.forEach(resourceServer => {
        resourceServer.resourceServerSettings?.policies.forEach(policy => {
          const found = policy.config?.roles?.find(policyRole => policyRole.id == role.name)
          if (found) {
            rolePolicies.push(policy);
          }
        })
      });
      //search the role policies applied to scope policies (permissions)
      let finalPermissions: KamResourceServerSimplePermissionModel[] = [];

      this.resourceServers.forEach(resourceServer => {
        let kamResourceServerSimplePermissionModel: KamResourceServerSimplePermissionModel = {
          permissions: [],
          resourceServerId: resourceServer.clientId,
          resourceServerName: resourceServer.name,
        }
        rolePolicies.forEach(rolePolicy => {
          resourceServer.resourceServerSettings?.policies.forEach(policy => {
            if (policy.config?.applyPolicies) {
              const found = policy.config?.applyPolicies?.find(appliedPolicy => appliedPolicy == rolePolicy.name);
              if (found) {
                policy.config.resources?.forEach(resource => {
                  policy.config?.scopes?.forEach(scope => {
                    const rolePolicyId = rolePolicy.id ? rolePolicy.id : "";
                    const policyId = policy.id ? policy.id : "";
                    let kamSimplePermission: KamSimplePermissionModel = {
                      logic: rolePolicy.logic,
                      policyId: rolePolicyId,
                      policyName: rolePolicy.name,
                      permissionPolicyId: policyId,
                      permissionPolicyName: policy.name,
                      permissionPolicyDescription: policy.description,
                      resourceName: resource,
                      scope: scope,
                    }
                    kamResourceServerSimplePermissionModel.permissions.push(kamSimplePermission);
                  })
                })
              }
            }
          })
        });
        finalPermissions.push(kamResourceServerSimplePermissionModel)
      });
      role.permissions = finalPermissions;
    })
  }


  private retrieveResourceServes() {
    let allModules: EmpusaMicroApp[] = (<any>window).empusa.modules;
    let resourceServers: Array<string> = [this.environment.empusaPermissionServer];
    allModules.forEach(module => {
      if (module.resourceServerId) {
        resourceServers.push(module.resourceServerId.trimEnd());
      }
    });
    this.store.dispatch(authManagementActions.RetrieveResourceServes({ clients: resourceServers }));
  }

  accept() {
    let control = this.formGroup?.get('permissionsList');
    if (!control) return;

    const policiesToAddRole: KeycloakPolicyModel[] = this.calPoliciesToAddRole(control);
    const policiesToDeleteRole: KeycloakPolicyModel[] = this.calPoliciesToDeleteRole(control);

    let policiesToUpdate: Observable<any>[] = [];
    this.generatePoliciesToAddRoleRequests(policiesToAddRole, policiesToUpdate);
    this.generatePoliciesToRemoveRoleRequests(policiesToDeleteRole, policiesToUpdate);

    forkJoin(policiesToUpdate).subscribe({
      next: (v) => { },
      error: (e) => {
        console.error(e)
        this.snackBarService.openSnackBar("La operación no se ha podido completar", "X");
      },
      complete: () => {
        this.snackBarService.openSnackBar("Operación realizada con exito", "X");

        //this.dialogRef.close(true)
      }
    })
  }

  private generatePoliciesToRemoveRoleRequests(policiesToDeleteRole: KeycloakPolicyModel[], policiesToUpdate: Observable<any>[]) {
    policiesToDeleteRole.forEach(toRemove => {

      let toRemoveRoles: KeycloakPolicyRoleModel[] = [];
      toRemove.config?.roles?.forEach(presentRole => {
        if (presentRole.id == this.data!.role.name)
          return;

        const role: KeycloakPolicyRoleModel = {
          id: presentRole.id
        };
        toRemoveRoles.push(role);
      });

      const updatePolicy: KeycloakPolicyModel = {
        description: toRemove.description,
        id: toRemove.id,
        logic: toRemove.logic,
        name: toRemove.name,
        type: toRemove.type,
        decisionStrategy: toRemove.decisionStrategy,
        roles: toRemoveRoles
      };
      const updateRequest = this.kamResourceServerServices.putPolicy(this.data!.resourceServerPermissions!.id, updatePolicy);
      policiesToUpdate.push(updateRequest);
    });
  }

  private generatePoliciesToAddRoleRequests(policiesToAddRole: KeycloakPolicyModel[], policiesToUpdate: Observable<any>[]) {
    policiesToAddRole.forEach(toAdd => {
      const role: KeycloakPolicyRoleModel = {
        id: this.data!.role.id
      };
      let toAddRoles: KeycloakPolicyRoleModel[] = [];
      toAddRoles.push(role);
      toAdd.config?.roles?.forEach(presentRole => {
        const role: KeycloakPolicyRoleModel = {
          id: presentRole.id
        };
        toAddRoles.push(role);
      });

      const updatePolicy: KeycloakPolicyModel = {
        description: toAdd.description,
        id: toAdd.id,
        logic: toAdd.logic,
        name: toAdd.name,
        type: toAdd.type,
        decisionStrategy: toAdd.decisionStrategy,
        roles: toAddRoles
      };
      const updateRequest = this.kamResourceServerServices.putPolicy(this.data!.resourceServerPermissions!.id, updatePolicy);
      policiesToUpdate.push(updateRequest);
    });
  }

  private calPoliciesToDeleteRole(control: any): KeycloakPolicyModel[] {
    let policiesToDeleteRole: KeycloakPolicyModel[] = []
    this.data!.resourceServerPermissions!.permissions.forEach(permission => {
      const found = control?.value.find((value: string) => value == permission.id);
      if (!found) {
        permission.config?.applyPolicies?.forEach(applyPolicy => {
          const foundPolicy = this.data!.resourceServerPermissions!.policies.find(policy => policy.name == applyPolicy);
          if (foundPolicy) {
            const alreadyHasAcccess = foundPolicy.config?.roles?.find((role: KeycloakPolicyRoleModel) => role.id == this.data!.role.name);
            if (alreadyHasAcccess) {
              policiesToDeleteRole.push(foundPolicy);
            }
          }
        });
      }
    });
    return policiesToDeleteRole
  }

  private calPoliciesToAddRole(control: any): KeycloakPolicyModel[] {
    let policiesToAddRole: KeycloakPolicyModel[] = [];
    control.value.forEach((element: string) => {
      const foundPermissions = this.data!.resourceServerPermissions!.permissions.find(permission => permission.id == element);
      foundPermissions?.config?.applyPolicies?.forEach(applyPolicy => {
        const foundPolicy = this.data!.resourceServerPermissions!.policies.find(policy => policy.name == applyPolicy);
        if (foundPolicy) {
          const alreadyHasAcccess = foundPolicy.config?.roles?.find((role: KeycloakPolicyRoleModel) => role.id == this.data!.role.name);
          if (!alreadyHasAcccess) {
            policiesToAddRole.push(foundPolicy);
          }
        }
      });
    });
    return policiesToAddRole
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}

export interface KamAssignPermissionsToRoleData {
  resourceServerPermissions?: ResourceServerPermissions,
  role: KeycloakRoleModel,
}


export interface ResourceServerPermissions {
  name?: string;
  id: string;
  clientId: string;
  permissions: KeycloakPolicyModel[];
  policies: KeycloakPolicyModel[];
}