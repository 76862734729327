import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaMicroAppType } from '@empusa/empusa-core';
import { HelloWorldHomeModule } from './hello-world.home.module';


@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class HelloWorldModule implements EmpusaMicroApp{
  name: string = "hello-world";
  description: string = "hello-world";
  icon: string = "language";
  page: any = HelloWorldHomeModule;
  homeApp: boolean = false;
  path: string = "hello-world";
  order: number = 10;
  resourceServerId: string = "keycloak-auth-manager-resources-server";
  appType: EmpusaMicroAppType = EmpusaMicroAppType.normal;

  constructor() {
    //we register ourselves
    (<any>window).empusa(this);    
    console.debug("Load microapp " + this.name);
  }
}
