export const locale_en = {
    "lang": "en",
    "data": {
        "MENU": {
            "TERMS_OF_USE":"Terms of Use",
            "PRIVACY_POLICY":"Privacy Policy",
            "MICROAPP-HOME": "Home",
            "HOME" : "Home",
            "MICROAPP-VMS" : "VMS",
            "KEYCLOAK-AUTH-MANAGER" : "Auth Manager",
            "HELLO-WORLD" : "Hello World",
        },
        "LOGIN": {
            "TITLE": "Login",
            "LABEL.USERNAME": "Username",
            "LABEL.PASSWORD": "Password",
            "BUTTON.SIGNIN": "Sign in",
            "FORGOTPASSWORD": "Forgot password?",
            "REQUIRED.USERNAME": "Username is required!",
            "REQUIRED.PASSWORD": "Password is required!"
        },
        "HOME": {
            "SITE_PREFIX": "Building:",
            "MENU.LANGUAGE": "Language",
            "MENU.LOGOUT": "Logout",
            "MENU.HOME": "Home",
            "MENU.LANGUAGE.SPANISH": "Español",
            "MENU.LANGUAGE.ENGLISH": "English",
            "MENU.LANGUAGE.SPANISH_MEXICO": "Español de México",
            "MENU.THEMES": "Themes",
            "MENU.THEME":{
                "DEFAULT": "Empusa",
                "MORI": "Mori"
            }
        },
        "PROFILE":{
            "TITLE": "User Profile",
            "REQUIRED": "This is a required field",
            "INVALID": "The field has an invalid value",
            "FIRSTNAME": "First Name",
            "LASTNAME": "Last Name",
            "EMAIL": "Email",
            "ROLES": "Roles",
            "GROUPS": "Groups",
            "PASSWORD": "New Password",
            "CONFIRM": "Confirm New Password",
            "NOTCONFIRM": " The confirmation does not match the password",
            "CANCEL": "Cancel",
            "CLOSE": "Close",
            "UPDATE": "Update Profile",
            "RESET": "Reset Password"
        },
        "MATERIAL": {
            "TABLE.ITEMS_PER_PAGE_LABEL": "Items/page",
            "TABLE.NEXT_PAGE_LABEL": "Next",
            "TABLE.PREVIOUS_PAGE_LABEL": "Previous",
            "TABLE.FIRST_PAGE_LABEL": "First",
            "TABLE.LAST_PAGE_LABEL": "Last",
            "TABLE.RANGE_PAGE_LABEL_1": "0 of {{length}}",
            "TABLE.RANGE_PAGE_LABEL_2": "{{startIndex}} - {{endIndex}} of {{length}}"
        },
    }
}