import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AgrupacionMaterialControllerService,
  AgrupacionMaterialDTO,
  CategoriaEnvaseControllerService,
  CategoriaEnvaseDTO,
  KeycloakRoleDTO,
  TarifaAdheridoUnUsoControllerService,
  TarifaAdheridoUnUsoDTO,
  TarifaAdheridoUnUsoPayloadDTO,
} from 'api-rest';
import { map } from 'rxjs';
import { Combo, ComboService } from '../../../services/combo.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { MappedResponse } from '../../../share/components/ge-select-pagination/ge-select-pagination.component';
import { RateCategoryTypeEnum } from '../../models/rate-category-type.enum';
import { SpinnerService } from '../../../services/spinner.service';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';

export interface AttachedRateOnUseModalData {
  editMode: boolean;
  item: TarifaAdheridoUnUsoDTO;
}

@Component({
  selector: 'lib-attached-rate-one-use-modal',
  templateUrl: './attached-rate-one-use-modal.component.html',
  styleUrls: ['./attached-rate-one-use-modal.component.scss'],
})
export class AttachedRateOneUseModalComponent {
  tarifa: TarifaAdheridoUnUsoDTO;
  newItem: boolean = true;
  formGroup!: FormGroup;
  categorias: CategoriaEnvaseDTO[] = [];
  tipologias: Combo[] = [];
  roles: KeycloakRoleDTO[] = [];
  editMode = false;
  grupoEnvaseEndpoint =
    this.environment.urlBackCore + 'api/v1/core/grupoEnvase';
  agrupacionMaterialEndpoint =
    this.environment.urlBackCore + 'api/v1/core/agrupacionMaterial';
  categoriaEnvaseEndpoint =
    this.environment.urlBackCore + 'api/v1/core/packaging-category';
  defaultFilter: boolean = true;
  constructor(
    @Inject('environment') private environment: any,
    public dialogRef: MatDialogRef<AttachedRateOneUseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AttachedRateOnUseModalData,
    private formBuilder: FormBuilder,
    private tarifaUnUsoService: TarifaAdheridoUnUsoControllerService,
    private categoryEnvaseService: CategoriaEnvaseControllerService,
    private comboService: ComboService,
    private snackbarService: SnackbarService,
    public spinnerSrv: SpinnerService,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.tarifa = this.data?.item;
    this.newItem = !this.tarifa?.id;
    this.editMode = this.data?.editMode || this.newItem;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.tipologias = this.comboService.getComboTipologia();
    this.categoryEnvaseService
      .listarCategoriaEnvases('body')
      .pipe(
        map((res) =>
          res.filter(
            (cat) =>
              cat.id == RateCategoryTypeEnum.IndustrialUnUso ||
              cat.id == RateCategoryTypeEnum.ComercialUnUso
          )
        )
      )
      .subscribe((res) => (this.categorias = res));
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      id: [this.tarifa ? this.tarifa.id : ''],
      categoria: [
        this.tarifa ? this.tarifa.categoria?.id : '',
        Validators.required,
      ],
      anio: [
        this.tarifa ? this.tarifa.anio : '',
        [Validators.required, this.customValidator()],
      ],
      tipologia: [
        this.tarifa ? this.tarifa.tipologia : '',
        Validators.required,
      ],
      valor: [
        this.tarifa ? this.formatCurrencyPipe.transform(this.tarifa.valor) : '',
        Validators.required,
      ],
      peligrosidad: [
        this.tarifa ? this.tarifa.peligrosidad : false,
        Validators.required,
      ],
      grupoEnvase: [
        this.tarifa ? this.tarifa.grupoEnvase?.descripcion : '',
        Validators.required,
      ],
      agrupacionMaterial: [
        this.tarifa ? this.tarifa.agrupacionMaterial?.descripcion : '',
        Validators.required,
      ],
    });

    if (!this.editMode) {
      this.formGroup.disable();
    }
  }

  habilitarEdicion() {
    this.formGroup.enable();
    this.editMode = true;
  }

  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      const tarifaPayload: TarifaAdheridoUnUsoPayloadDTO | any =
        this.formGroup.value;
      // Incializamos la entidad asociada con el valor original

      if (typeof tarifaPayload.categoria == 'number') {
        tarifaPayload.categoria = {
          id: tarifaPayload.categoria,
        };
      }

      tarifaPayload.valor = this.parseCurrencyPipe.transform(
        tarifaPayload.valor
      );

      if (typeof tarifaPayload.grupoEnvase !== 'object') {
        tarifaPayload.grupoEnvase = this.tarifa.grupoEnvase!;
      }
      if (typeof tarifaPayload.agrupacionMaterial !== 'object') {
        tarifaPayload.agrupacionMaterial = this.tarifa.agrupacionMaterial!;
      }
      this.spinnerSrv.loadingShow();
      let request, successMsg: string, errorMsg: string;
      if (this.newItem) {
        request = this.tarifaUnUsoService.create(tarifaPayload);
        successMsg = `La tarifa de envase de un solo uso ${tarifaPayload.anio} se ha creado correctamente`;
        errorMsg =
          'Ha ocurrido un error al crear la nueva tarifa de envase de un solo uso. ';
      } else {
        request = this.tarifaUnUsoService.editById(
          this.tarifa.id!,
          tarifaPayload
        );
        successMsg = `La tarifa de envase de un solo uso ${tarifaPayload.anio} se ha editado correctamente`;
        errorMsg =
          'Ha ocurrido un error al editar la tarifa de envase de un solo uso. ';
      }

      request.subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.dialogRef.close(true);
          this.snackbarService.showSnackBar(successMsg, 'success');
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    } else {
      console.log(this.formGroup.errors);
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  getFormControl(controlName: string): FormControl {
    return this.formGroup.controls[controlName] as FormControl;
  }

  mapResponse(response: any): MappedResponse<any> {
    return {
      datos: response.content,
      recordsFiltered: response.recordsTotal,
      totalElements: response.totalElements,
    };
  }

  getEntidadAsociadoFormControl(): FormControl<any> {
    return this.formGroup.controls['entidadAsociada'] as FormControl<any>;
  }

  customValidator() {
    return (control: { value: any }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (/^\d{4}$/.test(value)) {
        const intValue = parseInt(value, 10);
        if (intValue >= 1900 && intValue <= 2500) {
          return null;
        }
      }
      return { invalid: true };
    };
  }
  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
}
