import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'keycloak-auth-manager-kam-question-dialog',
  templateUrl: './kam-question-dialog.component.html',
  styleUrls: ['./kam-question-dialog.component.css']
})
export class KamQuestionDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<KamQuestionDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: KamQuestionDialogData) {
}

  ngOnInit(): void {    
  }

}


export interface KamQuestionDialogData {
  title: string;
  question: string;
  parameters: any;
  icon: string;
}