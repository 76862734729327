/**
 * Acuerdos-Con-Gestores API
 * Acuerdos-Con-Gestores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PageInfoDTO { 
    totalPages?: number;
    totalElements?: number;
    first?: boolean;
    last?: boolean;
    hasPrevious?: boolean;
    hasNext?: boolean;
    page?: number;
    size?: number;
    numberOfElements?: number;
    sort?: string;
}

