import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { KeycloakClientModel } from '../../../model/keycloak-client.model';
import { KeycloakResourceModel } from '../../../model/keycloak-resource.model';
import { KamResourceServerServices } from '../../../services/kam-resource-server.services';

@Component({
  selector: 'keycloak-auth-manager-kam-manage-resource',
  templateUrl: './kam-manage-resource.component.html',
  styleUrls: ['./kam-manage-resource.component.css']
})
export class KamManageResourceComponent implements OnInit {

  formGroup: FormGroup | undefined;
  resourceNameCtrl = new FormControl('', [Validators.required]);
  displayNameCtrl = new FormControl('', [Validators.required]);
  update: boolean = false;

  constructor(public dialogRef: MatDialogRef<KamManageResourceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KamManageResourceData,
    private kamResourceServerServices: KamResourceServerServices,
    ) { }

    ngOnInit(): void {
      if (this.data.resource)
        this.update=true;
      this.buildForm();
    }
  
    private buildForm() {
      if (this.update){
        this.resourceNameCtrl.setValue(this.data.resource.name);
        this.displayNameCtrl.setValue(this.data.resource.displayName);
      }
      this.formGroup = new FormGroup({
        resourceName: this.resourceNameCtrl,
        resourceDisplayName: this.displayNameCtrl,
      });      
    }
  
    resourceCancel(){
      this.dialogRef.close(undefined);
    }

    resourceAcept(){
        let resource: KeycloakResourceModel = {
          name: this.resourceNameCtrl.value === null ? "":this.resourceNameCtrl.value,
          displayName: this.displayNameCtrl.value === null ? "":this.displayNameCtrl.value,
          type: "urn:"+this.data.resourceServer.clientId+":resources:"+this.resourceNameCtrl.value,
        }
        let request:Observable<KeycloakResourceModel|void>;
        if (this.update){
          resource._id=this.data.resource._id;
          resource.scopes=this.data.resource.scopes;
          request =  this.kamResourceServerServices.putResource(this.data.resourceServer.id, resource);
        }else{
          request = this.kamResourceServerServices.postResource(this.data.resourceServer.id, resource)
        }

        request.subscribe({
          next: (v) => {
            this.dialogRef.close(true);
          },
          error: (e) => console.error(e),
          complete: () => {console.info('complete') }
        });
    }
}

export interface KamManageResourceData {
  icon: string,
  title: string,
  resourceServer: KeycloakClientModel,
  resource:KeycloakResourceModel,
}
