import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTwoDigitDecimalNumber]',
})
export class TwoDigitDecimalNumberDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private control: NgControl
  ) {}

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    let input: string = this.el.nativeElement.value;

    input = input.replace(/[^\d,]/g, '');

    const commaIndex = input.indexOf(',');
    if (commaIndex !== -1) {
      input =
        input.substring(0, commaIndex + 1) +
        input.substring(commaIndex + 1).replace(/,/g, '');
    }

    let parts = input.split(',');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (parts[1]) {
      parts[1] = parts[1].slice(0, 2);
    }

    const formattedValue = parts.join(',');

    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);

    if (this.control.control) {
      this.control.control.setValue(formattedValue, { emitEvent: false });
    }

    setTimeout(() => {
      this.el.nativeElement.setSelectionRange(
        this.el.nativeElement.value.length,
        this.el.nativeElement.value.length
      );
    }, 0);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedData = event.clipboardData?.getData('text');
    if (pastedData) {
      let pastedValue = pastedData.replace(/[^\d,]/g, '');

      const commaIndex = pastedValue.indexOf(',');
      if (commaIndex !== -1) {
        pastedValue =
          pastedValue.substring(0, commaIndex + 1) +
          pastedValue.substring(commaIndex + 1).replace(/,/g, '');
      }

      let parts = pastedValue.split(',');

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      if (parts[1]) {
        parts[1] = parts[1].slice(0, 2);
      }

      pastedValue = parts.join(',');

      this.renderer.setProperty(this.el.nativeElement, 'value', pastedValue);

      if (this.control.control) {
        this.control.control.setValue(pastedValue, { emitEvent: false });
      }

      event.preventDefault();
    }
  }
}
