import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EmpusaAppHeaderClassicComponent, EmpusaTranslationLoaderService } from '@empusa/empusa-core';
import { locale_en as english } from '../translations/translation.en';
import { locale_es as spanish } from '../translations/translation.es';
import { locale_en_GB as english_GB } from '../translations/translation.en-GB';
import { locale_es_ES as spanish_ES } from '../translations/translation.es-ES';
import { MainPageComponent } from '../pages/main-page.component';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

export const explorerRoutes = [
  {
    path: '', component: MainPageComponent, children: []
  },
];

@NgModule({
  declarations: [
    MainPageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatListModule,
    MatFormFieldModule,
    MatIconModule,
    EmpusaAppHeaderClassicComponent,
    RouterModule.forChild(explorerRoutes),
  ],
  exports: [
    MainPageComponent
  ]
})
export class MicroappHomeHomeModule {
  constructor(private translationLoader: EmpusaTranslationLoaderService) {
    let compilationVersion = "0.0.1-R->04/10/23-12:14:49"
    console.log("Home version:" + compilationVersion)
    //loading micro app translations
    this.translationLoader.loadTranslations(english);
    this.translationLoader.loadTranslations(spanish);
    this.translationLoader.loadTranslations(english_GB);
    this.translationLoader.loadTranslations(spanish_ES);
  }
}
