import { Injectable } from "@angular/core";

export interface Combo {
  id: string | number,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class ComboService {
  // Tipos de usuario solicitud de reutilización / retirada
  getTiposUsuarioSolicitud(): Combo[] {
    return [
      { id: 'ADHERIDO', name: 'Adherido' },
      { id: 'POSEEDOR', name: 'Poseedor' }
    ];
  }
  getUsosEnvases(): Combo[]{
    return [
        { id: 'REUTILIZACION_EN_ESPAÑA', name: 'Reutilización en España' },
        { id: 'REUTILIZACION_EXPORTACION', name: 'Reutilización exportación' },
        { id: 'ENVASADO_DE_RESIDUOS', name: 'Envasado de residuos' }
      ];
  }

}
