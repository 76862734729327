import { Attribute, Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import {
  ComunidadControllerService,
  GestorPtoRecogidaCodigoControllerService,
  GestorPtoRecogidaCodigoFilterDTO,
  GestorPtoRecogidaControllerService,
  GestorPtoRecogidaEstadoControllerService,
  GestorPtoRecogidaFilterDTO,
  PageDTOPtoRecogidaDTO,
  PtoRecogidaEstadoControllerService,
  PtoRecogidaGestorControllerService,
  PtoRecogidaGestorFilterDTO,
} from 'api-rest';
import { CollectionPointsManagersPreliminaryComponent } from '../collection-points-managers-preliminary/collection-points-managers-preliminary.component';
import { CollectionPointsSelectManagerDownComponent } from '../collection-points-select-manager-down/collection-points-select-manager-down.component';
import { NextPageService } from '../../../services/nextPage.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-collection-points-managers-search',
  templateUrl: './collection-points-managers-search.component.html',
  styleUrls: ['./collection-points-managers-search.component.scss'],
})
export class CollectionPointsManagersSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  updateList: boolean = false;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  comunidadArray: any[] = [];
  estadoArray: any[] = [];

  headArray: any = [
    {
      Head: 'ID. GESTOR',
      FieldName: 'id',
    },
    {
      Head: 'Razón Social',
      FieldName: 'razonSocial',
    },
    {
      Head: 'Denominación',
      FieldName: 'denominacion',
    },
    {
      Head: 'Documento (NIE,NIF)',
      FieldName: 'codigoDocumento',
    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcionCorta',
    },
    {
      Head: 'NIMA',
      FieldName: 'nima',
    },
    {
      Head: 'Comunidad autónoma (Dirección Física)',
      FieldName: 'domicilios',
      renderValue: (item: any) => {
        const matchingDomicilio = item?.domicilios?.find(
          (domicilio: any) => domicilio?.tipoDireccion?.id == 5
        );
        return matchingDomicilio ? matchingDomicilio.provincia?.nombre : null;
      },
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Editar',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'completado_punto',
              name: 'Gestor completado',
              show: {
                params: 'canAccessComplete',
                accion: 'completado_punto',
              },
            },
            {
              nameButton: 'baja_punto',
              name: 'Baja del gestor',
              show: {
                params: 'canAccessDown',
                accion: 'baja_punto',
              },
            },
          ],
        },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: Array<any> = [];

  estadosArray: any = [];
  codigoLerArray: any = [];
  gestorAsociadoArray: any = [];

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    private comunidadControllerService: ComunidadControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService,
    private gestorPtoRecogidaEstadoControllerService: GestorPtoRecogidaEstadoControllerService,
    private nextPageService: NextPageService,
    private gestorPtoRecogidaCodigoControllerService: GestorPtoRecogidaCodigoControllerService,
    private ptoRecogidaGestorControllerService: PtoRecogidaGestorControllerService
  ) {
    this.searchForm = this.formBuilder.group({
      idGestorPuntoRecogida: [{ value: null, disabled: false }],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      comunidad_autonoma: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
      nima: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.getComboComunidad();
    this.comboEstados();
  }

  checked(event: any) {}

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.gestorPtoRecogidaControllerService
      .exportGestorPtoRecogida(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportación Gestor Punto de recogida.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    const backendFilter: GestorPtoRecogidaFilterDTO | any = {
      id: this.searchForm.get('idGestorPuntoRecogida')?.value,
      razonSocial: this.searchForm.get('razon_social')?.value
        ? `ci(${this.searchForm.get('razon_social')?.value})`
        : undefined,
      codigoDocumento: this.searchForm.get('codigo_documento')?.value
        ? `ci(${this.searchForm.get('codigo_documento')?.value})`
        : undefined,
      comunidadAutonoma: this.searchForm.get('comunidad_autonoma')?.value?.id,
      estado: { id: this.searchForm.get('estado')?.value?.id },
      nima: this.searchForm.get('nima')?.value
        ? `ci(${this.searchForm.get('nima')?.value})`
        : undefined,
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.gestorPtoRecogidaControllerService
      .findAllGestorPtoRecogida(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOPtoRecogidaDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos);
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openDetailShow(element.id);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'completado_punto') {
      this.verificarCompletado(element);
    } else if (element.nameButton == 'baja_punto') {
      Swal.fire({
        text: `¿Desea modificar la gestión de los Puntos de Recogida del actual gestor?`,
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      }).then((result: any) => {
        if (result.isConfirmed) {
          const dialogRef = this.dialog.open(
            CollectionPointsSelectManagerDownComponent,
            {
              width: '700px',
              maxWidth: '95%',
              maxHeight: '95vh',
              data: {
                gestorDetalle: element,
              },
              disableClose: true,
            }
          );
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.searchData();
            }
          });
        }
      });
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el gestor de punto de recogida?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarPoseedor(element);
      }
    });
  }

  eliminarPoseedor(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.gestorPtoRecogidaControllerService
        .borrarGestorPtoRecogida(element.id)
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              `El gestor de punto de recogida se ha eliminado correctamente`,
              'success'
            );
            this.gridArray = this.gridArray.filter(
              (objeto) => objeto.id !== element.id
            );
            this.flagSearch = this.gridArray.length != 0 ? true : false;
          },
          error: (err: { message: any }) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al eliminar el gestor de punto recogida',
              'error'
            );
          },
        });
    }
  }

  comboEstados() {
    this.gestorPtoRecogidaEstadoControllerService
      .findAllGestorEstados()
      .subscribe({
        next: (data: any) => {
          this.estadosArray = data!;
        },
        error: () => {},
      });
  }

  openDetailShow(id: number): void {
    this.nextPageService.setTempNextSearch(true);
    this.router.navigate(['/collection-points/manager-detail', id]);
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('ptosrecogida-gestor', 'view');
    const resultDelete = this.authService.can('ptosrecogida-gestor', 'delete');
    const resultUpdateComplete = this.authService.can(
      'ptosrecogida-gestor',
      'update-complete'
    );
    const resultUpdateDown = this.authService.can(
      'ptosrecogida-gestor',
      'update-unregister'
    );

    const listPermission = list.map((objeto: any) => {
      const estadoDelete = objeto.estado && [1, 2].includes(objeto.estado.id);
      const estadocompletado = objeto.estado && objeto.estado.id === 2;
      const estadobaja = objeto.estado && objeto.estado.id === 3;

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete: resultDelete && estadoDelete,
        canAccessComplete: resultUpdateComplete && estadocompletado,
        canAccessDown: resultUpdateDown && estadobaja,
      };
    });

    return listPermission;
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  getComboComunidad() {
    this.comunidadControllerService
      .listarComunidades3({}, { page: 0, size: 500, sort: [] })
      .subscribe({
        next: (data: any) => {
          this.comunidadArray = data.datos!;
        },
        error: () => {},
      });
  }

  getGestorAsociados(element?: any) {
    return this.ptoRecogidaGestorControllerService.findAllPtoRecogidaGestor(
      {
        gestorPtoRecogida: { id: element.id },
      },
      { page: 0, size: 10 }
    );
  }

  getGestorCodigoLer(element?: any) {
    return this.gestorPtoRecogidaCodigoControllerService.findAllGestorPtoRecogidaCodigo(
      {
        gestorPtoRecogida: { id: element.id },
      },
      { page: 0, size: 10 }
    );
  }

  gestorCompleto(element?: any) {
    this.gestorPtoRecogidaControllerService
      .completarGestorPtoRecogida(element.id)
      .subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.searchData();
          this.snackBarSrv.showSnackBar(
            'Se ha completado correctamente el Gestor',
            'success'
          );
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          console.log(err);
        },
      });
  }

  verificarCompletado(element?: any) {
    this.spinnerSrv.loadingShow();
    zip(
      this.getGestorAsociados(element),
      this.getGestorCodigoLer(element)
    ).subscribe({
      next: ([gestorAsociadosResponse, codigoLerResponse]) => {
        this.codigoLerArray = codigoLerResponse?.datos || [];

        if (this.codigoLerArray.length > 0) {
          this.gestorCompleto(element);
        } else {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'No se puede completar el gestor porque debe disponer de, al menos, un código LER informado',
            'error'
          );
        }
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  openModalPreliminary() {
    const dialogRef = this.dialog.open(
      CollectionPointsManagersPreliminaryComponent,
      {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchData();
      }
    });
  }

  canAccessCreate(): boolean {
    return this.authService.can('ptosrecogida-gestor', 'create-alta');
  }
}
