import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EmpusaAuthenticationService, EmpusaGroupModel, SnackBarService} from "@empusa/empusa-core";
import { arrayBufferToBase64 } from '../../core/util';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ProfileService } from 'src/app/services/profile.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {


  currentEmail: string;
  groups: EmpusaGroupModel[];
  @ViewChild('userPicture') userPictureImg: any;
  name: string | undefined;
  public userForm: FormGroup;
  name_roles: string[] = [];
  matcher = new MyErrorStateMatcher();
  loading: boolean = false;

  constructor(
    private snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<ProfileComponent>,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    @Inject(MAT_DIALOG_DATA) public ticket_id: string) {
    
      this.currentEmail = this.authService.getCurrentUserMail();
      this.groups = this.authService.getGroupMembership();
      this.name = this.authService.user!.name  

      this.userForm = this.formBuilder.group({
        firstname: ['', [Validators.required, Validators.pattern('^[a-zA-Z\u00f1\u00d1-]+(?: [a-zA-Z\u00f1\u00d1-]+)*$')]],
        lastname: ['', [Validators.required, Validators.pattern('^[a-zA-Z\u00f1\u00d1-]+(?: [a-zA-Z\u00f1\u00d1-]+)*$')]],
      });

      if (this.authService.userRoleList()){
        this.name_roles = this.authService.userRoleList()!
      }
    
      this.f.firstname.setValue(this.authService.user?.givenName);
      this.f.lastname.setValue(this.authService.user?.familyName);
  }

  ngOnInit() {
  }


  // convenience getter for easy access to form fields
  get f(): any { return this.userForm.controls; }

  ngAfterViewInit() {
    if (this.authService.user && this.authService.user.photo){
      this.userPictureImg.nativeElement.src = 'data:image/jpeg;base64,' + arrayBufferToBase64(this.authService.user?.photo);
    }
  }
  
  onSubmit() {
    if (this.userForm.invalid) {
      return;
    }
    this.loading = true;
    let user: UpdateUser = {
      uid: this.authService.user!.user_id!,
      givenName: this.f.firstname.value.trim(),
      surname: this.f.lastname.value.trim(),
    }
    
    //TODO CALL UPDATE SERVICE

    // TODO END UPDATE  SERVICE

    this.authService.refreshToken();
    this.loading = false;

  }


  changePassword() {
    this.loading = true;
    this.profileService.changePassword(this.currentEmail).subscribe(data => {
        this.dialogRef.close();
        this.loading = false;
        this.snackBarService.openSnackBar(this.translate.instant(data.message),
            this.translate.instant("PROFILE.RESET"), {
            duration: 5000,
        });
    })
}
  ngOnDestroy() {
  }

}

export interface UpdateUser {
  uid: string
  givenName: string
  surname: string
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}