import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { KeycloakGroupModel } from '../../model/keycloak-group.model';
import { KeycloakUserDataModel, KeycloakUserModel } from '../../model/keycloak-user.model';
import { AuthManagerState } from '../../state/auth-manager.model';
import { selectUsersCount, selectUsersList } from '../../state/auth-manager.selectors';
import * as authManagementActions  from '../../state/auth-manager.actions';
import { MatDialog } from '@angular/material/dialog';
import { KamManageUserComponent } from './kam-manage-user/kam-manage-user.component';
import { KamQuestionDialogComponent } from '../common/kam-question-dialog/kam-question-dialog.component';
import { KamUserService } from '../../services/kam-user.services';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { KamNewUserComponent } from './kam-new-user/kam-new-user.component';
import { KamUserRolesComponent } from './kam-user-roles/kam-user-roles.component';
import { KamUserRolesData } from './kam-user-roles/kam-user-roles-data.interface';
import { KamUserGroupsComponent } from './kam-user-groups/kam-user-groups.component';
import { KamUserGroupsData } from './kam-user-groups/kam-user-groups-data.interface';
import { KamUserAttributesData } from './kam-user-attributes/kam-user-attributes-data.interface';
import { KamUserAttributesComponent } from './kam-user-attributes/kam-user-attributes.component';
import { KamUserFilter } from '../../services/kam-user.filter';

@Component({
  selector: 'keycloak-auth-manager-kam-users-tab',
  templateUrl: './kam-users-tab.component.html',
  styleUrls: ['./kam-users-tab.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class KamUsersTabComponent implements OnInit, OnDestroy {

  usersCountgroupsSubscription: Subscription | undefined;
  usersSubscription: Subscription | undefined;
  dataSource: MatTableDataSource<KeycloakUserModel> = new MatTableDataSource();
  columnsToDisplay = ['username','firstName','lastName','email','enabled','actions'];
  expandedElement: KeycloakUserDataModel | null = null;
  groups: KeycloakGroupModel[] = [];
  usersCount: number=0;
  hasAttributes: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  userFilter: KamUserFilter = {};

  @ViewChild('paginatorUsers') set paginatorUsers(mp: MatPaginator) {
    this.dataSource.paginator = mp
  }

  @ViewChild(MatSort, { static: true }) set groupTableSort(ts: MatSort){
    this.dataSource.sort=ts
    ts.sortChange.subscribe(change=>{
      this.expandedElement=null
    })    
  }


  constructor(private store: Store<AuthManagerState>,
    private kamUserService: KamUserService,
    @Inject('environment') public environment:any,
    public dialog: MatDialog,) { 
      this.hasAttributes = environment.kamUserAttributes?true:false;
    }


  ngOnInit(): void {
    this.getUsers({offset: 0, limit: 10});
  }

  loadListUser(event : PageEvent){
    this.paginator.pageSize = event.pageSize;
    this.paginator.pageIndex = event.pageIndex;
    let userFilter: KamUserFilter = {
      offset: event.pageIndex,
      limit: event.pageSize
    }

    this.getUsers(userFilter);
  }  

  getUsers(userFilter: KamUserFilter){
    this.store.dispatch(authManagementActions.RetrieveUsers({filter: userFilter}));

    this.usersSubscription = this.store.select(selectUsersList).subscribe(users =>{
      const clonedUsers: KeycloakUserModel[] = [];
      users.forEach(user => {
        clonedUsers.push({...user})
      })            
      this.dataSource.data = clonedUsers;      
    });

    
    this.usersCountgroupsSubscription = this.store.select(selectUsersCount).subscribe(usersCount =>{
      this.usersCount = usersCount;
    });
  }

  addNewUser(){
    const dialogRef = this.dialog.open(KamNewUserComponent, {
      width: '500px',  
      data:{user: undefined}    
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.store.dispatch(authManagementActions.RetrieveUsers({filter: this.userFilter}));
      }
    });
  }

  updateUser(user: KeycloakUserModel){
    const dialogRef = this.dialog.open(KamManageUserComponent, {
      width: '500px',  
      data:{user: user}    
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.store.dispatch(authManagementActions.RetrieveUsers({filter: this.userFilter}));
      }
    });
  }

  deleteUser(user: KeycloakUserModel){
    const dialogRef = this.dialog.open(KamQuestionDialogComponent, {
      width: '500px',  
      data: {
            title: "KAM-QUESTION-DIALOG.DELETE_USER_TITLE",
            question: "KAM-QUESTION-DIALOG.DELETE_USER_QUESTION",
            parameters:{username: user.username},
            icon: "delete_outline"
          } 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.kamUserService.deleteUser(user.id).subscribe({
          next: (v) => {
            this.store.dispatch(authManagementActions.RetrieveUsers({filter: this.userFilter}));
          },
          error: (e) => console.error(e),
          complete: () => {console.info('complete') }
        });
        
      }
    });
  }

  updateUserRoles(user: KeycloakUserModel){
    this.kamUserService.getUserRoles(user.id).subscribe({
      next: (roles) => {
        let data: KamUserRolesData={
          user: user,
          roles: []
        }
        roles.forEach(role =>{
          data.roles.push(role.name)
        })
        this.openUpdateUserRolesDialog(data)
      },
      error: (err) => {
          console.error(err)
      },
      complete: () => {        
      },
    })
  }

  private openUpdateUserRolesDialog(data: KamUserRolesData){
    const dialogRef = this.dialog.open(KamUserRolesComponent, {
      width: '500px',  
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.store.dispatch(authManagementActions.RetrieveUsers({filter: this.userFilter}));
      }
    });
  }

  updateUserGroups(user: KeycloakUserModel){
    const data:KamUserGroupsData ={
      user: user
    }
    const dialogRef = this.dialog.open(KamUserGroupsComponent, {
      width: '500px',  
      data: data
    });
    let userFilter: KamUserFilter =  {
      offset: 0,
      limit: 10
    };
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.store.dispatch(authManagementActions.RetrieveUsers({filter: this.userFilter}));
      }
    });
  }

  updateUserAttributes(user: KeycloakUserModel){
    const data:KamUserAttributesData ={
      user: user
    }
    const dialogRef = this.dialog.open(KamUserAttributesComponent, {
      width: '500px',  
      data: data
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.store.dispatch(authManagementActions.RetrieveUsers({filter: this.userFilter}));
      }
    });
  }

  getAttributeDescription(attributeKey: any){    
    const attribute = this.environment.kamUserAttributes[attributeKey.key]
    if (!attribute) return attributeKey.key
    return this.environment.kamUserAttributes[attributeKey.key].description?this.environment.kamUserAttributes[attributeKey.key].description:attributeKey.key
  }

  print(onj: any){
    console.log(JSON.stringify(onj));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    if (this.usersSubscription) this.usersSubscription.unsubscribe();
    if (this.usersCountgroupsSubscription) this.usersCountgroupsSubscription.unsubscribe();
  }
}


