<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">Nuevo documento</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <div class="title-name mb-3" style="font-size: 1.3rem; font-weight: 700;"> {{puntoRecogidaInfo?.razonSocial}}</div>
    <!-- Formulario dirección-->
    <form class="form-modal py-3" [formGroup]="formGroup">
      <div class="row-fields">
        <div class="field field-50">
          <label for="tipoDoc">Tipo de documento <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="tipoDocumento" id="tipoDoc" placeholder="Tipo de documento">
              <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
                {{tipo.descripcion}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('tipoDocumento', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="flagTooltip" class="tool-help mb-4" matTooltip="Poderes, Autorizaciones,... " data-placement="top"
          title="">
          <span>?</span>
        </div>
      </div>
      <div class="row-fields">
        <div class="field field-100">
          <label for="descripcion">Descripción</label>
          <mat-form-field appearance="outline">
            <input formControlName="descripcion" id="descripcion" placeholder="Descripción" matInput type="text">
          </mat-form-field>
        </div>
      </div>
      <div class="attach">
        <div>
          <button mat-button aria-labe="Adjuntar archivo" class="adjuntar-btn" type="button" (click)="file.click()"
            [disabled]="adjuntosBlob!.length>0">
            Adjuntar archivo
          </button>
          <label class="sr-only" for="adjuntar">Adjuntar archivo </label>
          <input #file type="file" (change)="file && file.files && attachAdjunto(file.files[0])" class="hide"
            id="adjuntar" name="adjuntar">
        </div>
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let archivo of adjuntosBlob"
            class="centrar archivo">
            <div><span class="sprite-icon ico-adjuntar"></span><span class="nombre-archivo">{{archivo?.name}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- FIN Formulario dirección -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()" [disabled]="botonEstado"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
