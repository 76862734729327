
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { map, mergeMap, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { KeycloakClientModel } from '../model/keycloak-client.model';
import { KeycloakGroupModel } from '../model/keycloak-group.model';
import { KeycloakRoleModel } from '../model/keycloak-role.model';
import { KeycloakUserModel } from '../model/keycloak-user.model';
import { KeycloakAuthManagerService } from '../public-api';
import { KeycloakGroupService } from '../services/kam-group.services';
import { KamResourceServerServices } from '../services/kam-resource-server.services';
import { KamUserService } from '../services/kam-user.services';
import * as authManagementActions  from './auth-manager.actions';
import { AuthManagerState } from './auth-manager.model';

@Injectable()
export class AuthManagerEffects {
 
  retrieveAllData$ = createEffect(() => this.actions$.pipe(
    ofType(authManagementActions.RetrieveResourceServes),    
    switchMap((action) => {
      console.debug("Effect: ", action.type);
      const getClients = this.kamResourceServerServices.getClients();
      return forkJoin([getClients,]).pipe(
        map(resultados => {
          let clients: KeycloakClientModel[] = []  
          //filter clients                  
          resultados[0].map(oneClient => {
            let found = action.clients.find(element => element === oneClient.clientId)
            if (found){
              clients.push(oneClient)
            };            
          })
          console.debug("Effect End: ", action.type);       
          return authManagementActions.StoreResourceServes({clients})
        }),catchError(error =>{
          console.log("********Error",error)
          return EMPTY;
        })

      );      
    })
  ));

  storeAllDataStep1$ = createEffect(() => this.actions$.pipe(
    ofType(authManagementActions.StoreResourceServes),
    map((action) => {
      console.debug("Effect: ", action.type);
      console.debug("Effect End: ", action.type);       
      return authManagementActions.AllData_RetrieveResourceServerSettings_Step2({clients: action.clients });
    })
  ));


  retrieveResourceServerSettings$ = createEffect(() => this.actions$.pipe(
    ofType(authManagementActions.AllData_RetrieveResourceServerSettings_Step2),
    switchMap((action) => {
        console.debug("Effect: ", action.type);
        let serverSettingsRequests: Observable<any>[] =[];
        action.clients.forEach(client =>{
            serverSettingsRequests.push(this.kamResourceServerServices.getResourceServersetting(client.id))
        })
        return forkJoin(serverSettingsRequests).pipe(
          map(resultados => {
            let clients: KeycloakClientModel[]=[];
            action.clients.forEach((oneClient, index) => {
              let clone = {...oneClient}              
              clone.resourceServerSettings = resultados[index];
              clients.push(clone)
            });
            console.debug("Effect End: ", action.type);       
            return authManagementActions.AllData_StoreResourceServerSettings_Step2({clients})
          }),catchError(error =>{
            console.log("********Error",error)
            return EMPTY;
          }));      
    })
  ));


/***  ROLES ********* */
  retrieveRoles$ = createEffect(() => this.actions$.pipe(
    ofType(authManagementActions.RetrieveRoles),
    switchMap((action) => {
        console.debug("Effect: ", action.type);
        const getRoles = this.keycloakAuthManagerService.getRoles();
        return getRoles.pipe(
          map(resultados => {
            //filter roles
            let roles:KeycloakRoleModel[] = [];
            resultados.forEach(oneRole =>{
              if (oneRole.description){
                if (!oneRole.description.startsWith('$')){
                  roles.push(oneRole);
                }
              }else{
                roles.push(oneRole);
              }
            })
            console.debug("Effect End: ", action.type);       
            return authManagementActions.StoreRoles({roles})
          }),catchError(error =>{
            console.log("********Error",error)
            return EMPTY;
          }));      
    })
  ));

/** USERS  */
  retrieveUsers$ = createEffect(() => this.actions$.pipe(
    ofType(authManagementActions.RetrieveUsers),
    switchMap((action) => {
        const getUsers = this.kamUserService.getUsers(action.filter);
        const getUsersCount = this.kamUserService.getUsersCount();

        return forkJoin([getUsers,getUsersCount]).pipe(
          map(response => {
            return authManagementActions.RetrieveUsersRolesGroups({users:response[0], usersCount:response[1]})
          }),catchError(error =>{
            console.log("********Error",error)
            return EMPTY;
          }));      
    })
  ));

  retrieveUsersRolesGroups$ = createEffect(() => this.actions$.pipe(
    ofType(authManagementActions.RetrieveUsersRolesGroups),    
    switchMap((action) => {
      let userAllRequests: Observable<any>[] =[];
      
      action.users.forEach(user =>{
        userAllRequests.push(this.kamUserService.getUserGroups(user.id));
        userAllRequests.push(this.kamUserService.getUserRoles(user.id));
      })
      return forkJoin(userAllRequests).pipe(
        map(resultados => {
          let users: KeycloakUserModel[] = [] 
          action.users.forEach((user, index) => {
            let clone = {...user}              
            clone.data = {groups: resultados[index*2],roles:resultados[(index*2)+1]}
            users.push(clone)
          });
          console.debug("Effect End: ", action.type);       
          return authManagementActions.StoreUsers({users, usersCount: action.usersCount})
        }),catchError(error =>{
          console.log("********Error",error)
          return EMPTY;
        })

      );      
    })
  ));

/** groups */
  retrieveGroups$ = createEffect(() => this.actions$.pipe(
    ofType(authManagementActions.RetrieveGroups),
    switchMap((action) => {
        console.debug("Effect: ", action.type);
        const getGroups = this.keycloakGroupService.getGroups();
        return getGroups.pipe(
          map(resultados => {
            console.debug("Effect End: ", action.type);       
            return authManagementActions.RetrieveGroupMembers({groups:resultados})
          }),catchError(error =>{
            console.log("********Error",error)
            return EMPTY;
          }));      
    })
  ));  
 

  retrieveGroupMembers$ = createEffect(() => this.actions$.pipe(
    ofType(authManagementActions.RetrieveGroupMembers),
    switchMap((action) => {
        console.debug("Effect: ", action.type);
        let groupMembersRequests: Observable<any>[] =[];
        action.groups.forEach(group =>{
            groupMembersRequests.push(this.keycloakGroupService.getGroupsMembers(group.id))
        })
        let groupDetailRequests: Observable<any>[] =[];
        action.groups.forEach(group =>{
          groupDetailRequests.push(this.keycloakGroupService.getGroupDetail(group.id))
        })
        let allRequests = groupDetailRequests.concat(groupMembersRequests);
        return forkJoin(allRequests).pipe(
          map(resultados => {
            let groups: KeycloakGroupModel[]=[];
            for (let i=0; i < action.groups.length;i++){
              let aGroup = {...resultados[i]}
              aGroup.members = resultados[action.groups.length + i]
              groups.push(aGroup)
            }
            console.debug("Effect End: ", action.type);       
            return authManagementActions.StoreGroupMembers({groups})
          }),catchError(error =>{
            console.log("********Error",error)
            return EMPTY;
          }));      
    })
  ));  
/*
  retrieveUsers$ = createEffect(() => this.actions$.pipe(
    ofType(authManagementActions.retrieveUsers),
    mergeMap(() => this.keycloakAuthManagerService.getUsers()
      .pipe(
        map(users => authManagementActions.storeUsers({users})),
        catchError(() => EMPTY)
      ))
    )
  );
 */
  constructor(
    private actions$: Actions,
    private keycloakAuthManagerService: KeycloakAuthManagerService,
    private kamResourceServerServices: KamResourceServerServices,
    private kamUserService: KamUserService,
    private keycloakGroupService: KeycloakGroupService,
    private store: Store<AuthManagerState>
  ) {
    console.debug("AuthManagerEffects actions", this.actions$)
  }
}