<div>
    <h1 mat-dialog-title>
        <mat-icon class="icon" style="vertical-align: middle;">add_circle</mat-icon>
        {{data.title | translate:data.parameters}}
    </h1>

    <div mat-dialog-content>
        <mat-stepper [linear]="isLinear" orientation="vertical">
            <mat-step label="{{'KAM-MANAGE-GROUPS.STEP1.TITLE' | translate}}" 
                [completed]="step1CompetedSubscription">
                <keycloak-auth-manager-kam-mg-step1></keycloak-auth-manager-kam-mg-step1>
            </mat-step>
            <mat-step label="{{'KAM-MANAGE-GROUPS.STEP2.TITLE' | translate}}" 
                [completed]="step2CompetedSubscription" *ngIf="groupAttributes">
                <keycloak-auth-manager-kam-mg-step2></keycloak-auth-manager-kam-mg-step2>
            </mat-step>
            <mat-step label="{{'KAM-MANAGE-GROUPS.STEP3.TITLE' | translate}}" >
                <keycloak-auth-manager-kam-mg-step3></keycloak-auth-manager-kam-mg-step3>
            </mat-step>
            <mat-step label="{{'KAM-MANAGE-GROUPS.STEP4.TITLE' | translate}}" >
                <keycloak-auth-manager-kam-mg-step4></keycloak-auth-manager-kam-mg-step4>
                <div class="actions">
                    <button mat-button matStepperPrevious>{{'KAM-MANAGE-GROUPS.STEP4.BACK' | translate}}</button>
                    <button mat-button matStepperNext (click)="manageRoleEnd()">{{'KAM-MANAGE-GROUPS.STEP4.NEXT' | translate}}</button>
                </div>
            </mat-step>
        </mat-stepper>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button [mat-dialog-close]="">{{'KAM-GENERAL-DIALOG.CANCEL'| translate}}</button>
</div>