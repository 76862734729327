import { NgModule } from '@angular/core';
import { HelloWorldComponent } from '../pages/hello-world.component';
import { locale_en as english } from '../translations/translation.en';
import { locale_es as spanish } from '../translations/translation.es';
import { locale_en_GB as english_GB } from '../translations/translation.en-GB';
import { locale_es_ES as spanish_ES } from '../translations/translation.es-ES';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EmpusaAppHeaderClassicComponent, EmpusaTranslationLoaderService } from '@empusa/empusa-core';
import { TranslateModule } from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';


export const explorerRoutes = [
  {
    path: '', component: HelloWorldComponent, children: []
  },
];

@NgModule({
  declarations: [
    HelloWorldComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    MatIconModule,
    EmpusaAppHeaderClassicComponent,
    RouterModule.forChild(explorerRoutes),
  ],
  exports: [
    TranslateModule
  ]
})
export class HelloWorldHomeModule { 

  constructor(private translationLoader: EmpusaTranslationLoaderService) {
   let compilationVersion = "0.0.1-R->04/10/23-12:14:49"
     console.log("Hello world version: " + compilationVersion)
     //loading empusa core translations
     this.translationLoader.loadTranslations(english);
     this.translationLoader.loadTranslations(spanish);
     this.translationLoader.loadTranslations(english_GB);
     this.translationLoader.loadTranslations(spanish_ES);
   }
}
