/**
 * Comunicaciones API
 * Comunicaciones API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentoDTO } from './documentoDTO';
import { ComunicacionEstadoDTO } from './comunicacionEstadoDTO';
import { KeycloakUserDTO } from './keycloakUserDTO';


export interface ComunicacionDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    tipo?: ComunicacionDTO.TipoEnum;
    prioridad?: ComunicacionDTO.PrioridadEnum;
    categoria?: ComunicacionDTO.CategoriaEnum;
    fechaIncidencia?: string;
    horaIncidencia?: string;
    impacto?: string;
    asunto?: string;
    descripcion?: string;
    user?: KeycloakUserDTO;
    documentoIncidencia?: DocumentoDTO;
    contactoSoporte?: boolean;
    comentarios?: string;
    estado?: ComunicacionEstadoDTO;
}
export namespace ComunicacionDTO {
    export type TipoEnum = 'INCIDENCIA' | 'SUGERENCIA' | 'RECLAMACION';
    export const TipoEnum = {
        Incidencia: 'INCIDENCIA' as TipoEnum,
        Sugerencia: 'SUGERENCIA' as TipoEnum,
        Reclamacion: 'RECLAMACION' as TipoEnum
    };
    export type PrioridadEnum = 'BAJA' | 'MEDIA' | 'ALTA';
    export const PrioridadEnum = {
        Baja: 'BAJA' as PrioridadEnum,
        Media: 'MEDIA' as PrioridadEnum,
        Alta: 'ALTA' as PrioridadEnum
    };
    export type CategoriaEnum = 'TECNICA' | 'FUNCIONAL' | 'FUNCIONAL_Y_TECNICA';
    export const CategoriaEnum = {
        Tecnica: 'TECNICA' as CategoriaEnum,
        Funcional: 'FUNCIONAL' as CategoriaEnum,
        FuncionalYTecnica: 'FUNCIONAL_Y_TECNICA' as CategoriaEnum
    };
}


