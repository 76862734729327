import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from '../services/spinner.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'lib-attached',
  templateUrl: 'attached.component.html',
  styleUrls: ['./attached.component.scss'],
})
export class AttachedComponent implements OnInit {
  @ViewChild('containerMainRouter') containerMainRouter!: ElementRef;
  constructor(public spinnerSrv: SpinnerService, private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.containerMainRouter) {
        this.containerMainRouter.nativeElement.scrollTop = 0;
      }
    });
  }
}
