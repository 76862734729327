<table mat-table [dataSource]="dataSource">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null; update()"
                        [checked]="selection.isSelected(element)"
                        color="primary">
            </mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{'KAM-ROLE-SELECTION.TABLE_ROLE' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.name}} </td>        
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>{{'KAM-ROLE-SELECTION.TABLE_DESCRIPTION' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10]" [pageSize]="5" 
    #paginatorRoles="matPaginator" 
    showFirstLastButtons 
    aria-label="Seleccionar página"></mat-paginator>

          