import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@empusa/empusa-core';
import { Observable } from 'rxjs';
import { KamResourceServerServices } from '../../../services/kam-resource-server.services';

@Component({
  selector: 'keycloak-auth-manager-kam-manage-role',
  templateUrl: './kam-manage-role.component.html',
  styleUrls: ['./kam-manage-role.component.scss']
})
export class KamManageRoleComponent implements OnInit {
  formGroup: FormGroup | undefined;
  resourceNameCtrl = new FormControl('', [Validators.required]);
  displayNameCtrl = new FormControl('');
  constructor(
    public dialogRef: MatDialogRef<KamManageRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KamManageRoleData,
    private kamResourceServerServices: KamResourceServerServices,
    private snackBarService: SnackBarService,
    ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm() {
 
    this.formGroup = new FormGroup({
      resourceName: this.resourceNameCtrl,
      resourceDisplayName: this.displayNameCtrl,
    });      
  }

  resourceCancel(){
    this.dialogRef.close(undefined);
  }

  resourceAcept() {
    console.log("this.resourceNameCtrl.value: " + this.resourceNameCtrl.value);
    this.kamResourceServerServices.postRole(this.resourceNameCtrl.value!, this.displayNameCtrl.value!).subscribe({
      next: (value: any) => {
        this.dialogRef.close(undefined);
        this.snackBarService.openSnackBar("Operación realizada con exito", "X");
      },
      error: (error: any) => {
      },
    });
 
    console.log("this.displayNameCtrl.value: " + this.displayNameCtrl.value);
    let request = this.kamResourceServerServices.postRole(this.resourceNameCtrl.value!, this.displayNameCtrl.value!);

  }

}
export interface KamManageRoleData {
  icon: string,
  title: string,
}

