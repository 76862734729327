import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appNumericDecimalComa]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumericDecimalComaDirective,
      multi: true,
    },
  ],
})
export class NumericDecimalComaDirective implements ControlValueAccessor {
  private regex: RegExp = new RegExp(/^\d*(\,\d{0,5})?$/); // Acepta hasta 5 decimales

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (!this.regex.test(value)) {
      value = this.correctValue(value);
    }
    this.writeValue(value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedData = event.clipboardData?.getData('text');
    if (pastedData) {
      let pastedValue = pastedData.replace('.', ',');

      if (!this.regex.test(pastedValue)) {
        event.preventDefault();
      } else {
        const decimalIndex = pastedValue.indexOf(',');
        if (decimalIndex !== -1 && pastedValue.length - decimalIndex - 1 > 5) {
          pastedValue = pastedValue.slice(0, decimalIndex + 6);
        }
        this.writeValue(pastedValue);
      }
    }
  }

  writeValue(value: any): void {
    this.renderer.setProperty(
      this.el.nativeElement,
      'value',
      this.formatValue(value)
    );
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private correctValue(value: string): string {
    const decimalIndex = value.indexOf(',');
    if (decimalIndex !== -1) {
      value = value.slice(0, decimalIndex + 6);
    }
    return value;
  }

  private formatValue(value: any): string {
    if (value === null || value === undefined) {
      return '';
    }
    return value.toString().replace('.', ',');
  }
}
