import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';
import { DownloadService } from '../../services/download.service';
import { SnackbarService } from '../../services/snackbar.service';
import Swal from 'sweetalert2';
import {
  GestoresConAcuerdoControllerService,
  GestoresConAcuerdoFilterDTO,
  GestoresResiduosDTO,
  PageDTOGestoresResiduosDTO,
  ProvinciaControllerService,
} from 'api-rest';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { HoldersService } from '../../services/holders.service';

@Component({
  selector: 'app-managers-agreements-search',
  templateUrl: './managers-agreements-search.component.html',
  styleUrls: ['./managers-agreements-search.component.scss']
})
export class ManagersAgreementsSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  updateList: boolean = false;
  cnaeEndpoint = this.environment.urlBackCore + 'api/v1/core/cnae';

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  provinciaArray: any[] = [];
  estadoArray: any[] = [];

  headArray: any = [
    {
      Head: 'NIMA',
      FieldName: 'nima',
    },
    {
      Head: 'Razón Social',
      FieldName: 'razonSocial',
    },
    {
      Head: 'Denominación del centro',
      FieldName: 'centro',
    },
    {
      Head: 'Provincia (dirección física)',
      FieldName: 'provinciaFisica',
      Attribute: 'nombre',
    },
    {
      Head: 'Dirección física',
      renderValue: (item: any) => item?.domicilios?.[0]?.fullAddress,
    },
    {
      Head: 'Teléfono',
      renderValue: (item: any) => item?.contactos?.[0]?.numero_telefono,
    },
    {
      Head: 'Email',
      renderValue: (item: any) => item?.contactos?.[0]?.email,
    },
    {
      Head: 'Autorización de peligroso',
      renderValue: (item: any) => item?.autorizacionPeligrosos,
    },
  ];
  gridArray: Array<GestoresResiduosDTO> = [];

  estadosArray: any = [];

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    private gestoresConAcuerdoControllerService: GestoresConAcuerdoControllerService,
    private provinciaControllerService: ProvinciaControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private holdersService: HoldersService
  ) {
    this.searchForm = this.formBuilder.group({
      provincia_fisica: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      nima: [{ value: null, disabled: false }],
      auto_peligrosidad: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.getComboProvincia();
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter } = this.getBackendFilter();
    this.gestoresConAcuerdoControllerService
      .exportCSV1(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_acuerdos_gestor.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'cnae': {
        return this.searchForm.get('cnae') as FormControl;
        break;
      }
      default: {
        return this.searchForm.get('') as FormControl;
        break;
      }
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    const backendFilter: GestoresConAcuerdoFilterDTO = {
      nima: this.searchForm.get('nima')?.value,
      razonSocial: this.searchForm.get('razon_social')?.value,
      provincia: this.searchForm.get('provincia_fisica')?.value,
      autorizacionPeligrosos: this.searchForm.get('auto_peligrosidad')?.value,
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.gestoresConAcuerdoControllerService
      .findAllGestoresConAcuerdo(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOGestoresResiduosDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos);
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('poseedores', 'view');
    const resultDelete = this.authService.can('poseedores', 'delete');
    const resultUpdateCont = this.authService.can(
      'poseedores',
      'update-contract'
    );
    const resultUpdateUnregist = this.authService.can(
      'poseedores',
      'update-unregister'
    );

    const listPermission = list.map((objeto: any) => {
      const estadoDelete =
        objeto.estado && [1, 2, 3, 6].includes(objeto.estado.id);
      const estadoUpdateCont =
        objeto.estado && (objeto.estado.id === 2 || objeto.estado.id === 6);
      const estadoUpdateUnregist = objeto.estado && objeto.estado.id === 4;

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete: resultDelete && estadoDelete,
        canAccessUpdateCont: resultUpdateCont && estadoUpdateCont,
        canAccessUpdateUnregist: resultUpdateUnregist && estadoUpdateUnregist,
      };
    });

    return listPermission;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  getComboProvincia() {
    this.provinciaControllerService
      .listarComunidades1({}, { page: 0, size: 500, sort: [] })
      .subscribe({
        next: (data: any) => {
          this.provinciaArray = data.datos!;
        },
        error: () => {},
      });
  }
}
