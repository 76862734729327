<!-- Modal Alta Preliminar-->
<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">Alta preliminar</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <!-- Formulario Alta preliminar -->
    <form class="form-modal py-3" [formGroup]="dataForm">
      <div class="title-group">Datos del Punto de recogida</div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="razonSocial">Razón Social <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="razonSocial" id="razonSocial" placeholder="Razón Social"
                value="">
              <mat-error *ngIf="controlHasError('razonSocial', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="denominacion">Denominación <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="denominacion" id="denominacion" placeholder="Denominación"
                value="">
              <mat-error *ngIf="controlHasError('denominacion', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="nimaPtoRecogida">NIMA PdR </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="nimaPtoRecogida" id="nimaPtoRecogida" placeholder="NIMA PdR"
                value="">
              <mat-error *ngIf="controlHasError('nimaPtoRecogida', 'pattern')">Formato incorrecto</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="nimaOperador">NIMA OT </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="nimaOperador" id="nimaOperador" placeholder="NIMA OT"
                value="">
              <mat-error *ngIf="controlHasError('nimaOperador', 'pattern')">Formato incorrecto</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="documentType">Tipo Documento <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="documentType" (selectionChange)="tipoDocumentoSel($event.value)"
                id="documentType" placeholder="Tipo documento">
                <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
                  {{tipo.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('documentType', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="documentNumber">Documento <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="documentNumber" id="documentNumber" placeholder="Documento"
                value="">
              <mat-error *ngIf="controlHasError('documentNumber', 'pattern')">Formato incorrecto</mat-error>
              <mat-error *ngIf="controlHasError('documentNumber', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="title-group pt-3">Contacto gestión</div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="nombre">Nombre <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="nombre" id="nombre" placeholder="Nombre" value="">
              <mat-error *ngIf="controlHasError('nombre', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="apellido1">Apellido 1 <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="apellido1" id="apellido1" placeholder="Apellido 1" value="">
              <mat-error *ngIf="controlHasError('apellido1', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="apellido2">Apellido 2</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="apellido2" id="apellido2" placeholder="Apellido 2" value="">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="documentTypeContact">Tipo Documento <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="documentTypeContact"
                (selectionChange)="tipoDocumentoSelContact($event.value)" id="documentTypeContact"
                placeholder="Tipo documento">
                <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
                  {{tipo.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('documentTypeContact', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="documentNumberContact">Documento <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="documentNumberContact" id="documentNumberContact"
                placeholder="Documento" value="">
              <mat-error *ngIf="controlHasError('documentNumberContact', 'pattern')">Formato incorrecto</mat-error>
              <mat-error *ngIf="controlHasError('documentNumberContact', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="email">Email <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="email" id="email" placeholder="Email" value="">
              <mat-error *ngIf="controlHasError('email', 'pattern')">Formato incorrecto</mat-error>
              <mat-error *ngIf="controlHasError('email', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="tlfType">Tipo teléfono <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <mat-select formControlName="tlfType" id="tlfType" placeholder="Tipo teléfono">
                <mat-option value="MOVIL">Móvil</mat-option>
                <mat-option value="FIJO">Fijo</mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('tlfType', 'required')">Campo obligatorio</mat-error>

            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="countryCode">Prefijo País <span class="oblig">*</span></label>
          <div class="custom-mat-field-select" style="width: 250px">
            <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="paisesEndpoint"
              (filteredList)="filterList($event)" [updateList]="updateList"
              [formControlNameSelect]="getFormControl('countryCode')" [placeHolder]="'Prefijo pais'"
              [objQueryName]="'prefijo'" [customClass]="rightHolder('countryCode')" descripcion="prefijoNombre"
              [disabled]="flagDisabledForm" [defaultValue]=69>
            </lib-ge-select-pagination>
            <div class="ge-select-error">
              <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('countryCode')">Campo obligatorio
              </mat-error>
            </div>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="tlfNumber">Nº teléfono <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input type="text" matInput formControlName="tlfNumber" id="tlfNumber" placeholder="Nº teléfono" value="">
              <mat-error *ngIf="controlHasError('tlfNumber', 'required')">Campo obligatorio</mat-error>
              <mat-error *ngIf="!controlHasError('tlfNumber', 'required') && controlHasError('tlfNumber',
                  'invalidPhoneFormat')">
                Formato incorrecto.</mat-error>
              <mat-error *ngIf="!controlHasError('tlfNumber', 'required') && !controlHasError('tlfNumber',
                  'invalidPhoneFormat')
                  && controlHasError('tlfNumber', 'invalidTelefonoFijo')">
                Formato incorrecto para tipo Fijo con prefijo 34.
              </mat-error>
              <mat-error *ngIf="!controlHasError('tlfNumber', 'required') && !controlHasError('tlfNumber',
                  'invalidPhoneFormat')
                  && !controlHasError('tlfNumber', 'invalidTelefonoFijo') && controlHasError('tlfNumber',
                  'invalidTelefonoMovil')">
                Formato incorrecto para tipo Movil con prefijo 34.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
    <!-- FIN Formulario de Alta preliminar -->
  </div>

  <div class="fixed-footer">
    <div class="modal-footer actions">
      <div class="note">* Campos Obligatorios</div>
      <div class="button-group">
        <button type="button" (click)="closeDialog()" class="btn btn-outline-primary" data-dismiss="modal"
          aria-label="Close">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close"
          (click)="saveData()">Guardar</button>
      </div>
    </div>
  </div>

</div>
