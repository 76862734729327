import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EmpusaAuthenticationService, EmpusaGroupModel, EmpusaUser} from '@empusa/empusa-core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import jwt_decode from "jwt-decode";
import { catchError, firstValueFrom, forkJoin, from, map, Observable, of, throwError } from 'rxjs';
import { initializeKeycloak } from './keycloak-init.factory';

interface KeycloakGroupsIds {
  id: string
  name: string
  path: string
}

export class EnvaloraUser extends EmpusaUser {
   roleEntities: any
}


@Injectable({
  providedIn: 'root'
})
export class EmpusaKeycloakService extends EmpusaAuthenticationService {

  public uma2TokenEndPoint: string | undefined;
  userIamge: string | undefined;


  constructor(private httpClient: HttpClient, 
            @Inject('environment') private environment:any, 
            protected keycloak: KeycloakService) {   
    super(httpClient);
    
    this.getUma2ConfigEndPoint().subscribe((resp:any)=>{
      this.uma2TokenEndPoint = resp.token_endpoint
    })    
    
    this.getUserDataFromToken(this.keycloak)
    

    this.keycloak.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          this.keycloak.updateToken();
          console.debug("Token updated");
          this.getUserDataFromToken(this.keycloak)
        }
      }
    });
  }


  private getUserDataFromToken(keycloak: KeycloakService) {
    //second manage token
    this.roles = keycloak.getUserRoles();
    this.keycloak.getToken().then(token => {
      const decoded: any = jwt_decode(token);
      this.user = this.tokenToUser(decoded);                  
      // if (!this.user.photo){
      //   const id = this.user.user_id
      //   let pictureURL = `${this.environment.urlbase}usermanagement/services/users/photo/${id}`;
      //   this.loadUserPhoto(pictureURL)
      // }
 
      this.setAuthInitialized()   

    });
  }

  public refreshToken(): void {
    this.keycloak.clearToken()
  }
  
  public getGroupsDetails(groups: EmpusaGroupModel[]){
    let groupDetailsRequests: Observable<EmpusaGroupModel[]>[] =[];
    groups.forEach(group=>{
      groupDetailsRequests.push(this.getGroupDetail(group.id!))
    })
    return forkJoin (groupDetailsRequests).pipe(
      map((groups)=>{
        let requestMerged:EmpusaGroupModel[]=[];
        groups.forEach(requestResponse=>{
          requestMerged = requestMerged.concat(requestResponse)
        })
        return requestMerged
      })
    )
  }

  private getGroupDetail(groupId: string): Observable<EmpusaGroupModel[]>{
    return this.httpClient.get<EmpusaGroupModel[]>(this.environment.keycloak.url+ '/admin/realms/' + this.environment.keycloak.realm + '/groups/'+groupId)
      .pipe(map((response)=>{
        return response
      }))
  }

  public getGroupByName(groupName: string): Observable<EmpusaGroupModel[]> {
    return this.httpClient.get<EmpusaGroupModel[]>(this.environment.keycloak.url+ '/admin/realms/' + this.environment.keycloak.realm + '/groups?search='+groupName)
    .pipe(map((response)=>{
      return response
    }))
  }

  public getGroups(){
    return this.httpClient.get<EmpusaGroupModel[]>(this.environment.keycloak.url+ '/admin/realms/' + this.environment.keycloak.realm + '/groups')
    .pipe(map((response)=>{
      return response
    }))
  }
  
  private getUma2ConfigEndPoint(){
    return this.httpClient.get(this.environment.keycloak.url + '/realms/' + this.environment.keycloak.realm + '/.well-known/uma2-configuration')
  }
  
  loadPermissions(clienresourceServers: Array<string>): Observable<any>{
      return from(new Promise(async (resolve, reject) => {
        try {
          if (this.uma2TokenEndPoint == undefined){
            const uma2Configuration:any = await firstValueFrom(this.getUma2ConfigEndPoint());
            this.uma2TokenEndPoint = uma2Configuration.token_endpoint;
          }
          if (this.uma2TokenEndPoint != undefined){
            let requests: any = []
            clienresourceServers.forEach(clientId =>{
                if (this.uma2TokenEndPoint != undefined){
                  let x = this.getEntitlement(this.uma2TokenEndPoint,clientId)
                  requests.push(x);
                }
            })
            let permissionList: any = await firstValueFrom(forkJoin(requests));
            let permissionListMerge: Map<string, Array<string>> = new Map();
            permissionList.forEach((element:Map<string, Array<string>> | undefined) => {
                if (element){
                  element.forEach((value: Array<string>, key: string) => {                                        
                    if (!(permissionListMerge.has(key)) && value){
                      permissionListMerge.set(key,value);
                    }else{
                      console.warn("skip permission for",key, value);
                    }

                  });
                }
            });
            this.setPermissions(permissionListMerge); 
            console.debug("permission list", permissionListMerge)
            resolve(permissionListMerge);
          }
          reject("error. No uma2 token end point");
        } catch (error) {
          console.log("Rejet error",error)
          reject(error);
        }
        })
      )
  }

  getGroupMembership(): EmpusaGroupModel []{
    if (this.user && this.user.groupMembership)
      return this.user.groupMembership
    return[]
  }

  getCurrentUserName(): string {
    if (this.user && this.user.userName)
      return this.user.userName;
    return ""
  }

  getCurrentUserMail(): string {
    if (this.user && this.user.userMail)
      return this.user.userMail;
    return ""
  }

  _logout() {
    this.keycloak.logout();
  }  

  private tokenToUser(decodedToken:any): EnvaloraUser {
     let user = new EnvaloraUser()
     user.userMail = decodedToken.email
     user.roleEntities = decodedToken.role_entities

     if (decodedToken.user_groups){
      user.groupMembership = []
      decodedToken.user_groups.forEach((group:KeycloakGroupsIds)=>{  

        const aMembership: EmpusaGroupModel ={
          id: group.id,
          name: group.name,
          path: group.path,
          attributes: undefined,
          subGroups: []
        }
        user.groupMembership?.push(aMembership)
      })      
     }else{
      console.warn(" user_groups not found in token")      
     }
     if (decodedToken.user_id){
      /** check readme */
        user.user_id = decodedToken.user_id     
     }else{
      /* Add mapper to client:
          Mapper Type: User Property
          Property: id
          Token Claim Name: user_id
          Claim JSON Type: String
          */
      console.warn("user_id not found in token")      
     }
     user.userName = decodedToken.preferred_username
     user.name = decodedToken.name
     user.givenName = decodedToken.given_name
     user.familyName = decodedToken.family_name   
     return user
  }

  private searchGroup (path: string, groups: EmpusaGroupModel[]){
      if (!groups) return undefined
      var splitted = path.split("/")
      splitted.shift()
      let searchInGroups = groups;
      let searchPath: string = "";
      let groupFound: EmpusaGroupModel|undefined;
      splitted.forEach(path =>{
        searchPath = searchPath + "/" + path
        groupFound = searchInGroups.find(a => a.path == searchPath)
        if (groupFound){          
          searchInGroups = groupFound.subGroups
        }
      }) 

      let returnObj: EmpusaGroupModel|undefined;
      if (groupFound){
        returnObj = {...groupFound}
        returnObj.subGroups=[]
      }
      return returnObj
  }


  private getEntitlement(server__permission_endpoint: string, resourceServerId: string):Observable<Map<string, string[]> | undefined> {
            
    let __params = new HttpParams({});
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    
    __headers = __headers.set("Content-type", "application/x-www-form-urlencoded");
    

    __params = __params.set('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket');
    //__params = __params.set('client_id', this.keycloakConfig.clientId);
    __params = __params.set('audience', resourceServerId);
    

   
    return this.httpClient.post<any>(server__permission_endpoint, __params.toString(), {
        headers: __headers,
        responseType: 'json'
    })
    .pipe(
        //catchError(this.handleError),
        map((value) => {          
          const decoded:any = jwt_decode(value.access_token);
          const authorizations:[] = decoded.authorization.permissions;
          let permissions: Map<string, Array<string>>  = new Map();
          authorizations.forEach((authorization: any) =>{
            const resource = authorization.rsname;
            permissions.set(resource,authorization.scopes)            
          });  
          return permissions;
        }),
        catchError((error) => {     
          console.error(server__permission_endpoint,  error)     
          return of(undefined);
        })
    );
         
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(error)
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
  
}
