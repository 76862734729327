import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ProvinciaControllerService,
  TarifaGestoresControllerService,
  TarifaGestoresFilterDTO,
  PageDTOTarifaGestoresDTO
} from 'api-rest';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';
import { ManagersAgreementsRatesDialogComponent } from '../managers-agreements-rates-dialog/managers-agreements-rates-dialog.component';

@Component({
  selector: 'lib-managers-agreements-rates-search',
  templateUrl: './managers-agreements-rates-search.component.html',
  styleUrls: ['./managers-agreements-rates-search.component.scss'],
})
export class ManagerAgreementsRatesSearchComponent implements OnInit {
  searchForm: FormGroup;
  codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?acuerdoGestores=true';
  gestoresFilter = '?gestoresResiduosEstado.id=2&acuerdoEnvalora=true';
  listaGestores = this.environment.urlBackCore + 'api/v1/poseedores/gestores-residuos' + this.gestoresFilter;
  provinciaArray: any[] = [];
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  flagSearch: boolean = false;
  totalSearch: number = 0;
  defaultFilter: boolean = true;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  headArray: any = [
    {
      Head: 'Año',
      FieldName: 'year',
    },
    {
      Head: 'Provincia',
      FieldName: 'provincia',
      renderValue: (item: any) => item.provincia?.nombre || item.gestor?.domicilios?.[0]?.provincia?.nombre || item.gestor?.domicilios?.[0]?.provinciaRef?.nombre,
    },
    {
      Head: 'Gestor',
      FieldName: 'gestor',
      renderValue: (item: any) => `${item.gestor?.razonSocial} - ${item.gestor?.centro}`,
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'Código LER',
      FieldName: 'codigoLer',
      renderValue: (item: any) =>
        `${item.codigoLer.tipoCodigo} ${item.codigoLer.descripcion}`,
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: '€/kg',
      FieldName: 'valorTarifa',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item.valorTarifa),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        { nameButton: 'view', icon: 'open_in_new', toolTip: 'Editar' },
        { nameButton: 'delete', icon: 'delete', toolTip: 'Borrar' },
      ],
      width: '10',
    },
  ];
  gridArray: any[] = [];

  constructor(
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private downloadService: DownloadService,
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private provinciaControllerSrv: ProvinciaControllerService,
    private tarifaGestoresControllerService: TarifaGestoresControllerService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.searchForm = this.formBuilder.group({
      year: [
        { value: new Date().getFullYear(), disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoLer: [{ value: null, disabled: false }],
      gestor: [{ value: null, disabled: false }],
      provincia: [{ value: null, disabled: false }],
      valorTarifa: [{ value: null, disabled: false }],
    });
  }
  ngOnInit() {
    this.getComboProvincia();
  }
  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }
  getGestorDescription(gestor: any) {
    return gestor?.razonSocial;
  }

  getOperacionDescription(op: any) {
    return op?.descripcion;
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.searchForm.get('codigoLer') as FormControl;
      }
      case 'gestor': {
        return this.searchForm.get('gestor') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }
  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }
  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  getComboProvincia() {
    this.provinciaControllerSrv
      .listarComunidades1({}, { page: 0, size: 500 })
      .subscribe({
        next: (data: any) => {
          this.provinciaArray = data.datos!;
        },
        error: () => {},
      });
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  limpiar(): void {
    this.searchForm.reset();
    this.gridArray = [];
  }

  buscar(): void {
    this.obtenerTarifas();
  }

  obtenerTarifas(): void {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.tarifaGestoresControllerService
      .findAll1(backendFilter, {
        page: page,
        size: size,
        // sort: ['provincia,asc'],
      })
      .subscribe({
        next: (data: PageDTOTarifaGestoresDTO) => {
          if (data) {
            this.flagSearch = data.datos?.length !== 0 ? true : false;
            this.gridArray = data.datos ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  private getBackendFilter() {
    const filter = this.searchForm.value;

    if (filter.codigoLer) {
      filter.codigoLer = {
        id: filter.codigoLer.id,
      };
    }

    if (filter.provincia) {
      filter.provincia = {
        id: filter.provincia.id,
      };
    }
    if (filter.gestor) {
      filter.gestor = {
        id: filter.gestor.id,
      };
    }

    const backendFilter: TarifaGestoresFilterDTO  | any = {
      year: filter.year,
      gestor: filter.gestor,
      codigoLer: filter.codigoLer,
      provincia: filter.provincia,
      valorTarifa: filter.valorTarifa
        ? this.parseCurrencyPipe.transform(filter.valorTarifa)
        : undefined,
    };

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }
  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.buscar();
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.buscar();
    }
  }
  action(element: any) {
    if (element.nameButton == 'view') {
      this.abrirModalTarifa(element);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.tarifaGestoresControllerService.exportCSV1(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_TarifasAcuerdoGestores.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: '¿Desea eliminar la tarifa?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarTarifa(element);
      }
    });
  }

  eliminarTarifa(element: any): void {
    this.spinnerSrv.loadingShow();
    this.tarifaGestoresControllerService.deleteById1(element.id)
      .subscribe({
        next: (value: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `La tarifa se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar la tarifa',
            'error'
          );
        },
      });
  }

  abrirModalTarifa(tarifa?: any): void {
    const dialogRef = this.dialog.open(ManagersAgreementsRatesDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        tarifa: tarifa,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.buscar();
      }
    });
  }
}
