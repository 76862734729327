import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttachedRateClosedSearchComponent } from '../attached-rate-closed-search/attached-rate-closed-search.component';
import {
  TarifaAdheridoSDDRCerradoDTO,
  TarifaAdheridoSDDRCerradoPayloadDTO,
  TarifaAdheridoSddrCerradoControllerService,
} from 'api-rest';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';

@Component({
  selector: 'app-rate-closed-dialog',
  templateUrl: './rate-closed-dialog.component.html',
  styleUrls: ['./rate-closed-dialog.component.scss'],
})
export class RateClosedDialogComponent implements OnInit {
  titulo: String = '';
  itemClosed: any;
  modelForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AttachedRateClosedSearchComponent>,
    private attachedClosedService: TarifaAdheridoSddrCerradoControllerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.itemClosed = this.data.itemClosed;
    this.modelForm = this.formBuilder.group({
      anio: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            this.customValidator(),
          ]),
        },
      ],
      desde: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      hasta: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      cuota: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      deposito: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
  }

  ngOnInit() {
    if (this.itemClosed) {
      this.titulo = 'Edición tarifa envases SDDR Cerrado ';
      this.cargarTarifaClosed(this.itemClosed);
    } else {
      this.titulo = 'Nueva tarifa envases SDDR Cerrado';
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  cargarTarifaClosed(itemClosed: TarifaAdheridoSDDRCerradoDTO) {
    this.modelForm.get('anio')?.setValue(itemClosed?.anio),
      this.modelForm
        .get('desde')
        ?.setValue(this.formatCurrencyPipe.transform(itemClosed?.desde)),
      this.modelForm
        .get('hasta')
        ?.setValue(this.formatCurrencyPipe.transform(itemClosed?.hasta)),
      this.modelForm
        .get('cuota')
        ?.setValue(this.formatCurrencyPipe.transform(itemClosed?.cuota)),
      this.modelForm
        .get('deposito')
        ?.setValue(this.formatCurrencyPipe.transform(itemClosed?.deposito));
  }

  guardar() {
    if (this.modelForm.invalid) {
      this.modelForm.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }

    const objeto: TarifaAdheridoSDDRCerradoPayloadDTO = {
      anio: this.modelForm.get('anio')?.value,
      desde: this.parseCurrencyPipe.transform(
        this.modelForm.get('desde')?.value
      ),
      hasta: this.parseCurrencyPipe.transform(
        this.modelForm.get('hasta')?.value
      ),
      cuota: this.parseCurrencyPipe.transform(
        this.modelForm.get('cuota')?.value
      ),
      deposito: this.parseCurrencyPipe.transform(
        this.modelForm.get('deposito')?.value
      ),
    };

    if (this.itemClosed) {
      let body = Object.assign({ id: this.itemClosed?.id }, objeto);
      this.spinnerSrv.loadingShow();
      this.attachedClosedService
        .editById1(this.itemClosed?.id, body)
        .subscribe({
          next: (data: TarifaAdheridoSDDRCerradoDTO) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'La tarifa se ha actualizado correctamente',
              'success'
            );
            this.dialogRef.close(true);
          },
          error: () => {
            this.spinnerSrv.loadingHide();
          },
        });
    } else {
      this.spinnerSrv.loadingShow();
      this.attachedClosedService.create1(objeto).subscribe({
        next: (data: TarifaAdheridoSDDRCerradoDTO) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'La tarifa se ha creado correctamente',
            'success'
          );
          this.dialogRef.close(true);
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  customValidator() {
    return (control: { value: any }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (/^\d{4}$/.test(value)) {
        const intValue = parseInt(value, 10);
        if (intValue >= 1900 && intValue <= 2500) {
          return null;
        }
      }
      return { invalid: true };
    };
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.modelForm.controls[controlName].hasError(errorName) &&
      this.modelForm.controls[controlName].touched
    );
  }
}
