import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { KeycloakAssociatedPolicyModelAdapter } from "../model/adapters/keycloak-associated-policy.model.adapter copy";
import { KeycloakClientModelAdapter } from "../model/adapters/keycloak-client.model.adapter";
import { KeycloakPolicyModelAdapter } from "../model/adapters/keycloak-policy.model.adapter";
import { KeycloakResourceModelAdapter } from "../model/adapters/keycloak-resource.model.adapter";
import { KeycloakResourceServerSettingsModelAdapter } from "../model/adapters/keycloak-respource-server-settings.model.adapter";
import { KeycloakScopeModelAdapter } from "../model/adapters/keycloak-scope.model.adapter";
import { KeycloakAssociatedPolicyModel } from "../model/keycloak-associated-policy.model";
import { KeycloakClientModel } from "../model/keycloak-client.model";
import { KeycloakPolicyModel } from "../model/keycloak-policy.model";
import { KeycloakResourceModel } from "../model/keycloak-resource.model";
import { KeycloakResourceServerSettingsModel } from "../model/keycloak-respource-server-settings.model";
import { KeycloakScopeModel } from "../model/keycloak-scope.model";

@Injectable({
    providedIn: 'root'
})
export class KamResourceServerServices {

    constructor(private http: HttpClient,
        @Inject('environment') private environment:any,
        private keycloakClientModelAdapter: KeycloakClientModelAdapter,
        private keycloakResourceServerSettingsModelAdapter: KeycloakResourceServerSettingsModelAdapter,
        private keycloakResourceModelAdapter: KeycloakResourceModelAdapter,
        private keycloakScopeModelAdapter: KeycloakScopeModelAdapter,
        private keycloakPolicyModelAdapter: KeycloakPolicyModelAdapter,
        private keycloakAssociatedPolicyModelAdapter: KeycloakAssociatedPolicyModelAdapter,
    ){}
    

    getClients(): Observable<Array<KeycloakClientModel>> {
        return this.http.get<KeycloakClientModel[]>(
            this.environment.keycloak.url + this.environment.kamUrlClients
          )
          .pipe(map((response) => {
            let items: KeycloakClientModel[] = [];        
            response.forEach(element => {
              items.push(this.keycloakClientModelAdapter.adapt(element));
            });
            return items;
          }));
      }

    getResourceServersetting(clientId: string): Observable<KeycloakResourceServerSettingsModel> {
        return this.http.get<KeycloakResourceServerSettingsModel>(
            this.environment.keycloak.url + this.environment.kamUrlResourceServerSettings.replace("{{clientId}}",clientId)
        )
        .pipe(map((response) => {
            return this.keycloakResourceServerSettingsModelAdapter.adapt(response);
        }));
    }
    /** Resource */
    postResource(clientId: string, resource: KeycloakResourceModel): Observable<KeycloakResourceModel> {
        return this.http.post<KeycloakResourceModel>(
            this.environment.keycloak.url + this.environment.kamUrlResource.replace("{{id}}",clientId),
            resource
        )
        .pipe(map((response) => {
            return this.keycloakResourceModelAdapter.adapt(response);
        }));
    }

    putResource(clientId: string, resource: KeycloakResourceModel): Observable<void> {
        return this.http.put<void>(
            this.environment.keycloak.url + this.environment.kamUrlResource.replace("{{id}}",clientId) + "/" + resource._id,
            resource
        )
        .pipe(map(() => {
            return;
        }));
    }

    deleteResource(clientId: string, resourceId?: string): Observable<void> {
        return this.http.delete<void>(
            this.environment.keycloak.url + this.environment.kamUrlResource.replace("{{id}}",clientId) + "/" + resourceId,
        )
        .pipe(map(() => {
            return;
        }));
    }

    deleteRole(roleId?: string){
        console.log("roleId: " + roleId);
        
        console.log(this.environment.keycloak.url + this.environment.kamUrlDeleteRole.replace("{{id}}",roleId));
        console.log("entra servidor");

        return this.http.delete(this.environment.keycloak.url + this.environment.kamUrlDeleteRole.replace("{{id}}",roleId));
    }

    /** Scope */
    postScope(clientId: string, scope: KeycloakScopeModel): Observable<KeycloakScopeModel> {
        return this.http.post<KeycloakScopeModel>(
            this.environment.keycloak.url + this.environment.kamUrlScope.replace("{{id}}",clientId),
            scope
        )
        .pipe(map((response) => {
            return this.keycloakScopeModelAdapter.adapt(response);
        }));
    }

    putScope(clientId: string, scope: KeycloakScopeModel): Observable<void> {
        return this.http.put<void>(
            this.environment.keycloak.url + this.environment.kamUrlScope.replace("{{id}}",clientId) + "/" + scope.id,
            scope
        )
        .pipe(map(() => {
            return;
        }));
    }

    deleteScope(clientId: string, scopeId?: string): Observable<void> {
        return this.http.delete<void>(
            this.environment.keycloak.url + this.environment.kamUrlScope.replace("{{id}}",clientId) + "/" + scopeId,
        )
        .pipe(map(() => {
            return;
        }));
    }

    // policies
    getAssociatedPolicies(clientId: string, policyId:string): Observable<KeycloakAssociatedPolicyModel[]> {
        return this.http.get<KeycloakAssociatedPolicyModel[]>(
            this.environment.keycloak.url + this.environment.kamUrlAssociatedPolicies.replace("{{id}}",clientId).replace("{{policyId}}",policyId)
        )
        .pipe(map((response) => {
            let items: KeycloakAssociatedPolicyModel[] = [];        
            response.forEach(element => {
              element.originalPolicyId = policyId;
              items.push(this.keycloakAssociatedPolicyModelAdapter.adapt(element));
            });
            return items;
        }));
    }

    postPolicy(clientId: string, policy: KeycloakPolicyModel): Observable<KeycloakPolicyModel> {
        return this.http.post<KeycloakPolicyModel>(
            this.environment.keycloak.url + this.environment.kamUrlPolicies.replace("{{id}}",clientId) + "/role",
            policy
        )
        .pipe(map((response) => {
            return this.keycloakPolicyModelAdapter.adapt(response);
        }));
    }

    putPolicy(clientId: string, policy: KeycloakPolicyModel): Observable<void> {
        return this.http.put<void>(
            this.environment.keycloak.url + this.environment.kamUrlPolicies.replace("{{id}}",clientId) + "/role/" + policy.id,
            policy
        )
        .pipe(map(() => {
            return ;
        }));
    }


    deletePolicies(clientId: string, policyId: string): Observable<void> {
        return this.http.delete<void>(
            this.environment.keycloak.url + this.environment.kamUrlPolicies.replace("{{id}}",clientId) + "/" + policyId,
        )
        .pipe(map(() => {
            return;
        }));
    }

    // permission
    postPermission(clientId: string, permission: KeycloakPolicyModel): Observable<KeycloakPolicyModel> {
        return this.http.post<KeycloakPolicyModel>(
            this.environment.keycloak.url + this.environment.kamUrlPermission.replace("{{id}}",clientId) + "/scope",
            permission
        )
        .pipe(map((response) => {
            return this.keycloakPolicyModelAdapter.adapt(response);
        }));
    }


    deletePermission(clientId: string, permissionId: string): Observable<void> {
        return this.http.delete<void>(
            this.environment.keycloak.url + this.environment.kamUrlPermission.replace("{{id}}",clientId) + "/" + permissionId,
        )
        .pipe(map(() => {
            return;
        }));
    }

    postRole2(name: string, description: string): Observable<KeycloakResourceModel> {
        return this.http.post<string>(
            this.environment.keycloak.url + this.environment.kamUrlRoles,
            {name: name, description: description}
        )
        .pipe(map((response) => {
            return this.keycloakResourceModelAdapter.adapt(response);
        }));
    }

    postRole(name: string, description: string) {
       
        
        const url =this.environment.keycloak.url + this.environment.kamUrlRoles;


        return this.http.post(url, {name: name, description: description});
    }
}