import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { KeycloakRoleModel } from '../../../model/keycloak-role.model';
import { AuthManagerState } from '../../../state/auth-manager.model';
import { selectRoles } from '../../../state/auth-manager.selectors';
import { Subscription } from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'keycloak-auth-manager-kam-role-selection',
  templateUrl: './kam-role-selection.component.html',
  styleUrls: ['./kam-role-selection.component.css']
})
export class KamRoleSelectionComponent implements OnInit, OnDestroy {

  @Output() roleSelection = new EventEmitter<KeycloakRoleModel[]>();
  @Output() rolesUnselected = new EventEmitter<KeycloakRoleModel[]>();

  @Input() initialRolesSelected: string[]|undefined;

  @ViewChild('paginatorRoles') set paginatorScopes(mp: MatPaginator) {
    this.dataSource.paginator = mp
  }
  

  rolesSubscription: Subscription | undefined;
  dataSource: MatTableDataSource<KeycloakRoleModel> = new MatTableDataSource();
  columnsToDisplay = ['select','name','description'];
  selection = new SelectionModel<KeycloakRoleModel>(true, []);
  private initalRoles: KeycloakRoleModel[] = [];

  constructor(private store: Store<AuthManagerState>,) { }
 
  ngOnInit(): void {
    this.rolesSubscription = this.store.select(selectRoles).subscribe(roles =>{          
      if (this.initialRolesSelected){
        this.initialRolesSelected.forEach(selectedRole=>{
            let foundRole = roles.find(role => role.name===selectedRole)
            if (foundRole){
              this.selection.select(foundRole)    
              this.initalRoles.push(foundRole)                     
            }
        })        
      }
      this.dataSource.data = roles;
      this.roleSelection.emit(this.selection.selected);
    });
  }

  update(){    

    let unSelect: KeycloakRoleModel[] = []
    this.initalRoles.forEach(initialRole=>{
      let found = this.selection.selected.findIndex(role => role.name === initialRole.name)
      if (found<0){
        unSelect.push(initialRole)
      }
    })
    this.roleSelection.emit(this.selection.selected);    
    this.rolesUnselected.emit(unSelect)
  }

  ngOnDestroy(): void {
    if (this.rolesSubscription) this.rolesSubscription.unsubscribe();
  }

}
