import { Component } from '@angular/core';

@Component({
  selector: 'comunications-comunications',
  templateUrl: './comunications.component.html',
  styleUrls: ['./comunications.component.scss']
})
export class ComunicationsComponent {

}
