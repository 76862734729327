import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdheridoControllerService } from './api/adheridoController.service';
import { AdheridoEstadoControllerService } from './api/adheridoEstadoController.service';
import { AportacionDiAdheridoControllerService } from './api/aportacionDiAdheridoController.service';
import { CentrosEnvasadoControllerService } from './api/centrosEnvasadoController.service';
import { CertificadosControllerService } from './api/certificadosController.service';
import { ContratoControllerService } from './api/contratoController.service';
import { DeclaracionAdheridoControllerService } from './api/declaracionAdheridoController.service';
import { DeclaracionCentroControllerService } from './api/declaracionCentroController.service';
import { DeclaracionEnvaseControllerService } from './api/declaracionEnvaseController.service';
import { DeclaracionEnvaseMaterialControllerService } from './api/declaracionEnvaseMaterialController.service';
import { EstadoDeclaracionControllerService } from './api/estadoDeclaracionController.service';
import { FacturaAdheridoControllerService } from './api/facturaAdheridoController.service';
import { MailControllerService } from './api/mailController.service';
import { ReportControllerService } from './api/reportController.service';
import { SectorControllerService } from './api/sectorController.service';
import { TarifaAdheridoSddrAbiertoControllerService } from './api/tarifaAdheridoSddrAbiertoController.service';
import { TarifaAdheridoSddrCerradoControllerService } from './api/tarifaAdheridoSddrCerradoController.service';
import { TarifaAdheridoUnUsoControllerService } from './api/tarifaAdheridoUnUsoController.service';
import { TramosCuotaControllerService } from './api/tramosCuotaController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdheridoControllerService,
    AdheridoEstadoControllerService,
    AportacionDiAdheridoControllerService,
    CentrosEnvasadoControllerService,
    CertificadosControllerService,
    ContratoControllerService,
    DeclaracionAdheridoControllerService,
    DeclaracionCentroControllerService,
    DeclaracionEnvaseControllerService,
    DeclaracionEnvaseMaterialControllerService,
    EstadoDeclaracionControllerService,
    FacturaAdheridoControllerService,
    MailControllerService,
    ReportControllerService,
    SectorControllerService,
    TarifaAdheridoSddrAbiertoControllerService,
    TarifaAdheridoSddrCerradoControllerService,
    TarifaAdheridoUnUsoControllerService,
    TramosCuotaControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
