import { Attribute, Component, Inject, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import {
  PuntoRecogidaControllerService,
  PtoRecogidaPayloadDTO,
  SolicitudControllerService,
  PtoRecogidaGestorControllerService,
  PtoRecogidaCodigoControllerService,
  SolicitudRetiradaControllerService,
  TipoEnvasesControllerService,
  SubTipoEnvaseFilterDTO,
  SubTipoEnvaseControllerService,
  TipoEnvasesDTO,
  SubTipoEnvaseDTO,
  SolicitudInformeRetiradaDocumControllerService,
  SolicitudInformeRetiradaDocumFilterDTO
} from 'api-rest';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DatePipe } from '@angular/common';
import { DocumentService } from '../../../services/document.service';
import { DownloadService } from '../../../services/download.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-open-sddr-requests-withdrawn-detail',
  templateUrl: './open-sddr-requests-withdrawn-detail.component.html',
  styleUrls: ['./open-sddr-requests-withdrawn-detail.component.scss'],
})
export class OpenSddrRequestsWithdrawnDetailComponent implements OnInit {
  datosSolicitudForm: FormGroup;
  informeRetiradaForm: FormGroup;
  facturaForm: FormGroup;
  informacionSolicitud: FormGroup;
  documentoInformeForm: FormGroup;
  pageNumber: number = 0;
  pageSize: number = 100;
  titulo: any;

  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';

  //codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler';

  flagDisabledForm: boolean = false;
  updateList: boolean = false;

  comunidadArray: any[] = [];
  gestorArray: any[] = [];
  estadosArray: any[] = [];

  infoRequests: any;
  ptoArray: any[] = [];
  documentosAdjuntosArray: any[] = [];
  archivosAdjuntosArray: any[] = [];

  facturaBlob: File | undefined;
  documentoBlob: File | undefined;

  headFacturaArray: any[] = [
    {
      Head: 'Nº Factura',
      FieldName: 'nFacturaDeposito',
    },
    {
      Head: 'Documento',
      FieldName: 'facturaBlob',
      Attribute: 'name',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'download_factura',
          icon: 'download',
          toolTip: 'Descargar',
          // show: { accion: 'donwload' },
        },
        {
          nameButton: 'delete_factura',
          icon: 'delete',
          toolTip: 'Borrar',
          // show: { accion: 'delete' },
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  headDocumentosArray: any[] = [
    {
      Head: 'Nombre',
      FieldName: 'nombre',
    },
    {
      Head: 'Documento',
      FieldName: 'documento',
      renderValue: (item: any) => item.name || item.documento.nombre,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'download_documento',
          icon: 'download',
          toolTip: 'Descargar',
        },
        {
          nameButton: 'delete_documento',
          icon: 'delete',
          toolTip: 'Borrar',
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  nombreArchivoA: string = '';
  nombreArchivoC: string = '';
  fileD: File | undefined;
  fileD2: File | undefined;
  @ViewChild('fileInputA') fileInputA: any;

  flagPrGestor1: boolean = false;
  flagPrGestor2: boolean = false;
  tipoEnvaseArray: any = [];
  subtipoEnvaseArray!: SubTipoEnvaseDTO[];

  constructor(
    private formBuilder: FormBuilder,
    @Inject('environment') private environment: any,
    private dialogRef: MatDialogRef<OpenSddrRequestsWithdrawnDetailComponent>,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private documentService: DocumentService,
    private downloadService: DownloadService,
    private solicitudRetiradaControllerService: SolicitudRetiradaControllerService,
    private solicitudControllerService: SolicitudControllerService,
    private tipoEnvasesControllerService: TipoEnvasesControllerService,
    private subtipoEnvaseControllerService: SubTipoEnvaseControllerService,
    private solicitudInformeRetiradaDocumControllerService: SolicitudInformeRetiradaDocumControllerService
  ) {
    this.infoRequests = this.data.infoRequests;

    this.datosSolicitudForm = this.formBuilder.group({
      tipoEnvase: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      subtipoEnvase: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      unidadesRetirar: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      pesoRetirar: [{ value: null, disabled: false }],
      aceptacionRetirada: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      acondicionador: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      loteEnvase: [{ value: null, disabled: false }],
      observacionesRetirada: [{ value: null, disabled: false }],
      autofactura: [{ value: this.infoRequests?.autofactura, disabled: false }],
    });

    this.facturaForm = this.formBuilder.group({
      importeDepositoUnidad: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      nFacturaDeposito: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });

    this.informacionSolicitud = this.formBuilder.group({
      usuarioSolicitud: [{ value: null, disabled: false }],
      fechaSolicitud: [{ value: null, disabled: false }],
      estadoSolicitud: [{ value: null, disabled: false }],
    });

    this.informeRetiradaForm = this.formBuilder.group({
      usuarioSddrCa: [{ value: null, disabled: false }],
      verificacion: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      udEnvases: [{ value: null, disabled: false }],
      kgEnvases: [{ value: null, disabled: false }],
      udRetirados: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      kgRetirados: [
        { value: null, disabled: false }],

      udAptos: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      kgAptos: [{ value: null, disabled: false }],
      udNoAptos: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      kgNoAptos: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      envasesSalenSddr: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      envasesMantienenSddr: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });

    this.documentoInformeForm = this.formBuilder.group({
      nombre: [{ value: null, disabled: false }],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tipoEnvaseArray.length) {
      this.inicializar();
    }
  }

  inicializar() {
    this.cargarDatos(this.infoRequests);
    this.controlGeneralForm();
  }

  ngOnInit(): void {
    this.cargarCombos();
    this.inicializar();
    if (this.infoRequests?.id) {
      this.titulo = 'Editar aportación de DI';
      // this.updateDataForm(this.infoRequests);
      this.inicializar();
    } else {
      this.titulo = 'Alta de aportación de DI';
    }
  }

  controlGeneralForm() {
    this.informacionSolicitud.get('usuarioSolicitud')?.disable();
    this.informacionSolicitud.get('fechaSolicitud')?.disable();
    this.informacionSolicitud.get('estadoSolicitud')?.disable();
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  cargarDatos(data?: any) {
    this.datosSolicitudForm.get('tipoEnvase')?.setValue(data?.tipoEnvase.descripcion);
    this.datosSolicitudForm.get('subtipoEnvase')?.setValue(data?.subTipoEnvase.descripcion);
    this.datosSolicitudForm.get('loteEnvase')?.setValue(data?.loteEnvase);
    this.informacionSolicitud.get('usuarioSolicitud')?.setValue(data?.adherido?.razonSocial);
    this.informacionSolicitud.get('fechaSolicitud')?.setValue(this.datePipe.transform(data?.fechaSolicitud, 'dd/MM/yyyy'));
    this.informacionSolicitud.get('estadoSolicitud')?.setValue(data?.estado.descripcion);


    // this.nombreArchivoA = data?.documento !== null ? data?.documento?.nombre! : '';
  }

  cargarCombos(): void {
    const tipoEnvaseFilter = { sddrca: true };
    const subtipoEnvaseFilter: SubTipoEnvaseFilterDTO = {
      tipoEnvase: {
        sddrca: true
      }
    };
    const solicitudInformeRetirada: SolicitudInformeRetiradaDocumFilterDTO = {
      solicitudInformeRetirada: {
        id: this.infoRequests?.id
      }
    };
    this.spinnerSrv.loadingShow();
    zip(
      this.tipoEnvasesControllerService.findAll(tipoEnvaseFilter, { page: 0, size: 100 }),
      this.subtipoEnvaseControllerService.listarSubTipoEnvases(subtipoEnvaseFilter, { page: 0, size: 100 }),
      this.solicitudInformeRetiradaDocumControllerService.findAll(solicitudInformeRetirada, { page: 0, size: 100 }),
    ).subscribe({
      next: (responses: any[]) => {
        this.spinnerSrv.loadingHide();
        this.tipoEnvaseArray = responses[0].datos;
        this.subtipoEnvaseArray = responses[1].datos;
        this.documentosAdjuntosArray = responses[2].datos;
        // this.estados = responses[3].datos
        // this.cargarDatos(this.infoRequests)
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  controlHasError(
    formGroup: FormGroup,
    controlName: string,
    errorName: string
  ) {
    return (
      formGroup.controls[controlName].hasError(errorName) &&
      formGroup.controls[controlName].touched
    );
  }

  saveData() {
    this.datosSolicitudForm.markAllAsTouched();
    this.datosSolicitudForm.updateValueAndValidity();

    if (
      this.infoRequests?.documento == null &&
      this.infoRequests?.estado?.id == 2
    ) {
      // if (this.documento == undefined || this.documento == null) {
      //   this.snackBarSrv.showSnackBar('Adjunte documento', 'error');
      //   return;
      // }
    }

    if (this.datosSolicitudForm.valid || this.datosSolicitudForm.disabled) {
      const objPuntosRecogidaFilter = this.infoRequests;

      objPuntosRecogidaFilter.fechaPrevista = this.datePipe.transform(
        this.datosSolicitudForm.get('fechaPrevista')?.value,
        'yyyy-MM-dd'
      );

      objPuntosRecogidaFilter.fechaRecogida = this.datePipe.transform(
        this.datosSolicitudForm.get('fechaRecogida')?.value,
        'yyyy-MM-dd'
      );

      objPuntosRecogidaFilter.codigoLerReal = {
        id: this.datosSolicitudForm.get('codigoLerRecogida')?.value,
      };

      objPuntosRecogidaFilter.cantidadRecogidaReal =
        this.datosSolicitudForm.get('kgReales')?.value;

      objPuntosRecogidaFilter.observacionesGestor = this.datosSolicitudForm.get(
        'observacionesGestor'
      )?.value;

      objPuntosRecogidaFilter.documentoIdentificacion =
        this.datosSolicitudForm.get('documentoDi')?.value;

      objPuntosRecogidaFilter.autofactura = {
        id: this.datosSolicitudForm.get('autoFactura')?.value,
      };

      this.spinnerSrv.loadingShow();
      // this.solicitudControllerService
      //   .guardarSolicitud(
      //     this.infoRequests?.id,
      //     objPuntosRecogidaFilter,
      //     this.documento
      //   )
      //   .subscribe({
      //     next: (value: any) => {
      //       this.spinnerSrv.loadingHide();
      //       this.dialogRef.close(true);
      //       this.snackBarSrv.showSnackBar(
      //         'Se ha guardado correctamente la solicitud',
      //         'success'
      //       );
      //     },
      //     error: (error: any) => {
      //       this.spinnerSrv.loadingHide();
      //     },
      //   });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  saveDataFinal() {
    this.datosSolicitudForm.markAllAsTouched();
    this.datosSolicitudForm.updateValueAndValidity();

    if (
      this.infoRequests?.documento == null &&
      this.infoRequests?.estado?.id == 2
    ) {
      // if (this.documento == undefined || this.documento == null) {
      //   this.snackBarSrv.showSnackBar('Adjunte documento', 'error');
      //   return;
      // }
    }

    if (this.datosSolicitudForm.valid || this.datosSolicitudForm.disabled) {
      this.spinnerSrv.loadingShow();
      this.solicitudControllerService
        .finalizarSolicitud(this.infoRequests?.id)
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha finalizado correctamente la solicitud',
              'success'
            );
          },
          error: (error: any) => {
            this.spinnerSrv.loadingHide();
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  saveDataCourse() {
    this.datosSolicitudForm.markAllAsTouched();
    this.datosSolicitudForm.updateValueAndValidity();

    if (
      this.infoRequests?.documento == null &&
      this.infoRequests?.estado?.id == 2
    ) {
      // if (this.documento == undefined || this.documento == null) {
      //   this.snackBarSrv.showSnackBar('Adjunte documento', 'error');
      //   return;
      // }
    }

    if (this.datosSolicitudForm.valid || this.datosSolicitudForm.disabled) {
      const objPuntosRecogidaFilter = this.infoRequests;

      objPuntosRecogidaFilter.fechaPrevista = this.datePipe.transform(
        this.datosSolicitudForm.get('fechaPrevista')?.value,
        'yyyy-MM-dd'
      );

      objPuntosRecogidaFilter.fechaRecogida = this.datePipe.transform(
        this.datosSolicitudForm.get('fechaRecogida')?.value,
        'yyyy-MM-dd'
      );

      objPuntosRecogidaFilter.codigoLerReal = {
        id: this.datosSolicitudForm.get('codigoLerRecogida')?.value,
      };

      objPuntosRecogidaFilter.cantidadRecogidaReal =
        this.datosSolicitudForm.get('kgReales')?.value;

      objPuntosRecogidaFilter.observacionesGestor = this.datosSolicitudForm.get(
        'observacionesGestor'
      )?.value;

      objPuntosRecogidaFilter.documentoIdentificacion =
        this.datosSolicitudForm.get('documentoDi')?.value;

      objPuntosRecogidaFilter.autofactura = {
        id: this.datosSolicitudForm.get('autoFactura')?.value,
      };

      this.spinnerSrv.loadingShow();
      this.solicitudControllerService
        .guardarSolicitudEnCurso(this.infoRequests?.id, objPuntosRecogidaFilter)
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado correctamente la solicitud en curso',
              'success'
            );
          },
          error: (error: any) => {
            this.spinnerSrv.loadingHide();
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.datosSolicitudForm.controls[controlName]?.touched ||
        this.datosSolicitudForm.controls[controlName]?.dirty) &&
      this.datosSolicitudForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  showClearButton(formName: string): boolean {
    return this.datosSolicitudForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.datosSolicitudForm.get(formName)?.setValue(null);
  }

  attachFile(archivo: File | null, nombre: string): void {
    if (archivo) {
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        if (nombre == 'factura') {
          this.facturaBlob = archivo;
        } else if (nombre == 'documento') {
          this.documentoBlob = archivo;
        }
      }
    }
  }

  descargar(documento: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(documento.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  eliminarFactura(element?: any): void {
    this.archivosAdjuntosArray = this.archivosAdjuntosArray.filter(
      (objeto) => objeto.nFacturaDeposito !== element.nFacturaDeposito
    );
  }

  eliminarDocumento(element?: any): void {
    //TODO eliminar documento aprovechando alguna propiedad que lo identifique
    this.documentosAdjuntosArray = this.documentosAdjuntosArray.filter(
      (objeto) => objeto.documento?.id !== element.documento?.id || objeto.documento.name !== element.documento.name)
  }

  action(item: any) {
    if (item.nameButton == 'download_factura') {
      this.descargar(item);
    }
    if (item.nameButton == 'download_documento') {
      this.descargar(item);
    }
    else if (item.nameButton == 'delete_factura') {
      this.eliminarFactura(item);
    }
    else if (item.nameButton == 'delete_documento') {
      this.eliminarDocumento(item);
    }
  }

  addFactura(): void {
    if (this.facturaForm.invalid) {
      this.facturaForm.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Revisar los campos Nº de factura y el importe del depósito por unidad',
        'error'
      );
      return;
    }

    const existe = this.archivosAdjuntosArray.find(
      (x) =>
        x.nFacturaDeposito == this.facturaForm.get('nFacturaDeposito')?.value
    );
    if (existe) {
      this.snackBarSrv.showSnackBar(
        'Ya existe el número de factura introducido',
        'error'
      );
    } else {
      const obj = {
        nFacturaDeposito: this.facturaForm.get('nFacturaDeposito')?.value,
        importeDepositoUnidad: this.facturaForm.get('importeDepositoUnidad')?.value,
        facturaBlob: this.facturaBlob,
      };
      this.archivosAdjuntosArray.push(obj);
      // this.archivosAdjuntosArray = this.canAccessAll([...this.archivosAdjuntosArray]);
      this.facturaForm.get('nFacturaDeposito')?.setValue(null);
      this.facturaForm.get('importeDepositoUnidad')?.setValue(null);
      this.facturaBlob = undefined;
      this.facturaForm.markAsPristine();
      this.facturaForm.markAsUntouched();
    }
  }

  addDocumento(): void {
    const obj = {
      nombre: this.documentoInformeForm.get('nombre')?.value,
      documento: this.documentoBlob,
    };
    this.documentosAdjuntosArray.push(obj);
    // this.archivosAdjuntosArray = this.canAccessAll([...this.archivosAdjuntosArray]);
    this.documentoInformeForm.get('nombre')?.setValue(null);
    this.documentoBlob = undefined;
    this.documentoInformeForm.markAsPristine();
    this.documentoInformeForm.markAsUntouched();
  }

  canEditWithdrawReport() {
    return (
      this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'edit-informe') &&
      this.infoRequests && this.infoRequests?.estado?.id === 2
    );
  }

  canEditDataRequests() {
    return (
      this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'edit-datos') &&
      this.infoRequests && this.infoRequests?.estado?.id === 1
    );
  }

  canEditReportRequests() {
    return (
      this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'edit-informe') &&
      this.infoRequests && this.infoRequests?.estado?.id === 2
    );
  }

  canSaveDraftDataRequests() {
    return (
      this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'access-b-datos-guardar') &&
      this.infoRequests && this.infoRequests?.estado?.id === 1
    );
  }

  canSaveAndSendDataRequests() {
    return (
      this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'access-b-datos-guardar-enviar') &&
      this.infoRequests && this.infoRequests?.estado?.id === 1
    );
  }

  canSaveDraftReportRequests() {
    return (
      this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'access-b-informe-guardar') &&
      this.infoRequests && this.infoRequests?.estado?.id === 2
    );
  }

  canSaveAndSendDraftRequests() {
    return (
      this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'access-b-informe-guardar-enviar') &&
      this.infoRequests && this.infoRequests?.estado?.id === 2
    );
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
