<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font" *ngIf="newItem; else editTarifaTitle">Nueva tarifa de un solo uso</h3>
    <ng-template #editTarifaTitle>
      <h3 class="modal-title font">Edición tarifa de un solo uso</h3>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <div *ngIf="formGroup.value?.anio" class="title-name mb-3" style="font-size: 1.3rem; font-weight: 700;">Tarifa un
      solo uso {{ formGroup.value?.anio }}</div>

    <form class="form-search py-3" [formGroup]="formGroup">
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="anio">Año <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="number" matInput formControlName="anio" id="anio" placeholder="Año" value="" [min]="0">
              <mat-error *ngIf="controlHasError('anio', 'required')">Campo obligatorio</mat-error>
              <mat-error *ngIf="formGroup.get('anio')?.invalid && formGroup.get('anio')?.touched">Año inválido (1900 a
                2500)
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="categoria">Categoría <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="categoria" id="categoria" placeholder="-- Categoría --">
                <mat-option *ngFor="let categoria of categorias" [value]="categoria.id">
                  {{ categoria.descripcion }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('categoria', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="grupoEnvase">Grupo envase <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination class="lib-ge-select" descripcion="grupoEnvase" [defaultFilter]=defaultFilter
              [endPointFilter]="grupoEnvaseEndpoint" objQueryName="descripcion" placeHolder="-- Grupo envase --"
              descripcion="descripcion" [formControlNameSelect]="getFormControl('grupoEnvase')"
              [modelSelected]="this.formGroup.value.grupoEnvase" [customClass]="rightHolder('grupoEnvase')">
            </lib-ge-select-pagination>
            <mat-error *ngIf="controlHasError('grupoEnvase', 'required')">Campo obligatorio</mat-error>
          </div>
        </div>
      </div>
      <div class="row form pt-2">
        <div class="col-sm flex-grow-1">
          <label for="tipologia">Tipología <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="tipologia" id="tipologia" placeholder="-- Tipología --">
                <mat-option *ngFor="let tipologia of tipologias" [value]="tipologia.id">
                  {{ tipologia.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('tipologia', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="agrupacionMaterial">Agrupacion Material <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination class="lib-ge-select" [defaultFilter]=defaultFilter
              [endPointFilter]="agrupacionMaterialEndpoint" objQueryName="descripcion"
              placeHolder="-- Agrupacion Material --" descripcion="descripcion"
              [formControlNameSelect]="getFormControl('agrupacionMaterial')"
              [modelSelected]="this.formGroup.value.grupoEnvase" [customClass]="rightHolder('agrupacionMaterial')">
            </lib-ge-select-pagination>
            <mat-error *ngIf="controlHasError('agrupacionMaterial', 'required')">Campo obligatorio</mat-error>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="valor">€/Kg <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="number" matInput formControlName="valor" id="valor" placeholder="€/Kg" value="" type="text"
              appTreeDigitDecimalNumber>
              <mat-error *ngIf="controlHasError('valor', 'required')">Campo obligatorio</mat-error>

            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="peligrosidad">Peligrosidad <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="peligrosidad" id="peligrosidad" placeholder="-- Seleccione una opción --">
                <mat-option [value]="true">Si</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('peligrosidad', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1"></div>
        <div class="col-sm flex-grow-1"></div>
      </div>
    </form>

  </div>
  <div class="modal-footer actions">
    <div class="note"><span *ngIf="editMode">* Campos Obligatorios</span></div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button *ngIf="!editMode" type="button" class="btn btn-primary" data-dismiss="modal" (click)="habilitarEdicion();"
        aria-label="Guardar">Editar</button>
      <button *ngIf="editMode" type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
