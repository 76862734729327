import { Injectable } from "@angular/core";
import { KeycloakRoleModel } from "../keycloak-role.model";
import { Adapter } from "./keycloak.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakRoleModelAdapter implements Adapter<KeycloakRoleModel> {

    constructor() { }
    adapt(item: any): KeycloakRoleModel {
        let model:KeycloakRoleModel = {            
            id: item.id,
            clientRole: item.clientRole,
            containerId: item.containerId,
            description: item.description,
            name: item.name
        };
        return model;
    }
}