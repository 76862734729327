<div *ngIf="group">
    <h1 mat-dialog-title>
        <mat-icon class="icon" style="vertical-align: middle;">group</mat-icon>
            {{data.title | translate}}
    </h1>

    <div mat-dialog-content>
        <div>{{'KAM-GROUPS-TAB.NAME_COLUMN' | translate}}: {{group.name}}</div>
        <ul>
            <li *ngIf="group.attributesMap">{{'KAM-MANAGE-GROUPS.STEP4.ATTRIBUTE_LABEL' | translate}}</li>
            <ul *ngIf="group.attributesMap">
                <li *ngFor="let customAttribute of group.attributesMap | keyvalue">
                    {{customAttribute.key}}: {{customAttribute.value}}
                </li>
            </ul>    
            <li *ngIf="groupRoles.length > 0">{{'KAM-MANAGE-GROUPS.STEP4.ROLE_LABEL' | translate}}</li>
            <ul *ngIf="groupRoles.length > 0">        
                <li *ngFor="let role of groupRoles">
                    {{role}}
                </li>
            </ul>
        </ul>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button color="primary" [mat-dialog-close]="">{{'KAM-GENERAL-DIALOG.CLOSE'| translate}}</button>
    </div>
</div>


