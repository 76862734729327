
export const database = {
    roles:[
        'building_manager',
        'home',
        'app.grafana.access',
    ],

    users: [
        {
            uid: "1",
            mail: "john.doe@boustead.sh",
            userPassword: "admin",
            userName: "john.doe",
            givenName: "fakeJohn",
            surname: "Doe",
            sites: [
                {
                    uid: "1",
                    name: "GDL",
                    description: "Guadalajara",
                }
            ],
            roles: [
                {
                    uid: "ADMIN",
                    name: "ADMIN",
                    cn: "ADMIN",
                }
            ]
        }
    ],
    loggedUser: 0
}

