import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactoControllerService, DireccionControllerService, DireccionDTO, PoseedorControllerService, PoseedorDTO } from 'api-rest';
import { HoldersAddressDialogComponent } from '../holders-address-dialog/holders-address-dialog.component';
import swal from 'sweetalert2'
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';

@Component({
  selector: 'lib-holders-detail-address',
  templateUrl: './holders-detail-address.component.html',
  styleUrls: ['./holders-detail-address.component.scss']
})
export class HoldersDetailAddressComponent implements OnChanges {
  @Input() poseedorInfo!: PoseedorDTO;
  @Output() public refrescarPoseedorEvent = new EventEmitter<any>();

  direcciones: DireccionDTO[] = [];

  gridArray: any[] = [];

  headArray: any = [
    {
      Head: 'Requerida',
      FieldName: 'requerido',
      check: true,
      disabled: true,
      width: '1'
    },
    {
      Head: 'Tipo direccion',
      FieldName: 'tipoDireccion',
      Attribute: 'descripcion',
      width: '2'
    },
    {
      Head: 'Dirección',
      FieldName: 'fullAddress',
      Tooltip: true,
      width: '8',
      Maxtext: 50
    },
    {
      'Head': 'Acciones', 'FieldName': 'actions', 'buttons':
        [
          { 'nameButton': "view", "icon": "open_in_new", 'toolTip': 'Detalle', 'show': { 'params': 'canAccessView', 'accion': 'view' } },
          { 'nameButton': "delete", "icon": "delete", 'toolTip': 'Borrar', 'show': { 'params': 'canAccessDelete', 'accion': 'delete' } },
        ], width: '2'
    },
  ];

  constructor(public dialog: MatDialog,
    private spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    private direccionControllerSrv: DireccionControllerService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.poseedorInfo?.domicilios) {
      this.direcciones = this.poseedorInfo?.domicilios;
      this.gridArray = this.canAccessAll(this.direcciones);
    }
  }
  canAccessAll(list: any) {
    const resultListShow = this.authService.can('poseedores-direcciones', 'access');
    const resultDelete = this.authService.can('poseedores-direcciones', 'delete');

    const listPermission = list.map((objeto: any) => {
      const noRequerido = (!objeto.requerido || objeto.requerido === null);
      return {
        ...objeto,
        canAccessView: resultListShow,
        canAccessDelete: resultDelete && noRequerido
      };

    });

    return listPermission;
  }
  action(item: any) {
    if (item.nameButton == 'view') {
      this.abrirModalDireccion(item)
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }

  abrirModalDireccion(element?: any): void {
    const dialogRef = this.dialog.open(
      HoldersAddressDialogComponent,
      {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          direccion: element,
          poseedor: this.poseedorInfo
        },
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.refrescarPoseedorEvent.emit(true);
        }
      }
    );
  }

  confirmacionEliminar(element?: DireccionDTO): void {
    swal.fire({
      text: '¿Desea eliminar la dirección?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarDireccion(element);
      }

    });
  }

  eliminarDireccion(element?: DireccionDTO) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.direccionControllerSrv.borrarDireccion(element.id)
        .subscribe({
          next: response => {
            this.spinnerSrv.loadingHide();
            this.refrescarPoseedorEvent.emit(true);
            this.snackBarSrv.showSnackBar('La dirección se ha eliminado correctamente', 'success');
          },
          error: err => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar('Ha ocurrido un error al eliminar la dirección', 'error');
            console.log(err);
          }
        });
    }
  }

  canAccessCreateDireccion(): boolean {
    return this.authService.can('poseedores-direcciones', 'create');
  }
}
