/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentoDTO } from './documentoDTO';


export interface SolicitudFacturaExportacionDTO { 
    id?: number;
    importeDeposito?: number;
    numFacturaDeposito?: number;
    documento?: DocumentoDTO;
    solicitud?: number;
    tipoSolicitud?: SolicitudFacturaExportacionDTO.TipoSolicitudEnum;
}
export namespace SolicitudFacturaExportacionDTO {
    export type TipoSolicitudEnum = 'REUTILIZACION' | 'RETIRADA';
    export const TipoSolicitudEnum = {
        Reutilizacion: 'REUTILIZACION' as TipoSolicitudEnum,
        Retirada: 'RETIRADA' as TipoSolicitudEnum
    };
}


