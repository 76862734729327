<h1 mat-dialog-title>
    <mat-icon class="icon" style="vertical-align: middle;">{{data.icon}}</mat-icon>
        {{data.title | translate:data.parameters}}
</h1>

<div mat-dialog-content>
    {{data.question | translate:data.parameters }}    
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button
        (click)="dialogRef.close(false)">
            {{'KAM-GENERAL-DIALOG.CANCEL' | translate}} 
    </button>
    <button mat-raised-button color="primary"
        (click)="dialogRef.close(true)">
            {{'KAM-GENERAL-DIALOG.ACCEPT' | translate}} 
    </button>
</div>