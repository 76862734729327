import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  AportacionDIEstadoControllerService,
  OperacionGestionControllerService,
  AportacionDIEstadoDTO,
  CodigoOperacionFilterDTO,
  AportacionDiAdheridoControllerService,
  SolicitudGestoresDTO,
  SolicitudAdheridoPrevisionDTO,
  AdheridoControllerService,
  BusquedaAdheridoDTO,
  AportacionDIAdheridoPayloadDTO,
  AportacionDIAdheridoDTO,
} from 'api-rest';
import { Observable, Subscription, tap } from 'rxjs';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'app-holders-contributions-dis-dialog',
  templateUrl: './contributions-dis-dialog.component.html',
  styleUrls: ['./contributions-dis-dialog.component.scss'],
})
export class ContributionsDisDialogComponent implements OnInit {
  estadoArray: AportacionDIEstadoDTO[] = [];
  codigoLerArray: any[] = [];
  documentoArray: any[] = [];
  residuoArray: any[] = [];
  descripcionEnvaseArray: any[] = [];
  gestionArray: any[] = [];
  adheridoArray: any[] = [];
  nimaGestorArray: any[] = [];
  entityRoles: any[] = [];

  modelForm!: FormGroup;
  subscriptions: Subscription[] = [];
  roleEntities: any[] = [];
  documento: File | undefined;
  titulo: any;
  edicion = false;
  alta = false;
  estado: Number = 1;

  pageNumber: number = 0;
  pageSize: number = 100;
  recordsTotal: number = 0;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private snackbarSrv: SnackbarService,
    private dialogRef: MatDialogRef<ContributionsDisDialogComponent>,
    private estadoAportacionesService: AportacionDIEstadoControllerService,
    private aportacionesDiService: AportacionDiAdheridoControllerService,
    private adheridoControllerService: AdheridoControllerService,
    private adheridosService: AdheridoControllerService,
    private downloadService: DownloadService,
    private documentService: DocumentService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService | any,
    @Inject(MAT_DIALOG_DATA) public aportacion: AportacionDIAdheridoDTO | any,
    private operacionGestionControllerService: OperacionGestionControllerService
  ) {
    this.inicializarForm();
  }

  ngOnInit() {
    this.spinnerSrv.loadingShow();
    let peticionInicial = this.buscarInicialAdherido();
    peticionInicial.subscribe({
      next: () => {
        this.cargaInicial();
        this.spinnerSrv.loadingHide();
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  inicializarForm() {
    this.aportacion.fechaAceptacion =
      this.aportacion.fechaAceptacion ?? new Date().toISOString();
    this.modelForm = this.formBuilder.group({
      id: [{ value: this.aportacion?.id }],
      documentoIdentificacion: [
        { value: this.aportacion?.documentoIdentificacion, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      fechaAceptacion: [
        { value: this.aportacion?.fechaAceptacion, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      estado: [{ value: this.aportacion?.estado, disabled: false }],

      adherido: [
        { value: this.aportacion?.adherido, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      nimaGestor: [
        { value: this.aportacion?.gestorResiduo, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],

      documento: [{ value: this.aportacion?.documento, disabled: false }],

      codigoLer: [
        { value: this.aportacion?.codigoLer, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      gestorResiduo: [
        { value: this.aportacion?.gestorResiduo, disabled: false },
      ],
      operacionGestion: [
        { value: this.aportacion?.operacionGestion, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],

      unidadesGestionadas: [
        { value: this.aportacion?.unidadesGestionadas, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });

    const sub = this.modelForm.controls[
      'fechaAceptacion'
    ].valueChanges.subscribe((val) => {
      this.buscarNimaGestor(val, this.modelForm.value?.adherido);
      this.buscaCodigoLer(
        val,
        this.modelForm.value?.adherido,
        this.modelForm.value?.nimaGestor
      );
      this.gestionArray = [];
    });
    const sub2 = this.modelForm.controls['adherido'].valueChanges.subscribe(
      (val) => {
        this.buscarNimaGestor(this.modelForm.value?.fechaAceptacion, val);
        this.buscaCodigoLer(
          this.modelForm.value?.fechaAceptacion,
          val,
          this.modelForm.value?.nimaGestor
        );
      }
    );
    const sub3 = this.modelForm.controls['nimaGestor'].valueChanges.subscribe(
      (val) => {
        this.buscaCodigoLer(
          this.modelForm.value?.fechaAceptacion,
          this.modelForm.value?.adherido,
          val
        );
      }
    );
    this.subscriptions.push(sub);
    this.subscriptions.push(sub2);
    this.subscriptions.push(sub3);

    this.valueChangeCodigoLer();
  }
  cargaInicial() {
    this.roleEntities = (this.authService.user as any).roleEntities;
    if (this.aportacion?.id) {
      this.titulo = 'Editar aportación de DI';
      this.buscarOperacionesGestion();
      this.edicion = true;
    } else {
      this.titulo = 'Alta de aportación de DI';
      this.edicion = false;
    }

    if (!this.canSaveAll()) {
      this.modelForm.disable();
    }

    this.estadoAportacionesService
      .findAll14()
      .subscribe((estados) => (this.estadoArray = estados));

    this.getAdheridos();

    this.buscarNimaGestor(
      this.modelForm.value?.fechaAceptacion,
      this.aportacion.adherido
    );
    this.buscaCodigoLer(
      this.modelForm.value?.fechaAceptacion,
      this.aportacion.adherido,
      this.aportacion.gestorResiduo
    );

    this.updateDisableField('documentoIdentificacion');
    this.updateDisableField('fechaAceptacion');
    this.updateDisableField('unidadGestionada');
  }

  buscarInicialAdherido(): Observable<any> {
    return this.adheridoControllerService
      .obtenerAdheridoPorId(this.aportacion?.adherido?.id)
      .pipe(tap((res) => (this.aportacion.adherido = res)));
  }

  updateDisableField(field: any): void {
    if (this.aportacion.estado) {
      if (!this.canAccessEditEstado()) {
        this.modelForm.get(field)?.disable();
      }
    }
  }

  buscarNimaGestor(fechaAceptacion: any, adherido: any) {
    const filtroNimaGestor: SolicitudGestoresDTO = {
      fechaAceptacion: fechaAceptacion,
      adherido: {
        id: adherido.id,
      },
    };
    this.spinnerSrv.loadingShow();
    this.aportacionesDiService.gestoresDelAdherido(filtroNimaGestor).subscribe({
      next: (res) => {
        this.nimaGestorArray = res;
        this.setItemSelected('nimaGestor', 'id', this.nimaGestorArray);
        this.spinnerSrv.loadingHide();
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  buscarAherido(fechaAceptacion: any, nimaGestor: any) {
    const filtroAdherido: SolicitudAdheridoPrevisionDTO = {
      fechaAceptacion: fechaAceptacion,
      gestor: nimaGestor,
    };
    this.spinnerSrv.loadingShow();
    this.aportacionesDiService
      .getAdheridosEnPrevision(filtroAdherido)
      .subscribe({
        next: (res) => {
          this.adheridoArray = res;
          this.setItemSelected('adherido', 'id', this.adheridoArray);
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  getAdheridos() {
    if (this.authService.user?.roleEntities?.length > 0) {
      const adheridosIds = this.authService.user?.roleEntities
        .filter((adheridoRol: any) => adheridoRol.type === 'ADHERIDO')
        .map((adheridoRol: any) => adheridoRol.entity as number);
      const filter: BusquedaAdheridoDTO = {
        id: adheridosIds,
        estado: [6],
        declaracionFacturada: true,
      };

      this.spinnerSrv.loadingShow();
      this.adheridoControllerService
        .listarAdheridos({ page: 0, size: 100 }, filter)
        .subscribe({
          next: (response: any) => {
            this.adheridoArray = response?.content;
            this.setItemSelected('adherido', 'id', this.adheridoArray);
            this.spinnerSrv.loadingHide();
          },
          error: (err) => {
            console.log(err);
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  buscaCodigoLer(fechaAceptacion: any, adherido: any, gestor: any) {
    if (fechaAceptacion && adherido && gestor) {
      this.spinnerSrv.loadingShow();
      const anoAceptacion = new Date(fechaAceptacion).getFullYear();
      this.adheridosService
        .codigoLer(adherido.id, gestor.id, anoAceptacion)
        .subscribe({
          next: (res) => {
            this.codigoLerArray = res;
            this.setItemSelected('codigoLer', 'id', this.codigoLerArray);
            this.spinnerSrv.loadingHide();
          },
          error: () => {
            this.spinnerSrv.loadingHide();
          },
        });
    } else {
      this.codigoLerArray = [];
    }
  }

  valueChangeCodigoLer() {
    this.modelForm.get('codigoLer')?.valueChanges.subscribe((value: any) => {
      if (value) {
        this.buscarOperacionesGestion();
      } else {
        this.gestionArray = [];
      }
    });
  }

  buscarOperacionesGestion() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.operacionGestionControllerService
      .findByCodigoOperacion(backendFilter, { page: page, size: size })
      .subscribe({
        next: (res) => {
          if (res) {
            this.gestionArray = res?.datos ?? [];
            this.setItemSelected('operacionGestion', 'id', this.gestionArray);
            this.updateDisableField('operacionGestion');
          }
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  private getBackendFilter() {
    const backendFilter: CodigoOperacionFilterDTO = {
      codigoLer: [this.modelForm.get('codigoLer')?.value?.id],
      poseedores: true
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  private setItemSelected(
    controlName: string,
    propertyName: string,
    items: any[]
  ) {
    let selectedItem = null;
    if (this.modelForm.value[controlName]) {
      if (
        typeof this.modelForm.value[controlName] == 'object' &&
        this.modelForm.value[controlName][propertyName]
      ) {
        selectedItem = items.find(
          (item) =>
            this.modelForm.value[controlName][propertyName] ==
            item[propertyName]
        );
      } else {
        selectedItem = items.find(
          (item) => this.modelForm.value[controlName] == item[propertyName]
        );
      }
    }
    this.modelForm.controls[controlName].patchValue(selectedItem, {
      emitEvent: false,
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveData(estadoId: any) {
    this.modelForm.markAllAsTouched();
    this.modelForm.updateValueAndValidity();

    if (this.aportacion?.documento == null) {
      if (this.documento == undefined || this.documento == null) {
        this.snackbarSrv.showSnackBar('Adjunte documento', 'error');
        return;
      }
    }

    if (this.modelForm.valid) {
      const formValue = this.modelForm.value;
      const aportacionPayload: AportacionDIAdheridoPayloadDTO = formValue;
      aportacionPayload.gestorResiduo = formValue.nimaGestor;
      if (aportacionPayload.estado !== undefined && estadoId !== null) {
        aportacionPayload.estado!.id = estadoId;
      }
      let request, successMsg: string, errorMsg: string;
      if (this.aportacion?.id) {
        request = this.aportacionesDiService.editByIdAportacionDIAdherido(
          this.aportacion.id,
          aportacionPayload,
          this.documento
        );
        successMsg = 'La aportación DI se ha editado correctamente';
        errorMsg = 'Ha ocurrido un error al editar la aportación DI';
      } else {
        if (!this.documento) {
          this.snackbarSrv.showSnackBar(
            'Debe seleccionar un documento',
            'error'
          );
          return;
        }

        request = this.aportacionesDiService.createAportacionDIAdherido(
          aportacionPayload,
          this.documento
        );
        successMsg = 'La aportación DI se ha creado correctamente';
        errorMsg = 'Ha ocurrido un error al crear la nueva aportación DI';
      }

      this.spinnerSrv.loadingShow();
      request.subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.dialogRef.close(true);
          this.snackbarSrv.showSnackBar(successMsg, 'success');
        },
        error: () => {
          this.aportacion.estado.id = 1;
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  showClearButton(formName: string): boolean {
    return this.modelForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.modelForm.get(formName)?.setValue(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.modelForm.controls[controlName].hasError(errorName) &&
      this.modelForm.controls[controlName].touched
    );
  }

  attachAdjunto(archivo: File | null): void {
    if (archivo) {
      if (archivo.size === 0) {
        this.snackbarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackbarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.documento = archivo;
      }
    }
  }

  descargar(documento: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(documento.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackbarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  canAccessEditEstado(): boolean {
    return this.aportacion?.estado?.id === 1;
  }

  canAccessEditByRol(): boolean {
    //this.authService.can('poseedores-di-detalle', 'access-update');
    return true;
  }

  canSaveAll(): boolean {
    if (this.canAccessEditByRol() && this.canAccessEditEstado()) {
      if (
        this.edicion &&
        this.aportacion.creadoPor == this.authService.getCurrentUserMail()
      ) {
        return true;
      } else if (!this.edicion) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
