<ng-select appendTo="body" #selectElement class="custom" [class]="customClass" [multiple]="multiple"
  [formControl]="formControlNameSelect" [placeholder]="placeHolder" (change)="change($event)"
  (scrollToEnd)="getNextBatchFiltered()" [typeahead]=input$>
  <ng-template ng-header-tmp>
    <label class="form-control">Resultados: {{ filteredModelList.length }} de
      {{totalElements}}</label>
  </ng-template>
  <ng-container>
    <ng-option *ngFor="let model of filteredModelList" [value]="model">{{ getItemDescription(model) }}</ng-option>
  </ng-container>

  <ng-template ng-footer-tmp>
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Buscar" #box />
      <button type="button" class="btn btn-primary btn-search-lib" (click)="filterSearch(box.value)"
        style="height: auto"><span class="material-icons">search</span></button>
    </div>
    <div class="input-group">
      <label class="form-control">Limpiar</label>
      <button type="button" class="btn btn-outline-primary btn-limpiar" (click)="clearFilter()"><span
          class="material-icons">close</span></button>
    </div>
  </ng-template>
</ng-select>
