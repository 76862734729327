import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
 
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeycloakGroupModel } from '../model/keycloak-group.model';
import { KeycloakRoleModel } from '../model/keycloak-role.model';
import { KeycloakUserCredentials, KeycloakUserModel } from '../model/keycloak-user.model';
import { KeycloakRoleModelAdapter } from '../model/adapters/keycloak-role.model.adapter';
import { KeycloakUserModelAdapter } from '../model/adapters/keycloak-user.model.adapter';
import { KeycloakGroupModelAdapter } from '../model/adapters/keycloak-group.model.adapter';
import { KamUserFilter } from './kam-user.filter';


@Injectable({
  providedIn: 'root'
})
export class KamUserService {
 

  userData: BehaviorSubject<KeycloakUserModel|undefined> = new BehaviorSubject<KeycloakUserModel|undefined>(undefined);
  userGroups: BehaviorSubject<KeycloakGroupModel[]|undefined> = new BehaviorSubject<KeycloakGroupModel[]|undefined>(undefined);
  userDataAttributesMap: BehaviorSubject<Map<string, string[]>|undefined> = new BehaviorSubject<Map<string, string[]>|undefined>(undefined);
  userRoles: BehaviorSubject<KeycloakRoleModel[]> = new BehaviorSubject<KeycloakRoleModel[]>([]);


  constructor(private http: HttpClient,
    @Inject('environment') private environment:any,
    private keycloakUserModelAdapter: KeycloakUserModelAdapter,
    private keycloakRoleModelAdapter: KeycloakRoleModelAdapter,
    private keycloakGroupModelAdapter: KeycloakGroupModelAdapter,
    ) { }

  /** Datat  share */
  userDataSubscription () :Observable<KeycloakUserModel|undefined>{
    return this.userData.asObservable();
  }

  setUserData(user:KeycloakUserModel|undefined){
      this.userData.next(user)
  }

  userDataCustomAttributesSubscription():Observable <Map<string, string[]>|undefined> {
    return this.userDataAttributesMap.asObservable()
  }

  setUserDataCustomAttributes(customAttributes:Map<string, string[]>|undefined){    
    this.userDataAttributesMap.next(customAttributes)
  }

  userGroupSubscription () :Observable<KeycloakGroupModel[]|undefined>{
    return this.userGroups.asObservable();
  }

  setUserGroup(groups: KeycloakGroupModel[]|undefined) {
    this.userGroups.next(groups)
  }

  userRolesSubscription () : Observable<KeycloakRoleModel[]|undefined>{
    return this.userRoles.asObservable();
  }

  setUserSelectedRoles(roles: KeycloakRoleModel[]) {
    this.userRoles.next(roles);
  }

/*** */
  getUsers(userFilter:KamUserFilter|undefined): Observable<Array<KeycloakUserModel>> {
    let params: HttpParams = new HttpParams();
    let first = userFilter?.limit! * userFilter?.offset!;

    if (userFilter){
      for (const [p, val] of Object.entries(userFilter)) {
        params = params.append(p,val)
      }
    }
    return this.http
      .get<KeycloakUserModel[]>(
        this.environment.keycloak.url + this.environment.kamUrlUsers + "/?max=" + userFilter?.limit + "&first=" + first
      )
      .pipe(map((response) => {
        let items: KeycloakUserModel[] = [];        
        response.forEach(element => {
          items.push(this.keycloakUserModelAdapter.adapt(element));
        });
        return items;
      }));
    }

  getUsersCount(): Observable<number> {
    return this.http
      .get<number>(
        this.environment.keycloak.url + this.environment.kamUrlUsersCount
      )
      .pipe(map((response) => {
        return response;
      }));
    }
  
  postUser(kamUser: KeycloakUserModel): Observable<void> {
    return this.http
      .post<void>(
        this.environment.keycloak.url + this.environment.kamUrlUsers,
        kamUser
      )
      .pipe(map(() => {
        return;
      }));
    }


  postUserRoles(userId: string, roles:KeycloakRoleModel[]): Observable<void> {
    return this.http
      .post<void>(
        this.environment.keycloak.url + this.environment.kamUrlUserRoles.replace("{{userId}}",userId),
        roles
      )
      .pipe(map(() => {
        return;
      }));
    }

  deleteUserRoles(userId: string, roles:KeycloakRoleModel[]): Observable<void> {
    return this.http
      .delete<void>(
        this.environment.keycloak.url + this.environment.kamUrlUserRoles.replace("{{userId}}",userId),
        {
          body:roles
        }
      )
      .pipe(map(() => {
        return;
      }));
    }


  putUser(kamUser: KeycloakUserModel): Observable<void> {
    return this.http
      .put<void>(
        this.environment.keycloak.url + this.environment.kamUrlUsers + "/" + kamUser.id, 
        kamUser
      )
      .pipe(map(() => {
        return;
      }));
  }

  deleteUser(userId: string): Observable<void> {
    return this.http
      .delete<void>(
        this.environment.keycloak.url + this.environment.kamUrlUsers + "/" + userId 
      )
      .pipe(map(() => {
        return;
      }));
    }

  resetPassword(kamUserId: string, credential: KeycloakUserCredentials): Observable<void> {
    return this.http
      .put<void>(
        this.environment.keycloak.url + this.environment.kamUrlUsers + "/" + kamUserId + "/reset-password", 
        credential
      )
      .pipe(map(() => {
        return;
      }));
    }


  getUserRoles(userId: string): Observable<Array<KeycloakRoleModel>> {
    return this.http
      .get<KeycloakRoleModel[]>(
        this.environment.keycloak.url + this.environment.kamUrlUserRoles.replace("{{userId}}",userId)
      )
      .pipe(map((response) => {
        let items: KeycloakRoleModel[] = [];        
        response.forEach(element => {
          const role = this.keycloakRoleModelAdapter.adapt(element);
          if (role.description){
            if (!role.description.startsWith('$')){
              items.push(role);
            }
          }else{
            items.push(role);
          }
        });
        return items;
      }));
    }

  getUserGroups(userId: string): Observable<Array<KeycloakGroupModel>> {
    return this.http
      .get<KeycloakGroupModel[]>(
        this.environment.keycloak.url + this.environment.kamUrlUserGroups.replace("{{userId}}",userId)
      )
      .pipe(map((response) => {
        let items: KeycloakGroupModel[] = [];        
        response.forEach(element => {
          items.push(this.keycloakGroupModelAdapter.adapt(element));
        });
        return items;
      }));
    }   

    putUserGroup(userId: string, groupId: string): Observable<void> {
      const addGroup = {
        realm: this.environment.keycloak.realm,
        groupId,
        userId
      }
      return this.http
        .put<void>(
          this.environment.keycloak.url + this.environment.kamUrlUserGroups.replace("{{userId}}",userId) + "/" + groupId, 
          addGroup
        )
        .pipe(map(() => {
          return;
        }));
    }  
  
    deleteUserGroup(userId: string, groupId: string): Observable<void> {
      return this.http
        .delete<void>(
          this.environment.keycloak.url + this.environment.kamUrlUserGroups.replace("{{userId}}",userId) + "/" + groupId          
        )
        .pipe(map(() => {
          return;
        }));
    }  
}
