/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AgrupacionMaterialPayloadDTO } from './agrupacionMaterialPayloadDTO';
import { GrupoEnvaseDTO } from './grupoEnvaseDTO';
import { CategoriaEnvaseDTO } from './categoriaEnvaseDTO';


export interface TarifaAdheridoUnUsoPayloadDTO { 
    id?: number;
    anio: number;
    peligrosidad: boolean;
    valor: number;
    tipologia: TarifaAdheridoUnUsoPayloadDTO.TipologiaEnum;
    categoria: CategoriaEnvaseDTO;
    grupoEnvase: GrupoEnvaseDTO;
    agrupacionMaterial: AgrupacionMaterialPayloadDTO;
}
export namespace TarifaAdheridoUnUsoPayloadDTO {
    export type TipologiaEnum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const TipologiaEnum = {
        Primario: 'PRIMARIO' as TipologiaEnum,
        Secundario: 'SECUNDARIO' as TipologiaEnum,
        Terciario: 'TERCIARIO' as TipologiaEnum
    };
}


