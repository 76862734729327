import { Injectable } from "@angular/core";
import { KeycloakResourceServerSettingsModel } from "../keycloak-respource-server-settings.model";
import { KeycloakScopeModelAdapter } from "./keycloak-scope.model.adapter";
import { KeycloakResourceModelAdapter } from "./keycloak-resource.model.adapter";
import { Adapter } from "./keycloak.adapter";
import { KeycloakPolicyModelAdapter } from "./keycloak-policy.model.adapter";

@Injectable({
    providedIn: "root",
})
export class KeycloakResourceServerSettingsModelAdapter implements Adapter<KeycloakResourceServerSettingsModel> {

    constructor(private keycloakScopeModelAdapter: KeycloakScopeModelAdapter,
                private keycloakResourceModelAdapter: KeycloakResourceModelAdapter,
                private keycloakPolicyModelAdapter: KeycloakPolicyModelAdapter,
                ) { }
    adapt(item: any): KeycloakResourceServerSettingsModel {
        let model:KeycloakResourceServerSettingsModel = {
            allowRemoteResourceManagement: item.allowRemoteResourceManagement, 
            resources: [],
            policies: [],
            scopes: [],
        };
        if (item.resources){
            item.resources.forEach((element: any) => {
                model.resources.push(this.keycloakResourceModelAdapter.adapt(element))
            });
        }
        if (item.policies){
            item.policies.forEach((element: any) => {
                model.policies.push(this.keycloakPolicyModelAdapter.adapt(element))
            });
        }
        if (item.scopes){
            item.scopes.forEach((element: any) => {
                model.scopes.push(this.keycloakScopeModelAdapter.adapt(element))
            });
        }
        return model;
    }
}