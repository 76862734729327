import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import {
  DeclaracionCentroControllerService,
  DeclaracionCentroDTO,
  SectorDTO,
  SectorControllerService,
  DeclaracionAdheridoDTO,
  DeclaracionCentroFilterDTO,
  SectorFilterDTO,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';

@Component({
  selector: 'lib-package-center-sectors',
  templateUrl: './package-center-sectors.component.html',
  styleUrls: ['./package-center-sectors.component.scss'],
})
export class PackageCenterSectorsComponent implements OnChanges {
  sectoresFormGroup!: FormGroup;

  @Input() centro!: DeclaracionCentroDTO;
  sectores!: any[];
  @Output() actualizarDeclaracion: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() declaracionAdherido!: DeclaracionAdheridoDTO;

  constructor(
    private formBuilder: FormBuilder,
    public spinnerSrv: SpinnerService,
    public snackBarSrv: SnackbarService,
    private declaracionCentroControllerSrv: DeclaracionCentroControllerService,
    private sectorControllerSrv: SectorControllerService,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.centro && this.declaracionAdherido) {
      let categoria: string = this.centro.categoriaEnvase?.descripcion
        ? this.centro.categoriaEnvase?.descripcion.toLowerCase()
        : '';

      //TODO: Revisar porque hay una categoría que es doméstica. O sobra esa categroría o hay que cambiar esto,
      //porque estoy presuponiendo que lo que no es industrial es comercial
      let filtro: SectorFilterDTO = {
        categoria: [
          categoria.includes('industrial') ? 'Industrial' : 'Comercial',
        ],
      };
      this.spinnerSrv.loadingShow();
      this.sectorControllerSrv
        .listarSectores(filtro, { page: 0, size: 500, sort: [] })
        .subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            this.sectores = response?.datos!;
            this.sectores.forEach((sector) => {
              let match = this.centro.sectores?.find((x) => x.id === sector.id);
              sector.check = match ? true : false;
            });
            this.sectoresFormGroup = this.formBuilder.group(
              this.crearCamposForm()
            );
            if(!this.authService.can('dec_envases', 'update') || !(this.declaracionAdherido?.estadoDeclaracion?.id === 0)){
              this.sectoresFormGroup.disable();
            }
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al cargar los datos de los sectores',
              'error'
            );
          },
        });
    }
  }

  crearCamposForm(): any {
    let camposFormulario: any = {};
    this.sectores.forEach((el) => {
      camposFormulario[el.id] = { value: el.check, disabled:false};
    });
    return camposFormulario;
  }

  obtenerFormularioChecked() {
    const sectoresMarcados: any[] = [];
    Object.keys(this.sectoresFormGroup.controls).forEach((key) => {
      const control = this.sectoresFormGroup.get(key);
      if (control && control.value === true) {
        const sector = this.sectores.find((el) => el.id == key);
        if (sector) {
          sectoresMarcados.push(sector);
        }
      }
    });
    return sectoresMarcados;
  }
  guardarSectores(): void {
    const arrayCheck = this.obtenerFormularioChecked();

    this.spinnerSrv.loadingShow();
    this.declaracionCentroControllerSrv
      .guardarSectores(this.centro?.id!, arrayCheck)
      .subscribe({
        next: (responses) => {
          this.snackBarSrv.showSnackBar(
            'Se han guardado los sectores correctamente',
            'success'
          );
          this.actualizarDeclaracion.emit(true);
          this.spinnerSrv.loadingHide();
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al guardar los sectores',
            'error'
          );
        },
      });
  }

  canAccessSectoresEdit(): boolean {
    return this.authService.can('dec_envases-sectores', 'update');
  }

  canAccessEditAllGlobal(): boolean {
    return (
      this.authService.can('dec_envases', 'update') &&
      this.declaracionAdherido?.estadoDeclaracion?.id == 0
    );
  }
}
