import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeycloakClientModel } from '../../../model/keycloak-client.model';
import { KeycloakScopeModel } from '../../..//model/keycloak-scope.model';
import { KamResourceServerServices } from '../../../services/kam-resource-server.services';
import { Observable } from 'rxjs';

@Component({
  selector: 'keycloak-auth-manager-kam-manage-scope',
  templateUrl: './kam-manage-scope.component.html',
  styleUrls: ['./kam-manage-scope.component.css']
})
export class KamManageScopeComponent implements OnInit {

  formGroup: FormGroup | undefined;
  scopeNameCtrl = new FormControl('', [Validators.required]);
  update: boolean = false;

  constructor(public dialogRef: MatDialogRef<KamManageScopeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KamManageScopeData,
    private kamResourceServerServices: KamResourceServerServices,
    ) { }

  ngOnInit(): void {
    if (this.data.scope)
      this.update=true;
    this.buildForm();
  }

  private buildForm() {
    if (this.update){
      this.scopeNameCtrl.setValue(this.data.scope.name);
    }
    this.formGroup = new FormGroup({
      scopeName: this.scopeNameCtrl,
    });      
  }

  scopeCancel(){
    this.dialogRef.close(undefined);
  }

  scopeAccept(){
    let scope: KeycloakScopeModel = {
      name: this.scopeNameCtrl.value === null ? "":this.scopeNameCtrl.value,
    }
    let request:Observable<KeycloakScopeModel|void>;
    if (this.update){
      scope.id=this.data.scope.id;
      request =  this.kamResourceServerServices.putScope(this.data.resourceServer.id, scope);
    }else{
      request = this.kamResourceServerServices.postScope(this.data.resourceServer.id, scope)
    }

    request.subscribe({
      next: (v) => {
        this.dialogRef.close(true);
      },
      error: (e) => console.error(e),
      complete: () => {console.info('complete') }
    });
}

}


export interface KamManageScopeData {
  resourceServer: KeycloakClientModel,
  scope:KeycloakScopeModel,
  icon: string
  title: string
}
