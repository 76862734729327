import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AcondicionamientoControllerService } from './api/acondicionamientoController.service';
import { FacturaPtoRecogidaControllerService } from './api/facturaPtoRecogidaController.service';
import { GestorPtoRecogidaCodigoControllerService } from './api/gestorPtoRecogidaCodigoController.service';
import { GestorPtoRecogidaControllerService } from './api/gestorPtoRecogidaController.service';
import { GestorPtoRecogidaEstadoControllerService } from './api/gestorPtoRecogidaEstadoController.service';
import { HelloWorldControllerService } from './api/helloWorldController.service';
import { OperacionGestionControllerService } from './api/operacionGestionController.service';
import { OperacionesTratamientoControllerService } from './api/operacionesTratamientoController.service';
import { PtoRecogidaCodigoControllerService } from './api/ptoRecogidaCodigoController.service';
import { PtoRecogidaEstadoControllerService } from './api/ptoRecogidaEstadoController.service';
import { PtoRecogidaGestorControllerService } from './api/ptoRecogidaGestorController.service';
import { PuntoRecogidaControllerService } from './api/puntoRecogidaController.service';
import { SolicitudControllerService } from './api/solicitudController.service';
import { SolicitudEstadoControllerService } from './api/solicitudEstadoController.service';
import { TarifaPtoRecogidaControllerService } from './api/tarifaPtoRecogidaController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AcondicionamientoControllerService,
    FacturaPtoRecogidaControllerService,
    GestorPtoRecogidaCodigoControllerService,
    GestorPtoRecogidaControllerService,
    GestorPtoRecogidaEstadoControllerService,
    HelloWorldControllerService,
    OperacionGestionControllerService,
    OperacionesTratamientoControllerService,
    PtoRecogidaCodigoControllerService,
    PtoRecogidaEstadoControllerService,
    PtoRecogidaGestorControllerService,
    PuntoRecogidaControllerService,
    SolicitudControllerService,
    SolicitudEstadoControllerService,
    TarifaPtoRecogidaControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
