<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>

<div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">

    <!-- Formulario de tarifa abierta-->
    <form class="form-modal py-3  flex-container" [formGroup]="modelForm">
      <div class="form-row">
        <div class="form-item ">
          <label for="anio">Año <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="anio" id="anio" placeholder="Año" matInput type="number" [min]="0">
            <mat-error *ngIf="controlHasError('anio', 'required')">Campo obligatorio</mat-error>
            <mat-error *ngIf="modelForm.get('anio')?.invalid && modelForm.get('anio')?.touched">Año inválido (1900
              a 2500)
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-item">
          <label for="categoria">Categoría <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="categoria" id="categoria" placeholder="--Seleccione una opción --">
              <mat-option *ngFor="let categoria of categorias" [value]="categoria.id">
                {{ categoria.descripcion }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('categoria', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="form-item" style="width: 28%;">
          <label for="tipoEnvase">Tipo de envase <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination (selectedModel)="selectedModel('tipoEnvase')"
              [endPointFilter]="tipoEnvasesEndpoint" (filteredList)="filterList($event)" [updateList]="updateList"
              [customClass]="rightHolder('tipoEnvase')" [disabled]=false [defaultFilter]=defaultFilter
              [formControlNameSelect]="getFormControl('tipoEnvase')" descripcion="descripcion"
              [placeHolder]="'--Tipo de envase--'">
            </lib-ge-select-pagination>
            <mat-error *ngIf="controlHasError('tipoEnvase', 'required')">Campo obligatorio</mat-error>
          </div>
        </div>
      </div>
      <div class="form-row pt-3">
        <div class="form-item">
          <label for="grupoTipologia">Tipologia <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="tipologia" id="tipologia" placeholder="--Seleccione una opción --">
              <mat-option value="PRIMARIO">Primario</mat-option>
              <mat-option value="SECUNDARIO">Secundario</mat-option>
              <mat-option value="TERCIARIO">Terciario</mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('tipologia', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="form-item">
          <label for="grupoPeligrosidad">Peligrosidad <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="peligrosidad" id="peligrosidad" placeholder="-- Seleccione una opción --">
              <mat-option [value]="true">Si</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('peligrosidad', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="form-item">
          <label for="cuota">€/Ud <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="eurosUnidad" id="eurosUnidad" placeholder="€/Ud" matInput type="text"
              appTwoDigitDecimalNumber>
            <mat-error *ngIf="controlHasError('eurosUnidad', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="form-row pt-3">
        <div class="form-item">
          <label for="deposito">Depósito (€/Ud) <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="depositoUnidad" id="depositoUnidad" placeholder="Depósito (€/Ud)" matInput
              type="text" appTwoDigitDecimalNumber>
            <mat-error *ngIf="controlHasError('depositoUnidad', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>

    </form>


    <!-- FIN Formulario tarifa abierta -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
