
<empusa-app-header-classic [data]="headerData"></empusa-app-header-classic>
<div class="empusa-microapp-fondo  gba_mh_Home">
    <mat-selection-list class="gba_mh_SelectionModuleList" [multiple]="false">
        <mat-list-option class="gba_mh_ModuleListOption {{module.icon}}" *ngFor="let module of modules; let i = index"
            routerLinkActive="active" [routerLink]="['/'+module.path]">
            <div>
                <div class="gba_mh_ModuleTitle">
                    <span>{{'MAPP-HOME.'+module.name.toUpperCase() | translate}}</span>
                </div>
            </div>
        </mat-list-option>
            <a href="{{bmsGrafana}}" target="_blank" *ngIf="incluideGrafana">
                <mat-list-option class="gba_mh_ModuleListOption gba_mh_grafana" 
                    *ngIf="incluideGrafana">
                    <div>
                        <div class="gba_mh_ModuleTitle">
                            <span>BMS Dashboards</span>
                        </div>
                    </div>
                </mat-list-option>
            </a>
    </mat-selection-list>
</div>