<div *ngIf="group">
    <div>{{'KAM-MANAGE-GROUPS.STEP4.NAME_LABEL' | translate}}: {{group.name}}</div>
    <ul>
        <li *ngIf="group.attributesMap">{{'KAM-MANAGE-GROUPS.STEP4.ATTRIBUTE_LABEL' | translate}}</li>
        <ul *ngIf="group.attributesMap">
            <li *ngFor="let customAttribute of group.attributesMap | keyvalue">
                {{customAttribute.key}}: {{customAttribute.value}}
            </li>
        </ul>    
        <li *ngIf="groupRoles">{{'KAM-MANAGE-GROUPS.STEP4.ROLE_LABEL' | translate}}</li>
        <ul *ngIf="groupRoles">        
            <li *ngFor="let role of groupRoles">
                {{role.name}}
            </li>
        </ul>
    </ul>
</div>
