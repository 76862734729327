import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { KeycloakGroupService } from '../../../../services/kam-group.services';

@Component({
  selector: 'keycloak-auth-manager-kam-mg-step1',
  templateUrl: './kam-mg-step1.component.html',
  styleUrls: ['./kam-mg-step1.component.css']
})
export class KamMgStep1Component implements OnInit , OnDestroy{

  nameCtrl = new FormControl('', [Validators.required]);
  groupFormGroup: FormGroup | undefined;
  subscription: Subscription | undefined;

  constructor(
    private keycloakGroupService: KeycloakGroupService) { }

  ngOnInit(): void {
    this.buildForm();  
    this.groupFormGroup?.valueChanges.subscribe(val=>{
      const  completed = this.groupFormGroup?this.groupFormGroup?.valid:false
      this.keycloakGroupService.setStep1CompetedSubscription(completed)
    })

    this.subscription = this.keycloakGroupService.dataSubscription()?.subscribe(group=>{
      if (group){
        this.nameCtrl.setValue(group.name);
      }
    })

  }

  private buildForm() {
    this.groupFormGroup = new FormGroup({
      name: this.nameCtrl
    })
  }

  step1End(){
    const name = this.nameCtrl.value
    if (name)
      this.keycloakGroupService.dataSetGroupName(name)
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription?.unsubscribe()
  }

}
