import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[DirectDecimalNumber]',
})
export class DecimalNumberDirective {
  private regex: RegExp = new RegExp(/^\d*(\.\d{0,4})?$/);

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const current: string = this.el.nativeElement.value;
    if (current && !String(current).match(this.regex)) {
      this.el.nativeElement.value = current.substring(0, current.length - 1);
      event.preventDefault();
    }
  }
}
