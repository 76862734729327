<empusa-core-spinner *ngIf="loading" [overlay]="true"></empusa-core-spinner>

<div class="container">    
    <img #userPicture src="assets/img/default_user.png" class="image animated fadeIn" style="width:100%">
    <label for="file-input">
        <div class="middle">
            <div class="text">{{'PROFILE.ADDPHOTO' | translate}}</div>
        </div>
    </label>
</div>

<div>
    <h2 class="titleNew">{{'PROFILE.TITLE' | translate}}</h2>
    <h5>({{name}})</h5>
    <h5>({{currentEmail}})</h5>
    <h4>{{name_roles}}</h4>
    <h4><mat-list *ngFor="let group of groups">
            <mat-list-item>{{group.name}}</mat-list-item>
        </mat-list></h4>
    <mat-divider></mat-divider>
</div>

<div>
    <form [formGroup]="userForm">
            <mat-form-field class="formUserMan">
                <input matInput [errorStateMatcher]="matcher" formControlName="firstname"
                    placeholder="{{'PROFILE.FIRSTNAME' | translate}}" name="firstname" required
                    [ngClass]="{ 'is-invalid': f.firstname.errors }" mt-autofocus>
                <mat-error *ngIf="f.firstname.hasError('required')">
                    {{'PROFILE.REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="f.firstname.hasError('pattern')">
                    {{'PROFILE.INVALID' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="formUserMan">
                <input matInput [errorStateMatcher]="matcher" formControlName="lastname"
                    placeholder="{{'PROFILE.LASTNAME' | translate}}" name="lastname" required
                    [ngClass]="{ 'is-invalid': f.lastname.errors }" mt-autofocus>
                <mat-error *ngIf="f.lastname.hasError('required')">
                    {{'PROFILE.REQUIRED' | translate}}</mat-error>
                <mat-error *ngIf="f.lastname.hasError('pattern')">
                    {{'PROFILE.INVALID' | translate}}</mat-error>
            </mat-form-field>

            <button mat-button color="primary" (click)="changePassword()"> {{'PROFILE.RESET' | translate}}</button>
        
    </form>
</div>
<mat-divider></mat-divider>

<div  class="buttonUserMan" align="end">
    <button class="buttonConfirm" [disabled]="!(userForm.valid && userForm.touched)" mat-raised-button color="primary" (click)="onSubmit()">
        {{'PROFILE.UPDATE' | translate}}</button>
    <button class="buttonClose" mat-raised-button mat-dialog-close>
        {{'PROFILE.CANCEL' | translate}}</button>
</div>


