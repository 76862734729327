
<empusa-core-spinner *ngIf="loading" [overlay]="true"></empusa-core-spinner>
<div style="text-align:center">
  <mat-form-field style="width: 60%; margin-top: 10px;">
    <mat-label>{{ "KAM-GENERAL-DIALOG.SEARCH" | translate }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder='{{ "KAM-GENERAL-DIALOG.SEARCH" | translate }}' #input>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="addRole()" style="float: right; margin: 10px;">
    <mat-icon>playlist_add</mat-icon>
    {{'KAM-ROLES-TAB.NEW_ROLE' | translate}}
  </button>
</div>
<table mat-table [dataSource]="dataSource" multiTemplateDataRows>
  <ng-container matColumnDef="{{column}}" *ngFor="let column of
    columnsToDisplay" click)="dataReload()">
    <th mat-header-cell *matHeaderCellDef color="primary"> {{"KAM-ROLES-TAB." + (column |uppercase) |translate | uppercase}} </th>
    <td mat-cell *matCellDef="let element">
      {{element[column]}}
      <button mat-icon-button *ngIf="column == 'action'" (click)="expandedElement= expandedElement === element ? null : element; event()">
        <mat-icon>
          {{ expandedElement === element ? "expand_less" : "expand_more" }}
        </mat-icon>
      </button>
      <button  *ngIf="column == 'action'" matTooltip="Delete role" mat-icon-button (click)="deleteResource(element.id)">
        <mat-icon>delete_outline</mat-icon>
     </button>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element"
      [attr.colspan]="columnsToDisplay.length">
      <div class="role-element-detail"
        [@detailExpand]="(element == expandedElement || allRowsExpanded) ? 'expanded' : 'collapsed'">
        <div class="role-element-description" *ngIf="!element ||
          !element.permissions || element.permissions.length==0">
          {{"KAM-ROLES-TAB.NO_PERMISSIONS"|translate}}
        </div>
        <div class="role-element-description" *ngIf="element &&
          element.permissions && element.permissions.length>0">

          <div class="row">
            <div class="col-servers">
              <mat-selection-list [multiple]="false">
                <div mat-subheader>{{"KAM-ROLES-TAB.RESOURCE_SERVERS"|translate}}</div>
                <mat-list-option class="kam-resource-servers-list"  style="height: auto; margin-top: 10px;"
                  *ngFor="let permissionsInServer of element.permissions">
                  <div style="font-weight: 595;" (click)="updatePermissions(element,permissionsInServer.resourceServerId)"><mat-icon style="vertical-align: middle;">memory</mat-icon> {{permissionsInServer.resourceServerId}}</div>
                </mat-list-option>
              </mat-selection-list>
            </div>
            <div class="col-resources">
              <form [formGroup]="formGroup" *ngIf="formGroup && this.data!.resourceServerPermissions!.permissions.length > 0" style="text-align: center;">
                <mat-selection-list formControlName="permissionsList" *ngIf="ready">
                  <mat-list-option
                    style="text-align: left;"
                    color="primary"
                    *ngFor="let permission of this.data!.resourceServerPermissions!.permissions"
                    [value]="permission.id">
                    <span style="vertical-align: middle;">
                      <mat-icon style="margin-right: 10px;">settings</mat-icon>
                      {{permission.name}}
                    </span>
                  </mat-list-option>
                </mat-selection-list>
                <button mat-raised-button style="margin-top: 10px;" color="primary" (click)="accept()">{{"KAM-GENERAL-DIALOG.UPDATE"|translate}}</button>
              </form>
              <span *ngIf="formGroup && this.data!.resourceServerPermissions!.permissions.length <= 0">{{"KAM-ROLES-TAB.NO_PERMISSIONS"|translate}}</span>
            </div>
          </div>
          
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
    class="role-element-row"
    [class.role-expanded-row]="expandedElement === element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
    class="role-detail-row"></tr>
</table>
<mat-toolbar class="toolbar-bottom">
  <mat-toolbar-row>
      <mat-paginator 
          [pageSizeOptions]="[5, 10, 20, 50]" 
          [pageSize]="10" 
          [length]="usersCount"
          showFirstLastButtons 
          (page)="loadListUser($event)"
          aria-label="Seleccionar página"></mat-paginator>        
  </mat-toolbar-row>
</mat-toolbar>