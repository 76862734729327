<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<!-- MIGAS DE PAN Y TÍTULO -->
<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Comunicaciones</li>
          <!--USUARIO FINAL -->
          <li *ngIf="!isAdmComunicaciones()" class="breadcrumb-item active"><a href="#/comunications/search">Buzón de contacto</a></li>
          <!--ADM-COMUNICACIONES -->
          <li *ngIf="isAdmComunicaciones()" class="breadcrumb-item active"><a href="#/comunications/search">Búsqueda de comunicaciones</a>
          </li>
        </ol>
      </nav>
      <div class="content-title">
        <h2 class="title-pag">Comunicaciones</h2>
        <button *ngIf="!isAdmComunicaciones()" type="button" class="btn btn-primary" data-toggle="modal" (click)="abrirModalComunicacion()">
          <span class="icon-add"></span>Nueva comunicación
        </button>
      </div>
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->
<!-- CONTENIDO -->

<!--SOY ADM-COMUNICACIONES -->
<div class="container-form" *ngIf="canAccessComunicacionesFilter()">
  <!-- Formulario de búsqueda -->
  <form class="container form-search py-3 padding-form" [formGroup]="formSearch">
    <div class="row-fields">
      <div class="field field-grow">
        <label for="email">Email</label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input type="text" matInput formControlName="email" id="email" placeholder="Email" value="">
          <mat-error *ngIf="controlHasError('email', 'pattern')">Formato incorrecto</mat-error>
        </mat-form-field>
      </div>
      <div class="field field-20">
        <label for="tipo">Tipo </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="tipo" id="tipo" placeholder="-- Tipo --">
            <mat-option *ngFor="let tipo of tipos" [value]="tipo.id">
              {{ tipo.name }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('tipo')" tabindex="0" class="ng-clear-wrapper"
            (click)="clearSelection($event,'tipo')">
            <span aria-hidden="true" class="ng-clear">×</span>
          </span>
        </mat-form-field>
      </div>
      <div class="field field-20">
        <label for="estado">Estado </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="estado" id="estado" placeholder="-- Estado --">
            <mat-option *ngFor="let estado of estados" [value]="estado">
              {{ estado.descripcion }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('estado')" tabindex="0" class="ng-clear-wrapper"
            (click)="clearSelection($event,'estado')">
            <span aria-hidden="true" class="ng-clear">×</span>
          </span>
        </mat-form-field>
      </div>
      <div class="field field-20">
        <label for="prioridad">Prioridad </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="prioridad" id="prioridad" placeholder="-- Prioridad --">
            <mat-option *ngFor="let prioridad of prioridades" [value]="prioridad.id">
              {{ prioridad.name }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('prioridad')" tabindex="0" class="ng-clear-wrapper"
            (click)="clearSelection($event,'prioridad')">
            <span aria-hidden="true" class="ng-clear">×</span>
          </span>
        </mat-form-field>
      </div>
    </div>
    <div class="row-fields">
      <div class="field field-20">
        <label for="categoria">Categoría </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="categoria" id="categoria" placeholder="-- Categoría --">
            <mat-option *ngFor="let categoria of categorias" [value]="categoria.id">
              {{ categoria.name }}
            </mat-option>
          </mat-select>
          <span *ngIf="showClearButton('categoria')" tabindex="0" class="ng-clear-wrapper"
            (click)="clearSelection($event,'categoria')">
            <span aria-hidden="true" class="ng-clear">×</span>
          </span>
        </mat-form-field>
      </div>
      <div class="field field-15">
        <label for="fechaDesde">Fecha desde</label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="datepickerDesde" formControlName="fechaDesde">
          <mat-datepicker-toggle matIconSuffix [for]="datepickerDesde"></mat-datepicker-toggle>
          <mat-datepicker #datepickerDesde>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaDesde', 'matDatepickerParse')">Formato incorrecto</mat-error>
        </mat-form-field>
      </div>
      <div class="field field-15">
        <label for="fechaHasta">Fecha hasta</label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="fechaHasta" formControlName="fechaHasta">
          <mat-datepicker-toggle matIconSuffix [for]="fechaHasta"></mat-datepicker-toggle>
          <mat-datepicker #fechaHasta>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaHasta', 'matDatepickerParse')">Formato incorrecto</mat-error>

        </mat-form-field>
      </div>
      <div class="field field-grow">
        <label for="asunto">Asunto </label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input type="text" matInput formControlName="asunto" id="asunto" placeholder="Asunto" value="">
        </mat-form-field>
      </div>
    </div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" (click)="limpiar()">Limpiar</button>
      <button type="button" class="btn btn-primary" (click)="buscar()">Buscar</button>
    </div>
  </form>
  <!-- FIN Formulario de búsqueda -->
</div>
<div class="bg-second-table-info pt-3 pb-5 ">
  <div *ngIf="flagSearch; else messageClear" class="container">
    <div class="actions-table">
      <div class=""><span class="align-middle tit-table">{{totalSearch}} registros
          encontrados</span></div>
      <div class="text-right" *ngIf="canAccessComunicacionesExportar()">
        <div class="d-inline">
          <div class="dropdown show">
            <a class="btn btn-outline-primary" role="button" aria-haspopup="true" aria-expanded="false"
              (click)="exportarCsv()">
              <span>Exportar</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="card-tabla-proy">
      <div class="card-body">
        <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
          <div class="datatable-container table-responsive">

            <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (actionButton)="action($event)">
            </lib-ge-table>
            <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
              <div class="d-flex mt-2">
                <ngb-pagination [pageSize]="pageSize" [collectionSize]="totalElements" [(page)]="pageNumber"
                  (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
                </ngb-pagination>
              </div>

              <div class="mt-2 selectPage">
                <label for="itemsPerPage">Mostrar:</label>
                <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                  <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #messageClear>
    <div class="mensaje-container">
      <p class="mensaje-linea">No hay resultados</p>
    </div>
  </ng-template>
</div>


<!-- FIN CONTENIDO -->